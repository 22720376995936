<template>
    <div class="product-form">
        <FormUsers form-type="add" :detail-data="{}" @onValidateSuccess="doSave" :on-loading="onProgress" />
    </div>
</template>
<script>
import rewardService from '@/service/reward.service';

import FormUsers from "./Form.vue";

export default {
    data() {
        return {
            onProgress: false
        };
    },
    components: {
        FormUsers,
    },
    methods: {
        async doSave(payload) {
            this.onProgress = true;
            payload = {
                ...payload.reward
            };
            // payload.expiredDate = `${payload.expiredDate} 00:00:00`
            rewardService
                .postReward(payload)
                .then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: "/reward/list" });
                    });
                })
                .catch((e) => {
                    this.$swal({
                        icon: "error",
                        title: "Error Notification",
                        text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                    });
                })
                .finally(() => (this.onProgress = false));
        },
    },
};
</script>