<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div class="login-cover-img"></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center justify-content-center">
              <img src="/assets/img/logo/generali-logo.png" alt="Generali" />
              &nbsp; <b>Generali</b> &nbsp;CMS
            </div>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <div class="login-content">
          <form @submit.prevent="handleLogin()">
            <div class="form-floating">
              <input
                type="text"
                class="form-control fs-13px h-45px border-0"
                placeholder="Username"
                v-model="login.username"
                id="username"
              />
              <label
                for="username"
                class="d-flex align-items-center text-gray-600 fs-13px"
                >Username</label
              >
            </div>
            <span
              class="text-danger"
              v-if="!$v.login.username.required && $v.login.username.$error"
              >Username is required</span
            >
            <span
              class="text-danger"
              v-if="!$v.login.username.email && $v.login.username.$error"
              >Username invalid</span
            >
            <div class="form-floating mt-20px">
              <input
                :type="passwordTypeField"
                v-model="login.password"
                class="form-control fs-13px h-45px border-0"
                placeholder="Password"
              />
              <label
                for="emailAddress"
                class="d-flex align-items-center text-gray-600 fs-13px"
                >Password</label
              >
              <span class="btn btn-toggle-password"
                ><i @click="showPassword" :class="passwordClass"></i
              ></span>
            </div>
            <span
              class="text-danger"
              v-if="!$v.login.password.required && $v.login.password.$error"
              >Password is required</span
            >
            <div class="mt-20px">
              <button
                type="submit"
                :class="{ disabled: inProgress }"
                class="btn btn-primary d-block w-100 h-45px btn-lg"
              >
                <span
                  v-show="inProgress"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Log in
              </button>
            </div>
          </form>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->
  </div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
import { required } from "vuelidate/lib/validators/";

export default {
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  data() {
    return {
      inProgress: false,
      login: {
        username: "",
        password: "",
      },
      authDriver: null,
      passwordTypeField: "password",
      passwordClass: "fas fa-eye",
      headerAlert: "Login",
      serverErrorFields: false,
      errorFields: {},
    };
  },
  mounted() {
    // Check if already logged in
    this.$store.dispatch("auth/checkLogin").then((isLogin) => {
      if (isLogin) {
        // clear authentication
        if (this.$store.state.auth?.token) {
          this.$router.push("/dashboard");
          //this.$store.commit("auth/token", "dummy-token");
        }else{
          return this.$store.dispatch("auth/logout")
        }
      }
    });
  },
  methods: {
    showPassword() {
      this.passwordTypeField =
        this.passwordTypeField === "password" ? "text" : "password";
      this.passwordClass =
        this.passwordTypeField === "password"
          ? "fas fa-eye"
          : "fas fa-eye-slash";
    },
    handleLogin() {
      this.$v.login.$touch();
      if (this.$v.login.$invalid) return;
      this.inProgress = true;
      this.$store
        .dispatch("auth/login", this.login)
        .then(
          () => {
            // this.$router.push("/dashboard");
            this.$router.go(0);
            console.log("Logout OK");
          },
          (error) => {
            if (error.response?.data?.errorFields) {
              this.serverErrorFields = true;
              this.errorFields = error.response.data.errorFields;
            } else if (error.response?.data?.responseMessage) {
              this.$swal({
                icon: "warning",
                title: "Failed",
                text: error.response.data.responseMessage,
              });
            }
          }
        )
        .finally(() => (this.inProgress = false));
    },
  },
  validations: {
    login: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
};
</script>
