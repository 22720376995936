import axios from "axios";
import qs from "qs"

const apim = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-Type": "multipart/form-data",
    },
    paramsSerializer: (params) => qs.stringify(params)
    // paramsSerializer: (params) => qs.stringify(params, {
    //     arrayFormat: 'repeat'
    // })
});

apim.create = (configOrServiceName) => {
   
    if (typeof configOrServiceName == 'string') {
        //ternary if kalau pakai backend local
        let baseURL = (process.env['VUE_APP_' + configOrServiceName.toUpperCase() + '_SERVICE']) ?
            process.env['VUE_APP_' + configOrServiceName.toUpperCase() + '_SERVICE'] :
            process.env.VUE_APP_API + '/' + configOrServiceName;

        configOrServiceName = { baseURL, service: configOrServiceName }
    }


    /** @ts-ignore @type {import("axios").AxiosRequestConfig} */
    const config = { ...apim.defaults, ...configOrServiceName }

    /**
     * @type {ApiAxiosInstance}
     */
    const apiService = axios.create(config)
    apiService.defaults.headers = apim.defaults.headers
    // @ts-ignore
    // apiService.interceptors.request.handlers = api.interceptors.request.handlers
    // // @ts-ignore
    // apiService.interceptors.response.handlers = api.interceptors.response.handlers
    return apiService
}


export default apim;