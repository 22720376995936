<template>
  <div>
    <h1 class="page-header"><span v-if="isEdit">Edit</span> Bank</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <div class="row" v-if="$can($permissionsAll[68])">
              <div class="col-md-5">
                <div class="mb-2">
                  <label for="formFile" class="form-label">Import Bank Data</label>
                  <input class="form-control" ref="file" type="file" id="formFile"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    v-on:change="handleFileUpload()" />
                </div>
              </div>
            </div>
            <form @submit.prevent="doSave">
              <div class="row align-items-end">
                <div class="col">
                  <label class="form-label"><span v-if="!isEdit">Add</span><span v-else>Edit</span> Bank Name</label>
                  <input type="text" class="form-control" placeholder="Add New Bank Name" v-model="name" />
                </div>
                <div class="col">
                  <label class="form-label">Bank Code</label>
                  <input type="text" class="form-control" placeholder="Add New Bank Code" v-model="code" />
                </div>
                <div v-if="isEdit && $can($permissionsAll[64])" class="col-auto">
                  <button type="submit" :class="{ disabled: isLoading }" class="btn btn-outline-primary">
                    <span>Save</span>
                    <span v-show="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  </button>
                </div>
                <div v-else-if="isUpload && $can($permissionsAll[68])" class="col-auto">
                  <button type="submit" :class="{ disabled: isLoading }" class="btn btn-outline-primary">
                    <span>Import</span>
                    <span v-show="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  </button>
                </div>
                <div v-else-if="$can($permissionsAll[65])" class="col-auto">
                  <button type="submit" :class="{ disabled: isLoading }" class="btn btn-outline-primary">
                    <span>Add</span>
                    <span v-show="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  </button>
                </div>

                <div class="col-auto">
                  <button type="button" class="btn btn-outline-primary" :disabled="!isEdit && !isUpload"
                    @click.prevent="onCancel()">
                    Cancel
                  </button>
                </div>
              </div>
              <br v-if="$v.name.$error" />
              <span class="text-danger" v-if="$v.name.$error">Bank Name required</span>
              <br />
              <br v-if="$v.code.$error" />
              <span class="text-danger" v-if="$v.code.$error">Bank Code required</span>
            </form>
          </div>
        </div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
      enabled: true,
      dropdownAllowAll: false,
    }" :columns="columns" :rows="rows" :sort-options="{
      enabled: false,
      initialSortBy: { field: 'name', type: 'asc' },
    }" styleClass="vgt-table" slot="outsideBody">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <div class="row g-2 align-items-center justify-content-end">
                <div class="col-auto" v-if="$can($permissionsAll[64])">
                  <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)" v-b-tooltip.hover
                    title="Edit">
                    <i class="fas fa-pencil"></i>
                  </button>
                </div>
                <div class="col-auto" v-if="$can($permissionsAll[66])">
                  <button type="button" @click.prevent="showAlertConfirm(props.row.id)" class="btn btn-lg text-dark"
                    v-b-tooltip.hover title="Delete">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import bankService from "../../service/bank.service";
import { required, minLength, between } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: "",
      name: "",
      code: "",
      isEdit: false,
      isUpload: false,
      isLoading: false,
      file: "",
      columns: [
        {
          label: "Bank Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Bank Code",
          field: "code",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "name",
            type: "ASC",
          },
          {
            field: "lastUpdated",
            type: "ASC",
          },
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      optionParent: [],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleFileUpload() {
      this.isUpload = true;
      this.isEdit = false;
      this.name = "";
      this.code = "";
      this.$v.name.$reset();
      this.$v.code.$reset();
      this.file = this.$refs.file.files[0];
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: "name",
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      bankService
        .getMasterBankList(this.setPaginationParams())
        .then(async (rsp) => {
          let arrData = rsp.data?.data ? rsp.data?.data.content : [];
          this.rows = arrData;
          this.totalRecords = rsp.data?.data ? rsp.data?.data.totalElements : 0;
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    setPaginationParams() {
      let body = {
        searchField: "",
        searchValue: "",
        pageNumber: this.serverParams.page - 1,
        pageSize: this.serverParams.perPage,
      };
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters.hasOwnProperty(key)) {
          if (this.serverParams.columnFilters[key] !== "") {
            if (key === "name") {
              body.name = this.serverParams.columnFilters[key];
            }
            if (key === "code") {
              body.code = this.serverParams.columnFilters[key];
            }
          }
        }
      }
      return body;
    },
    doSave() {
      this.isLoading = true;
      if (this.isUpload) {
        let formData = new FormData();
        formData.append("file", this.file);
        bankService
          .importBankMaster(formData)
          .then((res) => {
            console.log(res);
            this.$notify({
              type: "succes",
              title: "Succes Notification",
              text: res.data?.responseMessage,
            });
            this.loadItems();
          })
          .catch((err) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: err.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          })
          .finally(() => {
            this.isEdit = false;
            this.isUpload = false;
            this.isLoading = false;
            this.$refs.file.value = null;
          });
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.isEdit) {
            bankService
              .putBankMaster(this.id, {
                id: this.id,
                name: this.name,
                code: this.code,
              })
              .then((rsp) => {
                this.$notify({
                  type: "succes",
                  title: "Succes Notification",
                  text: "Success Update",
                });
                this.$v.name.$reset();
                this.$v.code.$reset();
                this.loadItems();
                this.code = "";
                this.name = "";
                this.id = "";
              })
              .catch((e) => {
                this.$notify({
                  type: "error",
                  title: "Error Message",
                  text:
                    e.response?.data?.responseMessage || "Terjadi Kesalahan",
                });
              })
              .finally(() => {
                this.isEdit = false;
                this.isLoading = false;
              });
          } else {
            bankService
              .postBankMaster({
                name: this.name,
                code: this.code,
              })
              .then((rsp) => {
                this.$notify({
                  type: "succes",
                  title: "Succes Notification",
                  text: "Success Add",
                });
                this.$v.name.$reset();
                this.$v.code.$reset();
                this.loadItems();
                this.code = "";
                this.name = "";
                this.id = "";
              })
              .catch((e) => {
                this.$notify({
                  type: "error",
                  title: "Error Message",
                  text:
                    e.response?.data?.responseMessage || "Terjadi Kesalahan",
                });
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        }
      }
    },

    async doEdit(data) {
      this.isEdit = true;
      this.isUpload = false;
      this.code = data.code;
      this.name = data.name;
      this.id = data.id;
    },

    onDelete(id) {
      bankService.deleteBankMasterById(id).then((rsp) => {
        this.$notify({
          type: "succes",
          title: "Success message",
          text: "Delete Success",
        });
        this.loadItems();
      }).catch((e) => {
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
        });
        this.loadItems();
      });
    },
    onCancel() {
      this.isEdit = false;
      this.isUpload = false;
      this.code = "";
      this.name = "";
      this.id = "";
      this.file = "";
      this.$v.name.$reset();
      this.$v.code.$reset();
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },
  },
  mounted() { },

  validations: {
    name: { required },
    code: { required },
  },
};
</script>

<style lang="scss" scoped>
.document-logo {
  height: auto;
  width: 100px;
}

.form-check-label {
  height: 50px;
}

.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
