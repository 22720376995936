<template>
  <div class="product-form">
    <FormBanner form-type="edit" :detail-data="detail" :on-loading="onProgress" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormBanner from "./Form.vue";
import BannerService from "../../../service/banner.service";
import ConvertToBase64 from "@/pages/Helper/base64";
import bannerService from '../../../service/banner.service';

export default {
  data() {
    return {
      onProgress: false,
      detail: {
        id: this.$route.params.id,
        title: "Amore Health Plan 500",
        description: "News",
        images: {
          desktop: null,
          mobile: null,
        },
      },
    };
  },
  components: {
    FormBanner,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      // console.log(payload, 'payload');
      this.onProgress = true
      let { banner, status } = payload
      if (banner) {
        let { cta } = banner

        payload = {
          id: banner.id,
          title: banner.title,
          description: banner.description,
          imageUrlMainBase64: banner.images.desktop.length != 0 ? await ConvertToBase64(banner.images.desktop[0]) : null,
          imageUrlMobileBase64: banner.images.mobile.length != 0 ? await ConvertToBase64(banner.images.mobile[0]) : null,
          ctaUrl1: cta.url1,
          ctaText1: cta.text1,
          ctaUrl2: cta.url2,
          ctaText2: cta.text2,
          status: status
        }
        BannerService
          .putBanner(banner.id, payload)
          .then((rsp) => {
            this.onProgress = false
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              if (status === "PUBLISHED") {
                this.$router.push({ path: '/sections/banner' })
              } else if (status === "DRAFTS") {
                this.$router.push({ path: '/sections/banner/drafts' })
              }
            });
          }).catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          })
      }
    },
    loadItems(id) {
      BannerService
        .getBannerById(id)
        .then(async (rsp) => {
          // console.log(rsp)
          this.detail = {
            id: this.$route.params.id,
            title: rsp.data.data.title,
            description: rsp.data.data.description,
            cta: {
              text1: rsp.data.data.ctaText1,
              url1: rsp.data.data.ctaUrl1,
              text2: rsp.data.data.ctaText2,
              url2: rsp.data.data.ctaUrl2,
            },
            images: {
              desktop: [],
              mobile: [],
              desktopSrc: rsp.data.data.imageUrlMainFileName ? bannerService.getFileUrl(rsp.data.data.imageUrlMainFileName) : '',
              mobileSrc: rsp.data.data.imageUrlMobileFileName ? bannerService.getFileUrl(rsp.data.data.imageUrlMobileFileName) : ''
            },
          }
        }).catch((e) => {
          console.error(e)
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
  async mounted() {
    await this.loadItems(this.$route.params.id)
  },
};
</script>
