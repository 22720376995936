import api from "./api";

class RiplayInformationService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }

    getRiplayInformationServiceList(serverParams) {
        return this.api.get("/product/riplay-information", { params: serverParams });
    }

    postRiplayInformationService(serverParams) {
        return this.api.post("/product/riplay-information", { ...serverParams });
    }

    getRiplayInformationServiceById(serverParams) {
        return this.api.get("/product/riplay-information/" + serverParams);
    }

    putRiplayInformationService(id, serverParams) {
        return this.api.put("/product/riplay-information/" + id, { ...serverParams });
    }

    deleteRiplayInformationServiceById(serverParams) {
        return this.api.delete("/delete/riplay-information/" + serverParams);
    }
}

export default new RiplayInformationService();