import api from "./api";

class PageService {
  /** @type {ApiAxiosInstance} */
  api;
  apiChecking;

  constructor() {
    this.api = api.create("web");
  }

  getFileUrl(name) {
    return this.api.getUrl("/file/" + name)
  }

  getPageLists(serverParams) {
    return this.api.get("cms/page", { params: serverParams });
  }
  createPage(page) {
    return this.api.post("cms/page", page).then((response) => {
      return response.data;
    });
  }
  previewPage(page) {
    return this.api.post("cms/page/preview", page).then((response) => {
      return response.data;
    });
  }
  getSinglePage(id) {
    return this.api.get(`cms/page/${id}`);
  }

  deletePage(id) {
    return this.api.delete(`cms/delete/page/${id}`);
  }

  updatePage(id, data) {
    return this.api.put(`cms/page/${id}`, data);
  }

  changePageStatus(id, data) {
    return this.api.put(`cms/update/page-status/${id}?status=${data}`);
  }

  checkSlug(serverParams) {
    return this.api.get("/check/slug", { params: serverParams });
  }

  duplicatePage(id) {
    return this.api.post(`cms/page/duplicate?id=${id}`);
  }

  generateHrefLink(link) {
    // Check is absolute url
    if (link.match(/^https?:\/\/.+/)) {
      return link;
    } else {
      return env.APP_FRONTEND + '/' + link.replace(/^\/+/, '')
    }
  }

  getHomepage() {
    return this.api.get(`cms/page/homepage`);
  }

  pageLoadImage(name) {
    return this.api.get("/cms/page/view/" + name);
  }
}

export default new PageService();
