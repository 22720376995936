import MenuService from "../service/menu.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListMenu(_ctx, serverParams) {
            return MenuService.getMenuList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postMenu(_ctx, serverParams) {
            return MenuService.postMenu(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getMenuById(_ctx, serverParams) {
            return MenuService.getMenuById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateMenuById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return MenuService.putMenu(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteMenuById(_ctx, serverParams) {
            return MenuService.deleteMenuById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
