import WeightContributionService from "../service/weightcontribution.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListWeightContribution(_ctx, serverParams) {
            return WeightContributionService.getWeightContributionList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postWeightContribution(_ctx, serverParams) {
            return WeightContributionService.postWeightContribution(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getWeightContributionById(_ctx, serverParams) {
            return WeightContributionService.getWeightContributionById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateWeightContributionById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return WeightContributionService.putWeightContribution(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteWeightContributionById(_ctx, serverParams) {
            return WeightContributionService.deleteWeightContributionById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
