<template>
  <div class="product-form">
    <FormProduct form-type="edit" :detail-data="product" :on-loading="onProgress" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import productService from "@/service/product.service";
import FormProduct from "./Form.vue";
export default {
  data() {
    return {
      onProgress: false,
      product: {
        code: "",
        name: "",
        category: "",
        benefit: "",
        insurance: "",
        description: "",
        riplay: "",
        contributions: [],
        configurations: [],
        benefits: [],
      },
    };
  },
  components: {
    FormProduct,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      this.onProgress = true

      // console.log(payload, 'test')
      if (payload) {
        let { product, status } = payload;
        let premis = [], ekstra = []
        for (const [k, v] of Object.entries(product.configurations)) {
          premis.push({
            ...v,
            innerLimit: Number(v.innerLimit.replace(/\D/g, ''))
          })
        }

        for (const [k, v] of Object.entries(product.ekstra)) {
          ekstra.push({ id: v.item.id })
        }

        let payloadData = {
          name: product.name,
          productCategory: product.category,
          description: product.description,
          riplayInformation: product.riplay,
          contributionDetails: product.contributions,
          cashlessFacility: product.cashlessFacility,
          annualLimit: product.annualLimit,
          roomBoard: product.roomBoard,
          kelasKamar: product.kelasKamar,
          kamarPerawatan: product.kamarPerawatan,
          biayaDokterUmum: product.biayaDokterUmum,
          biayaDokterSpesialis: product.biayaDokterSpesialis,
          biayaLainnya: product.biayaLainnya,
          pembedahanPulangHari: product.pembedahanPulangHari,
          rawatJalan: product.rawatJalan,
          displayOrder: product.displayOrder,
          extraAddons: ekstra,
          productConfiguration: {
            code: product.code,
            epolicy: product.ePolicy,
            benefitLimit: product.benefit,
            sumInsurance: product.sumInsurance,
            coInsurance: product.insurance,
            wakaf: product.wakaf,
            coveragePeriod: product.coverage,
          },
          premis: premis,
          benefits: product.benefits,
          status
        };

        productService
          .putProduct(product.id, payloadData)
          .then((rsp) => {
            this.onProgress = false
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              if (status === "PUBLISHED") {
                this.$router.push("/products/list");
              } else if (status === "DRAFTS") {
                this.$router.push("/products/list/drafts");
              }
            });
          })
          .catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          });
      }
    },
  },
  async mounted() {
    // await this.loadItems(this.$route.params.id)
  },
};
</script>
