<template>
    <form @submit.prevent="doSave" class="pb-5 mb-4" autocomplete="off">
        <div class="row">
            <div class="col-12">
                <h1 class="page-header">{{ formTitle }} Referral</h1>
            </div>
            <div class="col-lg-9 col-md-8">
                <div class="mb-15px">
                    <input type="text" class="form-control form-control-lg" placeholder="Nama" v-model="referral.name"
                        name="username" autocomplete="off" value="" />
                    <span class="text-danger" v-if="$v.referral.name.$error">Nama is required</span>
                </div>
            </div>
        </div>
        <div class="panel col-lg-9">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Referral Code</label>
                        <input type="text" class="form-control" placeholder="Referral Code" v-model="referral.referralCode"
                            name="username" autocomplete="off" value="" maxlength="10" />
                        <span class="text-danger" v-if="$v.referral.referralCode.$error">Referral Code is required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Partner</label>
                        <input type="text" class="form-control" placeholder="Partner" v-model="referral.partner" />
                        <span class="text-danger" v-if="$v.referral.partner.$error">Partner is required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Cabang Partner</label>
                        <input type="text" class="form-control" placeholder="Cabang Partner"
                            v-model="referral.cabangPartner" />
                        <span class="text-danger" v-if="$v.referral.cabangPartner.$error">Cabang Partner is required</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Status</label>
                        <div class="pt-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="status" id="status1" value="ACTIVE"
                                    v-model="referral.status" />
                                <label class="form-check-label" for="">Active</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="status" id="status2" value="INACTIVE"
                                    v-model="referral.status" />
                                <label class="form-check-label" for="">Not Active</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-action">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
                        <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>
  
<script>
import { required, minLength, between } from "vuelidate/lib/validators";
export default {
    props: {
        formType: {
            type: String,
            default: "add",
        },
        detailData: {
            type: Object,
            default: null,
        },
        onLoading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
    },
    data() {
        return {
            referral: {
                name: null,
                referralCode: null,
                partner: null,
                cabangPartner: null
            }
        };
    },

    watch: {
        detailData: {
            immediate: true,
            handler(newVal) {
                this.referral = newVal
            },
        },
    },
    computed: {
        formTitle() {
            return this.formType === "add" ? "Add New" : "Edit";
        },
    },
    methods: {
        doSave() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const payload = {
                    referral: this.referral
                };

                this.$emit("onValidateSuccess", payload);
            }
        },
    },
    validations: {
        referral: {
            name: { required },
            referralCode: { required },
            partner: { required },
            cabangPartner: { required },
            status: { required }
        }
    },
};
</script>
  