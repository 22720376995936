import ManagePdfService from "../service/managepdf.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListManagePdf(_ctx, serverParams) {
            return ManagePdfService.getManagePdfList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postManagePdf(_ctx, serverParams) {
            return ManagePdfService.postManagePdf(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getManagePdfById(_ctx, serverParams) {
            return ManagePdfService.getManagePdfById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateManagePdfById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return ManagePdfService.putManagePdf(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteManagePdfById(_ctx, serverParams) {
            return ManagePdfService.deleteManagePdfById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
