<template>
  <div>
    <h1 class="page-header"><span v-if="isEdit">Edit</span> Post Category</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <form @submit.prevent="doSave">
              <div class="row align-items-end">
                <div class="col">
                  <label class="form-label"><span v-if="!isEdit">Add New</span><span v-else>Edit</span> Post
                    Category</label>
                  <input type="text" class="form-control" placeholder="Add New Category Name" v-model="category" />
                </div>
                <div v-if="!isEdit && $can($permissionsAll[80])" class="col-auto">
                  <button type="submit" class="btn btn-outline-primary">
                    <span>Add</span>
                  </button>
                </div>
                <div v-else-if="$can($permissionsAll[81]) && isEdit" class="col-auto">
                  <button type="submit" class="btn btn-outline-primary">
                    <span>Save</span>
                  </button>
                </div>

                <div class="col-auto">
                  <button type="button" class="btn btn-outline-primary" :disabled="!isEdit" @click.prevent="onCancel()">
                    Cancel
                  </button>
                </div>
              </div>

            </form>
          </div>
        </div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
          }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <input ref="categoryName" class="form-control" placeholder="Search category" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'name'
              " @input="(value) => updateFilters(column, $refs.categoryName.value)" />
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <div class="row g-2 align-items-center justify-content-end">
                <div class="col-auto" v-if="$can($permissionsAll[81])">
                  <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)" v-b-tooltip.hover
                    title="Edit">
                    <i class="fas fa-pencil"></i>
                  </button>
                </div>
                <div class="col-auto" v-if="$can($permissionsAll[78])">
                  <button type="button" class="btn btn-lg text-dark" @click="showAlertConfirm(props.row)"
                    v-b-tooltip.hover title="Delete">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import postCategoryService from "@/service/postcategory.service";
export default {
  data() {
    return {
      editData: "",
      category: "",
      isEdit: false,
      isLoading: false,
      columns: [
        {
          label: "Category Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "name",
            type: "ASC",
          },
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {

      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      // this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      let filterparam = this.serverParams;
      // console.log(filterparam);
      let rowsData = []
      let paramsGetData = {
        "searchField": filterparam.columnFilters.name != '' ? "name" : "",
        "searchValue": filterparam.columnFilters.name,
        "sort": "created",
        "direction": "ASC",
        "pageNumber": filterparam.page - 1,
        "pageSize": filterparam.perPage
      }
      postCategoryService.getArticleCategoryList(paramsGetData).then((rsp) => {
        this.rows = rsp.data?.data.content
        this.totalRecords = rsp.data?.data.totalElements
      })
      this.isLoading = false
    },
    doSave(payload) {
      let category = this.category;
      let currentDate = new Date().getTime();
      let catcode = 'cat-' + Math.floor(Math.random() * (999 - 100 + 1) + 100);
      payload = {
        // "id": this.isEdit ? this.editData.id : "",
        "createdBy": this.isEdit ? this.editData.createdBy : "",
        "updatedBy": "",
        "created": this.isEdit ? this.editData.created : "",
        "updated": "",
        "deleted": 0,
        "recordStatus": this.isEdit ? this.editData.recordStatus : "ACTIVE",
        "code": this.isEdit ? this.editData.code : catcode,
        "name": category
      }

      var apiurl = '';
      if (this.isEdit) {
        postCategoryService.putArticleCategory(this.editData.id, payload).then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes!',
            text: 'Berhasil merubah data'
          });
          this.category = "";
          this.loadItems();
        }).catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: 'Gagal merubah data'
          });
          this.category = "";
          this.loadItems();
        })

      } else {
        postCategoryService.postArticleCategory(payload).then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes!',
            text: 'Berhasil menambah data'
          });
          this.category = "";
          this.loadItems();
        }).catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: 'Gagal menambah data'
          });
          this.category = "";
          this.loadItems();
        })
      }
    },

    doEdit(data) {
      let idCat = data.id
      this.editData = data
      this.isEdit = true
      this.category = data.name
    },
    doDelete(data) {
      let id_cat = data.id;
      this.$store.dispatch("postcategory/deleteArticleCategoryById", id_cat).then((rsp) => {
        if (rsp.status == 200) {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.loadItems();
        } else {
          this.$notify({
            type: "error",
            title: "Error message",
            text: "Delete Error",
          });
          this.loadItems();
        }
      });
    },
    onCancel() {
      this.isEdit = false
      this.category = ''
      this.editData = ''
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
