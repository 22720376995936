export const Permissions = {
    0: "View Dashboard",
    1: "Bussines Analytic",
    2: "Performance Metric",
    3: "Drop-off Issue",
    4: "View List Submission",
    5: "Add Submission",
    6: "Update Submission",
    7: "Delete Submission",
    8: "Add Product",
    9: "Edit Product",
    10: "Delete Products",
    11: "View List Product",
    12: "Import Product",
    13: "Product Category",
    14: "Add Products Category",
    15: "Edit Products Category",
    16: "Delete Products Category",
    17: "Product Configuration",
    18: "View List Hospital",
    19: "Add Hospital",
    20: "Edit Hospital",
    21: "Delete Hospital",
    22: "Import Hospital",
    23: "Add Hospital Tier",
    24: "Edit Hospital Tier",
    25: "Delete Hospital Tier",
    26: "Hospital Tier",
    27: "Hospital Group",
    28: "Add Hospital Groups",
    29: "Edit Hospital Groups",
    30: "Delete Hospital Groups",
    31: "Hospital Area",
    32: "Add Hospital Area",
    33: "Edit Hospital Area",
    34: "Delete Hospital Area",
    35: "Edit Homepage",
    36: "Homepage",
    37: "All Page",
    38: "Add Page",
    39: "Edit Page",
    40: "Add Faq",
    41: "Edit Faq",
    42: "FAQ",
    43: "Delete Faq",
    44: "FAQ Category",
    45: "Add Faq Category",
    46: "Edit Faq Category",
    47: "Delete Faq Category",
    48: "Menu",
    49: "Banner",
    50: "Add Banner",
    51: "Edit Banner",
    52: "Delete Banner",
    53: "Add SIO Questionnaire",
    54: "Edit SIO Questionnaire",
    55: "Delete SIO Questionnaire",
    56: "SIO Questionnaire",
    57: "Charity Organizations",
    58: "Add Charity Organizations",
    59: "Edit Charity Organizations",
    60: "Delete Charity Organizations",
    61: "Upload Manage PDF",
    62: "Delete Manage PDF",
    63: "Manage PDF",
    64: "Edit Manage Bank",
    65: "Add Manage Bank",
    66: "Delete Manage Bank",
    67: "Manage Bank",
    68: "Import Manage Bank",
    69: "Import Referral",
    70: "Add Referral",
    71: "Edit Referral",
    72: "Delete Referral",
    73: "View List Referral",
    74: "View List Posts",
    75: "Add Posts",
    76: "Edit Posts",
    77: "Delete Posts",
    78: "Delete Posts Category",
    79: "Post Category",
    80: "Add Posts Category",
    81: "Edit Posts Category",
    82: "Edit SMS Notifications",
    83: "Add SMS Notifications",
    84: "Delete SMS Notifications",
    85: "SMS",
    86: "Email",
    87: "Add Email Notifications",
    88: "Edit Email Notifications",
    89: "Delete Email Notifications",
    90: "Manage User",
    91: "Manage Role",
    92: "Activity Report",
    93: "Delete Pages",
    94: "Master BMI",
    95: "Add Master BMI",
    96: "Edit Master BMI",
    97: "Delete Master BMI",
    98: "View List Redeem",
    99: "Add Redeem",
    100: "List Pop up",
    101: "Add Pop up",
    102: "View List Reward",
    103: "Add Reward",
    
    // // Submissions
    // "Update Submission": 1,
    // "Delete Submission": 2,
    // // Product
    // "Add Product": 3,
    // "Update Product": 4,
    // "Delete Product": 5,
    // "Import Product": 6,
    // // Product Category
    // "Add Product Category": 7,
    // "Update Product Category": 8,
    // "Delete Product Category": 9,
    // //  Hospital
    // "Add Hospital": 10,
    // "Update Hospital": 11,
    // "Delete Hospital": 12,
    // "Import Hospital": 13,
    // // Hospital Category
    // "Add Hospital Category": 14,
    // "Update Hospital Category": 15,
    // "Delete Hospital Category": 16,
    // // Hospital Group
    // "Add Hospital Group": 17,
    // "Update Hospital Group": 18,
    // "Delete Hospital Group": 19,
    // // Manage Website
    // "Manage Microsite": 20,
    // "Manage User": 21,
}

// Object.entries(Permissions).forEach(([name, value]) => Permissions[value] = name)

export default Permissions