// const CAPTCHA_SITE_KEY = process.env.VUE_APP_CAPTCHA_SITE_KEY
// const DOWNLOAD_LIMIT = parseInt(process.env.VUE_APP_DOWNLOAD_LIMIT || '1000')
// const NAME = process.env.VUE_APP_NAME || "n/a"
// const DESCRIPTION = process.env.VUE_APP_DESCRIPTION || "n/a"
// const VERSION = process.env.VUE_APP_VERSION || "n/a"
// const VERSION_MAJOR = process.env.VUE_APP_VERSION_MAJOR || "n/a"
// const GIT_BRANCH = process.env.VUE_APP_GIT_BRANCH || "n/a"
// const GIT_COMMIT_INFO = process.env.VUE_APP_GIT_COMMIT_INFO || "n/a"
// const GIT_COMMIT_DATE = process.env.VUE_APP_GIT_COMMIT_DATE || "n/a"
const BASE_URL = process.env.VUE_APP_BASE_URL

const AUTH_SERVICE = process.env.VUE_APP_AUTH_SERVICE  || `${BASE_URL}/auth`
const PRODUCT_SERVICE = process.env.VUE_APP_PRODUCT_SERVICE  || `${BASE_URL}/product`
const REGISTRATION_SERVICE = process.env.VUE_APP_REGISTRATION_SERVICE  || `${BASE_URL}/registration`
const WEB_SERVICE = process.env.VUE_APP_WEB_SERVICE  || `${BASE_URL}/web`
const HOSPITAL_SERVICE = process.env.VUE_APP_HOSPITAL_SERVICE  || `${BASE_URL}/hospital`
const ACTIVITY_SERVICE = process.env.VUE_APP_ACTIVITY_SERVICE  || `${BASE_URL}/activity`

const APP_FRONTEND = (process.env.VUE_APP_FRONTEND || 'https://amore.generali.co.id').replace(/\/+$/,'')

const env = window.env = {
    BASE_URL,
    AUTH_SERVICE,
    PRODUCT_SERVICE,
    REGISTRATION_SERVICE,
    WEB_SERVICE,
    HOSPITAL_SERVICE,
    ACTIVITY_SERVICE,
    APP_FRONTEND
}

export const Env = {
    /**
     * @type {import("vue").PluginFunction<any>}
     */
    install(vue) {
        vue.prototype.$env = env
    }
};

export default env
