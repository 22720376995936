var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"w-300px mx-auto mt-50px"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Buat password")]),_c('div',{staticClass:"input-password",class:{
          'is-invalid': _vm.$v.password.$invalid,
          'is-valid': !_vm.$v.password.$invalid,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.$model),expression:"$v.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.password.$invalid,
            'is-valid': !_vm.$v.password.$invalid,
          },attrs:{"type":_vm.passwordFieldType},domProps:{"value":(_vm.$v.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"btn btn-toggle-password"},[_c('i',{staticClass:"fas fa-eye",on:{"click":_vm.showPassword}})])]),_c('strong',{staticClass:"pwd-length",class:{
          'invalid-feedback': !_vm.$v.password.required || !_vm.$v.password.minLength,
          'valid-feedback': _vm.$v.password.required && _vm.$v.password.minLength,
        }},[_vm._v("Minimum 8 karakter")]),_c('strong',{staticClass:"pwd-uppercase",class:{
          'invalid-feedback':
            !_vm.$v.password.required || !_vm.$v.password.pasMinOneUpp,
          'valid-feedback': _vm.$v.password.required && _vm.$v.password.pasMinOneUpp,
        }},[_vm._v("Minimum 1 huruf kapital")]),_c('strong',{staticClass:"pwd-lowercase",class:{
          'invalid-feedback':
            !_vm.$v.password.required || !_vm.$v.password.pasMinOneLow,
          'valid-feedback': _vm.$v.password.required && _vm.$v.password.pasMinOneLow,
        }},[_vm._v("Minimum 1 huruf kecil")]),_c('strong',{staticClass:"pwd-number",class:{
          'invalid-feedback':
            !_vm.$v.password.required || !_vm.$v.password.pasMinOneNum,
          'valid-feedback': _vm.$v.password.required && _vm.$v.password.pasMinOneNum,
        }},[_vm._v("Minimum 1 angka")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Ketik ulang password")]),_c('div',{staticClass:"input-password",class:{
          'is-invalid': _vm.$v.retypePassword.$invalid,
          'is-valid': !_vm.$v.retypePassword.$invalid,
        }},[((_vm.retypeFieldType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.retypePassword),expression:"retypePassword"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.retypePassword.$invalid,
            'is-valid': !_vm.$v.retypePassword.$invalid,
          },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.retypePassword)?_vm._i(_vm.retypePassword,null)>-1:(_vm.retypePassword)},on:{"change":function($event){var $$a=_vm.retypePassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.retypePassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.retypePassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.retypePassword=$$c}}}}):((_vm.retypeFieldType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.retypePassword),expression:"retypePassword"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.retypePassword.$invalid,
            'is-valid': !_vm.$v.retypePassword.$invalid,
          },attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.retypePassword,null)},on:{"change":function($event){_vm.retypePassword=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.retypePassword),expression:"retypePassword"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.retypePassword.$invalid,
            'is-valid': !_vm.$v.retypePassword.$invalid,
          },attrs:{"type":_vm.retypeFieldType},domProps:{"value":(_vm.retypePassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.retypePassword=$event.target.value}}}),_c('span',{staticClass:"btn btn-toggle-password"},[_c('i',{staticClass:"fas fa-eye",on:{"click":_vm.showRetypePassword}})])]),_c('strong',{staticClass:"pwd-notMatch",class:{
          'invalid-feedback':
            !_vm.$v.retypePassword.required || !_vm.$v.retypePassword.sameAsPassword,
          'valid-feedback':
            _vm.$v.retypePassword.required && _vm.$v.retypePassword.sameAsPassword,
        }},[_vm._v("Password tidak sama")])]),_c('button',{staticClass:"btn btn-primary d-block w-100",class:{
        disabled: _vm.$v.retypePassword.$invalid,
      },on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" Buat password ")])]),_c('b-modal',{attrs:{"id":"modalLupaPasswordSukses","centered":"","no-close-on-backdrop":"","content-class":"modal-question"}},[_c('template',{slot:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Reset Password")])]),_c('p',[_vm._v(_vm._s(_vm.alertMessage))]),(!_vm.serverError)?_c('p',[_vm._v("Silakan login dengan email dan password baru")]):_vm._e(),(!_vm.serverError)?_c('template',{slot:"modal-footer"},[_c('div',{staticClass:"w-100"},[_c('b-button',{attrs:{"variant":"primary","href":"#/login"},on:{"click":function($event){return _vm.toLogin()}}},[_vm._v(" Log in ")])],1)]):_vm._e()],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert alert-success fade show mb-0"},[_c('h5',{staticClass:"mb-0 fw-900"},[_vm._v(" Validasi email sukses. "),_c('span',{staticClass:"fw-normal"},[_vm._v("Lanjutkan membuat password baru.")])])])}]

export { render, staticRenderFns }