<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Role</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <router-link to="/users/manage/role/add">
          <a class="btn btn-outline-primary">Add New Role</a>
        </router-link>
      </div>
    </div>

    <vue-good-table 
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
        initialSortBy: { 
          field: 'name',
          type: 'asc' 
        }, 
      }"
      styleClass="vgt-table" 
      slot="outsideBody"
    >
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="roleName" class="form-control" placeholder="Search roles" v-if="
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'roleName'
        " @input="(value) => updateFilters(column, $refs.roleName.value)" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div v-if="props.row.id == 'admin'" class="row g-2 align-items-center justify-content-end">
            <div class="col-auto">
              <div class="btn btn-lg text-gray">
                Readonly
              </div>
            </div>
          </div>
          <div v-else class="row g-2 align-items-center justify-content-end">
            <div class="col-auto">
              <router-link :to="`/users/manage/role/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto">
              <button type="button" @click.prevent="showAlertConfirm(props.row.id)" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Move to Trash">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import roleService from "@/service/role.service";

export default {
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Role Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchField: '',
        searchValue: '',
        sort: 'name',
        direction: 'ASC',
        pageNumber: 1,
        perSize: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ pageNumber: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageNumber: 1 });
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params[0].field,
        direction: params[0].type.toUpperCase()
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      // console.log(params.columnFilters.name, 'test');
      this.updateParams({
        searchField: 'name',
        searchValue: params.columnFilters.name
      });
      this.loadItems();
    },
    loadItems() {
      // Cancel previous call
      if (this.$data._debounceQueryTimer)
        clearTimeout(this.$data._debounceQueryTimer);
      this.$data._debounceQueryTimer = setTimeout(() => {
        roleService
          .getListRole(this.serverParams)
          .then((rsp) => {
            this.rows = rsp.data.data.content
            this.totalRecords = rsp.data.data.totalElements
          }).catch((e) => {
            if (e.response?.status == 404) {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
              this.rows = [];
            } else {
              console.log('e', e);
              this.$notify({
                type: 'error',
                title: 'Error Notification',
                text: e.response?.data?.responseMessage || e.message || 'Terjadi Kesalahan'
              });
            }
          })

        this.$data._debounceQueryTimer = null;
      }, 300)
    },
    onDelete(id) {
      roleService
        .deleteRoleById(id)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Success message',
            text: 'Delete Success'
          });
          this.loadItems();
        }).catch((e) => {
          this.$swal({
            icon: 'warning',
            text: e.response?.data?.responseMessage || 'Terjadi Kesalahan'
          });
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
