import SioService from "../service/sio.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListSio(_ctx, serverParams) {
            return SioService.getSioQuestionList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postSio(_ctx, serverParams) {
            return SioService.postSio(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getSioById(_ctx, serverParams) {
            return SioService.getSioById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateSioById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return SioService.putSio(id, serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteSioById(_ctx, serverParams) {
            return SioService.deleteSioById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        increment(context) {
            return this.state.count
        }
    },
};
