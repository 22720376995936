<template>
  <div class="row">
    <div class="col-md-9">
      <GroupFilter form-type='edit' :filter-select='filter' @onFilter="doFilter" />
      <div class="panel">
        <GroupNav />
        <div class="panel-body p-0">
          <div class="panel mb-0 panel-inverse rounded-0">
            <div class="panel-heading rounded-0">
              <h4 class="panel-title fw-bolder">{{ nameGroupHospital }} - {{ codeGroupHospital }}</h4>
            </div>
          </div>
          <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
            @on-selected-rows-change="selectionChanged" :select-options="{ enabled: true }" :totalRows="totalRecords"
            :isLoading.sync="isLoading" :pagination-options="{
              enabled: true,
              dropdownAllowAll: false,
            }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  //initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
            <template slot="column-filter" slot-scope="{ column, updateFilters }">
              <input ref="name" class="form-control" placeholder="Search hospital" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'name'
                " @input="(value) => updateFilters(column, $refs.name.value)
    " />
              <v-select placeholder="Cari hospital Tier" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'hospitalTier.tier'
                " :options="paginatedHospitalTier.content" :reduce="(option) => option.id" :filterable="false"
                @open="openSelectHospitalTier" @search="searchHospitalTier"
                @option:selected="selectedHospitalTierToTemporary" @input="(value) => updateFilters(column, value)">
                <li slot="list-footer" class="pagination">
                  <button :disabled="!hasTierPrevPage" @click.prevent="handlePrevProv('tier')">
                    Prev
                  </button>
                  <button :disabled="!hasTierNextPage" @click.prevent="handleNextProv('tier')">
                    Next
                  </button>
                </li>
              </v-select>
            </template>
            <template slot="table-actions-bottom">
              <form @submit.prevent="doEditGroup">
                <div class="row justify-content-end align-items-end">
                  <div class="col-4">
                    <label class="form-label">Hospital Group Code</label>
                    <div class="input-group">
                      <span class="input-group-text">PRGH</span>
                      <input type="text" placeholder="Code Number" v-model="hospitalGroupData.code"
                        class="form-control bg-white" :disabled="!selectedHospitals">
                    </div>
                    <span class="text-danger" v-if="!$v.hospitalGroupData.code.required &&
                      $v.hospitalGroupData.code.$error
                      ">Code is required</span>
                  </div>
                  <div class="col-auto" v-if="$can($permissionsAll[29])">
                    <button class="btn btn-primary" type="submit" :disabled="!selectedHospitals">Edit Group</button>
                  </div>
                </div>
              </form>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalService from "../../../service/hospital.service"
import GroupFilter from "./GroupFilter.vue";
import GroupNav from "./GroupNav.vue";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    GroupFilter,
    GroupNav,
  },
  data() {
    return {
      //firstTempIdHospital: true,
      provServerParamsHospitalTier: {
        pageSize: 10,
        pageNumber: 0,
      },
      firstLoadDataHospital: true,
      isFirstLoad: true,
      filter: {
        selectProvince: "",
        selectCity: "",
        selectDistrict: "",
        //selectTier: "",
      },
      isLoading: false,
      timer: null,
      isLoading: false,
      hospitalTierSelectedData: {},
      isServerParamsChange: false,
      hospitalDataWhenParamsChange: [],
      selectedDataTemp: [],
      selectedDataTempIfChange: [],
      nameGroupHospital: '',
      codeGroupHospital: '',
      hospitalGroupData: {
        name: '',
        code: '',
        idHospitals: []
      },
      columns: [
        {
          label: "Hospital Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Tier",
          field: "hospitalTier.tier",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          name: "",
          selectProvince: "",
          selectCity: "",
          "hospitalTier.tier": ""
        },
        sort: [
          // {
          //   field: "name",
          //   type: "ASC",
          // }
          {
            field: "created",
            type: "DESC",
          }
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      selectedHospitals: false,
      //groupCode: null,
    };
  },
  mounted() {
    this.isFirstLoad = false
    // console.log('id: ', this.$route.params.id)
    // this.loadItems();
    this.getHospitalGroupId()
    //this.selectionChanged();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      //this.loadItems();
      this.loadItemsFromApi()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      //this.loadItems();
      this.loadItemsFromApi()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      //this.loadItems();
      this.loadItemsFromApi()
    },
    onColumnFilter(params) {
      // console.log('params: ', params)
      this.updateParams(params);
      this.serverParams.page = 1;
      //this.loadItems();
      this.loadItemsFromApi()
    },
    setPaginationParams() {
      // console.log('this.serverParams.columnFilters: ', this.serverParams.columnFilters)
      this.serverParams.columnFilters = {
        ...this.serverParams.columnFilters,
        ...this.filter
      }
      let body = {
        pageNumber: this.serverParams.page - 1,
        pageSize: this.serverParams.perPage,
        // status: [
        //   'PUBLISHED'
        // ]
      };
      //  console.log('this.serverParams: ', this.serverParams)
      if (this.serverParams.sort[0].type !== "none") {
        body.direction = this.serverParams.sort[0].type.toUpperCase()
        body.sort = this.serverParams.sort[0].field
      } else {
        body.sort = "created"
        body.direction = "DESC"
      }
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters.hasOwnProperty(key)) {
          if (this.serverParams.columnFilters[key]) {
            if (key === "name") {
              // body.name = [this.serverParams.columnFilters[key]];
              body.name = this.serverParams.columnFilters[key];
            } else if (key === "district.name") {
              // body.district = [this.serverParams.columnFilters[key]];
              body.district = this.serverParams.columnFilters[key];
            } else if (key === "hospitalTier.tier") {
              const tier = this.$store.getters["hospital/getTier"].content.find((e) => e.id === this.serverParams.columnFilters[key])
              // body.tier = [tier.label]
              body.tier = tier.label
            } else if (key === 'selectTier' || key === 'selectProvince' || key === 'selectCity' || key === 'selectDistrict') {
              if (this.serverParams.columnFilters[key]) {
                // body[key] = [this.serverParams.columnFilters[key]]
                body[key] = this.serverParams.columnFilters[key]
              }
            }
          }
        }
      }
      return body;
    },
    getHospitalList() {
      hospitalService.getHospitalLists(this.setPaginationParams()).then(
        (response) => {
          if (response.responseCode === '200' && response.responseMessage == "Data's Empty!") {
            this.rows = [];
            this.totalRecords = 0;
          } else {
            // this.rows = response?.data?.content;
            // this.totalRecords = response?.data?.totalElements;
            const set = new Set(this.hospitalGroupData.idHospitals)
            this.rows = response?.data?.content.map((e) => {
              if (set.has(e.id)) {
                e.vgtSelected = true
              }
              return e
            })
            this.totalRecords = response?.data?.totalElements;
          }
        }
      ).catch(
        (error) => {
          if (error.response.status === 404) {
            alert("Data Not Found");
          }
          this.rows = [];
          this.totalRecords = 0;
        }
      );
    },
    getHospitalGroupId() {
      hospitalService.getHospitalGroupById(this.$route.params.id).then(
        (response) => {
          //console.log('response: ', response)
          if (response.responseCode === '200' && response.responseMessage == "Hospital Group with id : 1 not found") {
            this.rows = [];
            //this.totalRecords = 0;
          } else {
            // const hospitals = response?.data?.hospitals.map((h) => {
            //   h.vgtSelected = true
            //   return h
            // })
            this.nameGroupHospital = response.data.name
            this.codeGroupHospital = response.data.code

            const hospitals = response?.data?.hospitals.map((h) => {
              //h.vgtSelected = true
              return h.id
            })
            let code = response.data.code.split("PRGH")
            this.hospitalGroupData.code = code[1]
            // console.log('hospitals: ', hospitals)
            // console.log('response.data.hospitals: ', response.data.hospitals)
            this.filter.selectProvince = response.data.hospitals[0].province.name
            this.filter.selectCity = response.data.hospitals[0].city.name
            this.hospitalGroupData.idHospitals = hospitals
            this.selectedDataTempIfChange = hospitals
            this.getHospitalList()
            // this.totalRecords = response?.data?.totalElements;
            //this.totalRecords = 10;
          }
        }
      ).catch(
        (error) => {
          if (error.response.status === 404) {
            alert("Data Not Found");
          }
          this.rows = [];
          this.totalRecords = 0;
        }
      );
    },
    updateHospitalGroupById() {
      const payload = {
        ...this.hospitalGroupData,
        code: `PRGH${this.hospitalGroupData.code}`
      }
      //console.log('payload: ', payload)
      hospitalService.updateHospitalGroupById(this.$route.params.id, payload).then(
        (response) => {
          this.$swal({
            title: "Data berhasil diupdate",
            showCloseButton: true,
          })
          this.$router.push("/hospitals/group/list");
        }
      ).catch(
        (error) => {
          this.$swal({
            title: error.response.data.responseMessage || "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
      );
    },
    loadItemsFromApi() {
      if (!this.isFirstLoad) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(this.getHospitalList(), 800);
      }
    },
    filterArray(arr1, arr2) {
      const newArr = arr2.reduce(function (prev, value) {
        var isDuplicate = false;
        for (var i = 0; i < arr1.length; i++) {
          if (value == arr1[i]) {
            isDuplicate = true;
            break;
          }
        }

        if (!isDuplicate) {
          prev.push(value);
        }

        return prev;

      }, []);
      return newArr
    },
    selectionChanged(selectedData) {

      this.selectedDataTemp = selectedData.selectedRows.map(e => {
        return e.id
      })
      this.selectedDataTempIfChange = this.filterArray(this.selectedDataTemp, this.selectedDataTempIfChange)
      // console.log('test 4: ', this.selectedDataTempIfChange)

    },
    loadItems() {
      this.rows = [
        {
          id: 1,
          name: "RS Pondok Indah",
          hospitalTier: "C",
          //vgtSelected: true,
        },
        {
          id: 2,
          name: "Brawijaya Women & Children Hospital",
          hospitalTier: "C",
          //vgtSelected: true,
        },
        {
          id: 3,
          name: "RS Pusat Pertamina",
          hospitalTier: "C",
          //vgtSelected: true,
        },
        {
          id: 4,
          name: "RS Pondok Indah",
          hospitalTier: "C",
          //vgtSelected: false,
        },
        {
          id: 5,
          name: "Brawijaya Women & Children Hospital",
          hospitalTier: "C",
          //vgtSelected: true,
        },

      ];
    },
    async doFilter(payload) {
      // Submit here
      // console.log(payload);
    },
    doEditGroup() {
      // console.log('doEditGroup')
      this.$v.hospitalGroupData.$touch();
      if (!this.$v.hospitalGroupData.$invalid) {
        // console.log('doEditGroup')
        // this.hospitalGroupData.name = this.filter.city
        //this.hospitalGroupData.code = `PRGH${this.hospitalGroupData.code}`
        this.updateHospitalGroupById()
      } else {
        this.hospital.status = null
        // console.log(this.$v.hospital)
        console.debug("False")
      }
    },
    //tier
    openSelectHospitalTier() {
      this.provServerParamsHospitalTier.searchValue = ""
      this.provServerParamsHospitalTier.pageNumber = 0
      this.provServerParamsHospitalTier.pageSize = 3
      this.getHospitalTier()
    },
    searchHospitalTier(query, loading) {
      this.provServerParamsHospitalTier.pageNumber = 0
      this.provServerParamsHospitalTier.pageSize = 3
      this.provServerParamsHospitalTier.searchField = 'tier'
      this.provServerParamsHospitalTier.searchValue = query
      this.getHospitalTier()
    },
    selectedHospitalTierToTemporary(hospitalTierSelectedData) {
      // const tierContent = this.$store.state['hospital'].tier.content
      // const tier = tierContent.find(e => e.id === this.hospital.hospitalTierId)
      // console.log('hospitalTierSelectedData: ', hospitalTierSelectedData)
      this.hospitalTierSelectedData = hospitalTierSelectedData
    },
    async handleNextProv(kind) {
      let lastPage
      switch (kind) {
        case 'tier':
          lastPage = this.$store.state['hospital'].tier.totalPages - 1
          if (this.provServerParamsHospitalTier.pageNumber < lastPage) {
            this.provServerParamsHospitalTier.pageNumber += 1
          }
          //this.onSearchTier = true
          this.getHospitalTier()
          break;

        default:
          break;
      }
    },
    async handlePrevProv(kind) {
      switch (kind) {
        case 'tier':
          if (this.provServerParamsHospitalTier.pageNumber !== 0) {
            this.provServerParamsHospitalTier.pageNumber -= 1
          }
          this.getHospitalTier()
          break;

        default:
          break;
      }
    },
    getHospitalTier() {
      this.$store.dispatch(
        "hospital/getHospitalTier",
        this.provServerParamsHospitalTier
      )
        .then(
          () => {
            if (Object.keys(this.hospitalTierSelectedData).length > 0) {
              this.hospitalTierSelectedData.type = 'tier'
              this.$store.commit('hospital/pushSelectedData', this.hospitalTierSelectedData)
            }
          }
        )
        .catch(
          (error) => {
            console.debug(error);
          }
        )
      // this.formInProgressProvince = false
    },
    hasTierNextPage() {
      return true
    },
    hasTierPrevPage() {
      return true
    },
  },
  computed: {
    paginatedHospitalTier() {
      return this.$store.getters["hospital/getTier"];
    },
    serverParamsChange() {
      return {
        name: this.serverParams.columnFilters.name,
        tier: this.serverParams.columnFilters['hospitalTier.tier'],
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        sort: this.serverParams.sort
      }

    },
    selectedDataTempChange() {
      return {
        selectedDataTemp: this.selectedDataTemp
      }
    },
    idHospitalsChange() {
      return {
        idHospitals: this.hospitalGroupData.idHospitals
      }
    }

  },
  watch: {
    serverParamsChange: {
      handler(newValue, oldValue) {
        // console.log('newValue.name !== oldValue.name: ', newValue.name !== oldValue.name)
        if (newValue.name !== oldValue.name || newValue.tier !== oldValue.tier || newValue.page !== oldValue.page || newValue.perPage !== oldValue.perPage || newValue.sort[0].type !== oldValue.sort[0].type || newValue.sort[0].field !== oldValue.sort[0].field) {
          this.isServerParamsChange = true
          this.selectedDataTempIfChange = []
          this.selectedDataTempIfChange = this.hospitalGroupData.idHospitals
          // console.log('his.selectedDataTempIfChange search: ', this.selectedDataTempIfChange)
          // console.log('this.hospitalGroupData.idHospitals search: ', this.hospitalGroupData.idHospitals)
        }

        if (this.hospitalGroupData.idHospitals.length === 0) {
          this.isServerParamsChange = false
        }
      },
      deep: true
    },
    selectedDataTempChange: {
      handler(newValue, oldValue) {
        // console.log('oldValue: ', oldValue)
        // console.log('newValue 1: ', newValue)
        // console.log('this.hospitalGroupData.idHospitals: ', this.hospitalGroupData.idHospitals)
        //  console.log('this.isServerParamsChange: ', this.isServerParamsChange)
        // console.log('this.isServerParamsChange: ', this.isServerParamsChange)
        // console.log('this.hospitalGroupData.idHospitals cekbox1 :  ', this.hospitalGroupData.idHospitals)

        if (!this.isServerParamsChange) {
          // console.log('this.firstLoadDataHospital: ', this.firstLoadDataHospital)
          if (!this.firstLoadDataHospital || newValue.selectedDataTemp.length > 0) {
            // console.log('newValue 2: ', newValue)
            this.hospitalGroupData.idHospitals = newValue.selectedDataTemp
          }
          // console.log('this.selectedDataTempIfChange: ', this.selectedDataTempIfChange)
          this.firstLoadDataHospital = false
          const set = new Set([...this.selectedDataTempIfChange, ...this.hospitalGroupData.idHospitals])
          const array = [...set];
          this.hospitalGroupData.idHospitals = array
          // console.log('this.hospitalGroupData.idHospitals.cekbox length :', this.hospitalGroupData.idHospitals.length )
          // console.log('this.hospitalGroupData.idHospitals :', this.hospitalGroupData.idHospitals )
          if (this.hospitalGroupData.idHospitals.length > 2) {
            this.selectedHospitals = true
          } else {
            this.selectedHospitals = false
          }
        }
        //console.log('his.selectedDataTempIfChange cekbox: ', this.selectedDataTempIfChange)
        // console.log('this.hospitalGroupData.idHospitals cekbox2 : ', this.hospitalGroupData.idHospitals)
        //  else{
        //   const set = new Set([...this.selectedDataTempIfChange, ...this.hospitalGroupData.idHospitals, ...newValue.selectedDataTemp])
        //   const array = [...set];
        //   this.hospitalGroupData.idHospitals = array
        //   console.log('this.hospitalGroupData.idHospitals: ', this.hospitalGroupData.idHospitals)
        //  }
        this.isServerParamsChange = false
        //console.log('this.isServerParamsChange: ', this.isServerParamsChange)
        //console.log('this.hospitalGroupData.idHospitals: ', this.hospitalGroupData.idHospitals)
      },
      deep: true
    },
  },
  validations() {
    return {
      hospitalGroupData: {
        code: {
          required,
        }
      },
    };
  },
};
</script>
