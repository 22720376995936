<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onProgress" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import base64Converter from "../../pages/Helper/base64";
import postService from "../../service/post.service";
export default {
  components: {
    FormPost,
  },
  data() {
    // this.loadItems(this.$route.params.id)
    return {
      onProgress: false,
    };
  },
  methods: {
    async doSave(payload) {
      this.onProgress = true
      let { post } = payload;
      if (post) {
        let { cta, preview } = post;
        let payload = {
          title: post.title,
          slug: post.slug,
          content: post.content,
          source: "CMS",
          mainImageDesktop: await base64Converter(post.images.desktop[0]),
          mainImageMobile: await base64Converter(post.images.mobile[0]),
          seoInformation: {
            metaTitle: post.meta.title,
            metaDescription: post.meta.description,
            // metaImage: post.meta.image ? await base64Converter(post.meta.image[0]) : '',
            seoKeywords: post.meta.keywords === '' ? post.meta.keywords.toString() : null,
          },
          status: post.status ?? 'PUBLISHED',
          articleCategory: {
            id: post.category.id,
          },
        };
        postService
          .postArticle(payload)
          .then((rsp) => {
            if (preview) {
              window.open(process.env.VUE_APP_FRONTEND + '/article-detail/' + post.slug, '_blank', 'noreferrer');
            }
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.onProgress = false
              this.$router.push({ path: "/posts/list" });
            });
          })
          .catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          });
      }
    },
  },
};
</script>