<template>
  <div class="row">
    <div class="col-md-9">
      <!-- <GroupFilter @onFilter="doFilter" /> -->
      <div class="panel mb-2">
        <GroupNav />
        <div class="panel-body p-0">
          <div class="p-3">
            <form @submit.prevent="doSearch">
              <div class="row align-items-end">
                <div class="col-md-5">
                  <label class="form-label"
                    >Search group name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search group name"
                    v-model="hospital.group"
                  />
                </div>
                <div class="col-md-5">
                  <label class="form-label"
                    >Search hospital name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search hospital name"
                    v-model="hospital.name"
                  />
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-outline-primary">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <panel
        v-for="(item, index) in rows"
        :title="`${item.name} - ${item.code}`"
        class="mb-2"
        header-class="fw-bolder"
        variant="default"
        body-class="p-0"
        :is-collapse="true"
        :show-collapse="false"
        :key="index"
      >
        <div class="table-responsive mb-0">
          <table class="table table-form">
            <thead>
              <tr>
                <th>Hospital Name</th>
                <th>Tier</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(hospital, index) in item.hospitals" :key="index">
                <td>{{ hospital.name }}</td>
                <td>{{ hospital.hospitalTier.tier }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="vgt-wrap__actions-footer">
          <div class="row justify-content-end">
            <div class="col-auto">
              <router-link
                :to="`/hospitals/group/list/${item.id}`"
                class="btn btn-primary"
              >
                Edit Group
              </router-link>
            </div>
          </div>
        </div>
      </panel>

      <div class="vgt-wrap">
        <div class="vgt-inner-wrap">
          <div class="vgt-wrap__footer vgt-clearfix">
            <div class="footer__row-count vgt-pull-left">
              <form>
                <label
                  for="vgt-select-rpp-507559520782"
                  class="footer__row-count__label"
                  >Rows per page:</label
                >
                <select
                  id="vgt-select-rpp-507559520782"
                  autocomplete="off"
                  name="perPageSelect"
                  aria-controls="vgt-table"
                  class="footer__row-count__select"
                  ref="hospitalTier"
                  @change="
                    (value) => onPerPageChange($refs.hospitalTier.value)
                  "
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  
                </select>
              </form>
            </div>
            <div class="footer__navigation vgt-pull-right">
              <div data-v-347cbcfa="" class="footer__navigation__page-info">
                <div data-v-347cbcfa="">{{startData}} - {{untilData}} of {{totalRecords}}</div>
              </div>
              
              <button
                type="button"
                aria-controls="vgt-table"
                class="footer__navigation__page-btn"
                @click="onPageChange(serverParams.page - 1)"
                :disabled="!prev"
              >
                <span aria-hidden="true" class="chevron left"></span>
                <span >Previous</span>
              </button>
              <button
                type="button"
                aria-controls="vgt-table"
                class="footer__navigation__page-btn"
                @click="onPageChange(serverParams.page + 1)"
                :disabled="!next"
              >
                <span >Next</span>
                <span aria-hidden="true" class="chevron right"></span>
              </button>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalService from "../../../service/hospital.service";
import GroupFilter from "./GroupFilter.vue";
import GroupNav from "./GroupNav.vue";
export default {
  components: {
   // GroupFilter,
    GroupNav,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Hospital Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Tier",
          field: "hospitalTier.tier",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "created",
            type: "DESC",
          }
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      selectedHospitals: false,
      groupCode: null,
      hospital: {
        name: "",
        group:""
      },
      startData: 0,
      untilData: 0,
      next: true,
      prev: true
      // totalRows: 1,
      // currentPage: 1,
      // perPage: 5,
    };
  },
  mounted() {
    // this.loadItems();
    this.getHospitalGroupLists()
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params });
      //this.loadItems();
      this.loadItemsFromApi()
    },
    onPerPageChange(currentPerPage) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: currentPerPage });
      //this.loadItems();
      this.loadItemsFromApi()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      //this.loadItems();
      this.loadItemsFromApi()
    },
    onColumnFilter(params) {
      // console.log('params: ', params.columnFilters['district.name'])
      this.updateParams(params);
      this.serverParams.page = 1;
      //this.loadItems();
      this.loadItemsFromApi()
    },
    setPaginationParams() {
      this.serverParams.columnFilters = {
        ...this.serverParams.columnFilters,
        ...this.hospital
      }
      let body = {
        pageNumber: this.serverParams.page - 1,
        pageSize: this.serverParams.perPage,
        // status: [
        //   'PUBLISHED'
        // ]
      };
      //  console.log('this.serverParams: ', this.serverParams)
      if (this.serverParams.sort[0].type !== "none") {
        (body.direction = this.serverParams.sort[0].type.toUpperCase()),
          (body.sort = this.serverParams.sort[0].field);
      }
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters.hasOwnProperty(key)) {
          if (this.serverParams.columnFilters[key]) {
            if (key === "group") {
              // body.name = [this.serverParams.columnFilters[key]];
              body.searchField = 'name'
              body.searchValue = this.serverParams.columnFilters[key];
            } else if (key === "name") {
              // body.district = [this.serverParams.columnFilters[key]];
              body.hospitalName = this.serverParams.columnFilters[key];
            } 
          }
        }
      }
      return body;
    },
    getHospitalGroupLists() {
      hospitalService.getHospitalGroupLists(this.setPaginationParams()).then(
        (response) => {
          if(response.responseCode === '200' &&  response.responseMessage == "Data's Empty!"){
            this.rows = [];
            this.totalRecords = 0;
            this.next = false
            this.prev = false
          }else{
            this.rows = response?.data?.content;
            this.totalRecords = response?.data?.totalElements;
           
            if(!response?.data.first && !response?.data.last){
              
              this.next = true
              this.prev = true
              this.startData = (this.serverParams.page - 1) * this.serverParams.perPage + 1
              this.untilData = (this.startData + response?.data.numberOfElements) - 1
            }else if(response?.data.first){
              
              this.next = response?.data.totalElements === response?.data.numberOfElements ? false : true
              this.prev = false
              this.startData = 1
              this.untilData = response?.data.numberOfElements
            }else if(response?.data.last){
          
              this.next =  false
              this.prev = true
              this.startData = (this.totalRecords - response?.data.numberOfElements) + 1
              this.untilData = this.totalRecords
            }
            
          }
        }
      ).catch(
          (error) => {
          if(error.response.status === 404){
            alert("Data Not Found");
          }
          this.rows = [];
          this.totalRecords = 0;
        }
      );
    },
    loadItemsFromApi() {
     
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(this.getHospitalGroupLists(), 800);
      
    },
   
    loadItems() {
      this.rows = [
        {
          id: 1,
          name: "Bekasi",
          code: "PRGH000000000031",
          hospitals: [
            {
              id: 1,
              name: "RS Pondok Indah",
              hospitalTier: {
                tier: "C"
              },
              //vgtSelected: false,
            },
            {
              id: 2,
              name: "Brawijaya Women & Children Hospital",
              hospitalTier: {
                tier: "C"
              },
              //vgtSelected: false,
            },
            {
              id: 3,
              name: "RS Pusat Pertamina",
              hospitalTier: {
                tier: "C" 
              },
              //vgtSelected: true,
            },
            {
              id: 4,
              name: "RS Pondok Indah",
              hospitalTier: {
                tier: "C"
              },
              //vgtSelected: false,
            },
            {
              id: 5,
              name: "Brawijaya Women & Children Hospital",
              hospitalTier: {
                tier: "C"
              },
              //vgtSelected: true,
            },
          ],
        },
      
      ];
    },
    async doFilter(payload) {
      // Submit here
      // console.log('payload: ', payload);
    },
    async doSearch(payload) {
      // Submit here  
      // console.log('search : ', payload);
      // console.log('this.hospital.name : ', this.hospital.name);
      // console.log('this.hospital.group : ', this.hospital.group);
      this.loadItemsFromApi()
    },
    async doCreateGroup() {
      // Submit here
      // console.log("CreateGroup");
    },
    // startData(){
    //   this.totalRecords
    // },
    // untilData(){

    // }
  },
};
</script>
