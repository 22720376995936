<template>
  <div
    class="panel"
    v-bind:class="[
      { 'panel-expand': expand, 'panel-loading': reload },
      panelTheme,
      panelClass,
    ]"
    v-if="!remove"
  >
    <div class="panel-heading" v-bind:class="headerClass">
      <slot name="header"></slot>
      <h4 class="panel-title" v-if="!hasHeaderSlot">
        {{ title }}
      </h4>
      <div class="panel-heading-btn" v-if="!noButton">
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-default"
          v-if="!isCollapse"
          v-on:click="panelExpand"
          ><i class="fa fa-expand"></i
        ></a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-success"
          v-if="!isCollapse"
          v-on:click="panelReload"
          ><i class="fa fa-redo"></i
        ></a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-danger"
          v-if="!isCollapse"
          v-on:click="panelRemove"
          ><i class="fa fa-times"></i
        ></a>
        <label :for="checkboxName" class="is-hidden btn btn-xs" :class="{ 'text-white': variant === 'inverse' }" v-if="checkboxHidden">
          <input type="checkbox" :id="checkboxName" :checked="value" @click="handleIsHidden" />
          <i :class="`fas ${value ? 'fa-eye' : 'fa-eye-slash'}`"></i>
        </label>
        <a
          href="javascript:;"
          class="btn btn-xs"
          :class="{ 'text-white': variant === 'inverse' }"
          v-if="isCollapse"
          v-on:click="panelCollapse"
          ><i v-if="collapse" class="fa fa-chevron-up"></i
          ><i v-else class="fa fa-chevron-down"></i
        ></a>
      </div>
    </div>
    <slot name="beforeBody"></slot>
    <div
      class="panel-body"
      v-if="!noBody"
      v-show="collapse"
      v-bind:class="bodyClass"
    >
      <slot></slot>
      <div class="panel-loader" v-if="reload">
        <span class="spinner spinner-sm"></span>
      </div>
    </div>
    <slot v-else></slot>
    <slot name="outsideBody"></slot>
    <div class="panel-footer" v-if="hasFooterSlot" v-bind:class="footerClass">
      <slot name="footer"></slot>
    </div>
    <slot name="afterFooter"></slot>
  </div>
</template>

<script>
export default {
  name: "Panel",
  props: [
    "title",
    "body",
    "footer",
    "variant",
    "noBody",
    "noButton",
    "isCollapse",
    "showCollapse",
    "outsideBody",
    "headerClass",
    "bodyClass",
    "footerClass",
    "panelClass",
    "value",
    "checkboxHidden",
    "checkboxName",
  ],
  data() {
    return {
      expand: false,
      collapse: true,
      remove: false,
      reload: false,
      panelTheme: this.variant ? "panel-" + this.variant : "panel-inverse",
    };
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    isShowCollapse() {
      return this.showCollapse === null ||
        typeof this.showCollapse === "undefined"
        ? true
        : this.showCollapse;
    },
  },
  mounted() {
    this.collapse = this.isShowCollapse;
  },
  methods: {
    panelExpand: function () {
      this.expand = !this.expand;
    },
    panelCollapse: function () {
      this.collapse = !this.collapse;
    },
    panelRemove: function () {
      this.remove = !this.remove;
    },
    panelReload: function () {
      this.reload = true;
      setTimeout(
        function () {
          this.resetReload();
        }.bind(this),
        2000
      );
    },
    resetReload: function () {
      this.reload = false;
    },
    handleIsHidden(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.is-hidden{
  cursor: pointer;
  margin: 0;

  input{
    display: none;
  }
}
</style>
