<script>
import Permissions from "@/config/Permissions";

const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-th-large", title: "Dashboard", permission: Permissions[0] },
  {
    path: "#",
    icon: "fa-solid fa-chart-line",
    title: "Analytics",
    children: [
      { path: "/analytics/business", title: "Business Analytics", permission: Permissions[1] },
      { path: "/analytics/performance-metric", title: "Performance Metric", permission: Permissions[2] },
      { path: "/analytics/dropoff-issue", title: "Drop-off Issue", permission: Permissions[3] },
    ],
  },
  { path: "/submissions", icon: "fa-solid fa-users", title: "Submissions", permission: Permissions[4] },
  {
    path: "#",
    icon: "fas fa-star",
    title: "Products",
    children: [
      { path: "/products/list", title: "All Products", permission: Permissions[11] },
      { path: "/products/add", title: "Add Product", permission: Permissions[8] },
      { path: "/products/category", title: "Product Category", permission: Permissions[13] },
      { path: "/products/import", title: "Import Product", permission: Permissions[12] },
      { path: "/products/configuration", title: "Product Configuration", permission: Permissions[17] },
    ],
  },
  {
    path: "#",
    icon: "fas fa-hospital",
    title: "Hospitals",
    children: [
      { path: "/hospitals/list", title: "All Hospitals", permission: Permissions[18] },
      { path: "/hospitals/add", title: "Add Hospital", permission: Permissions[19] },
      { path: "/hospitals/tier", title: "Hospital Tier", permission: Permissions[26] },
      { path: "/hospitals/group", title: "Hospital Group", permission: Permissions[27] },
      { path: "/hospitals/area", title: "Hospital Area", permission: Permissions[31] },
      { path: "/hospitals/import", title: "Import Hospital", permission: Permissions[22] },
    ],
  },
  {
    path: "#",
    icon: "fas fa-file-lines",
    title: "Pages",
    children: [
      { path: "/pages/homepage", title: "Homepage", permission: Permissions[36] },
      { path: "/pages/list", title: "All Pages", permission: Permissions[37] },
      { path: "/pages/add", title: "Add Page", permission: Permissions[38] },
      { path: "/pages/faq/list", title: "FAQ", permission: Permissions[42] },
      { path: "/pages/faq/category", title: "FAQ Category", permission: Permissions[44] },
    ],
  },
  {
    path: "#",
    icon: "fas fa-puzzle-piece",
    title: "Sections",
    children: [
      { path: "/sections/menus", title: "Menus", permission: Permissions[48] },
      { path: "/sections/banner", title: "Banner", permission: Permissions[49] },
      { path: "/sections/sio-questionnaire", title: "SIO Questionnaire", permission: Permissions[56] },
      {
        path: "/sections/charity-organizations",
        title: "Charity organizations", permission: Permissions[57]
      },
      {
        path: "/sections/bmi",
        title: "Master BMI",
        permission: Permissions[94],
      },
      { path: "/sections/manage-pdf", title: "Manage PDFs", permission: Permissions[63] },
      { path: "/sections/bank", title: "Manage Bank", permission: Permissions[67] }
    ],
  },
  {
    path: "#",
    icon: "fas fa-users",
    title: "Manage Referral",
    children: [
      { path: "/referral/list", title: "List Manage Referral", permission: Permissions[73] },
      { path: "/referral/add", title: "Add Manage Referral", permission: Permissions[70] },
      { path: "/referral/import", title: "Import Referral", permission: Permissions[69] },
    ],
  },
  {
    path: "#",
    icon: "fas fa-gift",
    title: "Manage Redeem",
    children: [
      { path: "/redeem/list", title: "List Manage Redeem", permission: Permissions[98] },
      { path: "/redeem/add", title: "Add Manage Redeem", permission: Permissions[99] },
      { path: "/redeem/popup", title: "List Pop Up", permission: Permissions[100] },
      { path: "/redeem/upload/popup", title: "Add Pop Up", permission: Permissions[101] },
    ],
  },
  {
    path: "#",
    icon: "fas fa-gift",
    title: "Manage Reward",
    children: [
      { path: "/reward/list", title: "List Manage Reward", permission: Permissions[102] },
      { path: "/reward/add", title: "Add Manage Reward", permission: Permissions[103] }
    ],
  },
  {
    path: "#",
    icon: "fas fa-thumbtack",
    title: "Posts",
    children: [
      { path: "/posts/list", title: "All Posts", permission: Permissions[74] },
      { path: "/posts/add", title: "Add Post", permission: Permissions[74] },
      { path: "/posts/category", title: "Post Category", permission: Permissions[79] },
    ],
  },
  {
    path: "#",
    icon: "fas fa-certificate",
    title: "Notification",
    children: [
      { path: "/notification/sms", title: "SMS", permission: Permissions[85] },
      { path: "/notification/email", title: "Email", permission: Permissions[86] },
    ],
  },
  {
    path: "#",
    icon: "fas fa-users",
    title: "Users",
    children: [
      { path: "/users/manage/user", title: "Manage User", permission: Permissions[90] },
      { path: "/users/manage/role", title: "Manage Role", permission: Permissions[91] },
      { path: "/users/reports", title: "Activity Report", permission: Permissions[92] },
    ],
  },
];

export default sidebarMenu;
</script>
