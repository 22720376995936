<template>
  <div class="hospital-form">
    <FormHospital form-type="add" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import hospitalService from "../../service/hospital.service";
import FormHospital from "./Form.vue";
export default {
  components: {
    FormHospital,
  },
  data() {
    return {
      body: {
        name: "",
        hospitalTierId: "",
        address: "",
        provinceId: "",
        cityId: "",
        districtId: "",
        postalCode: "",
        latitude: 0,
        longitude: 0,
        phoneNumber: "",
        status:"",
      },
    };
  },
  methods: {
    doSave(payload) {
      this.body.name = payload.name;
      this.body.hospitalTierId = payload.hospitalTierId;
      this.body.address = payload.address;
      this.body.provinceId = payload.province;
      this.body.cityId = payload.city;
      this.body.districtId = payload.district;
      this.body.postalCode = payload.postalCode;
      this.body.latitude = payload.position.lat;
      this.body.longitude = payload.position.lng;
      this.body.phoneNumber = payload.phoneNumber;
      this.body.status = payload.status;
      hospitalService.createHospital(this.body)
        .then(
          (response) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              if(payload.status === 'DRAFTS'){
                this.$router.push("/hospitals/list/drafts");
              }else{
                this.$router.push("/hospitals/list");
              }
            });
          }
        ).catch((error) => {
            this.$swal({
            title: error.response?.data?.responseMessage || "Terjadi Kesalahan",
            showCloseButton: true,
          });
      });
    },
  },
  mounted() {},
};
</script>