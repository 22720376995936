<template>
    <div class="product-form">
        <FormPopUp form-type="add" @onValidateSuccess="doSave" :on-loading="onProgress" />
    </div>
</template>
<script>

import popUpService from '@/service/popUp.service';
import FormPopUp from './FormPopUp.vue'
import ConvertToBase64 from "@/pages/Helper/base64";

export default {
    components: {
        FormPopUp,
    },
    data() {
        return {
            onProgress: false,
        };
    },
    methods: {
        async doSave(payload) {
            // Submit here
            // console.log(await ConvertToBase64(payload.images.desktop[0]));
            this.onProgress = true
            // let { banner, status } = payload
            let { banner, status } = payload
            if (banner && status) {
                // let { cta } = banner
                payload = {
                    // recordStatus: "ACTIVE",
                    title: banner.title,
                    url: banner.url,
                    mainImageDesktop: banner.images.desktop ? await ConvertToBase64(banner.images.desktop[0]) : null,
                    mainImageMobile: banner.images.mobile ? await ConvertToBase64(banner.images.mobile[0]) : null,
                    // imageUrlMainBase64: banner.images.desktop ? await ConvertToBase64(banner.images.desktop[0]) : null,
                    // imageUrlMobileBase64: banner.images.mobile ? await ConvertToBase64(banner.images.mobile[0]) : null,
                    // ctaUrl1: cta.url1,
                    // ctaText1: cta.text1,
                    // ctaUrl2: cta.url2,
                    // ctaText2: cta.text2,
                    status
                }
                popUpService
                    .postPopUp(payload)
                    .then((rsp) => {
                        this.onProgress = false
                        this.$swal({
                            title: "Data berhasil disimpan",
                            showCloseButton: true,
                        }).then(() => {
                            this.$router.push({ path: '/redeem/popup' })
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })
            }
        }
    }
}
</script>