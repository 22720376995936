import SumInsuranceService from "../service/suminsurance.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListSumInsurance(_ctx, serverParams) {
            return SumInsuranceService.getSumInsuranceList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postSumInsurance(_ctx, serverParams) {
            return SumInsuranceService.postSumInsurance(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getSumInsuranceById(_ctx, serverParams) {
            return SumInsuranceService.getSumInsuranceById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateSumInsuranceById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return SumInsuranceService.putSumInsurance(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteSumInsuranceById(_ctx, serverParams) {
            return SumInsuranceService.deleteSumInsuranceById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
