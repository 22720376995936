import Permissions from "@/config/Permissions";
import api from "@/service/api";
import userService from "@/service/user.service";
import roleService from "@/service/role.service";

/**
 * @type {import('vuex').Module<{
 * login: boolean
 * user: any
 * permissionsAll: any
 * permissions: any
 * onTokenExpired: Function
 * timeoutExpiredToken: NodeJS.Timeout
 * token: {
 *   access: any
 *   refresh: any
 * }
 * }>}
 */
const auth = {
  namespaced: true,
  state: {
    //login: false,
    user: null,
    token: null,
    permissionsAll: null,
    permissions: null
    // timeoutExpiredToken: null,
  },
  actions: {
    boot(ctx) {
      if (localStorage.getItem("token")) {
        ctx.commit("token", localStorage.getItem("token"));
        return ctx.dispatch("checkLogin").then(async (isLogin) => {
          if (isLogin) {
            await ctx.dispatch("getRefPermissions")
            return true
          } else {
            ctx.commit("token", null);
          }
        });
      }
    },
    getRefPermissions(ctx) {
      return roleService.getRefPermission().then((r) => {
        ctx.commit("permissionsAll", r.data.data)
        return true
      })
    },
    checkLogin(ctx) {
      return userService.checkLogin()
        .then((res) => {
          if (res.data.data.user.role.permission.length > 0) {
            let permissions = []
            for (const [k, v] of Object.entries(res.data.data.user.role.permission)) {
              permissions.push(v.permission)
            }
            ctx.commit("permissions", permissions);
          }
          ctx.commit("user", res.data.data);
          return true;
        })
        .catch(() => {
          ctx.commit("user", null);
          return false;
        });
    },
    login(ctx, user) {
      return userService.login(user).then(res => {
        ctx.commit("token", res.data.data);
        // return new Promise((r, j) => setTimeout(
        //   () => ctx.dispatch("checkLogin").then(r).catch(j),
        //   500
        // ))
        return ctx.dispatch("checkLogin");
      });
    },
    logout(ctx) {
      return userService.logout()
        .catch(e => console.warn(e.response?.data))
        .then(
          () => {
            ctx.commit("user", null);
            ctx.commit("token", null);
          })
    },
  },
  getters: {
    isLogin: (s) => !!s.user,
  },
  mutations: {
    token(state, token, ctx) {
      if (token) {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        localStorage.setItem("token", token);
        state.token = token

      } else {
        delete api.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
        state.token = null
      }
    },
    user(state, user) {
      if (user) {
        // translate permissions string to Number
        user.permissions = user.permissions.map(
          v => v.toLowerCase().replace(/(^|_)(\w)/g,
            (m, g1, g2) => (g1 ? " " : "") + g2.toUpperCase())
        ).map(nameCamel => {
          if (nameCamel == "All") return 999;
          if (nameCamel in Permissions) {
            return Permissions[nameCamel];
          } else {
            console.warn("Permission not mapped:", nameCamel)
            return 0;
          }
        }).filter(v => !!v)
      }
      state.user = user;
    },
    permissionsAll(state, permissionsAll) {
      state.permissionsAll = permissionsAll
    },
    permissions(state, permissions) {
      state.permissions = permissions
    }
  },
};

export default auth;