<template>
  <div class="product-form">
    <form class="pb-5 mb-4" v-if="!!detail">
      <div class="row">
        <div class="col-12">
          <h1 class="page-header">Home Page</h1>
        </div>
        <div class="col-lg-9 col-md-8">
          <div class="mb-15px">
            <input type="text" class="form-control form-control-lg" placeholder="Page Title" v-model="detail.title"
              @keyup="chekingSlug('title')" />
            <span class="text-danger" v-if="!$v.title.required && $v.title.$error">Title is required</span>
          </div>

          <!--
            <div>
            <TextSection :value="section.one" title="Section 1 - Text" />
            <SliderSection :value="section.two" title="Section 2 - Horizontal Slider"/>
            <VideoSection :value="section.three" title="Section 3 - Video" />
            <TextSection :value="section.four" title="Section 4 - Text" />
            <CalcSection :value="section.five" title="Section 5 - Kalkulator Premi"/>
            <LinkSection :value="section.six" title="Section 6 - Text" />
            <TestimoniSection :value="section.seven" title="Section 7 - Testimoni"/>
            <PostSection :value="section.eight" title="Section 8 - Post Section" />
            <FaqSection :value="section.nine" title="Section 9 - FAQ Section" />
            <PdfSection :value="section.ten" title="Section 10 - PDF Section" />
          </div>
            -->
          <component v-for="(v, k) of detail.pageSections"
            :is="$options.sections[v.sectionType] || $options.sections.UNKNOWN" :key="k" :value="v" ref="sections" />

          <!-- SEO INFO -->
          <SeoInformation :value="detail.seoInformation" />
        </div>
        <div class="col-lg-3 col-md-4">
          <div class="panel panel-sticky">
            <div class="panel-body">
              <!-- <div class="mb-15px">
                <p class="fw-bolder mb-1">Published Date</p>
                <p class="mb-0">{{ detail.created | getDate }}</p>
              </div> -->
              <div class="mb-15px">
                <p class="fw-bolder mb-1">Last Modified Date</p>
                <p class="mb-0">{{ detail.updated | getDate }}</p>
              </div>
              <!-- <div class="mb-15px">
                <p class="fw-bolder mb-1">Author</p>
                <p class="mb-0">{{ detail.createdBy }}</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto" v-if="$can($permissionsAll[35])">
            <button @click.prevent="doSave" :class="{ disabled: onProgress }" class="btn btn-primary">
              <span v-show="onProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              Publish
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import pageService from "@/service/page.service";
import dayjs from "dayjs";
import sections from "./sections";
import SeoInformation from "./SeoInformation.vue";

export default {
  sections,
  components: {
    SeoInformation,
  },
  data() {
    return {
      onProgress: false,
      detail: {
        id: "",
        name: "",
        title: "",
        slug: "",
        status: "",
        pageTemplate: "",
        pageSections: [],
        seoInformation: {
          id: "",
          metaTitle: "",
          metaDescription: "",
          keywords: "",
          metaImage: "",
        },
      },
    };
  },
  filters: {
    getDate(date) {
      if (!date) return "-";
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
  // computed: {
  //   formType() {
  //     return this.detail?.id ? "edit" : "add";
  //   },
  // },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      pageService
        .getHomepage()
        .then(({ data: { data } }) => {
          this.detail = data;

          // Sort by sequences
          //this.detail.pageSections.sort((a, b) => a.sequence - b.sequence);

          // Visibility
          this.detail.pageSections.forEach(
            (v) => (v.isShow = v.recordStatus != "INACTIVE")
          );

          // Mapping detail
          // Object.keys(this.detail).forEach((k) =>
          //   this.$set(this.detail, k, data[k])
          // );

          // // Mapping section
          // data.pageSections.forEach((s) =>
          //   this.$set(this.pageSections, s.sectionName, s)
          // );

          // // Mapping SEO Information
          // Object.keys(this.seoInformation).forEach((k) =>
          //   this.$set(this.seoInformation, k, data.seoInformation[k])
          // );
          // console.log(rsp)
          // for (const key in this.original.seoInformation) {
          //   if (key === "metaImageFileUrl") {
          //     this.original.seoInformation.imageFile = [
          //       await this.convertURLtoFile(
          //         this.original.seoInformation.metaImageFileUrl
          //       ),
          //     ];
          //   }
          // }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    async doSave() {
      // this.onProgress = true;
      // if (payload.template === "GENERAL_PAGE") {
      //   await this.setGeneralBody(payload);
      // } else if (
      //   payload.template === "HOMEPAGE" ||
      //   payload.template === "PRODUCT_PAGE"
      // ) {
      // console.log(payload, 'test')
      //await this.setPageSection(payload);
      // await this.setMainPage(payload);
      // delete this.body["content"];
      // delete this.body["mainImageDesktop"];
      // delete this.body["mainImageMobile"];
      // }

      // let data = {
      //   ...this.detail,
      //   seoInformation: this.seoInformation,
      //   pageSections: Object.entries(this.section).map(([k, v]) => v),
      // };

      // if (payload.status === "PREVIEW") {
      //   data = { id: this.detail.id, ...this.body, status: null };
      // }
      // console.log(url, data, 'data')
      await pageService
        .updatePage(this.detail.id, this.detail)
        .then(
          (res) => {
            this.load();
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            });
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        )
        .finally(() => {
          this.onProgress = false;
        });
    },
  },
  validations() {
    return {
      title: { required },
    };
  },
};
</script>