import CoInsuranceService from "../service/coinsurance.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListCoInsurance(_ctx, serverParams) {
            return CoInsuranceService.getCoInsuranceList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postCoInsurance(_ctx, serverParams) {
            return CoInsuranceService.postCoInsurance(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getCoInsuranceById(_ctx, serverParams) {
            return CoInsuranceService.getCoInsuranceById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateCoInsuranceById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return CoInsuranceService.putCoInsurance(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteCoInsuranceById(_ctx, serverParams) {
            return CoInsuranceService.deleteCoInsuranceById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
