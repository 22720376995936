import api from "./api";

class WeightContributionService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }
    
    getWeightContributionList(serverParams) {
        return this.api.get("product/weightContribution", { params: serverParams });
    }

    postWeightContribution(serverParams) {
        return this.api.post("product/weightContribution", { ...serverParams });
    }

    getWeightContributionById(serverParams) {
        return this.api.get("product/weightContribution/" + serverParams);
    }

    putWeightContribution(id, serverParams) {
        return this.api.put("product/weightContribution/" + id, { ...serverParams });
    }

    deleteWeightContributionById(serverParams) {
        return this.api.delete("product/delete/weightContribution/" + serverParams);
    }
}

export default new WeightContributionService();