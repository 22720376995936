<template>
  <div>
    <label class="btn btn-primary">
      <div class="label-icon">
        <img src="/assets/img/svg/icon-csv.svg" alt="Upload" />
      </div>
      <div class="label-text">
        <h5>Choose File</h5>
        <p>.csv or .xls</p>
      </div>
      <input
        type="file"
        accept=".csv, .xls, .xlsx"
        @change="handleFileChange"
      />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: File,
  },
  methods: {
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  &.btn {
    display: inline-flex;
    align-items: center;
    padding: 12px 16px;
    text-align: left;
  }

  .label {
    &-icon {
      margin-right: 10px;
      img {
        height: 36px;
        width: auto;
      }
    }

    &-text {
      h5 {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;
      }
      p {
        font-size: 10.5px;
        line-height: 16px;
        margin-bottom: 0;
      }
    }
  }

  input {
    display: none;
  }
}
</style>
