import api from "./api";

class SmsService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('notification')
    }

    getSmsList(serverParams) {
        return this.api.get("/notification/sms", { params: serverParams });
    }

    postSms(serverParams) {
        return this.api.post("/notification/sms", { ...serverParams });
    }

    getSmsById(serverParams) {
        return this.api.get("/notification/sms/" + serverParams);
    }

    putSms(id, serverParams) {
        return this.api.put("/notification/sms/" + id, { ...serverParams });
    }

    deleteSmsById(serverParams) {
        return this.api.delete("/notification/sms/" + serverParams);
    }

}

export default new SmsService();