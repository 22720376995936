var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('GroupFilter',{attrs:{"form-type":"edit","filter-select":_vm.filter},on:{"onFilter":_vm.doFilter}}),_c('div',{staticClass:"panel"},[_c('GroupNav'),_c('div',{staticClass:"panel-body p-0"},[_c('div',{staticClass:"panel mb-0 panel-inverse rounded-0"},[_c('div',{staticClass:"panel-heading rounded-0"},[_c('h4',{staticClass:"panel-title fw-bolder"},[_vm._v(_vm._s(_vm.nameGroupHospital)+" - "+_vm._s(_vm.codeGroupHospital))])])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","select-options":{ enabled: true },"totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
              enabled: true,
              dropdownAllowAll: false,
            },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  //initialSortBy: { field: 'name', type: 'asc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.selectionChanged,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'name'
                )?_c('input',{ref:"name",staticClass:"form-control",attrs:{"placeholder":"Search hospital"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.name.value); }}}):_vm._e(),(column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'hospitalTier.tier'
                )?_c('v-select',{attrs:{"placeholder":"Cari hospital Tier","options":_vm.paginatedHospitalTier.content,"reduce":function (option) { return option.id; },"filterable":false},on:{"open":_vm.openSelectHospitalTier,"search":_vm.searchHospitalTier,"option:selected":_vm.selectedHospitalTierToTemporary,"input":function (value) { return updateFilters(column, value); }}},[_c('li',{staticClass:"pagination",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('button',{attrs:{"disabled":!_vm.hasTierPrevPage},on:{"click":function($event){$event.preventDefault();return _vm.handlePrevProv('tier')}}},[_vm._v(" Prev ")]),_c('button',{attrs:{"disabled":!_vm.hasTierNextPage},on:{"click":function($event){$event.preventDefault();return _vm.handleNextProv('tier')}}},[_vm._v(" Next ")])])]):_vm._e()]}}])},[_c('template',{slot:"table-actions-bottom"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doEditGroup.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-end align-items-end"},[_c('div',{staticClass:"col-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Hospital Group Code")]),_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-text"},[_vm._v("PRGH")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hospitalGroupData.code),expression:"hospitalGroupData.code"}],staticClass:"form-control bg-white",attrs:{"type":"text","placeholder":"Code Number","disabled":!_vm.selectedHospitals},domProps:{"value":(_vm.hospitalGroupData.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hospitalGroupData, "code", $event.target.value)}}})]),(!_vm.$v.hospitalGroupData.code.required &&
                      _vm.$v.hospitalGroupData.code.$error
                      )?_c('span',{staticClass:"text-danger"},[_vm._v("Code is required")]):_vm._e()]),(_vm.$can(_vm.$permissionsAll[29]))?_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":!_vm.selectedHospitals}},[_vm._v("Edit Group")])]):_vm._e()])])])],2)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }