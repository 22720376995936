// GENERALI
import Dashboard from "../pages/Dashboard.vue";
import UserLogin from "../pages/Auth/User-login.vue";
import LupaPassword from "../pages/Auth/Lupa-password.vue";

// Analytics
import AnalyticsLayout from "../pages/Analytics/Layout.vue";
import AnalyticsBusiness from "../pages/Analytics/index.vue";
import AnalyticPerformance from "../pages/Analytics/Performance.vue";
import AnalyticDropoffIssue from "../pages/Analytics/DropoffIssue.vue";

// Submissions
import SubmissionsLayout from "../pages/Submissions/Layout.vue";
import SubmissionsLayoutList from "../pages/Submissions/LayoutList.vue";
import SubmissionsList from "../pages/Submissions/index.vue";
import SubmissionsListIncompleted from "../pages/Submissions/Incompleted.vue";
import SubmissionsStepsLayout from "../pages/Submissions/Steps/Layout.vue";
import SubmissionsStep1 from "../pages/Submissions/Steps/Step1.vue";
import SubmissionsStep2 from "../pages/Submissions/Steps/Step2.vue";
import SubmissionsStep3 from "../pages/Submissions/Steps/Step3.vue";
import SubmissionsStep4 from "../pages/Submissions/Steps/Step4.vue";
import SubmissionsStep5 from "../pages/Submissions/Steps/Step5.vue";
import SubmissionsStep6 from "../pages/Submissions/Steps/Step6.vue";
import SubmissionsStep7 from "../pages/Submissions/Steps/Step7.vue";
import SubmissionsStep8 from "../pages/Submissions/Steps/Step8.vue";

// Products
import ProductsLayout from "../pages/Products/Layout.vue";
import ProductsLayoutList from "../pages/Products/LayoutList.vue";
import ProductsList from "../pages/Products/index.vue";
import ProductsListDrafts from "../pages/Products/Drafts.vue";
import ProductsListTrashed from "../pages/Products/Trashed.vue";
import ProductAdd from "../pages/Products/Add.vue";
import ProductEdit from "../pages/Products/Edit.vue";
import ProductCategory from "../pages/Products/Category.vue";
import ProductRiplay from "../pages/Products/Riplay.vue";
import ProductImport from "../pages/Products/Import.vue";
import ProductConfiguration from "../pages/Products/Configuration.vue";

// Hospitals
import HospitalsLayout from "../pages/Hospitals/Layout.vue";
import HospitalsLayoutList from "../pages/Hospitals/LayoutList.vue";
import HospitalsList from "../pages/Hospitals/index.vue";
import HospitalsListDrafts from "../pages/Hospitals/Drafts.vue";
import HospitalsListTrashed from "../pages/Hospitals/Trashed.vue";
import HospitalAdd from "../pages/Hospitals/Add.vue";
import HospitalEdit from "../pages/Hospitals/Edit.vue";
import HospitalImport from "../pages/Hospitals/Import.vue";
import HospitalTier from "../pages/Hospitals/Tier.vue";
import HospitalArea from "../pages/Hospitals/Area.vue";
import HospitalGroup from "../pages/Hospitals/Group/index.vue";
import HospitalGroupEdit from "../pages/Hospitals/Group/Edit.vue";
import HospitalAvailable from "../pages/Hospitals/Group/Available.vue";

// Posts
import PostsLayout from "../pages/Posts/Layout.vue";
import PostsLayoutList from "../pages/Posts/LayoutList.vue";
import PostsList from "../pages/Posts/index.vue";
import PostsListDrafts from "../pages/Posts/Drafts.vue";
import PostsListTrashed from "../pages/Posts/Trashed.vue";
import PostsAdd from "../pages/Posts/Add.vue";
import PostsEdit from "../pages/Posts/Edit.vue";
import PostsCategory from "../pages/Posts/Category.vue";

// Pages
import PagesLayout from "../pages/Pages/Layout.vue";
import PagesLayoutList from "../pages/Pages/LayoutList.vue";
import PagesList from "../pages/Pages/index.vue";
import PagesListDrafts from "../pages/Pages/Drafts.vue";
import PagesListTrashed from "../pages/Pages/Trashed.vue";
//import PagesAdd from "../pages/Pages/Add.vue";
import PagesHomepage from "../pages/Pages/HomePage.vue";
//import PageEdit from "../pages/Pages/Edit.vue";
import PageEditor from "../pages/Pages/Editor.vue";
import PagesFaqsLayoutList from "../pages/Pages/Faq/LayoutFaq.vue";
import PagesFaqsList from "../pages/Pages/Faq/index.vue";
import PagesFaqsListDrafts from "../pages/Pages/Faq/Drafts.vue";
import PagesFaqsListTrashed from "../pages/Pages/Faq/Trashed.vue";
import PagesFaqsAdd from "../pages/Pages/Faq/Add.vue";
import PagesFaqsEdit from "../pages/Pages/Faq/Edit.vue";
import PagesFaqsCategory from "../pages/Pages/Faq/Category.vue";

// Sections
import SectionsLayout from "../pages/Sections/Layout.vue";
import SectionsBannerLayoutList from "../pages/Sections/Banner/LayoutList.vue";
import SectionsBannerList from "../pages/Sections/Banner/index.vue";
import SectionsBannerListDrafts from "../pages/Sections/Banner/Drafts.vue";
import SectionsBannerListTrashed from "../pages/Sections/Banner/Trashed.vue";

import SectionsBannerAdd from "../pages/Sections/Banner/Add.vue";
import SectionsBannerEdit from "../pages/Sections/Banner/Edit.vue";

//redeem
import SectionsRedeemAdd from "../pages/Redeem/Add.vue";
import SectionsRedeemEdit from "../pages/Redeem/Edit.vue";
import SectionsRedeemList from "../pages/Redeem/index.vue";
import SectionsRedeemUploadPopUp from "../pages/Redeem/UploadPopUp.vue";
import SectionsRedeemPopUp from "../pages/Redeem/PopUp.vue";
import SectionsRedeemPopUpEdit from "../pages/Redeem/UploadPopUpEdit.vue";

//reward
import SectionsRewardAdd from "../pages/Reward/Add.vue";
import SectionsRewardEdit from "../pages/Reward/Edit.vue";
import SectionsRewardList from "../pages/Reward/index.vue";

import SectionsMenus from "../pages/Sections/Menus/index.vue";
import SectionsMenusAdd from "../pages/Sections/Menus/Add.vue";
import SectionsMenusLocation from "../pages/Sections/Menus/Location.vue";
import SectionsOrganizationsLayoutList from "../pages/Sections/CharityOrganizations/LayoutList.vue";
import SectionsOrganizationsList from "../pages/Sections/CharityOrganizations/index.vue";
import SectionsOrganizationsListDrafts from "../pages/Sections/CharityOrganizations/Drafts.vue";
import SectionsOrganizationsListTrashed from "../pages/Sections/CharityOrganizations/Trashed.vue";
import SectionsOrganizationsAdd from "../pages/Sections/CharityOrganizations/Add.vue";
import SectionsOrganizationsEdit from "../pages/Sections/CharityOrganizations/Edit.vue";
import SectionsQuestionsList from "../pages/Sections/SioQuestionnaire/index.vue";
import SectionsQuestionsAdd from "../pages/Sections/SioQuestionnaire/Add.vue";
import SectionsQuestionsEdit from "../pages/Sections/SioQuestionnaire/Edit.vue";
import SectionsPDFList from "../pages/Sections/ManagePdf/index.vue";
import BankMaster from "../pages/Bank/BankMaster.vue";

// import SectionsBmiAdd from "../pages/Sections/Bmi/Add.vue";
import SectionsBmiEdit from "../pages/Sections/Bmi/Edit.vue";
import SectionsBmiList from "../pages/Sections/Bmi/index.vue";
import SectionsBmiAdd from "../pages/Sections/Bmi/Add.vue";

//Users
import UsersLayout from "../pages/Users/Layout.vue";
import UsersUserList from "../pages/Users/ManageUser/index.vue";
import UsersUserAdd from "../pages/Users/ManageUser/Add.vue";
import UsersUserEdit from "../pages/Users/ManageUser/Edit.vue";
import UsersRoleList from "../pages/Users/ManageRole/index.vue";
import UsersRoleAdd from "../pages/Users/ManageRole/Add.vue";
import UsersRoleEdit from "../pages/Users/ManageRole/Edit.vue";
import UsersReports from "../pages/Users/Reports/index.vue";

//Notification
import NotificationSMSList from "../pages/Notification/Sms/index.vue";
import NotificationSMSAdd from "../pages/Notification/Sms/Add.vue";
import NotificationSMSEdit from "../pages/Notification/Sms/Edit.vue";
import NotificationEmailList from "../pages/Notification/Email/index.vue";
import NotificationEmailAdd from "../pages/Notification/Email/Add.vue";
import NotificationEmailEdit from "../pages/Notification/Email/Edit.vue";
import NotificationCategory from "../pages/Notification/Category";


import ReferralList from "../pages/Referral/index.vue";
import ReferralAdd from "../pages/Referral/Add.vue";
import ReferralEdit from "../pages/Referral/Edit.vue";
import ReferralImport from "../pages/Referral/Import.vue";

import Permissions from "./Permissions";

const routes = [
  // GENERALI
  { path: "/dashboard", component: Dashboard },
  // { path: "/dashboard", redirect: "/submissions" },
  { path: "/login", component: UserLogin },
  {
    path: "/password/:id",
    component: LupaPassword,
    name: "resetForgotPassword",
  },

  {
    path: "/analytics",
    component: AnalyticsLayout,
    children: [
      {
        path: "business",
        component: AnalyticsBusiness,
        name: "AnalyticsBusiness",
      },
      {
        path: "performance-metric",
        component: AnalyticPerformance,
        name: "AnalyticPerformance",
      },
      {
        path: "dropoff-issue",
        component: AnalyticDropoffIssue,
        name: "AnalyticDropoffIssue",
      },
    ],
  },

  {
    path: "/submissions",
    component: SubmissionsLayout,
    children: [
      {
        path: "",
        component: SubmissionsLayoutList,
        children: [
          {
            path: "",
            component: SubmissionsList,
            name: "SubmissionsList",
          },
          {
            path: "incompleted",
            component: SubmissionsListIncompleted,
            name: "SubmissionsListIncompleted",
          },
        ]
      },
      {
        path: "forms/:id",
        component: SubmissionsStepsLayout,
        children: [
          {
            path: "step-1",
            component: SubmissionsStep1,
            name: "SubmissionsStep1",
          },
          {
            path: "step-2",
            component: SubmissionsStep2,
            name: "SubmissionsStep2",
          },
          {
            path: "step-3",
            component: SubmissionsStep3,
            name: "SubmissionsStep3",
          },
          {
            path: "step-4",
            component: SubmissionsStep4,
            name: "SubmissionsStep4",
          },
          {
            path: "step-5",
            component: SubmissionsStep5,
            name: "SubmissionsStep5",
          },
          {
            path: "step-6",
            component: SubmissionsStep6,
            name: "SubmissionsStep6",
          },
          {
            path: "step-7",
            component: SubmissionsStep7,
            name: "SubmissionsStep7",
          },
          {
            path: "step-8",
            component: SubmissionsStep8,
            name: "SubmissionsStep8",
          }
        ]
      },
    ]
  },

  {
    path: "/products",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: ProductsLayoutList,
        children: [
          {
            path: "",
            component: ProductsList,
            name: "ProductsList",
          },
          {
            path: "drafts",
            component: ProductsListDrafts,
            name: "ProductsListDrafts",
          },
          {
            path: "trashed",
            component: ProductsListTrashed,
            name: "ProductsListTrashed",
          },
        ]
      },
      {
        path: "add",
        component: ProductAdd,
        name: "productAdd",
      },
      {
        path: "edit/:id",
        component: ProductEdit,
        name: "productEdit",
      },
      {
        path: "riplay",
        component: ProductRiplay,
        name: "productRiplay",
      },
      {
        path: "import",
        component: ProductImport,
        name: "productImport",
      },
      {
        path: "category",
        component: ProductCategory,
        name: "productCategory",
      },
      {
        path: "configuration",
        component: ProductConfiguration,
        name: "productConfiguration",
      },
    ],
  },

  {
    path: "/hospitals",
    component: HospitalsLayout,
    children: [
      {
        path: "list",
        component: HospitalsLayoutList,
        children: [
          {
            path: "",
            component: HospitalsList,
            name: "HospitalsList",
          },
          {
            path: "drafts",
            component: HospitalsListDrafts,
            name: "HospitalsListDrafts",
          },
          {
            path: "trashed",
            component: HospitalsListTrashed,
            name: "HospitalsListTrashed",
          },
        ]
      },
      {
        path: "add",
        component: HospitalAdd,
        name: "HospitalAdd",
      },
      {
        path: "edit/:id",
        component: HospitalEdit,
        name: "HospitalEdit",
      },
      {
        path: "import",
        component: HospitalImport,
        name: "HospitalImport",
      },
      {
        path: "tier",
        component: HospitalTier,
        name: "HospitalTier",
      },
      {
        path: "area",
        component: HospitalArea,
        name: "HospitalArea",
      },
      {
        path: "group",
        component: HospitalAvailable,
        name: "HospitalAvailable",
      },
      {
        path: "group/list",
        component: HospitalGroup,
        name: "HospitalGroup",
      },
      {
        path: "group/list/:id",
        component: HospitalGroupEdit,
        name: "HospitalGroupEdit",
      },
    ],
  },

  {
    path: "/posts",
    component: PostsLayout,
    children: [
      {
        path: "list",
        component: PostsLayoutList,
        children: [
          {
            path: "",
            component: PostsList,
            name: "PostsList",
          },
          {
            path: "drafts",
            component: PostsListDrafts,
            name: "PostsListDrafts",
          },
          {
            path: "trashed",
            component: PostsListTrashed,
            name: "PostsListTrashed",
          },
        ]
      },
      {
        path: "add",
        component: PostsAdd,
        name: "PostsAdd",
      },
      {
        path: "edit/:id",
        component: PostsEdit,
        name: "PostsEdit",
      },
      {
        path: "category",
        component: PostsCategory,
        name: "PostsCategory",
      },
    ],
  },

  {
    path: "/pages",
    component: PagesLayout,
    children: [
      {
        path: "list",
        component: PagesLayoutList,
        children: [
          {
            path: "",
            component: PagesList,
            name: "PagesList",
          },
          {
            path: "drafts",
            component: PagesListDrafts,
            name: "PagesListDrafts",
          },
          {
            path: "trashed",
            component: PagesListTrashed,
            name: "PagesListTrashed",
          },
        ]
      },
      {
        path: "homepage",
        component: PagesHomepage,
        name: "PagesHomepage",
      },
      {
        path: "add",
        component: PageEditor,
        name: "PagesAdd",
      },
      {
        path: "edit/:id",
        component: PageEditor,
        name: "PageEdit",
      },
      {
        path: "faq/list",
        component: PagesFaqsLayoutList,
        children: [
          {
            path: "",
            component: PagesFaqsList,
            name: "PagesFaqsList",
          },
          {
            path: "drafts",
            component: PagesFaqsListDrafts,
            name: "PagesFaqsListDrafts",
          },
          {
            path: "trashed",
            component: PagesFaqsListTrashed,
            name: "PagesFaqsListTrashed",
          },
        ]
      },
      {
        path: "faq/add",
        component: PagesFaqsAdd,
        name: "PagesFaqsAdd",
      },
      {
        path: "faq/edit/:id",
        component: PagesFaqsEdit,
        name: "PagesFaqsEdit",
      },
      {
        path: "faq/category",
        component: PagesFaqsCategory,
        name: "PagesFaqsCategory",
      },
    ],
  },

  {
    path: "/sections/banner",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsBannerLayoutList,
        children: [
          {
            path: "",
            component: SectionsBannerList,
            name: "SectionsBannerList",
          },
          {
            path: "drafts",
            component: SectionsBannerListDrafts,
            name: "SectionsBannerListDrafts",
          },
          {
            path: "trashed",
            component: SectionsBannerListTrashed,
            name: "SectionsBannerListTrashed",
          },
        ]
      },
      {
        path: "add",
        component: SectionsBannerAdd,
        name: "SectionsBannerAdd",
      },
      {
        path: "edit/:id",
        component: SectionsBannerEdit,
        name: "SectionsBannerEdit",
      },
    ],
  },

  {
    path: "/sections/menus",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsMenus,
        name: "SectionsMenus",
      },
      {
        path: "add",
        component: SectionsMenusAdd,
        name: "SectionsMenusAdd",
      },
      {
        path: "location",
        component: SectionsMenusLocation,
        name: "SectionsMenusLocation",
      },
    ],
  },

  {
    path: "/sections/charity-organizations",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsOrganizationsLayoutList,
        children: [
          {
            path: "",
            component: SectionsOrganizationsList,
            name: "SectionsOrganizationsList",
          },
          {
            path: "drafts",
            component: SectionsOrganizationsListDrafts,
            name: "SectionsOrganizationsListDrafts",
          },
          {
            path: "trashed",
            component: SectionsOrganizationsListTrashed,
            name: "SectionsOrganizationsListTrashed",
          },
        ]
      },
      {
        path: "add",
        component: SectionsOrganizationsAdd,
        name: "SectionsOrganizationsAdd",
      },
      {
        path: "edit/:id",
        component: SectionsOrganizationsEdit,
        name: "SectionsOrganizationsEdit",
      },
    ],
  },

  {
    path: "/sections/sio-questionnaire",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsQuestionsList,
        name: "SectionsQuestionsList",
      },
      {
        path: "add",
        component: SectionsQuestionsAdd,
        name: "SectionsQuestionsAdd",
      },
      {
        path: "edit/:id",
        component: SectionsQuestionsEdit,
        name: "SectionsQuestionsEdit",
      },
    ],
  },
  {
    path: "/sections/bmi",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsBmiList,
        name: "SectionsBmiList",
      },
      {
        path: "add",
        component: SectionsBmiAdd,
        name: "SectionsBmiAdd",
      },
      {
        path: "edit/:id",
        component: SectionsBmiEdit,
        name: "SectionsBmiEdit",
      },
    ],
  },
  {
    path: "/redeem",
    component: SectionsLayout,
    children: [
      {
        path: "list",
        component: SectionsRedeemList,
        name: "SectionsRedeemList",
      },
      {
        path: "add",
        component: SectionsRedeemAdd,
        name: "SectionsRedeemAdd",
      },
      {
        path: "edit/:id",
        component: SectionsRedeemEdit,
        name: "SectionsRedeemEdit",
      },
      {
        path: "popup",
        component: SectionsRedeemPopUp,
        name: "SectionsRedeemPopUp",
      },
      {
        path: "upload/popup",
        component: SectionsRedeemUploadPopUp,
        name: "SectionsRedeemUploadPopUp",
      },
      {
        path: "edit/popup/:id",
        component: SectionsRedeemPopUpEdit,
        name: "SectionsRedeemPopUpEdit",
      },
    ],
  },
  {
    path: "/reward",
    component: SectionsLayout,
    children: [
      {
        path: "list",
        component: SectionsRewardList,
        name: "SectionsRewardList",
      },
      {
        path: "add",
        component: SectionsRewardAdd,
        name: "SectionsRewardAdd",
      },
      {
        path: "edit/:id",
        component: SectionsRewardEdit,
        name: "SectionsRewardEdit",
      }
    ],
  },
  {
    path: "/sections/manage-pdf",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsPDFList,
        name: "SectionsPDFList",
      },
    ],
  },
  {
    path: "/sections/bank",
    component: BankMaster,
  },
  {
    path: "/users",
    component: UsersLayout,
    children: [
      {
        path: "manage",
        component: UsersLayout,
        children: [
          {
            path: "user",
            component: UsersLayout,
            children: [
              {
                path: "",
                component: UsersUserList,
                name: "UsersUserList",
              },
              {
                path: "add",
                component: UsersUserAdd,
                name: "UsersUserAdd",
              },
              {
                path: "edit/:id",
                component: UsersUserEdit,
                name: "UsersUserEdit",
              },
            ]
          },
          {
            path: "role",
            component: UsersLayout,
            children: [
              {
                path: "",
                component: UsersRoleList,
                name: "UsersRoleList",
              },
              {
                path: "add",
                component: UsersRoleAdd,
                name: "UsersRoleAdd",
              },
              {
                path: "edit/:id",
                component: UsersRoleEdit,
                name: "UsersRoleEdit",
              },
            ]
          },
        ],
      },
      {
        path: "reports",
        component: UsersReports,
        name: "UsersReports",
      },
    ],
  },


  {
    path: "/referral",
    component: PagesLayout,
    children: [
      {
        path: "list",
        component: ReferralList,
        name: "ReferralList",
      },
      {
        path: "add",
        component: ReferralAdd,
        name: "ReferralAdd",
      },
      {
        path: "edit/:id",
        component: ReferralEdit,
        name: "ReferralEdit",
      },
      {
        path: "import",
        component: ReferralImport,
        name: "ReferralImport",
      },
    ],
  },
  {
    path: "/notification",
    component: SectionsLayout,
    children: [
      {
        path: "sms",
        component: SectionsLayout,
        children: [
          {
            path: "",
            component: NotificationSMSList,
            name: "NotificationSMSList",
          },
          {
            path: "add",
            component: NotificationSMSAdd,
            name: "NotificationSMSAdd",
          },
          {
            path: "edit/:id",
            component: NotificationSMSEdit,
            name: "NotificationSMSEdit",
          },
        ]
      },
      {
        path: "email",
        component: SectionsLayout,
        children: [
          {
            path: "",
            component: NotificationEmailList,
            name: "NotificationEmailList",
          },
          {
            path: "add",
            component: NotificationEmailAdd,
            name: "NotificationEmailAdd",
          },
          {
            path: "edit/:id",
            component: NotificationEmailEdit,
            name: "NotificationEmailEdit",
          },
        ]
      },
      {
        path: "category",
        component: SectionsLayout,
        name: "category",
        children: [
          {
            path: "",
            component: NotificationCategory,
            name: "NotificationCategory",
          },
        ]
      },
    ],
  },
  // { path: "*", redirect: "/dashboard" },
  { path: "*", redirect: "/submissions" },
];

export default routes;
