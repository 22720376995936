import BenefitLimitService from "../service/benefitlimit.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListBenefitLimit(_ctx, serverParams) {
            return BenefitLimitService.getBenefitLimitList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postBenefitLimit(_ctx, serverParams) {
            return BenefitLimitService.postBenefitLimit(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getBenefitLimitById(_ctx, serverParams) {
            return BenefitLimitService.getBenefitLimitById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateBenefitLimitById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return BenefitLimitService.putBenefitLimit(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteBenefitLimitById(_ctx, serverParams) {
            return BenefitLimitService.deleteBenefitLimitById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
