<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Activity Report</h1>
        <!-- END page-header -->
      </div>
    </div>

    <div class="panel">
      <div class="panel-heading">
        <div class="row">
          <label class="form-label col-form-label col-auto">Date range</label>
          <div class="form-group col-auto">
            <date-range-picker
              class="btn btn-white"
              v-model="filters.activityTime"
            >
            </date-range-picker>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-outline-primary">
              Generate
            </button>
          </div>
          <div class="col-auto">
            <b-dropdown variant="outline-secondary">
              <template slot="button-content">
                Download <i class="fa fa-chevron-down"></i>
              </template>
              <a :href="download.csv" target="_BLANK" class="dropdown-item"
                >CSV</a
              >
              <a :href="download.excel" target="_BLANK" class="dropdown-item"
                >Excel</a
              >
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-alert variant="warning" show dismissible>
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-triangle-exclamation me-3"></i>
        <span
          >Please add more filter or reduce the date range, maximum allowed
          download is 5000 rows</span
        >
      </div>
    </b-alert> -->

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
        initialSortBy: { field: 'activityTime', type: 'desc' },
      }"
      styleClass="vgt-table"
      slot="outsideBody"
    >
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input
          class="form-control"
          placeholder="Search username"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'username'
          "
          @input="($event) => updateFilters(column, $event.target.value)"
        />
        <select
          class="form-select"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'action'
          "
          @input="($event) => updateFilters(column, $event.target.value)"
        >
          <option value="">All</option>
          <option>Login</option>
          <option>Logout</option>
          <option>Get</option>
          <option>Change</option>
          <option>Create</option>
          <option>Update</option>
          <option>Delete</option>
          <option>List</option>
          <option>History</option>
          <option>Notification</option>
          <option>Send</option>
          <option>Refresh</option>
        </select>
        <select
          class="form-select"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'actionType'
          "
          @input="($event) => updateFilters(column, $event.target.value)"
        >
          <option value="">All</option>
          <option>Auth</option>
          <option>Page</option>
          <option>Data</option>
          <option>Download</option>
          <option>Email</option>
          <option>Import</option>
          <option>Service</option>
          <option>SMS</option>
        </select>
        <input
          class="form-control"
          placeholder="Search info"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'information'
          "
          @input="($event) => updateFilters(column, $event.target.value)"
        />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === '_action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-lg text-dark"
                v-b-tooltip.hover
                title="Show"
                @click="showDetail(props.row)"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <b-modal ref="detailModal" title="Detail aktivitas" hide-footer size="lg">
      <description-list :hide="['id']" :value="selected">
        <template #details:value:dd="{ value }">
          <description-list v-if="value[0] == '{'" :value="JSON.parse(value)" />
          <pre v-else v-text="value"></pre>
        </template>
      </description-list>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import activityService from "@/service/activity.service";
import DescriptionList from "@/components/widget/DescriptionList.vue";

export default {
  components: { DescriptionList },
  data() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    return {
      isLoading: false,
      selected: null,
      download: { csv: "#", excel: "#" },
      columns: [
        {
          label: "Date",
          field: "activityTime",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "P p",
          sortable: true,
          firstSortType: "desc",
          thClass: "vgt-left-align",
          tdClass: "vgt-left-align align-middle",
        },
        {
          label: "Principal",
          field: "username",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Type",
          field: "actionType",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action/Event",
          field: "action",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Info",
          field: "information",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "_action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      filters: {
        activityTime: { startDate, endDate },
        username: null,
        actionType: null,
        action: null,
        information: null,
      },
      serverParams: {
        sort: ["activityTime,desc"],
        page: 1,
        perPage: 10,
        query: null,
      },
    };
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(filters) {
        let query = { bool: {} };
        query.bool.filter = {
          range: {
            activityTime: {
              gte: moment(filters.activityTime.startDate)
                .startOf("day")
                .format("YYYYMMDDTHHmmss.SSSZ"),
              lte: moment(filters.activityTime.endDate)
                .endOf("day")
                .format("YYYYMMDDTHHmmss.SSSZ"),
            },
          },
        };

        const filteredFilter = Object.entries(filters).filter(
          ([field, value]) => !!value && field != "activityTime"
        );

        if (filteredFilter.length) {
          query.bool.must = filteredFilter.map(([field, value]) => ({
            match: { [field]: value },
          }));
        }
        this.$nextTick(() => (this.serverParams.query = query));
      },
    },
    serverParams: {
      deep: true,
      handler(params) {
        if (this.$data._debounceQueryTimer) {
          clearTimeout(this.$data._debounceQueryTimer);
          console.debug("loadItems: cancel previous stack");
        }

        this.$data._debounceQueryTimer = setTimeout(() => {
          this.rows = [];
          this.isLoading = true;
          // Just one level clone, not nested!
          const cloned = { ...params };
          // ES Pageable start from 0
          cloned.page = cloned.page - 1;
          // Query is JSON encoded
          cloned.query = cloned.query && JSON.stringify(cloned.query);

          this.download.csv = activityService.getCmsLogActivityDownloadUrl(
            "csv",
            cloned.query
          );
          this.download.excel = activityService.getCmsLogActivityDownloadUrl(
            "excel",
            cloned.query
          );

          activityService
            .getCmsLogActivity(cloned)
            .then((rsp) => {
              this.rows = rsp.data.data ? rsp.data.data.content : [];
            })
            .catch((e) => {
              this.$notify({
                type: "error",
                title: "Error Notification",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
            })
            .finally(() => (this.isLoading = false));

          this.$data._debounceQueryTimer = null;
        }, 700);
      },
    },
  },
  methods: {
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
    },
    onPerPageChange(params) {
      this.serverParams.page = 1;
      this.serverParams.perPage = params.currentPerPage;
    },

    onSortChange(params) {
      this.serverParams.sort = params.map(({ field, type }) =>
        type == "none" ? field : `${field},${type}`
      );
    },
    onColumnFilter(params) {
      if (params.columnFilters) {
        Object.assign(this.filters, params.columnFilters);
      }
    },
    showDetail(value) {
      this.selected = value;
      this.$nextTick(() => this.$refs.detailModal.show());
    },
  },
};
</script>
