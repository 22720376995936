<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All FAQs</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="$can($permissionsAll[40])">
        <router-link to="/pages/faq/add">
          <a class="btn btn-outline-primary"> Add New FAQ</a>
        </router-link>
      </div>
    </div>
    <ul class="nav nav-tabs nav-tabs--wp">
      <li class="nav-item">
        <router-link to="/pages/faq/list" class="nav-link" active-class="active" exact>
          Published <span class="badge">{{ publishCount }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/pages/faq/list/drafts" class="nav-link" active-class="active" exact>
          Drafts <span class="badge">{{ draftCount }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/pages/faq/list/trashed" class="nav-link" active-class="active" exact>
          Trashed <span class="badge">{{ trashCount }}</span>
        </router-link>
      </li>
    </ul>
    <router-view @reloadData="reloadData"></router-view>
  </div>
</template>

<script>
import faqService from "@/service/faq.service"
export default {
  data() {
    return {
      publishCount: 0,
      draftCount: 0,
      trashCount: 0,
    }
  },
  methods: {
    loadItems() {
      // publish
      let searchPublishParam = {
        status: "PUBLISHED",
      };
      faqService.getFaqList(searchPublishParam).then((rsp) => {
        this.publishCount = rsp.data?.data ? rsp.data?.data.numberOfElements : 0
      });

      // draft
      let searchDraftParam = {
        status: "DRAFTS",
      };
      faqService.getFaqList(searchDraftParam).then((rsp) => {
        this.draftCount = rsp.data?.data ? rsp.data?.data.numberOfElements : 0
      });

      // trash
      let searchTrashParam = {
        status: "TRASHED",
      };
      faqService.getFaqList(searchTrashParam).then((rsp) => {
        this.trashCount = rsp.data?.data ? rsp.data?.data.numberOfElements : 0
      });
    },
    reloadData(e) {
      this.loadItems()
    }
  },
  mounted() {
    this.loadItems()
  },
}
</script>