<template>
  <draggable v-bind="dragOptions" class="dragArea" tag="ul" :list="list" :value="value" :group="{ name: 'menusGroup' }"
    @input="emitter">
    <li v-for="(item, index) in realValue" :key="item.title">
      <panel :title="item.title" variant="grey" :is-collapse="true" :checkbox-hidden="true" v-model="item.isShow"
        :show-collapse="false" :checkbox-name="`menuItem-${index}`">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label class="form-label fw-normal">Navigation Label</label>
              <input type="text" v-model="item.label" placeholder="Navigation Label" class="form-control">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label class="form-label fw-normal">Title Attribute</label>
              <input type="text" v-model="item.attribute" placeholder="Title Attribute" class="form-control">
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6">
            <label class="form-label fw-normal">Link URL</label>
            <input type="text" v-model="item.href" @input="e => item.hrefLink = generateHrefLink(e.target.value)" placeholder="Link URL" class="form-control">
          </div>
          <div class="col-md-6">
            <div class="form-label fw-normal">
              Link Preview
            </div>
            <div class="form-control">
              <a :href="item.hrefLink" class="d-block" :title="item.title" target="_blank">{{ item.label }}</a>
            </div>
          </div>
        </div>
        <div class="row g-2 align-items-center">
          <div class="col-auto">
            <button class="btn p-0 fw-normal text-danger" type="button" @click.prevent="onRemove(index, item)">
              Remove
            </button>
          </div>
          <div class="col-auto">
            |
          </div>
          <div class="col-auto">
          </div>
        </div>
      </panel>
      <!-- <nested-draggable :list="item.submenus" /> -->
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import menuService from "@/service/menu.service";
import pageService from '@/service/page.service';

export default {
  name: "nested-draggable",
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
  components: {
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "menusGroup",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  methods: {
    generateHrefLink: pageService.generateHrefLink,
    emitter(value) {
      this.$emit("input", value);
    },
    onRemove(i, v) {
      if (v.id) {
        menuService
          .deleteChildMenuById(v.id)
          .then((rsp) => {
            this.$notify({
              type: 'succes',
              title: 'Succes Notification',
              text: 'Menu Success Remove'
            });
            this.$emit("refresh", true);
          })
          .catch((e) => {
            this.$notify({
              type: "error",
              title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"}`,
              text: e.response.data?.responseMessage || "Gagal mengambil data",
            });
          });
        this.realValue.splice(i, 1);
      } else {
        this.realValue.splice(i, 1);
      }
    }
  },
  mounted() {
    // console.log(this.list, 'test')
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    cursor: move;
    margin-bottom: 0;
    width: 500px;

    ul {
      margin-left: 30px;
    }
  }
}
</style>
