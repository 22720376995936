<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Users</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <router-link to="/users/manage/user/add">
          <a class="btn btn-outline-primary"> Add New User</a>
        </router-link>
      </div>
    </div>

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
        initialSortBy: { field: 'username', type: 'asc' },
      }"
      styleClass="vgt-table"
      slot="outsideBody"
    >
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input
          ref="username"
          class="form-control"
          placeholder="Search username"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'username'
          "
          @input="(value) => updateFilters(column, $refs.username.value)"
        />
        <input
          ref="userRole"
          class="form-control"
          placeholder="Search role"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'role.name'
          "
          @input="(value) => updateFilters(column, $refs.userRole.value)"
        />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto">
              <router-link
                :to="`/users/manage/user/edit/${props.row.id}`"
                class="btn btn-lg text-dark"
                v-b-tooltip.hover
                title="Edit"
              >
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAlertConfirm(props.row.id)"
                class="btn btn-lg text-dark"
                v-b-tooltip.hover
                title="Move to Trash"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import userService from "@/service/user.service";

export default {
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Username",
          field: "username",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Role",
          field: "role.name",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "username",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ pageNumber: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageNumber: 0 });
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      // console.log(params, 'test');
      // this.updateParams({
      //   sort: [
      //     {
      //       type: params[0].type,
      //       field: params[0].field,
      //     },
      //   ],
      // });
      this.loadItems();
    },

    onColumnFilter(params) {
      const { username, ...roleName } = params.columnFilters;
      params = {
        usernames: [],
        roles: [],
      };

      if (username) {
        params.usernames.push(username);
      }

      if (roleName["role.name"]) {
        params.roles.push(roleName["role.name"]);
      }

      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      // Cancel previous call
      if (this.$data._debounceQueryTimer)
        clearTimeout(this.$data._debounceQueryTimer);
      this.$data._debounceQueryTimer = setTimeout(() => {
        userService
          .getListUser(this.serverParams)
          .then((rsp) => {
            this.rows = rsp.data.data ? rsp.data.data.content : [];
          })
          .catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Notification",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          });

        this.$data._debounceQueryTimer = null;
      }, 300);
    },
    onDelete(id) {
      userService
        .deleteUserById(id)
        .then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.loadItems();
        })
        .catch((e) => {
          this.$swal({
            icon: "warning",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
