import api from "./api";
import apim from "./api.multipart";


class ProductService {
    /** @type {ApiAxiosInstance} */
    api;
    apim;

    constructor() {
        this.api = api.create('product')
        this.apim = apim.create('product')
    }

    getProductList(serverParams) {
        return this.api.get("/product", { params: serverParams });
    }

    postProduct(serverParams) {
        return this.api.post("/product", { ...serverParams });
    }

    getProductById(serverParams) {
        return this.api.get("/product/" + serverParams);
    }

    putProduct(id, serverParams) {
        return this.api.put("/product/" + id, { ...serverParams });
    }

    deleteProductById(serverParams) {
        return this.api.delete("/product/delete/" + serverParams);
    }

    getHistoryImportProduct(serverParams) {
        return this.api.get("/product/import-history", { params: serverParams });
    }

    importProduct(formData) {
        return this.apim.post('/import-product', formData)
    }

    duplicateProduct(id) {
        return this.api.post('/product/duplicate?id=' + id)
    }

    previewProduct(serverParams) {
        return this.api.post('/product/preview', { ...serverParams })
    }

    changeProductStatus(id, data) {
        return this.api.put(`update/product-status/${id}?status=${data}`)
    }

    getProductByGroup(serverParams) {
        return this.api.get("/product/group/" + serverParams);
    }
    getProductWeight(serverParams) {
        return this.api.get("/product/weightContribution", { params: serverParams });
    }
    getPaymentFrequency(serverParams) {
        return this.api.get("/product/paymentFrequency", { params: serverParams });
    }
}

export default new ProductService();