<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <h1 class="page-header">Import Hospital</h1>
    <b-alert variant="danger" show dismissible v-if="error">
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-circle-xmark"></i>
        <!-- <span><strong>Import Hospital Failed.</strong> Unrecognized file format. Kindly check and reupload.</span> -->
        <span><strong>Import Hospital Failed.</strong>Terjadi kesalahan upload</span>
      </div>
    </b-alert>
    <b-alert variant="success" show dismissible v-if="success">
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-circle-check"></i>
        <span><strong>Import Hospital Success.</strong> Kindly check <router-link to="/hospitals/list">All
            Hospitals</router-link> page.</span>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-lg-9 col-md-8">
        <div class="row align-items-center mb-4">
          <div class="col-auto">
            <!-- <Upload v-model="importFile"/> -->
            <Upload @input="fileUpload" />
          </div>
          <div class="col-auto">
            <p class="mb-0">or <a href="/assets/excel/hospital/template-import-hospital.xlsx"
                class="text-primary">download template</a></p>
          </div>
          <div class="col-12">
            <p class="mt-2" v-if="importFile">Selected File: {{ importFile.name }}</p>
          </div>
          <div class="col-auto mt-2">
            <button type="submit" class="btn btn-primary" v-if="importFile">
              Upload
            </button>
          </div>
          <!-- <span
              class="text-danger"
              v-if="!$v.importFile.required && $v.importFile.$error"
              >Hospital Name is required</span
          > -->
        </div>

        <h3 class="fs-18px mb-15px fw-normal">Import History</h3>
        <div class="table-responsive bg-white">
          <table class="table table-form">
            <thead>
              <tr>
                <th width="40%">File Name</th>
                <th>Imported by</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in rows" :key="index">
                <td>{{ item.filename }}</td>
                <td>{{ item.createdBy }}</td>
                <td>{{ dateFormat(item.created) }}</td>
                <td>{{ item.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Upload from '../../components/upload/csv.vue';
import { required } from "vuelidate/lib/validators";
import hospitalService from "../../service/hospital.service";
export default {
  components: {
    Upload,
  },
  data() {
    return {
      importFile: null,
      success: false,
      error: false,
      rows: [],
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 100,
        name: [],
        by: [],
        date: [],
        status: []
      }
    };
  },
  methods: {
    doSave() {
      // const payload = {
      //   riplay: this.product.riplay,
      //   information: this.product.information,
      // };

      // console.log("onValidateSuccess", payload);
      const formData = new FormData()

      formData.append('file', this.importFile, this.importFile?.name)

      hospitalService.importFile(formData)
        .then(
          (response) => {
            this.success = true
            this.error = false
          }
        ).catch((error) => {
          this.error = true
          this.success = false
          console.debug(error.response.data.message)
        });
      //  if (!this.$v.importFile.$invalid) {
      //   this.$emit("onValidateSuccess", this.hospital);
      // } else {
      //   console.log(this.$v.hospital)
      //   console.debug("False")
      // }

    },
    fileUpload(file) {
      if (file) {
        this.importFile = file
      }
    },
    loadItems() {
      hospitalService
        .getHistoryImportHistory(this.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.content;
        })
        .catch((e) => {
          this.rows = [];
          if (e.response.status == 404) {
            this.$notify({
              type: "warning",
              title: "Tidak Ditemukan",
              text: e.response.data?.responseMessage || "Gagal mengambil data",
            });
          } else {
            this.$notify({
              type: "error",
              title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
                }`,
              text: e.response.data?.responseMessage || "Gagal mengambil data",
            });
          }
        });
    },
    dateFormat(date) {
      let arrDate = date.split(' ')
      let newDate = new Date(arrDate[0])
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return newDate.toLocaleDateString("id", options);
    }
  },
  mounted() {
    this.loadItems()
  }

  // validations() {
  //   return { 
  //     importFile: required
  //   }
  // }
};
</script>
