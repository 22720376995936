import CharityService from "../service/charity.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListCharity(_ctx, serverParams) {
            return CharityService.getCharityList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postCharity(_ctx, serverParams) {
            return CharityService.postCharity(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getCharityById(_ctx, serverParams) {
            return CharityService.getCharityById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateCharityById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return CharityService.putCharity(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteCharityById(_ctx, serverParams) {
            return CharityService.deleteCharityById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getCharityFile(_ctx, serverParams) {
            return CharityService.getCharityFile(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
