<template>
  <div class="product-form">
    <FormNotification form-type="edit" v-bind:detail-data="detail" @onValidateSuccess="doSave" :on-loading="onProgress" />
  </div>
</template>
<script>
import FormNotification from "./Form.vue";
import EmailService from "@/service/email.service";

export default {
  data() {
    return {
      onProgress: false,
      detail: {
        id: this.$route.params.id,
        title: "",
        description: ""
      },
    };
  },
  components: {
    FormNotification,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      this.onProgress = true
      if (payload) {
        EmailService
          .putEmail(this.$route.params.id, payload)
          .then((rsp) => {
            this.onProgress = false
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.$router.push("/notification/email");
            });
          }).catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          })

      }
    },
    loadItems() {
      EmailService
        .getEmailById(this.$route.params.id)
        .then((rsp) => {
          this.detail = rsp.data.data
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    }
  },
  created() {
    this.loadItems();
  },
};
</script>
