<template>
    <div class="product-form">
        <FormNotification form-type="add" @onValidateSuccess="doSave" :on-loading="onProgress" />
    </div>
</template>
<script>
import FormNotification from './Form.vue'
import EmailService from "@/service/email.service";

export default {
    components: {
        FormNotification
    },
    data() {
        return {
            onProgress: false,
        };
    },
    methods: {
        async doSave(payload) {
            // Submit here
            this.onProgress = true
            if (payload) {
                EmailService
                    .postEmail(payload)
                    .then((rsp) => {
                        this.onProgress = false
                        this.$swal({
                            title: "Data berhasil disimpan",
                            showCloseButton: true,
                        }).then(() => {
                            this.$router.push("/notification/email");
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })

            }
        }
    }
}
</script>