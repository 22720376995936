<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Drop-Off Issue Error</h1>
        <!-- END page-header -->
      </div>
    </div>

    <div class="panel">
      <div class="panel-heading">
        <div class="row w-100">
          <label class="form-label col-form-label col-auto">Date range</label>
          <div class="form-group col-auto">
            <date-range-picker class="btn btn-white" v-model="filters.issueTime">
            </date-range-picker>
          </div>
          <label class="form-label col-form-label col-auto">Drop-off Issue</label>
          <div class="form-group col-3">
            <v-select v-model="filters.issueType" :reduce="(option) => option.code" :options="[
              { label: 'All', code: null },
              { label: 'Api Integration Error', code: 'API_ERROR' },
              { label: 'Page Error', code: 'PAGE_ERROR' },
              { label: 'Notification Error', code: 'NOTIFICATION_ERROR' },
              { label: 'Service Error', code: 'SERVICE_ERROR' },
            ]" placeholder="Type" />
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-outline-primary">
              Generate
            </button>
          </div>
          <div class="col-auto">
            <b-dropdown variant="outline-secondary">
              <template slot="button-content">
                Download <i class="fa fa-chevron-down"></i>
              </template>
              <a :href="download.csv" target="_BLANK" class="dropdown-item">CSV</a>
              <a :href="download.excel" target="_BLANK" class="dropdown-item">Excel</a>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'issueTime', type: 'desc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="content" class="form-control" placeholder="Search content" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'content'
          " @input="(value) => updateFilters(column, $refs.content.value)" />
      </template>

      <!-- <template #table-row="props">
        <template>
          {{ props.formattedRow[props.column.field] }}
        </template>
      </template> -->
    </vue-good-table>
  </div>
</template>

<script>
import moment from "moment";
import activityService from "@/service/activity.service";
export default {
  data() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    return {
      isLoading: false,
      download: { csv: "#", excel: "#" },
      columns: [
        {
          label: "Created Date",
          field: "issueTime",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "P p",
          sortable: true,
          firstSortType: "desc",
        },
        {
          label: "Customer Registration Id",
          field: "customerRegistrationId",
          //thClass: "text-nowrap",
          tdClass: "text-nowrap",
          filterOptions: { enabled: true },
        },
        {
          label: "Title",
          field: "title",
          //thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: { enabled: true },
        },
        {
          label: "URL Path",
          field: "url",
          //thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: { enabled: true },
        },
        {
          label: "Page Status",
          field: "errorStatus",
          filterOptions: { enabled: true },
          thClass: "text-nowrap",
          tdClass: "align-middle text-nowrap",
        },
        {
          label: "Issue",
          field: "issueType",
          thClass: "text-nowrap",
          tdClass: "align-middle",
        },
        {
          label: "Reason",
          field: "errorMessage",
          filterOptions: { enabled: true },
          tdClass: "align-middle",
        },
      ],
      rows: [],
      totalRecords: 0,
      filters: {
        issueTime: { startDate, endDate },
        issueType: null,
        title: null,
        url: null,
        errorMessage: null,
        errorStatus: null,
        customerRegistrationId: null,
      },
      serverParams: {
        sort: ["issueTime,desc"],
        page: 1,
        perPage: 10,
        query: null,
      },
    };
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(filters) {
        let query = { bool: {} };
        query.bool.filter = {
          range: {
            issueTime: {
              gte: moment(filters.issueTime.startDate)
                .startOf("day")
                .format("YYYYMMDDTHHmmss.SSSZ"),
              lte: moment(filters.issueTime.endDate)
                .endOf("day")
                .format("YYYYMMDDTHHmmss.SSSZ"),
            },
          },
        };

        const filteredFilter = Object.entries(filters).filter(
          ([field, value]) => !!value && field != "issueTime"
        );

        if (filteredFilter.length) {
          query.bool.must = filteredFilter.map(([field, value]) => ({
            match: { [field]: value },
          }));
        }
        this.$nextTick(() => (this.serverParams.query = query));
      },
    },
    serverParams: {
      deep: true,
      handler(params) {
        if (this.$data._debounceQueryTimer) {
          clearTimeout(this.$data._debounceQueryTimer);
          console.debug("loadItems: cancel previous stack");
        }

        this.$data._debounceQueryTimer = setTimeout(() => {
          this.rows = [];
          this.isLoading = true;
          // Just one level clone, not nested!
          const cloned = { ...params };
          // ES Pageable start from 0
          cloned.page = cloned.page - 1;
          // Query is JSON encoded
          cloned.query = cloned.query && JSON.stringify(cloned.query);

          this.download.csv = activityService.getMicrositeIssueDownloadUrl(
            "csv",
            cloned.query
          );
          this.download.excel = activityService.getMicrositeIssueDownloadUrl(
            "excel",
            cloned.query
          );

          activityService
            .getMicrositeIssue(cloned)
            .then((rsp) => {
              this.rows = rsp.data.data ? rsp.data.data.content : [];
              this.totalRecords = rsp.data.data ? rsp.data.data.totalElements : 0;
            })
            .catch((e) => {
              this.$notify({
                type: "error",
                title: "Error Notification",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
            })
            .finally(() => (this.isLoading = false));

          this.$data._debounceQueryTimer = null;
        }, 700);
      },
    },
  },
  methods: {
    onPageChange(params) {
      this.serverParams.page = params.currentPage
    },
    onPerPageChange(params) {
      this.serverParams.page = 1;
      this.serverParams.perPage = params.currentPerPage;
    },

    onSortChange(params) {
      this.serverParams.sort = params.map(({ field, type }) =>
        type == "none" ? field : `${field},${type}`
      );
    },
    onColumnFilter(params) {
      if (params.columnFilters) {
        Object.assign(this.filters, params.columnFilters);
      }
    },
  },
};
</script>
