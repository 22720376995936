import api from "./api";

class ProductCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }
    
    getProductCategoryList(serverParams) {
        return this.api.get("product/category", { params: serverParams });
    }

    postProductCategory(serverParams) {
        return this.api.post("product/category", { ...serverParams });
    }

    getProductCategoryById(serverParams) {
        return this.api.get("product/category/" + serverParams);
    }

    putProductCategory(id, serverParams) {
        return this.api.put("product/category/" + id, { ...serverParams });
    }

    deleteProductCategoryById(serverParams) {
        return this.api.delete("product/delete/category/" + serverParams);
    }

    getProductParentCategoryList(serverParams) {
        return this.api.get("product/parent/category", { params: serverParams });
    }

    getProductChildCategoryList(serverParams) {
        return this.api.get("product/category/child", { params: serverParams });
    }
}

export default new ProductCategoryService();