<template>
  <div>
    <h1 v-if="status == 'COMPLETE'" class="page-header">Submissions Detail Complete</h1>
    <h1 v-if="status == 'INCOMPLETE'" class="page-header">Submissions Detail Incomplete</h1>
    <ul class="nav nav-tabs nav-tabs--wp mb-0">
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-1`"
          class="nav-link"
          active-class="active"
        >
          1. Registrasi
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-2`"
          class="nav-link"
          active-class="active"
        >
          2. Location & Hospital
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-3`"
          class="nav-link"
          active-class="active"
        >
          3. Product Plan
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-4`"
          class="nav-link"
          active-class="active"
        >
          4. Rider
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-5`"
          class="nav-link"
          active-class="active"
        >
          5. SIO
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-6`"
          class="nav-link"
          active-class="active"
        >
          6. Payment
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-7`"
          class="nav-link"
          active-class="active"
        >
          7. Donation
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="`/submissions/forms/${$route.params.id}/step-8`"
          class="nav-link"
          active-class="active"
        >
          Documents
        </router-link>
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      status: ""
    };
  },
  methods: {
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            this.status = res.data.data.customerRegistration.status;
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
  },
  async mounted() {
    await this.handleDetail();
  },
};
</script>
