import api from "./api";

class AnalyticsService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getBusinessAnalytic(params) {
        return this.api.get("/cms/business/analytics", { params });
    }
}

export default new AnalyticsService();