import api from "./api";

class FaqService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }
    
    getFaqList(serverParams) {
        return this.api.get("/cms/faq", { params: serverParams });
    }

    postFaq(serverParams) {
        return this.api.post("cms/faq", { ...serverParams });
    }

    getFaqById(serverParams) {
        return this.api.get("/cms/faq/" + serverParams);
    }

    putFaq(id, serverParams) {
        return this.api.put("/cms/faq/" + id, { ...serverParams });
    }

    deleteFaqById(serverParams) {
        return this.api.delete("/cms/delete/faq/" + serverParams);
    }

    updateFaqStatus(id, serverParams) {
        return this.api.put("/cms/update/faq-status/" + id, {}, { params: serverParams });
    }
    
    updateFaqPosition(serverParams) {
        return this.api.put("/cms/reposition/faq", { ...serverParams });
    }
}

export default new FaqService();