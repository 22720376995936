<template>
  <div class="pb-5">
    <form>
      <div class="panel">
        <div class="panel-body">
          <h4 class="fw-normal mb-3">Pernyataan Pembelian/SIO Questionnaire</h4>
          <div class="row">
            <div class="col-md-8">
              <ol class="ps-3 mb-4">
                <li
                  class="mb-2"
                  v-for="(item, index) in questions"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ol>

              <p class="fw-bolder">
                Apakah anda mengalami/sedang berkaitan dengan salah satu dari
                {{ sioParams.totalElments }} kondisi di atas?
              </p>
              <div class="row">
                <div class="col-auto">
                  <div class="form-check form-check-button">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="answer"
                      id="answer1"
                      value="YA"
                      v-model="answer"
                      :class="{
                        disabled: isReadOnly,
                      }"
                    />
                    <label class="form-check-label" for="">Ya</label>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="form-check form-check-button">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="answer"
                      id="answer2"
                      value="TIDAK"
                      v-model="answer"
                      :class="{
                        disabled: isReadOnly,
                      }"
                    />
                    <label class="form-check-label" for="">Tidak </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isReadOnly" class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button @click.prevent="doSave()" class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import sioService from "@/service/sio.service";
import registrationService from "@/service/registration.service";
export default {
  data() {
    return {
      status: false,
      sioParams: {
        params: {
          pageSize: 10,
          pageNumber: 0,
          sort: "id",
          direction: "ASC",
          searchField: "",
          searchValue: "",
        },
        content: [],
        totalElments: 0,
      },
      questions: [],
      answer: "",
    };
  },
  computed: {
    isReadOnly() {
      if (this.status === "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.answer !== ""
        ) {
          registrationService
            .stepFifthUpdate(this.$route.params.id, {
              sioAnswer: this.answer,
            })
            .then((response) => {
              this.$swal({
                title: "Data berhasil diupdate",
                showCloseButton: true,
              });
            })
            .catch((error) => {
              this.$swal({
                title:
                  error.response?.data?.responseMessage || "Terjadi Kesalahan",
                showCloseButton: true,
              });
            });
        } else {
          alert("Jawab Pertanyaan");
        }
      } else {
        console.log("False");
      }
    },
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            let data = res.data.data.customerRegistration;
            this.status = res.data.data.customerRegistration?.status;
            this.answer = data.sioAnswer !== null ? data.sioAnswer : "";
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
    async getSioLIst() {
      await sioService
        .getSioQuestionList(this.sioParams.params)
        .then((res) => {
          this.questions = [];
          this.sioParams.totalElments = res.data.data.totalElements;
          res.data.data.content.forEach((ques) => {
            this.questions.push(ques.question);
          });
        })
        .catch(() => {
          alert("Something Wrong");
        });
    },
    async handleNext() {
      this.sioParams.params.pageNumber += 1;
      await this.getSioLIst();
    },
    async handlePrev() {
      this.sioParams.params.pageNumber -= 1;
      await this.getSioLIst();
    },
    hasNext() {
      let currentPage =
        (this.sioParams.params.pageNumber + 1) * this.sioParams.params.pageSize;
      if (currentPage < this.sioParams.totalElments) {
        return true;
      } else {
        return false;
      }
    },
    hasPrev() {
      if (this.sioParams.params.pageNumber === 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  async mounted() {
    await this.handleDetail();
    this.getSioLIst();
  },
  validations() {
    return {};
  },
};
</script>
<style scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>

