<template>
  <form class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} Page</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Page Title"
            v-model="page.title"
          />
          <span
            class="text-danger"
            v-if="!$v.page.title.required && $v.page.title.$error"
            >Title is required</span
          >
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Slug</label>
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white border-end-0 text-muted"
                      v-text="pageUrl"
                    ></span>
                    <input
                      type="text"
                      class="form-control border-start-0 ps-0"
                      placeholder="page-slug"
                      v-model="page.slug"
                    />
                    <a
                      v-if="isEdit"
                      class="input-group-text bg-white"
                      target="_blank"
                      :href="finalUrl"
                      >View</a
                    >
                  </div>
                  <span
                    class="text-danger"
                    v-if="!$v.page.slug.required && $v.page.slug.$error"
                    >Slug is required</span
                  >
                  <span class="text-danger" v-if="!$v.page.slug.slugRegex"
                    >Wrong Slug Format</span
                  >
                  <span class="text-danger" v-if="!slugIsAvailable">{{
                    errorMess.slug
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Page Template</label>
                  <v-select
                    v-model="page.pageTemplate"
                    :options="optionsTemplate"
                    :disabled="isEdit"
                  ></v-select>
                </div>
                <span
                  class="text-danger"
                  v-if="
                    !$v.page.pageTemplate.required &&
                    $v.page.pageTemplate.$error
                  "
                  >Page Template is required</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Template Editor -->
        <component ref="template" :is="template" :page="page" />

        <!-- Seo -->
        <SeoInformation ref="seo" :value="page.seoInformation" />
      </div>
      <div v-if="isEdit" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ page.created | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ page.updated | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ page.createdBy }}</p>
            </div>
            <button
              type="button"
              @click.prevent="doSave('PREVIEW')"
              class="btn btn-outline-primary"
              :class="{ disabled: onLoading }"
            >
              <span
                v-show="onLoading"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              Preview Page
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            @click.prevent="doSave('DRAFTS')"
            class="btn btn-outline-primary"
            :class="{ disabled: onLoading }"
          >
            <span
              v-show="onLoading"
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button
            @click.prevent="doSave('PUBLISHED')"
            :class="{ disabled: onLoading }"
            class="btn btn-primary"
          >
            <span
              v-show="onLoading"
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import UploadImage from "../../components/upload/Image.vue";
import { required, requiredIf, helpers } from "vuelidate/lib/validators";
import pageService from "@/service/page.service";
import micrositeService from "@/service/microsite.service";
import dayjs from "dayjs";

import SeoInformation from "./SeoInformation.vue";
import EmptyPage from "./templates/EmptyPage.vue";
import GeneralPage from "./templates/GeneralPage.vue";
import ProductPage from "./templates/ProductPage.vue";

const slugRegex = helpers.regex(
  "slugRegex",
  /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
);
export default {
  components: {
    UploadImage,
    SeoInformation,
  },
  data() {
    return {
      pageUrl: `${env.APP_FRONTEND}/pages/`,
      finalUrl: "#",
      onLoading: false,
      timer: null,
      slugIsAvailable: true,
      optionsTemplate: ["GENERAL_PAGE", "PRODUCT_PAGE"],
      errorMess: {
        slug: "",
      },
      page: {
        title: "",
        slug: "",
        pageSections: [],
        seoInformation: {
          metaTitle: "",
          metaDescription: "",
          keyword: "",
        },
      },
    };
  },
  watch: {
    "page.title": function (v) {
      this.page.slug = this.slugify(v);
    },
    "page.slug": function () {
      // Cancel previous call
      if (this.$data._debounceQueryTimer)
        clearTimeout(this.$data._debounceQueryTimer);

      this.$data._debounceQueryTimer = setTimeout(() => {
        this.checkSlug();
        this.$data._debounceQueryTimer = null;
      }, 700);
    },
    "$route.path": function () {
      location.reload();
    },
  },
  computed: {
    isEdit() {
      return !!this.page.id;
    },
    formTitle() {
      return this.isEdit ? "Edit" : "Add New";
    },
    template() {
      switch (this.page?.pageTemplate) {
        case "GENERAL_PAGE":
          return GeneralPage;
        case "PRODUCT_PAGE":
          return ProductPage;
        default:
          return EmptyPage;
      }
    },
  },
  mounted() {
    if (this.$route.params?.id) {
      this.onLoading = true;
      pageService
        .getSinglePage(this.$route.params?.id)
        .then(({ data: { data } }) => {
          this.page = data;
          this.finalUrl = this.pageUrl + this.page.slug;
        })
        .catch((e) =>
          this.$swal({
            icon: "warning",
            title: "Gagal",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          }).then(() => this.$router.replace({ path: "/pages/list" }))
        )
        .finally(() => (this.onLoading = false));
    }
  },
  methods: {
    async doSave(param) {
      if (this.invalid()) {
        this.$notify({
          type: "warn",
          title: "Data Belum Lengkap",
          text: "Silahkan lengkapi inputan yang bertanda merah.",
        });
        return;
      }
      if (!this.slugIsAvailable) {
        console.debug("Slug unavailable");
      }

      this.page.name = this.page.title;
      this.onLoading = true;
      if (param == "PREVIEW") {
        let linkPreview =
          env.APP_FRONTEND.replace(/\/+$/, "") +
          "/pages-preview/" +
          encodeURIComponent(this.page.slug) +
          "/" +
          encodeURIComponent(this.page.id);
        micrositeService
          .pagePreview(this.page)
          .then((res) => {
            window.open(linkPreview);
            this.$swal({
              title: "Preview OK",
              html: `Halaman preview sudah dibuka pada URL <a href="${linkPreview}" target="_blank">${linkPreview}</a>`,
              showCloseButton: true,
              timer: 5000,
            });
          })
          .catch((e) =>
            this.$swal({
              icon: "warning",
              title: "Gagal",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            })
          )
          .finally(() => (this.onLoading = false));
        return;
      }
      this.page.status = param;

      const promise = this.isEdit
        ? pageService.updatePage(this.page.id, this.page)
        : pageService.createPage(this.page);

      promise
        .then(() => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => this.$router.push({ path: "/pages/list" }));
        })
        .catch((e) =>
          this.$swal({
            icon: "warning",
            title: "Gagal",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          })
        )
        .finally(() => (this.onLoading = false));
    },
    // Auto generate slug
    slugify(text) {
      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-and-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    checkSlug() {
      this.slugIsAvailable = true;
      const param = { slug: this.page.slug, id: this.page.id };

      pageService.checkSlug(param).then(
        () => {
          this.slugIsAvailable = true;
        },
        (err) => {
          if (err.response.data?.responseCode == "409") {
            this.slugIsAvailable = false;
            this.errorMess.slug = err.response.data?.responseMessage;
          } else {
            alert("Terjadi Kesalahan");
          }
        }
      );
    },
    invalid() {
      this.$v.$touch();
      const thisInvalid = this.$v.$invalid;
      const templateInvalid = this.$refs.template?.invalid();
      const seoInvalid = this.$refs.seo.invalid();
      return thisInvalid || templateInvalid || seoInvalid;
    },
  },
  filters: {
    getDate(date) {
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
  validations() {
    return {
      page: {
        title: {
          required,
        },
        pageTemplate: {
          required,
        },
        slug: {
          required,
          slugRegex,
        },
      },
    };
  },
};
</script>
