import api from "./api";

class CharityService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getSubmissionCount(serverParams) {
        return this.api.get("/cms/submission/count", { params: serverParams });
    }
    

    getPaymentSumSuccess(serverParams) {
        return this.api.get("/cms/payment/sum/success", { params: serverParams });
    }

    getPaymentCountSucces(serverParams) {
        return this.api.get("/cms/payment/count/success", { params: serverParams });
    }

    getTotalVisitor(serverParams) {
        return this.api.get("/cms/business/getGaTotalVisitor", { params: serverParams });
    }

    getDailyVisitor(serverParams) {
        return this.api.get("/cms/business/getVisitorDaily", { params: serverParams });
    }

    getWeeklyVisitor(serverParams) {
        return this.api.get("/cms/business/getVisitorWeekly", { params: serverParams });
    }

    getMontlyVisitor(serverParams) {
        return this.api.get("/cms/business/getVisitorMonthly", { params: serverParams });
    }
}

export default new CharityService();