import BannerService from "../service/banner.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListBanner(_ctx, serverParams) {
            return BannerService.getBannerList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postBanner(_ctx, serverParams) {
            return BannerService.postBanner(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getBannerById(_ctx, serverParams) {
            return BannerService.getBannerById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateBannerById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return BannerService.putBanner(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteBannerById(_ctx, serverParams) {
            return BannerService.deleteBannerById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
