<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} Role</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Role title" v-model="form.name" />
        </div>
        <div class="panel">
          <div class="panel-body">
            <!-- Role Permission -->
            <div class="mb-4" v-for="(p, group) of groupedPermissions" :key="group">
              <h5 class="fw-normal mt-3 mb-3" v-text="p.name"></h5>
              <div class="row">
                <div class="col-auto">
                  <!-- <div class="btn-group btn-group-sm">
                    <button type="button" class="btn btn-outline-success">Check All</button>
                    <button type="button" class="btn btn-outline-danger">Uncheck All</button>
                  </div> -->
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="group" :id="`check-all-${group}`"
                      @change="checkAllClick(group, $event)" :indeterminate.prop="checkAll[group] === 'some'"
                      :checked.prop="checkAll[group] === true" />
                    <label class="form-check-label" :for="`check-all-${group}`">All</label>
                  </div>
                </div>
                <div class="col-auto" v-for="(v, i) of p.permissions" :key="v.id">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="v" :id="`check--${v}`"
                      v-model="form.permissions" @change="syncCheckAll(group, p)" />
                    <label class="form-check-label" :for="`check--${v}`" v-text="v.name"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Permissions from "../../../config/Permissions";
import roleService from "@/service/role.service";

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    checkAll: {},
    form: {
      permissions: []
    },
  }),
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    groupedPermissions() {
      return this.$store.state.auth.permissionsAll
    }
  },
  watch: {
    detailData: {
      immediate: true,
      handler(newVal) {
        this.checkAll = {};
        if (newVal) {
          this.form = {
            name: newVal.name,
            permissions: typeof newVal.permissions == 'string' ? [] : newVal.permissions,
          };

          this.$nextTick(
            () => (
              Object.keys(this.groupedPermissions).forEach((g) =>
                this.syncCheckAll(g)
              ),
              this.$forceUpdate()
            )
          );
        } else {
          this.form = {
            name: "",
            permissions: [],
          };
        }
      },
    },
  },
  methods: {
    doSave() {
      if (!this.form.name) {
        return this.$swal({
          icon: "warning",
          title: "Data Belum Lengkap",
        });
      }
      const value = { ...this.form };
      // value.permissions = value.permissions.join();
      // console.log(this.form)
      this.$emit("onValidateSuccess", value);
    },

    checkAllClick(group, { target: { checked } }) {
      const checkItAll = (v) =>
        this.form.permissions.includes(v) ||
        this.form.permissions.push(v);

      const uncheckItAll = (v) =>
        this.form.permissions.splice(this.form.permissions.indexOf(v), 1);

      this.groupedPermissions[group].permissions.forEach(checked ? checkItAll : uncheckItAll);
      this.checkAll[group] = checked;
    },

    syncCheckAll(group) {
      const items = this.groupedPermissions[group].permissions;

      const checkedCount = this.groupedPermissions[group].permissions.filter(({ id }) =>
        this.form.permissions.find((r) => r.id == id)
      ).length;

      // console.log(
      //   "sca",
      //   [...this.form.permissions],
      //   group,
      //   items.length,
      //   checkedCount
      // );

      if (items.length == checkedCount) {
        this.checkAll[group] = true;
      } else if (checkedCount == 0) {
        this.checkAll[group] = false;
      } else {
        this.checkAll[group] = "some";
      }
    },
  },
};
</script>
