<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'productName', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="productName" class="form-control" placeholder="Search product" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.productName.value)" />
        <select ref="productCategory" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'productCategory.name'
          " @change="(value) => updateFilters(column, $refs.productCategory.value)
    ">
          <option v-for="(option, key) in optionsProductCategory" v-bind:value="option.name" :key="key"
            v-text="option.name" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto" v-if="$can($permissionsAll[9])">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Undo"
                @click.prevent="showUndoConfirm(props.row.id)">
                <i class="fa-solid fa-rotate-left"></i>
              </button>
            </div>
            <div class="col-auto" v-if="$can($permissionsAll[10])">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import productService from "@/service/product.service";
import productCategoryService from "@/service/productcategory.service";

export default {
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Product Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Product Category",
          field: "productCategory.name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
        name: '',
        category: '',
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      optionsProductCategory: [],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ pageNumber: params.currentPage - 1 });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageNumber: 0 });
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: "",
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      if (
        params.columnFilters.productCategory != "" ||
        params.columnFilters.name != ""
      ) {
        this.updateParams({
          name: params.columnFilters.productName,
          category: params.columnFilters.productCategory,
        });
      } else {
        let { name, category, ...data } = this.serverParams;
        this.serverParams = data;
      }
      this.loadItems();
    },
    loadItems() {
      this.serverParams.status = 'TRASHED'
      productService
        .getProductList(this.serverParams)
        .then(async (rsp) => {
          let arrData = rsp.data?.data ? rsp.data.data.content : [];
          for (const [k, v] of Object.entries(arrData)) {
            if ((typeof v.idProductCategory) == 'string') {
              arrData[k].productCategory = await this.loadPerParentCategory(v.idProductCategory);
            }
          }
          this.rows = arrData
          this.totalRecords = rsp.data.data?.totalElements ? rsp.data.data?.totalElements : 0 
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response?.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    loadProductCategory() {
      let payload = {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      };
      productCategoryService
        .getProductCategoryList(payload)
        .then(async (rsp) => {
          if (rsp.status == 200) {
            this.optionsProductCategory = rsp.data.data.content;
            let arrProductCategory = this.optionsProductCategory.filter((e) => typeof e === 'string')
            for (let i = 0; i < arrProductCategory.length; i++) {
              let element = await this.loadPerParentCategory(arrProductCategory[i]);
              let iProduct = this.optionsProductCategory.findIndex((e) => e === arrProductCategory[i])
              this.optionsProductCategory[iProduct] = element
            }
            this.optionsProductCategory.unshift({ name: "" });
          } else {
            this.optionsProductCategory = [];
          }
        })
        .catch((e) => {
          this.rows = [];
          if (e.response.status == 404) {
            this.$notify({
              type: "warning",
              title: "Tidak Ditemukan",
              text: e.response.data?.responseMessage || "Gagal mengambil data",
            });
          } else {
            this.$notify({
              type: "error",
              title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
                }`,
              text: e.response.data?.responseMessage || "Gagal mengambil data",
            });
          }
        });
    },
    onDelete(id) {
      productService
        .deleteProductById(id)
        .then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.$emit('reloadData', 'TRASHED')
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },
    showUndoConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Drafts?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleUndo(id);
          }
        });
    },
    handleUndo(id) {
      productService
        .changeProductStatus(id, "DRAFTS")
        .then(
          () => {
            this.$swal({
              title: "Data berhasil Dipindah Ke DRAFTS",
              showCloseButton: true,
            }).then(() => {
              this.$router.push("/products/list/drafts");
            });
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
    loadPerParentCategory(id) {
      return productCategoryService
        .getProductCategoryById(id)
        .then((rsp) => {
          return rsp.data.data
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
  },
  mounted() {
    this.loadItems();
    this.loadProductCategory();
  },
};
</script>
