import api from "./api";

class BenefitLimitService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }
    
    getBenefitLimitList(serverParams) {
        return this.api.get("product/benefitLimit", { params: serverParams });
    }

    postBenefitLimit(serverParams) {
        return this.api.post("product/benefitLimit", { ...serverParams });
    }

    getBenefitLimitById(serverParams) {
        return this.api.get("product/benefitLimit/" + serverParams);
    }

    putBenefitLimit(id, serverParams) {
        return this.api.put("product/benefitLimit/" + id, { ...serverParams });
    }

    deleteBenefitLimitById(serverParams) {
        return this.api.delete("product/delete/benefitLimit/" + serverParams);
    }
}

export default new BenefitLimitService();