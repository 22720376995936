import api from "./api";

class RegionalService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create('hospital')
  }

  getProvinceList(serverParams) {
    return this.api.get("regional/province", { params: serverParams }).catch((error) => {
      return Promise.reject(error)
    });
  }

  getProvinceById(id) {
    return this.api.get(`regional/province/${id}`).then((response) => {
      return response.data;
    }).catch((error) => {
      return Promise.reject(error)
    });
  }

  getCityList(serverParams) {
    return this.api.get("regional/city", { params: serverParams }).catch((error) => {
      return Promise.reject(error)
    });
  }

  getCityById(id) {
    return this.api.get(`regional/city/${id}`).then((response) => {
      return response.data;
    }).catch((error) => {
      return Promise.reject(error)
    });
  }

  getDistrictList(serverParams) {
    return this.api.get("regional/district", { params: serverParams }).catch((error) => {
      return Promise.reject(error)
    });
  }

  getDistrictById(id) {
    return this.api.get(`regional/district/${id}`).then((response) => {
      return response.data;
    }).catch((error) => {
      return Promise.reject(error)
    });
  }
}

export default new RegionalService();