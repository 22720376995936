<template>
    <div class="product-form">
        <FormFaq form-type="add" @onValidateSuccess="doSave" />
    </div>
</template>
<script>
import FormFaq from './Form.vue'
import faqService from "@/service/faq.service";
export default {
    components: {
        FormFaq,
    },

    data() {
        return {
            totalElements: 0
        }
    },

    methods: {
        async doSave(payload) {
            let faq = payload.faq;
            payload = {
                "deleted": 0,
                "status": faq.recordStatus,
                "title": faq.title,
                "content": faq.description.replace(/<\/?[^>]+>/ig, ""),
                "showOnHomepage": faq.showOnHomepage,
                "faqCategory": {
                    "id": faq.category.id,
                },
            }
            faqService.postFaq(payload).then((rsp) => {
                this.$swal({
                    title: "Data berhasil disimpan",
                    showCloseButton: true,
                }).then(() => {
                    if (faq.recordStatus === "PUBLISHED") {
                        this.$router.push("/pages/faq/list");
                    } else if (faq.recordStatus === "DRAFTS") {
                        this.$router.push("/pages/faq/list/drafts");
                    }
                });
            }).catch((e) => {
                this.$notify({
                    type: "error",
                    title: "Error Message",
                    text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                });
            })
        },

        loadItems() {
            let searchParam = {
                sort: "created",
                direction: "DESC",
                pageNumber: 0,
                pageSize: 100,
            }

            faqService.getFaqList(searchParam).then((rsp) => {
                // console.log(rsp)
                this.totalElements = rsp.data.data?.totalElements
            }).catch((err) => {
                // console.log(err)
            })
        },
    },

    mounted() {
        this.loadItems()
    },
}
</script>