<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'name', type: 'ASC' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search pages" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.name.value)" />
        <select ref="pageTemplate" class="form-select" placeholder="Search Category" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'pageTemplate'
          " @change="(value) => updateFilters(column, $refs.pageTemplate.value)">
          <option value="" selected="selected">Select</option>
          <option value="GENERAL_PAGE" selected>General page</option>
          <option value="PRODUCT_PAGE">Product page</option>
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div v-if="props.row.pageTemplate === 'HOMEPAGE' && $can($permissionsAll[39])" class="col-auto">
              <router-link :to="`/pages/homepage`" class="btn btn-lg text-dark" v-b-tooltip.hover title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div v-else-if="$can($permissionsAll[39])" class="col-auto">
              <router-link :to="`/pages/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div v-if="props.row.pageTemplate !== 'HOMEPAGE' && $can($permissionsAll[39])" class="col-auto">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Duplicate"
                @click="showDuplicateConfirm(props.row.id)">
                <i class="fas fa-copy"></i>
              </button>
            </div>
            <div v-if="props.row.pageTemplate !== 'HOMEPAGE'" class="col-auto">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else-if="props.column.field == 'created'">
          {{ props.formattedRow[props.column.field] | getDate }}
        </span>
        <span v-else-if="props.column.field == 'updated'">
          {{ props.formattedRow[props.column.field] | getDate }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import pageService from "../../service/page.service";
export default {
  data() {
    return {
      isLoading: false,
      onLoading: false,
      timer: null,
      columns: [
        {
          label: "Page Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          width: "500px",
          firstSortType: "asc",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Published Date",
          field: "created",
          width: "250px",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Modified Date",
          field: "updated",
          width: "250px",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Page Template",
          field: "pageTemplate",
          thClass: "text-nowrap",
          width: "250px",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "name",
            type: "ASC",
          },
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItemsFromApi();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItemsFromApi();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.serverParams.page = 1;
      this.loadItemsFromApi();
    },
    setPaginationParams() {
      let body = {
        searchField: "",
        searchValue: "",
        pageNumber: this.serverParams.page - 1,
        pageSize: this.serverParams.perPage,
        status: "PUBLISHED",
      };
      if (this.serverParams.sort[0].type !== "none") {
        (body.direction = this.serverParams.sort[0].type.toUpperCase()),
          (body.sort = this.serverParams.sort[0].field);
      }
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters.hasOwnProperty(key)) {
          if (this.serverParams.columnFilters[key] !== "") {
            if (key === "pageTemplate") {
              body.template = this.serverParams.columnFilters[key];
            } else if (key === "name") {
              body.name = this.serverParams.columnFilters[key];
            }
          }
        }
      }
      return body;
    },
    async loadItemsFromApi() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await pageService.getPageLists(this.setPaginationParams()).then(
          (response) => {
            if (
              response.data.responseCode == "200" &&
              response.data.responseMessage == "Data's Empty!"
            ) {
              this.rows = [];
              this.totalRecords = 0;
            } else {
              this.rows = response.data?.data?.content;
              this.totalRecords = response.data?.data?.totalElements;
            }
          },
          () => {
            alert("Something Wrong");
            this.rows = [];
            this.totalRecords = 0;
          }
        );
      }, 800);
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Trash?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleDelete(id);
          }
        });
    },
    showDuplicateConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Duplikasi Data?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Duplikasi!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleDuplicate(id);
          }
        });
    },
    async handleDelete(id) {
      await pageService.changePageStatus(id, "TRASHED").then(
        () => {
          this.$swal({
            title: "Data berhasil Dipindah Ke Trash",
            showCloseButton: true,
          }).then(() => {
            this.$router.push("/pages/list/trashed");
          });
        },
        () => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
      );
    },
    async handleDuplicate(id) {
      await pageService.duplicatePage(id).then(
        () => {
          this.$swal({
            title: "Data berhasil Di Duplikasi",
            showCloseButton: true,
          }).then(() => {
            this.$store.dispatch("page/getPublishPageData");
            this.loadItemsFromApi();
          });
        },
        () => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
      );
    },
    loadItems() {
      this.createColumnFilter();
      this.rows = [
        {
          id: 1,
          pageName: "About Us",
          publishedDate: "4 December 2022",
          modifiedDate: "4 December 2022",
          pageTemplate: "General page",
        },
        {
          id: 2,
          pageName: "Homepage",
          publishedDate: "3 December 2022",
          modifiedDate: "3 December 2022",
          pageTemplate: "Homepage",
        },
        {
          id: 3,
          pageName: "Product Page",
          publishedDate: "1 December 2022",
          modifiedDate: "1 December 2022",
          pageTemplate: "Product page",
        },
      ];
    },
    createColumnFilter() {
      let searchField = [];
      let serachValue = [];
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters[key] !== "") {
          searchField.push(key);
          serachValue.push(this.serverParams.columnFilters[key]);
        }
      }

      let obj = {
        searchField: searchField.join(),
        searchValue: serachValue.join(),
        sort: this.serverParams.sort[0].field,
        direction: this.serverParams.sort[0].type,
        pageNumber: this.serverParams.page,
        pageSize: this.serverParams.perPage,
      };

      return obj;
    },
  },
  mounted() {
    this.$store.dispatch("page/getPublishPageData");
    this.$store.dispatch("page/getDraftsPageData");
    this.$store.dispatch("page/getTrashedPageData");
  },
  filters: {
    getDate(date) {
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
};
</script>
