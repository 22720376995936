<template>
  <div class="pb-5">
    <form>
      <div class="panel">
        <div class="panel-body">
          <h4 class="fw-normal mb-3">Pilih Yayasan</h4>
          <p class="mb-4">
            Anggaran klaim yang tidak terpakai tidak akan diambil, tapi akan
            didonasikan ke yayasan yang dipilih untuk membantu menjaga kesehatan
            jutaan orang lainnya.
          </p>
          <div class="row">
            <div class="col-md-5">
              <div
                class="form-check form-check-button mb-2"
                v-for="(item, index) in donationList"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="donation"
                  :id="`donation-${index}`"
                  :value="item.id"
                  v-model="donationId"
                  :class="{
                    disabled: isReadOnly,
                  }"
                />
                <label
                  class="form-check-label py-2 d-flex align-items-center"
                  for=""
                >
                  <div class="donation-logo">
                    <img :src="item.logo" :alt="item.label" />
                  </div>
                  <span class="fw-normal">
                    {{ item.label }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isReadOnly" class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button @click.prevent="doSave()" class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import charityService from "@/service/charity.service";
import registrationService from "@/service/registration.service";
export default {
  data() {
    return {
      status: false,
      donation: "Yayasan Jantung Indonesia",
      donationId: null,
      donationList: [],
      charityParams: {
        params: {
          pageSize: 10,
          pageNumber: 0,
          sort: "organizationName",
          direction: "ASC",
          searchField: "",
          searchValue: "",
          status: "PUBLISHED",
        },
        content: [],
        totalElments: 0,
      },
      detailCharity: {},
    };
  },
  methods: {
    async doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.donationId !== null
        ) {
          registrationService
            .stepSeventhUpdate(this.$route.params.id, {
              idDonation: this.donationId,
            })
            .then((response) => {
              this.$swal({
                title: "Data berhasil diupdate",
                showCloseButton: true,
              });
            })
            .catch((error) => {
              this.$swal({
                title:
                  error.response?.data?.responseMessage || "Terjadi Kesalahan",
                showCloseButton: true,
              });
            });
        } else {
          alert("Pilih Donasi");
        }
      } else {
        console.log("False");
      }
    },
    async handleNext() {
      this.charityParams.params.pageNumber += 1;
      await this.getCharityLIst();
    },
    async handlePrev() {
      this.charityParams.params.pageNumber -= 1;
      await this.getCharityLIst();
    },
    hasNext() {
      let currentPage =
        (this.charityParams.params.pageNumber + 1) *
        this.charityParams.params.pageSize;
      if (currentPage < this.charityParams.totalElments) {
        return true;
      } else {
        return false;
      }
    },
    hasPrev() {
      if (this.charityParams.params.pageNumber === 0) {
        return false;
      } else {
        return true;
      }
    },
    async getCharityLIst() {
      await charityService
        .getCharityList(this.charityParams.params)
        .then((res) => {
          this.setCharityList(res.data.data.content);
          this.charityParams.totalElments = res.data.data.totalElements;
        })
        .catch(() => {
          alert("Something Wrong");
        });
    },
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            let registrationData = res.data.data.customerRegistration;
            this.status =
              registrationData.status !== null ? registrationData.status : null;
            this.donationId =
              registrationData.donation !== null
                ? registrationData.donation.id
                : null;
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
    setCharityList(list) {
      let charityContent = [];
      list.forEach((charity) => {
        charityContent.push({
          id: charity.id,
          label: charity.organizationName,
          logo: charity.logoFileUrl,
        });
      });
      // var foundIndex = charityContent.findIndex(
      //   (x) => x.id == this.detailCharity.id
      // );
      // if (foundIndex < 0) {
      //   charityContent.push({
      //     id: this.detailCharity.id,
      //     label: this.detailCharity.nameDonation,
      //     logo: "https://api.amorehealth.otesuto.com/web/cms/charity/view/1cbf1d1d-861c-4c34-8dac-74eb21bf0c6d.png",
      //   });
      // }
      this.donationList = charityContent;
    },
  },
  async mounted() {
    await this.handleDetail();
    this.getCharityLIst();
  },
  validations() {
    return {};
  },
  computed: {
    isReadOnly() {
      if (this.status === "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.donation-logo {
  height: 20px;
  width: 100px;
  position: relative;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-position: center;
    object-fit: contain;
  }
}
.form-check-label {
  height: 50px;
}
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>