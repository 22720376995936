import Vue from "vue";
import VueX from "vuex";
// import SioService from "../service/sio.service";
import auth from "./auth.module";
import sio from "./sio.module";
import role from "./role.module";
import registration from "./registration.module";
import banner from "./banner.module";
import hospital from "./hospital.module"
import { page } from "./page.module";
import regional from "./regional.module";
import charity from "./charity.module";
import post from "./post.module";
import menu from "./menu.module";
import postcategory from "./postcategory.module";
import faq from "./faq.module";
import managepdf from "./managepdf.module";
import area from "./area.module";

import faqcategory from "./faqcategory.module";
import productcategory from "./productcategory.module";

import benefitlimit from "./benefitlimit.module";
import coinsurance from "./coinsurance.module";
import paymentfrequency from "./paymentfrequency.module";
import suminsurance from "./suminsurance.service";
import weightcontribution from "./weightcontribution.module";
import product from "./product.module";
import calculate from "./calculate";


Vue.use(VueX);

export const store = new VueX.Store({
    // state: {
    //     count: 0
    // },
    // mutations: {
    //     increment(context) {
    //         context.commit('increment')
    //     }
    // },
    // actions: {
    //     getListSio(_ctx, serverParams) {
    //         console.log(serverParams);
    //         return sioService.getSioQuestionList(serverParams).then((result) => {
    //             return result
    //         }).catch((err) => {
    //             return err
    //         });
    //         // return SioService.getSioQuestionList({ serverParams: serverParams }).then(
    //         //     (response) => {
    //         //         return Promise.resolve(response);
    //         //     }
    //         // );
    //     },
    //     increment(context) {
    //         return this.state.count
    //     }
    // },

    // state: {
    //     appOptions: {
    //       appSidebarMinified: false,
    //       appSidebarNone: false,
    //       appSidebarEnd: false,
    //       appSidebarTwo: false,
    //       appSidebarWide: false,
    //       appSidebarLight: false,
    //       appSidebarTransparent: false,
    //       appSidebarMobileToggled: false,
    //       appSidebarEndMobileToggled: false,
    //       appSidebarEndToggled: false,
    //       appSidebarEndCollapsed: false,
    //       appSidebarSearch: false,
    //       appContentFullHeight: false,
    //       appContentClass: false,
    //       appHeaderLanguageBar: false,
    //       appHeaderInverse: false,
    //       appHeaderMegaMenu: false,
    //       appHeaderMegaMenuMobileToggled: false,
    //       appTopMenu: false,
    //       appTopMenuMobileToggled: false,
    //       appEmpty: false,
    //       appBodyScrollTop: 0,
    //       appNavbarNotification: null,
    //       // Deprecated, use $store.state.auth.login instead
    //       //appNavbarUser: false
    //       contentLoading: false
    //     },
    // },
    modules: {
        auth,
        area,
        charity,
        banner,
        sio,
        role,
        registration,
        hospital,
        page,
        regional,
        post,
        menu,
        postcategory,
        faq,
        managepdf,
        faqcategory,
        productcategory,
        benefitlimit,
        coinsurance,
        paymentfrequency,
        suminsurance,
        weightcontribution,
        product,
        calculate
    },
})