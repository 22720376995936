import PageService from "../service/page.service";
export const page = {
  namespaced: true,
  state: {
    totalPublished: 0,
    totalDrafts: 0,
    totalTrashed: 0,
  },
  actions: {
    async getPageLists(_ctx, serverParams) {
      return PageService.getPageLists(serverParams).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      // return PageService.getPageLists(serverParams)
      //   .then((result) => {
      //     console.log(result);
      //     return result;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     return err;
      //   });
    },
    createPage(_ctx, page) {
      return PageService.createPage(page).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    previewPage(_ctx, page) {
      return PageService.previewPage(page).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getSinglePage(_ctx, id) {
      return PageService.getSinglePage(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deletePage(_ctx, id) {
      return PageService.deletePage(id);
    },
    updatePage(_ctx, data) {
      return PageService.updatePage(data.id, data.body);
    },
    getPublishPageData({ commit }) {
      return PageService.getPageLists({ status: "PUBLISHED" })
        .then((result) => {
          if (
            result.data.responseCode == "200" &&
            result.data.responseMessage == "Data's Empty!"
          ) {
            commit("setPublished", 0);
          } else {
            commit("setPublished", result.data?.data?.totalElements);
          }
        })
        .catch(() => {
          commit("setPublished", 0);
        });
    },
    getDraftsPageData({ commit }) {
      return PageService.getPageLists({ status: "DRAFTS" })
        .then((result) => {
          if (
            result.data.responseCode == "200" &&
            result.data.responseMessage == "Data's Empty!"
          ) {
            commit("setDrafts", 0);
          } else {
            commit("setDrafts", result.data?.data?.totalElements);
          }
        })
        .catch(() => {
          commit("setDrafts", 0);
        });
    },
    getTrashedPageData({ commit }) {
      return PageService.getPageLists({ status: "TRASHED" })
        .then((result) => {
          if (
            result.data.responseCode == "200" &&
            result.data.responseMessage == "Data's Empty!"
          ) {
            commit("setTrashed", 0);
          } else {
            commit("setTrashed", result.data?.data?.totalElements);
          }
        })
        .catch(() => {
          commit("setTrashed", 0);
        });
    },
  },
  getters: {},
  mutations: {
    setPublished(state, data) {
      state.totalPublished = data;
    },
    setDrafts(state, data) {
      state.totalDrafts = data;
    },
    setTrashed(state, data) {
      state.totalTrashed = data;
    },
  },
};
