<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionOne"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Apa Itu Amore Health"
        v-model="value.sectionTitle"
      />
    </div>
    <vue-editor
      v-model="value.content"
      :editor-toolbar="$options.defaultToolbar"
    />

  </panel>
</template>
<script>
import { defaultToolbar } from "@/config/Helpers"
export default {
  defaultToolbar,
  // Product section in Home and Product Template is differs
  alias: "PRODUCT_CONTENT",
  name: "PRODUCT",
  title: "Product Section",
  props: ["value"],
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>