<template>
  <div>
    <h1 class="page-header">Product Configuration</h1>
    <div class="row">
      <div class="col-md-6">
        <PaymentFrequency />
        <SumInsurance />
        <BenefitLimit />
        <CoInsurance />
        <WeightContribution />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentFrequency from "./PaymentFrequency.vue";
import SumInsurance from "./SumInsurance.vue";
import BenefitLimit from "./BenefitLimit.vue";
import CoInsurance from "./CoInsurance.vue";
import WeightContribution from "./WeightContribution.vue";
import paymentFrequencyService from "@/service/paymentfrequency.service";
import sumInsuranceService from "@/service/suminsurance.service";
import benefitLimitService from "@/service/benefitlimit.service";
import coInsuranceService from "@/service/coinsurance.service";
import weightContributionService from "@/service/weightcontribution.service";
import debounce from "lodash.debounce";

export default {
  components: {
    PaymentFrequency,
    SumInsurance,
    BenefitLimit,
    CoInsurance,
    WeightContribution
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      payments: [],
      sumInsurance: [],
      benefitLimit: [],
      coInsurance: [],
      factor: [],
      weight: [],
      payload: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10
      },
      payloadProductFreq: {
        code: " ",
        value: 0
      },
      payloadSumInsurance: {
        code: " ",
        value: 0
      },
      payloadBenefitLimit: {
        code: " ",
        value: " "
      },
      payloadCoInsurance: {
        code: " ",
        value: " "
      },
      payloadWeight: {
        code: " ",
        value: " "
      }
    };
  },
  watch: {
    payments: {
      immidiate: false,
      deep: true,
      handler: debounce(function (v, ov) {
        if (v.length > 0 && ov) {
          this.payment(v, ov)
        }
      }, 2000)
    },
    sumInsurance: {
      immidiate: false,
      deep: true,
      handler: debounce(function (v, ov) {
        if (v.length > 0 && ov) {
          this.suminsurance(v, ov)
        }
      }, 2000)
    },
    benefitLimit: {
      immidiate: false,
      deep: true,
      handler: debounce(function (v, ov) {
        if (v.length > 0 && ov) {
          this.benefitlimit(v, ov)
        }
      }, 2000)
    },
    coInsurance: {
      immidiate: false,
      deep: true,
      handler: debounce(function (v, ov) {
        if (v.length > 0 && ov) {
          this.coinsurance(v, ov)
        }
      }, 2000)
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    /*Payment*/
    payment(nv, ov) {
      for (const [k, v] of Object.entries(nv)) {
        if (v.id) {
          if (ov[k]) {
            this.putPaymentFrequency(v)
          }
        } else {
          if (v.code && v.value) {
            this.postPaymentFrequency(v)
          }
        }
      }
    },
    postPaymentFrequency(data) {
      paymentFrequencyService
        .postPaymentFrequency(data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Add Payment Frequency'
          });
          this.loadPaymentFrequence()
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    putPaymentFrequency(data) {
      let { id } = data
      paymentFrequencyService
        .putPaymentFrequency(id, data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Edit Payment Frequency'
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    addPayment() {
      this.payments.push(
        {
          code: "",
          value: ""
        }
      );
    },
    removePayment(index, item) {
      paymentFrequencyService
        .deletePaymentFrequencyById(item.id)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Success message',
            text: 'Delete Success'
          });
          this.loadPaymentFrequence();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    /*Sum Insurance */
    suminsurance(nv, ov) {
      for (const [k, v] of Object.entries(nv)) {
        if (v.id) {
          if (ov[k]) {
            this.putSumInsurance(v)
          }
        } else {
          if (v.code && v.value) {
            this.postSumInsurance(v)
          }
        }
      }
    },
    postSumInsurance(data) {
      sumInsuranceService
        .postSumInsurance(data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Add Sum Insurance'
          });
          this.loadSumInsurance();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    putSumInsurance(data) {
      let { id } = data
      sumInsuranceService
        .putSumInsurance(id, data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Edit Sum Insurance'
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    addSumInsurance() {
      this.sumInsurance.push({
        code: '',
        label: ''
      })
    },
    removeSumInsurance(index) {
      sumInsuranceService
        .deleteSumInsuranceById(this.sumInsurance[index].id)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Success message',
            text: 'Delete Success'
          });
          this.loadSumInsurance();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    editSumInsurance(index, item) {
    },
    /*Benefit Limit*/
    benefitlimit(nv, ov) {
      for (const [k, v] of Object.entries(nv)) {
        if (v.id) {
          if (ov[k]) {
            this.putBenefitLimit(v)
          }
        } else {
          if (v.code && v.value) {
            this.postBenefitLimit(v)
          }
        }
      }
    },
    postBenefitLimit(data) {
      benefitLimitService
        .postBenefitLimit(data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Add Benefit Limit'
          });
          this.loadBenefitLimit()
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    putBenefitLimit(data) {
      let { id } = data
      benefitLimitService
        .putBenefitLimit(id, data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Edit Benefit Limit'
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    addBenefitLimit() {
      this.benefitLimit.push({
        code: '',
        value: ''
      })
    },
    removeBenefitLimit(index) {
      benefitLimitService
        .deleteBenefitLimitById(this.benefitLimit[index].id)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Success message',
            text: 'Delete Success'
          });
          this.loadBenefitLimit();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
      this.benefitLimit.splice(index, 1);
    },
    editBenefitLimit(index, item) {
    },
    /*Co Insurance*/
    coinsurance(nv, ov) {
      for (const [k, v] of Object.entries(nv)) {
        if (v.id) {
          if (ov[k]) {
            this.putCoInsurance(v)
          }
        } else {
          if (v.code && v.value) {
            this.postCoInsurance(v)
          }
        }
      }
    },
    postCoInsurance(data) {
      coInsuranceService
        .postCoInsurance(data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Add Co Insurance'
          });
          this.loadCoInsurance();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    putCoInsurance(data) {
      let { id } = data
      coInsuranceService
        .putCoInsurance(id, data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Edit Co Insurance'
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    addCoInsurance() {
      this.coInsurance.push({
        code: '',
        value: ''
      })
    },
    removeCoInsurance(index) {
      coInsuranceService
        .deleteCoInsuranceById(this.coInsurance[index].id)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Success message',
            text: 'Delete Success'
          });
          this.loadCoInsurance();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    editCoInsurance(index, item) {
    },
    /*Weight Contribution*/
    weightcontribution(nv, ov) {
      for (const [k, v] of Object.entries(nv)) {
        if (v.id) {
          if (ov[k]) {
            this.putWeightContribution(v)
          }
        } else {
          if (v.code && v.value) {
            this.postWeightContribution(v)
          }
        }
      }
    },
    postWeightContribution(data) {
      weightContributionService
        .postWeightContribution(data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Add Weight Contribution'
          });
          this.loadWeightContribution();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    putWeightContribution(data) {
      let { id } = data
      weightContributionService
        .putWeightContribution(id, data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Succes Edit Weight Contribution'
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    addWeight() {
      this.weight.push({
        code: '',
        value: ''
      })
    },
    removeWeight(index) {
      weightContributionService
        .deleteWeightContributionById(this.weight[index].id)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Success message',
            text: 'Delete Success'
          });
          this.loadWeightContribution();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    editWeight(index, item) {
    },
    loadItems() {
      this.loadPaymentFrequence();
      this.loadSumInsurance();
      this.loadBenefitLimit();
      this.loadCoInsurance();
      this.loadWeightContribution();
    },
    loadPaymentFrequence() {
      paymentFrequencyService
        .getPaymentFrequencyList(this.payload)
        .then((rsp) => {
          this.payments = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    loadSumInsurance() {
      sumInsuranceService
        .getSumInsuranceList(this.payload)
        .then((rsp) => {
          this.sumInsurance = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    loadBenefitLimit() {
      let payload = this.payload
      payload.sort = 'code'
      benefitLimitService
        .getBenefitLimitList(payload)
        .then((rsp) => {
          // console.log()
          this.benefitLimit = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    loadCoInsurance() {
      coInsuranceService
        .getCoInsuranceList(this.payload)
        .then((rsp) => {
          this.coInsurance = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    loadWeightContribution() {
      weightContributionService
        .getWeightContributionList(this.payload)
        .then((rsp) => {
          // console.log()
          this.weight = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    }
  },
  mounted() {
    this.loadItems();
  },
};
</script>
