<template>
  <form @submit.prevent="doSave('PUBLISHED')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} BMI</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <!-- <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Charity Title"
            v-model="organization.title" />
          <span class="text-danger" v-if="$v.organization.title.$error">Title is required</span>
        </div> -->
        <div class="panel">
          <div class="panel-body">
            <div class="mb-15px">
              <label class="form-label">Age</label>
              <div class="mb-15px">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="organization.ageMin" :maxlength="11"
                    v-on:keypress="isNumber" />
                  <span class="input-group-text"> - </span>
                  <input type="text" class="form-control" v-model="organization.ageMax" :maxlength="11"
                    v-on:keypress="isNumber" />
                </div>
                <span class="text-danger" v-if="$v.organization.ageMin.$error">Age Min is required</span>
                <span class="text-danger" v-if="$v.organization.ageMax.$error">Age Max is required</span>
              </div>
            </div>

            <div class="mb-15px">
              <label class="form-label">Years / Month</label>
              <div class="mb-15px">
                <select class="form-select" v-model="organization.typeBmi">
                  <option disabled />
                  <option v-for="(option, key) in [
                    { label: 'Years', value: 'YEARS' },
                    { label: 'Month', value: 'MONTH' },
                  ]" v-bind:value="option.value" :key="key" v-text="option.label" />
                </select>
                <span class="text-danger" v-if="$v.organization.typeBmi.$error">Type is required</span>
              </div>
            </div>
            <div class="mb-15px">
              <label class="form-label">Gender</label>
              <div class="mb-15px">
                <select class="form-select" v-model="organization.gender">
                  <option disabled />
                  <option v-for="(option, key) in [
                    { title: 'MALE', value: 'MALE' },
                    { title: 'FEMALE', value: 'FEMALE' }
                  ]" v-bind:value="option.value" :key="key" v-text="option.title" />
                </select>
                <span class="text-danger" v-if="$v.organization.gender.$error">Gender is required</span>
              </div>
            </div>

            <div class="mb-15px">
              <label class="form-label">BMI</label>
              <div class="mb-15px">
                <div class="input-group">
                  <input type="text" class="form-control" v-model.number="organization.bmiMin"
                    v-on:keypress="isNumberFloat" />
                  <span class="input-group-text"> - </span>
                  <input type="text" class="form-control" v-model.number="organization.bmiMax"
                    v-on:keypress="isNumberFloat" />
                </div>
                <span class="text-danger" v-if="$v.organization.bmiMin.$error">Bmi Min is required</span>
                <span class="text-danger" v-if="$v.organization.bmiMax.$error">Bmi Max is required</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import { required, minLength, between } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      customToolbar: defaultToolbarEditor,
      organization: {
        gender: null,
        typeBmi: null,
        ageMin: null,
        ageMax: null,
        bmiMin: null,
        bmiMax: null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.organization = newVal;
    },
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isNumberFloat($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (this.price != null && this.price.indexOf(".") > -1 && (this.price.split('.')[1].length > 1)) {
        $event.preventDefault();
      }
    },
    doSave(type) {
      this.$v.organization.$touch();
      if (!this.$v.organization.$invalid) {
        const payload = {
          organization: this.organization,
        };

        this.$emit("onValidateSuccess", payload);
      }
    },

    dateFormat(date) {
      let arrDate = date.split(" ");
      let newDate = new Date(arrDate[0]);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return newDate.toLocaleDateString("id", options);
    },
  },
  filters: {
    getDate(date) {
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
  validations: {
    organization: {
      gender: { required },
      typeBmi: { required },
      ageMin: { required },
      ageMax: { required },
      bmiMin: { required },
      bmiMax: { required },
    },
  },
};
</script>
