import api from "./api";

class EmailService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('notification')
    }

    getEmailList(serverParams) {
        return this.api.get("/notification/email", { params: serverParams });
    }

    postEmail(serverParams) {
        return this.api.post("/notification/email", { ...serverParams });
    }

    getEmailById(serverParams) {
        return this.api.get("/notification/email/" + serverParams);
    }

    putEmail(id, serverParams) {
        return this.api.put("/notification/email/" + id, { ...serverParams });
    }

    deleteEmailById(serverParams) {
        return this.api.delete("/notification/email/" + serverParams);
    }

}

export default new EmailService();