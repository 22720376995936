<template>
  <div>
    <!-- BEGIN page-header -->
    <h1 class="page-header">Dashboard</h1>
    <!-- END page-header -->

    <!-- BEGIN row -->
    <div class="panel">
      <div class="panel-heading">
        <div class="row">
          <label class="form-label col-form-label col-auto">Date range</label>
          <div class="form-group col-auto">
            <date-range-picker ref="picker" class="btn btn-white" :opens="dateRange.opens"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" :singleDatePicker="dateRange.singleDatePicker"
              :timePicker="dateRange.timePicker" :timePicker24Hour="dateRange.timePicker24Hour"
              :showWeekNumbers="dateRange.showWeekNumbers" :showDropdowns="dateRange.showDropdowns"
              :autoApply="dateRange.autoApply" v-model="dateRange.range" @update="updateValues"
              :linkedCalendars="dateRange.linkedCalendars">
              <template v-slot:input="picker">
                <i class="fa fa-calendar fa-fw ms-n1"></i>
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                <b class="caret ms-1 opacity-5"></b>
              </template>
            </date-range-picker>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-outline-primary" @click.prevent="generateChart">
              <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              Generate
            </button>
          </div>
          <div class="col-auto">
            <b-dropdown variant="outline-secondary">
              <template slot="button-content">
                Download <i class="fa fa-chevron-down"></i>
              </template>
              <a href="javascript:;" class="dropdown-item" @click.prevent="exportFile('csv')">CSV</a>
              <a href="javascript:;" class="dropdown-item" @click.prevent="exportFile('xlsx')">Excel</a>
            </b-dropdown>
          </div>
          <div class="col-auto">
            <b-dropdown variant="outline-secondary" text="">
              <template slot="button-content">
                Filter Chart <i class="fa fa-chevron-down"></i>
              </template>
              <b-dropdown-form>
                <b-form-group>
                  <b-form-checkbox class="dropdown-item" v-for="option in optionsChart" v-model="filterChart"
                    :key="option.value" :value="option.value">
                    &nbsp;{{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
                <!-- <b-form-checkbox v-for="options in optionsChart" class="dropdown-item" :id="options.value"
                  :name="options.value" value="true" unchecked-value="false" @click="optionsSelected(options.value)">
                  &nbsp;{{ options.text }}
                </b-form-checkbox> -->
                <!-- <b-dropdown-divider></b-dropdown-divider>
                <b-button variant="primary" size="sm" @click="onSaveFilter">Save</b-button> -->
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-6" v-if="hideChart('submission_by_time')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">Submission by Time</h5>
                <b-tabs v-model="submissionTab" pills nav-class="nav-pills--underline bg-white">
                  <b-tab>
                    <template slot="title"> Daily </template>
                  </b-tab>
                  <b-tab>
                    <template slot="title"> Weekly </template>
                  </b-tab>
                  <b-tab active>
                    <template slot="title"> Monthly </template>
                  </b-tab>
                  <line-chart :data="lineChart.data" :options="lineChart.options"></line-chart>
                </b-tabs>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="hideChart('total_actions_by_leads')">
            <div class="panel mb-4">
              <div class="panel-body">
                <div class="text-center">
                  <h6 class="fw-bolder mb-3">TOTAL ACTIONABLE LEADS</h6>
                  <h3 class="fw-bolder">{{ barChartSubmissions.totalBar }}</h3>
                </div>
                <apexchart type="bar" :options="barChartSubmissions.options" :series="barChartSubmissions.series"
                  height="250"></apexchart>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="hideChart('age_category')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">Age Category</h5>
                <apexchart type="bar" :options="columnChart.options" :series="columnChart.series" height="250">
                </apexchart>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="hideChart('submission_by_city')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">Submissions by City</h5>
                <apexchart type="bar" :options="barChart.options" :series="barChart.series" height="250"></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" v-if="hideChart('selected_product')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">Selected Product</h5>
                <apexchart type="bar" :options="barChartProducts.options" :series="barChartProducts.series" height="250">
                </apexchart>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="hideChart('selected_rider')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">Selected Rider</h5>
                <apexchart type="bar" :options="barChartRiders.options" :series="barChartRiders.series" height="250">
                </apexchart>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="hideChart('selected_payment_method')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">Selected Payment Method</h5>
                <apexchart type="bar" :options="barChartPayments.options" :series="barChartPayments.series" height="250">
                </apexchart>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="hideChart('selected_donation_foundation')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">Selected Donation Foundation</h5>
                <apexchart type="bar" :options="barChartDonations.options" :series="barChartDonations.series"
                  height="250"></apexchart>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="hideChart('http_referrer')">
            <div class="panel mb-4">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">HTTP Referrer</h5>
                <apexchart type="bar" :options="barChartHttpReferrer.options" :series="barChartHttpReferrer.series"
                  height="150"></apexchart>
              </div>
            </div>
            <div class="panel mb-4" v-if="hideChart('utm')">
              <div class="panel-body">
                <h5 class="fw-bolder mb-3">UTM</h5>
                <apexchart type="bar" :options="barChartUtm.options" :series="barChartUtm.series" height="150">
                </apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END row -->
  </div>
</template>

<script>
import LineChart from "../../components/vue-chartjs/LineChart";
import moment from "moment";
import analyticService from "@/service/analytic.service";
import VueApexCharts from "vue-apexcharts";
import { read, utils, writeFileXLSX } from 'xlsx';
import activityService from '@/service/activity.service';

export default {
  components: {
    LineChart,
    apexchart: VueApexCharts
  },
  data() {
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontColor = "#2d353c";
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontFamily =
      "Open Sans, Helvetica Neue,Helvetica,Arial,sans-serif";
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontStyle = "600";

    let today = new Date();
    var eventsData = [
      {
        date: `${today.getFullYear()}/${today.getMonth() + 1}/15`,
        title: "Sales Reporting",
        badge: "bg-gradient-teal",
        time: "9:00am",
      },
      {
        date: `${today.getFullYear()}/${today.getMonth() + 1}/24`,
        title: "Have a meeting with sales team",
        badge: "bg-gradient-blue",
        time: "2:45pm",
      },
    ];
    var currentDate = new Date();
    return {
      optionsChart: [
        {
          "text": "Submission By Time",
          "value": "submission_by_time"
        },
        {
          "text": "Total Actions By Leads",
          "value": "total_actions_by_leads"
        },
        {
          "text": "Age Category",
          "value": "age_category"
        },
        {
          "text": "Submission By City",
          "value": "submission_by_city"
        },
        {
          "text": "Selected Product",
          "value": "selected_product"
        },
        {
          "text": "Selected Rider",
          "value": "selected_rider"
        },
        {
          "text": "Selected Payment Method",
          "value": "selected_payment_method"
        },
        {
          "text": "Selected Donation Foundation",
          "value": "selected_donation_foundation"
        },
        {
          "text": "HTTP Referrer",
          "value": "http_referrer"
        },
        {
          "text": "UTM",
          "value": "utm"
        }
      ],
      filterChart: [
        'submission_by_time',
        'total_actions_by_leads',
        'age_category',
        'submission_by_city',
        'selected_product',
        'selected_rider',
        'selected_payment_method',
        'selected_donation_foundation',
        'http_referrer',
        'utm'
      ],
      onLoading: false,
      submissionTab: 0,
      submissionType: '',
      loadedSubmissionByTime: true,
      dateRange: {
        opens: "right",
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        linkedCalendars: false,
        range: {
          startDate: moment(new Date('01/01/2023')),
          endDate: moment(new Date()),
          prevStartDate: moment(new Date('01/01/2023')),
          prevEndDate: moment(new Date()),
        },
      },
      lineChart: {
        dataExcelByTime: [],
        dataByMontly: [],
        dataByDaily: [],
        dataByWeekly: [],
        disabledMonthly: false,
        disabledWeekly: false,
        disabledDaily: false,
        data: {
          labels: [],
          datasets: [
            {
              label: "Submissions",
              backgroundColor: "rgba(194, 27, 23, 0.2)",
              borderColor: "#C21B17",
              pointBackgroundColor: "#C21B17",
              pointRadius: 3,
              borderWidth: 2,
              fill: "start",
              data: [],
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            mode: "nearest",
            intersect: true,
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 50,
                },
              },
            ],
          },
        },
      },
      columnChart: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -25,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [
            ],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          }, noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "",
            data: [],
          },
        ],
      },
      barChart: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -25,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [
            ],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          }, noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "City",
            data: [],
          },
        ],
      },
      barChartSubmissions: {
        data: [],
        totalBar: 0,
        options: {
          chart: {
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartProducts: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartRiders: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [
              "Rawat Inap",
              "Rawat Jalan",
              "Other",
            ],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [68, 28, 57],
          },
        ],
      },
      barChartPayments: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [
            ],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartDonations: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartHttpReferrer: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartUtm: {
        data: [],
        options: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          colors: ["#C21B17"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false,
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      events: {
        data: eventsData,
        displayData: eventsData,
      },
    };
  },
  watch: {
    filterChart: {
      handler: function (nv, ov) {
        console.log(nv)
      },
      deep: true
    },
    submissionTab: {
      handler: function (nv, ov) {
        this.loadChartLine(nv)
      },
      deep: true
    }
  },
  methods: {
    linkClass(type) {
      // console.log(type, 'type')
    },
    updateValues(d) {
      // console.log()
      var startDate = moment(d.startDate);
      var endDate = moment(d.endDate);

      this.dateRange.range.prevStartDate = moment(startDate)
        .format("D MMMM YYYY");

      this.dateRange.range.prevEndDate = moment(endDate)
        .format("D MMMM YYYY");
      this.loadItems()
    },
    formatDate(d) {
      var monthsName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      d = new Date(d);
      d = monthsName[d.getMonth()] + " " + d.getDate();
      return d;
    },
    loadItems() {
      this.onLoading = true
      let payload = {
        "startDate": moment(this.dateRange.range.prevStartDate).format("YYYY-MM-DD"),
        "endDate": moment(this.dateRange.range.prevEndDate).format("YYYY-MM-DD"),
        "time": this.submissionType
      }
      console.log(payload);

      analyticService.getBusinessAnalytic(payload).then((rsp) => {
        this.onLoading = false
        this.lineChart.dataByMontly = rsp.data.data.registrationByTimeDto.monthlyDtos ? rsp.data.data.registrationByTimeDto.monthlyDtos : [];
        this.lineChart.dataByDaily = rsp.data.data.registrationByTimeDto.dailyDtos ? rsp.data.data.registrationByTimeDto.dailyDtos : [];
        this.lineChart.dataByWeekly = rsp.data.data.registrationByTimeDto.weeklyDtos ? rsp.data.data.registrationByTimeDto.weeklyDtos : [];

        if (this.lineChart.dataByDaily?.length > 0) {
          this.loadChartLine(0);
        }
        if (this.lineChart.dataByWeekly?.length > 0) {
          this.loadChartLine(1);
        }
        if (this.lineChart.dataByMontly?.length > 0) {
          this.loadChartLine(2);
        }

        if (rsp.data.data.registrationByAgeDtos.length != 0) {
          let registrationByAgeDtos = [], numberOfRegistrant = [], barChart = [];
          for (const [k, v] of Object.entries(rsp.data.data.registrationByAgeDtos)) {
            registrationByAgeDtos.push(v.label ? v.label : '');
            numberOfRegistrant.push(v.count);
            barChart.push({ age: v.label, number: v.count })
          }
          this.columnChart.data = barChart
          this.columnChart.options =
          {
            ...this.barChart.options,
            ...{
              xaxis: {
                categories: registrationByAgeDtos
              }
            }
          }
          this.columnChart.series[0].data = numberOfRegistrant
        }

        if (rsp.data.data.registrationByCity.length != 0) {
          let registrationByCity = [], numberOfRegistrant = [], barChart = [];
          for (const [k, v] of Object.entries(rsp.data.data.registrationByCity)) {
            registrationByCity.push(v.registrantCity ? v.registrantCity : '')
            numberOfRegistrant.push(v.numberOfRegistrant);
            barChart.push({ location: v.registrantCity, number: v.numberOfRegistrant })
          }
          this.barChart.data = barChart
          this.barChart.options =
          {
            ...this.barChart.options,
            ...{
              xaxis: {
                categories: registrationByCity
              }
            }
          }
          this.barChart.series[0].data = numberOfRegistrant
        }

        if (rsp.data.data.registrationByProduct.length != 0) {
          let product = [], numberOfRegistrant = [], barChart = [];
          for (const [k, v] of Object.entries(rsp.data.data.registrationByProduct)) {
            product.push(v.product ? v.product : '')
            numberOfRegistrant.push(v.numberOfRegistrant);
            barChart.push({ product: v.product, number: v.numberOfRegistrant })
          }

          this.barChartProducts.data = barChart
          this.barChartProducts.options =
          {
            ...this.barChartProducts.options,
            ...{
              xaxis: {
                categories: product
              }
            }
          }
          this.barChartProducts.series[0].data = numberOfRegistrant
        }

        if (rsp.data.data.registrationByDonationFoundation.length != 0) {
          let donationFoundation = [], numberOfRegistrant = [], barChartExcel = [];
          for (const [k, v] of Object.entries(rsp.data.data.registrationByDonationFoundation)) {
            donationFoundation.push(v.donationFoundation ? v.donationFoundation : '')
            numberOfRegistrant.push(v.numberOfRegistrant);
            barChartExcel.push({ donationFoundation: v.donationFoundation, number: v.numberOfRegistrant })
          }

          this.barChartDonations.data = barChartExcel
          this.barChartDonations.options =
          {
            ...this.barChartDonations.options,
            ...{
              xaxis: {
                categories: donationFoundation
              }
            }
          }
          this.barChartDonations.series[0].data = numberOfRegistrant
        }

        if (rsp.data.data.registrationByPaymentMethod.length != 0) {
          let paymentMethod = [], numberOfRegistrant = [], barChartExcel = [];
          for (const [k, v] of Object.entries(rsp.data.data.registrationByPaymentMethod)) {
            paymentMethod.push(v.paymentMethod ? v.paymentMethod : '')
            numberOfRegistrant.push(v.numberOfRegistrant);
            barChartExcel.push({ paymentMethod: v.paymentMethod, number: v.numberOfRegistrant })
          }
          this.barChartPayments.data = barChartExcel
          this.barChartPayments.options =
          {
            ...this.barChartPayments.options,
            ...{
              xaxis: {
                categories: paymentMethod
              }
            }
          }
          this.barChartPayments.series[0].data = numberOfRegistrant
        }

        if (rsp.data.data.registrationByPhase.length != 0) {
          let registrantPhase = [], numberOfRegistrant = [], totalBar = 0, dataExcel = [];
          for (const [k, v] of Object.entries(rsp.data.data.registrationByPhase)) {
            registrantPhase.push(v.registrantPhase ? v.registrantPhase : '')
            numberOfRegistrant.push(v.numberOfRegistrant);
            totalBar += v.numberOfRegistrant
            dataExcel.push({ leads: v.registrantPhase, number: v.numberOfRegistrant })
          }

          this.barChartSubmissions.totalBar = totalBar
          this.barChartSubmissions.data = dataExcel
          this.barChartSubmissions.options =
          {
            ...this.barChartSubmissions.options,
            ...{
              xaxis: {
                categories: registrantPhase
              }
            }
          }
          this.barChartSubmissions.series[0].data = numberOfRegistrant
        }

        if (rsp.data.data.registrationByRiderDtos.length != 0) {
          let riders = [], numberOfRegistrant = [], dataExcel = [];
          for (const [k, v] of Object.entries(rsp.data.data.registrationByRiderDtos)) {
            riders.push(v.rider ? v.rider : '')
            numberOfRegistrant.push(v.numberOfRegistrant);
            dataExcel.push({ rider: v.rider, number: v.numberOfRegistrant })
          }
          this.barChartRiders.data = dataExcel
          this.barChartRiders.options =
          {
            ...this.barChartRiders.options,
            ...{
              xaxis: {
                categories: riders
              }
            }
          }
          this.barChartRiders.series[0].data = numberOfRegistrant
        }
        return Promise.all([
          this.loadRefererAndUtm('utmSource', 'barChartUtm'),
          this.loadRefererAndUtm('httpReferer', 'barChartHttpReferrer')
        ])

      }).catch((e) => {
        this.onLoading = false
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
        });
      })
    },
    loadRefererAndUtm(field = 'utmSource', barName = 'barChartUtm') {
      const baseParam = {
        startDate: moment(this.dateRange.range.startDate)
          .startOf("day")
          .valueOf(),
        endDate: moment(this.dateRange.range.endDate)
          .endOf("day")
          .valueOf()
      }
      return activityService.getPageViewGroupBy({ field, ...baseParam }).then(
        ({ data: { data } }) => {
          this[barName].options.xaxis.categories = [];
          this[barName].series[0].data = [];

          data.sort((a, b) => b.total - a.total)
          data.forEach(
            (v, i) => {
              this[barName].options.xaxis.categories.push(v.field)
              this[barName].series[0].data.push(v.total)
            }
          )
          this[barName].options = { ...this[barName].options };
        }
      );
    },
    loadChartLine(tab) {
      switch (tab) {
        case 0:
          this.lineChart.disabledDaily = true
          this.lineChart.disabledWeekly = false
          this.lineChart.disabledMonthly = false
          if (this.lineChart.dataByDaily.length != 0) {
            let regByTimeDaily = [], numbers = [];
            for (const [k, v] of Object.entries(this.lineChart.dataByDaily)) {
              regByTimeDaily.push(v.day + '/' + v.month + '/' + v.year)
              numbers.push(v.number);
            }
            this.lineChart.data = {
              labels: regByTimeDaily,
              datasets: [{ ...this.lineChart.data.datasets[0], data: numbers }]
            }
          }
          break;
        case 1:
          //week
          this.lineChart.disabledDaily = false
          this.lineChart.disabledWeekly = true
          this.lineChart.disabledMonthly = false
          if (this.lineChart.dataByWeekly.length != 0) {
            let regByTimeWeekly = [], numbers = [];
            for (const [k, v] of Object.entries(this.lineChart.dataByWeekly)) {
              regByTimeWeekly.push('Week ke - ' + v.week)
              numbers.push(v.number);
            }
            this.lineChart.data = {
              labels: regByTimeWeekly,
              datasets: [{ ...this.lineChart.data.datasets[0], data: numbers }]
            }
          }
          break;
        case 2:
          //monthly
          this.lineChart.disabledDaily = false
          this.lineChart.disabledWeekly = false
          this.lineChart.disabledMonthly = true
          if (this.lineChart.dataByMontly.length != 0) {
            let regByTimeMonthly = [], numbers = [];
            for (const [k, v] of Object.entries(this.lineChart.dataByMontly)) {
              regByTimeMonthly.push(this.formatDateMonth(v.month))
              numbers.push(v.number);
            }

            this.lineChart.data = {
              labels: regByTimeMonthly,
              datasets: [{ ...this.lineChart.data.datasets[0], data: numbers }]
            }
          }
          break;
        default:
          break;
      }
    },
    generateChart() {
      this.loadItems()
    },
    exportFile(type) {
      const wb = utils.book_new();


      if (this.lineChart.dataByDaily?.length > 0) {
        const byAge = utils.json_to_sheet(this.lineChart.dataByDaily);
        utils.book_append_sheet(wb, byAge, "SubmissionDataByDaily");
      }

      if (this.lineChart.dataByWeekly?.length > 0) {
        const byAge = utils.json_to_sheet(this.lineChart.dataByWeekly);
        utils.book_append_sheet(wb, byAge, "SubmissionDataByWeekly");
      }

      if (this.lineChart.dataByMontly?.length > 0) {
        const byAge = utils.json_to_sheet(this.lineChart.dataByMontly);
        utils.book_append_sheet(wb, byAge, "SubmissionDataByMonthly");
      }

      //registrationByAgeDtos
      if (this.columnChart.data.length != 0) {
        const byAge = utils.json_to_sheet(this.columnChart.data);
        utils.book_append_sheet(wb, byAge, "Age");
      }
      console.log(this.columnChart.data)

      //registrationByCity
      if (this.barChart.data.length != 0) {
        const byCity = utils.json_to_sheet(this.barChart.data);
        utils.book_append_sheet(wb, byCity, "City");
      }

      //registrationByDonationFoundation
      if (this.barChartDonations.data.length != 0) {
        const byDonation = utils.json_to_sheet(this.barChartDonations.data);
        utils.book_append_sheet(wb, byDonation, "Donation");
      }

      //registrationByPaymentMethod
      if (this.barChartPayments.data.length != 0) {
        const byPayment = utils.json_to_sheet(this.barChartPayments.data);
        utils.book_append_sheet(wb, byPayment, "Payment");
      }

      //registrationByPhase
      if (this.barChartSubmissions.data.length != 0) {
        const byPhase = utils.json_to_sheet(this.barChartSubmissions.data);
        utils.book_append_sheet(wb, byPhase, "Phase");
      }

      //registrationByProduct
      if (this.barChartProducts.data.length != 0) {
        const byProduct = utils.json_to_sheet(this.barChartProducts.data);
        utils.book_append_sheet(wb, byProduct, "Product");
      }

      //registrationByRiderDtos
      if (this.barChartRiders.data.length != 0) {
        const byRiders = utils.json_to_sheet(this.barChartRiders.data);
        utils.book_append_sheet(wb, byRiders, "Riders");
      }

      writeFileXLSX(wb, "analytics_bussiness." + type);
    },
    formatDateMonth(d) {
      var monthsName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthsName[d];
    },
    hideChart(type) {
      if (this.filterChart.includes(type)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    date: function (value) {
      if (value) {
        return moment(String(value)).format("D MMMM YYYY");
      }
    },
  },
  mounted() {
    this.loadItems()
  }
};
</script>
