<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div v-if="formType === 'edit'" class="col-12">
        <h1 class="page-header">{{ name }}</h1>
        <h5 class="mb-4 fw-normal">Konsumen baru yang sukses mengisi data dan melakukan pembayaran pertama akan mendapat
          notifikasi ini</h5>
      </div>
      <div class="col-lg-12">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Title" v-model="name" />
          <span class="text-danger" v-if="$v.name.$error">Name is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <label class="form-label">Category</label>
            <v-select v-model="group" :options="optionsGroup" label="name" />
            <span class="text-danger" v-if="$v.group.$error">Group is required</span>
          </div>
        </div>
        <div class="panel">
          <div class="panel-body">
            <textarea class="form-control" placeholder="Description" maxlength="160" v-model="description"
              rows="7"></textarea>
            <span class="text-danger" v-if="$v.description.$error">Description is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import NotificationCategoryService from "@/service/notificationcategory.service";

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  data() {
    let detail = this.detailData;
    this.loadGroup()
    return {
      name: detail ? detail.name : null,
      description: detail ? detail.description : null,
      optionsGroup: detail ? detail.categoryNotification : null,
      group: detail ? detail.categoryNotification : null,
      optionsGroup: []
    };
  },
  watch: {
    'detailData'(ov) {
      // console.log(ov)
      this.name = ov.name
      this.description = ov.description
      this.group = ov.categoryNotification
    },
  },
  methods: {
    doSave() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          name: this.name,
          description: this.description,
          categoryNotification: this.group
        };

        this.$emit("onValidateSuccess", payload);
      }
    },
    loadGroup() {
      let payload = {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 100,
        groupCategory: 'SMS'
      }
      NotificationCategoryService
        .getNotificationCategoryList(payload)
        .then((rsp) => {
          this.optionsGroup = rsp.data?.data ? rsp.data?.data.content : [];
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    }
  },
  validations: {
    name: {
      required
    },
    description: {
      required, maxlength: maxLength(160)
    },
    group: {
      required
    }
  }
};
</script>
