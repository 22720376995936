<template>
  <div>
    <div class="panel">
      <div class="panel-body p-0">
        <div class="table-responsive mb-0">
          <table class="table table-form">
            <thead>
              <tr>
                <th scope="col">Banner Name</th>
                <th scope="col">Published Date</th>
                <th scope="col">Author</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <draggable v-model="rows" tag="tbody" ghost-class="ghost" :move="checkMove" @start="dragging = true"
              @end="dragging = false">
              <tr v-for="item in rows" :key="item.id" class="bg-white">
                <td>{{ item.title }}</td>
                <td>{{ dateFormat(item.publishedDate) }}</td>
                <td>{{ item.createdBy }}</td>
                <td>
                  <div class="row g-2 align-items-center justify-content-end">
                    <div class="col-auto" v-if="$can($permissionsAll[51])">
                      <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Undo"
                        @click.prevent="showUndoConfirm(item.id)">
                        <i class="fa-solid fa-rotate-left"></i>
                      </button>
                    </div>
                    <div class="col-auto" v-if="$can($permissionsAll[52])">
                      <button type="button" @click.prevent="showAlertConfirm(item.id)" class="btn btn-lg text-dark"
                        v-b-tooltip.hover title="Delete">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import bannerService from "@/service/banner.service";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      isLoading: false,
      dragging: false,
      rows: [],
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
        status: ''
      },
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    loadItems() {
      this.serverParams.status = 'TRASHED'
      bannerService
        .getBannerList(this.serverParams)
        .then((rsp) => {
          this.rows = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    checkMove() {
      // console.log("dragging");
    },
    onDelete(id) {
      bannerService
        .deleteBannerById(id)
        .then(() => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.$emit('reloadData', 'TRASHED')
          this.loadItems();
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },

    showUndoConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Drafts?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleUndo(id);
          }
        });
    },
    handleUndo(id) {
      bannerService
        .changeBannerStatus(id, "DRAFTS")
        .then(
          () => {
            this.$swal({
              title: "Data berhasil Dipindah Ke DRAFTS",
              showCloseButton: true,
            }).then(() => {
              this.$emit('reloadData', 'PUBLISHED');
              this.$router.push("/sections/banner/trashed");
              this.$emit('reloadData', 'TRASHED');
            });
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
    dateFormat(date) {
      if (date) {
        let newDate = new Date(date)

        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric" };
        return newDate.toLocaleDateString("sv-SE", options);
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.loadItems();
  },
};
</script>
