<template>
    <div>
        <div class="row align-items-center justify-content-between mb-4">
            <div class="col-auto">
                <!-- BEGIN page-header -->
                <h1 class="page-header">All Referral</h1>
                <!-- END page-header -->
            </div>

            <div class="col-auto">
                <div class="row">
                    <div class="col-auto">
                        <button class="btn btn-dark" @click="getExportExcel">Export</button>
                    </div>
                    <div class="col-auto" v-if="$can($permissionsAll[70])">
                        <router-link to="/referral/add">
                            <a class="btn btn-outline-primary"> Add Referral</a>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-selected-rows-change="selectionChanged"
            @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords" :select-options="{ enabled: true }" :isLoading.sync="isLoading"
            :pagination-options="{
                            enabled: true,
                            dropdownAllowAll: false,
                        }" :columns="columns" :rows="rows" :sort-options="{
                            enabled: false,
                            initialSortBy: { field: 'username', type: 'asc' },
                        }" styleClass="vgt-table" slot="outsideBody">
            <template slot="selected-row-actions" slot-scope="props">
                <div class="col-auto" v-if="$can($permissionsAll[72])">
                    <button type="button" class="btn btn-lg text-dark" @click.prevent="showAlertConfirm('multiTrash')"
                        v-b-tooltip.hover title="Move to Trash">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
                <!-- <button>Action 1</button> -->
            </template>
            <template slot="column-filter" slot-scope="{ column, updateFilters }">

                <select ref="status" class="form-select" placeholder="Filter Status" v-if="column.filterOptions &&
                            column.filterOptions.customFilter &&
                            column.field === 'status'
                            " @change="(value) => updateFilters(column, $refs['status'].value)">
                    <option />
                    <option v-for="(option, key) in optionsStatus" v-bind:value="option.value" :key="key"
                        v-text="option.label" />
                </select>
            </template>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'action'">
                    <div class="row g-2 align-items-center justify-content-end">
                        <div class="col-auto" v-if="$can($permissionsAll[71])"> 
                            <router-link :to="`/referral/edit/${props.row.id}`" class="btn btn-lg text-dark"
                                v-b-tooltip.hover title="Edit">
                                <i class="fas fa-pencil"></i>
                            </router-link>
                        </div>
                    </div>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
import referralService from "@/service/referral.service";
import qs from "qs";
import base64Converter from "@/pages/Helper/base64";

export default {
    data() {
        return {
            selectedItems: [],
            optionsStatus: [
                {
                    label: 'Active',
                    value: 'ACTIVE'
                },
                {
                    label: 'Not Active',
                    value: 'INACTIVE'
                }
            ],
            isLoading: false,
            columns: [
                {
                    label: "Nama",
                    field: "name",
                    thClass: "text-nowrap",
                    tdClass: "align-middle",
                    filterOptions: {
                        customFilter: true,
                        enabled: true,
                        filterValue: "",
                    },
                },
                {
                    label: "Referral Code",
                    field: "referralCode",
                    thClass: "text-nowrap",
                    tdClass: "align-middle",
                    filterOptions: {
                        customFilter: true,
                        enabled: true,
                        filterValue: "",
                    },
                },
                {
                    label: "Partner",
                    field: "partner",
                    thClass: "text-nowrap",
                    tdClass: "align-middle",
                    filterOptions: {
                        customFilter: true,
                        enabled: true,
                        filterValue: "",
                    },
                },
                {
                    label: "Cabang Partner",
                    field: "cabangPartner",
                    thClass: "text-nowrap",
                    tdClass: "align-middle",
                    filterOptions: {
                        customFilter: true,
                        enabled: true,
                        filterValue: "",
                    },
                },
                {
                    label: "Status",
                    field: "status",
                    thClass: "text-nowrap",
                    tdClass: "align-middle",
                    filterOptions: {
                        customFilter: true,
                        enabled: true,
                        filterValue: "",
                    },
                },
                {
                    label: "Action",
                    field: "action",
                    width: "200px",
                    sortable: false,
                    thClass: "text-center text-nowrap",
                    tdClass: "text-center text-nowrap",
                },
            ],
            rows: [],
            totalRecords: 0,
            serverParams: {
                searchField: "",
                searchValue: "",
                sort: "",
                direction: "ASC",
                pageNumber: 0,
                pageSize: 10,
            },
            errorCode: "",
            errorMessage: "",
            error: "",
            forbidenError: false,
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ pageNumber: params.currentPage - 1 });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ pageNumber: 0 });
            this.updateParams({ pageSize: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            // console.log(params, 'test');
            // this.updateParams({
            //   sort: [
            //     {
            //       type: params[0].type,
            //       field: params[0].field,
            //     },
            //   ],
            // });
            this.loadItems();
        },

        onColumnFilter(params) {
            const { cabangPartner, name, partner, referralCode, status } = params.columnFilters;
            params = {};

            if (name) {
                params.name = name
            } else {
                delete this.serverParams.name
            }

            if (partner) {
                params.partner = partner
            } else {
                delete this.serverParams.partner
            }

            if (cabangPartner) {
                params.cabangPartner = cabangPartner
            } else {
                delete this.serverParams.cabangPartner
            }

            if (referralCode) {
                params.referralCode = referralCode
            } else {
                delete this.serverParams.referralCode
            }

            if (status) {
                params.searchField = "status"
                params.searchValue = status
            } else {
                delete this.serverParams.searchField
                delete this.serverParams.searchValue
            }

            this.updateParams(params);
            this.loadItems();
        },
        loadItems() {
            this.isLoading = true
            // Cancel previous call
            if (this.$data._debounceQueryTimer)
                clearTimeout(this.$data._debounceQueryTimer);
            this.$data._debounceQueryTimer = setTimeout(() => {
                referralService
                    .getReferralList(this.serverParams)
                    .then((rsp) => {
                        let arrData = []
                        if (rsp.data.data?.content?.length > 0) {
                            for (const [k, v] of Object.entries(rsp.data.data.content)) {
                                arrData.push({
                                    ...v,
                                    status: this.optionsStatus.find((r) => r.value == v.status)?.label
                                })
                            }
                        }
                        this.rows = arrData
                        this.totalRecords = rsp.data.data?.totalElements ? rsp.data.data?.totalElements : 0;
                    })
                    .catch((e) => {
                        this.isLoading = false
                        this.$notify({
                            type: "error",
                            title: "Error Notification",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    });
                this.$data._debounceQueryTimer = null;
            }, 300);
        },
        onDelete(id) {
            referralService
                .deleteReferralById(id)
                .then((rsp) => {
                    this.$notify({
                        type: "succes",
                        title: "Success message",
                        text: "Delete Success",
                    });
                    this.loadItems();
                })
                .catch((e) => {
                    this.$swal({
                        icon: "warning",
                        text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                    });
                });
        },
        showAlertConfirm(id) {
            this.$swal
                .fire({
                    title: "",
                    text: "Apakah Data Akan Di Hapus?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya",
                })
                .then(async (result) => {
                    if (result.value) {
                        if (id != 'multiTrash') {
                            await this.onDelete(id);
                        } else {
                            await this.multipleDelete();
                        }
                    }
                });
        },

        async postReferral(e) {
            let formData = new FormData()
            formData.append('file', e.target.files[0], e.target.files[0].name)

            referralService
                .postImportReferral(formData)
                .then((rsp) => {
                    this.$notify({
                        type: "succes",
                        title: "Success Message",
                        text: rsp?.data?.responseMessage,
                    });
                    this.loadItems();
                }).catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Message",
                        text: e.response?.data?.message || "Terjadi Kesalahan",
                    });
                })
        },
        chooseFiles() {
            const elem = this.$refs.fileUpload
            elem.click()
        },
        selectionChanged(params) {
            this.selectedRows = params.selectedRows
        },
        multipleDelete() {
            let arrData = []
            for (const [k, v] of Object.entries(this.selectedRows)) {
                arrData.push(v.id)
            }
            referralService
                .deleteReferralBulk(qs.stringify({ ids: arrData }, { arrayFormat: 'repeat' }))
                .then((rsp) => {
                    this.$notify({
                        type: "succes",
                        title: "Success Message",
                        text: rsp?.data?.responseMessage,
                    });
                    this.loadItems();
                }).catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Message",
                        text: e.response?.data?.message || "Terjadi Kesalahan",
                    });
                })
        },
        getExportExcel() {
            referralService
                .getReferralExport()
                .then((rsp) => {
                    this.$notify({
                        type: "succes",
                        title: "Success Message",
                        text: rsp?.data?.responseMessage,
                    });
                    this.loadItems();
                    window.location.assign(rsp.config.baseURL.slice(0, -1) + rsp.config.url)
                }).catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Message",
                        text: e.response?.data?.message || "Terjadi Kesalahan",
                    });
                })
        }
    },
    mounted() {
        this.loadItems();
    },
};
</script>