<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} SIO Question</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-editor v-model="title" :editor-toolbar="customToolbar" />
          </div>
        </div>
        <span class="text-danger" v-if="$v.title.$error">Question is
          required</span>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      customToolbar: defaultToolbarEditor,
      title: detail ? detail.title : null,
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add" : "Edit";
    },
  },
  watch: {
    'detailData'(newVal) {
      this.title = newVal.title;
    }
  },
  methods: {
    doSave() {

      this.$v.title.$touch();

      if (!this.$v.title.$invalid) {
        const payload = {
          title: this.title,
        };

        this.$emit("onValidateSuccess", payload);
      }
    },
  },
  validations: {
    title: { required },
  }
};
</script>
