<template>
  <div class="product-form">
    <FormQuestion form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" :on-loading="onProgress" />
  </div>
</template>
<script>
import FormQuestion from "./Form.vue";
import BmiService from "../../../service/bmi.service";
export default {
  data() {
    return {
      onProgress: false,
      detail: {
        id: this.$route.params.id,
        title: "",
      },
    };
  },
  components: {
    FormQuestion,
  },

  methods: {
    async doSave(payload) {
      this.onProgress = true
      payload = payload.organization
      BmiService
        .putBmi(this.$route.params.id, payload)
        .then((rsp) => {
          this.onProgress = false
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/sections/bmi' })
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    loadItems(id) {
      BmiService
        .getBmiById(id)
        .then((rsp) => {
          this.detail = rsp.data?.data
          this.detail.bmiMax = parseFloat(rsp.data?.data?.bmiMax).toFixed(1)
          this.detail.bmiMin = parseFloat(rsp.data?.data?.bmiMin).toFixed(1)
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
  },
  async mounted() {
    await this.loadItems(this.$route.params.id)
  },
};
</script>
