import api from "./api";

class CharityService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getCharityList(serverParams) {
        return this.api.get("/cms/charity", { params: serverParams });
    }

    postCharity(serverParams) {
        return this.api.post("/cms/charity", { ...serverParams });
    }

    getCharityById(serverParams) {
        return this.api.get("/cms/charity/" + serverParams);
    }

    putCharity(id, serverParams) {
        return this.api.put("/cms/charity/" + id, { ...serverParams });
    }

    deleteCharityById(serverParams) {
        return this.api.delete("/cms/delete/charity/" + serverParams);
    }

    getCharityFile(serverParams) {
        return this.api.get("/cms/charity/view/" + serverParams);
    }

    charityViewFile(name) {
        return this.api.get("/cms/banner/view/" + name);
    }

    charityReposisi(data) {
        return this.api.put("/cms/reposition/charity", data);
    }

    changeCharityStatus(id, status) {
        return this.api.put(`/cms/update/charity-status/${id}?status=${status}`)
    }
}

export default new CharityService();