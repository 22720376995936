<template>
  <panel title="SEO Information" variant="inverse" :is-collapse="true">
    <div class="mb-15px">
      <label class="form-label">Meta Title</label>
      <input
        type="text"
        v-model="value.metaTitle"
        placeholder="Meta Title"
        class="form-control"
      />
      <span
        class="text-danger"
        v-if="!$v.value.metaTitle.required && $v.value.metaTitle.$error"
        >Meta Title is required</span
      >
    </div>
    <div class="mb-15px">
      <label class="form-label">Meta Description</label>
      <textarea
        class="form-control"
        rows="7"
        v-model="value.metaDescription"
        placeholder="Meta Description"
      ></textarea>
      <span
        class="text-danger"
        v-if="
          !$v.value.metaDescription.required && $v.value.metaDescription.$error
        "
        >Meta Description is required</span
      >
    </div>
    <div class="mb-15px">
      <label class="form-label">Meta Keywords</label>
      <input-tag
        v-model="keywords"
        class="form-control default"
        add-tag-on-blur
      ></input-tag>
      <small class="fw-normal text-muted">Separate with commas</small>
      <br />
      <span
        class="text-danger"
        v-if="!$v.value.keyword.required && $v.value.keyword.$error"
        >Meta Keywords is required</span
      >
    </div>
    <div class="mb-15px">
      <label class="form-label"
        >Meta Image <span class="fw-normal text-muted">(Optional)</span></label
      >
      <UploadImage
        @input="handleImage"
        v-model="uploadImage"
        :src="imageSrc"
      />
    </div>
  </panel>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import base64Converter from "@/pages/Helper/base64";
import pageService from '@/service/page.service';

export default {
  props: ["value"],
  data() {
    return { uploadImage: null, keywords: [] };
  },
  watch: {
    value: {
      immediate: true,
      deep: false,
      handler(value) {
        if (value) {
          this.keywords = (value.keyword || "").split(",").filter((v) => !!v);
        } else {
          this.keywords = [];
        }
      },
    },
    keywords(v){
      this.value.keyword = v.join(",");
    }
  },
  computed: {
    imageSrc(){
      return this.value?.metaImageFileName ? pageService.getFileUrl(this.value.metaImageFileName) : null
    }
  },
  methods: {
    invalid(){
      this.value.keyword = this.keywords.join(",");
      this.$v.$touch()
      return this.$v.$invalid
    },
    async handleImage(val) {
      if (val?.length) {
        this.value["metaImage"] = await base64Converter(val[0]);
      }
    },
  },
  validations() {
    return {
      value: {
        metaTitle: {
          required,
        },
        metaDescription: {
          required,
        },
        keyword: {
          required,
        },
      },
    };
  },
};
</script>