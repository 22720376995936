<template>
  <div>
    <!-- :isLoading.sync="isLoading" -->
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading"
      :totalRows="totalRecords" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  //initialSortBy: { field: 'name', type: 'ASC' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search hospitals" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.name.value)" />
        <input ref="district" class="form-control" placeholder="Search district" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'district.name'
          " @input="(value) => updateFilters(column, $refs.district.value)" />
        <v-select placeholder="Cari hospital Tier" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'hospitalTier.tier'
          " :options="paginatedHospitalTier.content" :reduce="(option) => option.id" :filterable="false"
          @open="openSelectHospitalTier" @search="searchHospitalTier" @option:selected="selectedHospitalTierToTemporary"
          @input="(value) => updateFilters(column, value)">
          <li slot="list-footer" class="pagination">
            <button @click.prevent="handlePrevProv('tier')">
              Prev
            </button>
            <button @click.prevent="handleNextProv('tier')">
              Next
            </button>
          </li>
        </v-select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto" v-if="$can($permissionsAll[20])">
              <router-link :to="`/hospitals/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <!-- <div class="col-auto">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Duplicate">
                <i class="fas fa-copy"></i>
              </button>
            </div> -->
            <div class="col-auto">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import hospitalService from "../../service/hospital.service";
export default {
  data() {
    return {
      provServerParamsHospitalTier: {
        pageSize: 10,
        pageNumber: 0,
      },
      hospitalTierSelectedData: {},
      isLoading: false,
      timer: null,
      columns: [
        {
          label: "Hospitals",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          //firstSortType: "ASC",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "District",
          field: "district.name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Tier",
          field: "hospitalTier.tier",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // {
          //   field: "name",
          //   type: "ASC",
          // }
          {
            field: "created",
            type: "DESC",
          }
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      //console.log('onPageChange: ', params)
      this.updateParams({ page: params.currentPage });
      //this.loadItems();
      this.loadItemsFromApi()
    },
    onPerPageChange(params) {
      //console.log('onPerPageChange: ', params)
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      //this.loadItems();
      this.loadItemsFromApi()
    },

    onSortChange(params) {
      //console.log('onSortChange: ', params)
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      //this.loadItems();
      this.loadItemsFromApi()
    },

    onColumnFilter(params) {
      // console.log('params: ', params.columnFilters['district.name'])
      //console.log('onColumnFilter: ', params)
      this.updateParams(params);
      this.serverParams.page = 1;
      //this.loadItems();
      this.loadItemsFromApi()
    },
    setPaginationParams() {
      let body = {
        pageNumber: this.serverParams.page - 1,
        pageSize: this.serverParams.perPage,
        status: 'DRAFTS'

      };
      //  console.log('this.serverParams: ', this.serverParams)
      if (this.serverParams.sort[0].type !== "none") {
        body.direction = this.serverParams.sort[0].type.toUpperCase()
        body.sort = this.serverParams.sort[0].field
      } else {
        body.sort = "created"
        body.direction = "DESC"
      }
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters.hasOwnProperty(key)) {
          if (this.serverParams.columnFilters[key]) {
            if (key === "name") {
              body.name = this.serverParams.columnFilters[key];
            } else if (key === "district.name") {
              body.district = this.serverParams.columnFilters[key];
            } else if (key === "hospitalTier.tier") {
              const tier = this.$store.getters["hospital/getTier"].content.find((e) => e.id === this.serverParams.columnFilters[key])
              body.tier = tier.label

            }
          }
        }
      }
      return body;
    },
    getHospitalList() {
      hospitalService.getHospitalLists(this.setPaginationParams()).then(
        (response) => {
          if (response.responseCode === '200' && response.responseMessage == "Data's Empty!") {
            this.rows = [];
            this.totalRecords = 0;
          } else {
            this.rows = response?.data?.content;
            this.totalRecords = response?.data?.totalElements;
          }
        }
      ).catch(
        () => {
          // console.log('Data Not Found')
          alert("Data Not Found");
          this.rows = [];
          this.totalRecords = 0;
        }
      );
    },
    loadItemsFromApi() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(this.getHospitalList(), 800);
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Trash?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleDelete(id);
          }
        });
    },
    async handleDelete(id) {
      await hospitalService.changeHospitalStatus(id, "TRASHED").then(
        () => {
          this.$swal({
            title: "Data berhasil Dipindah Ke Trash",
            showCloseButton: true,
          }).then(() => {
            this.$router.push("/hospitals/list/trashed");
          });
        },
        () => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
      );
    },
    openSelectHospitalTier() {
      this.provServerParamsHospitalTier.searchValue = ""
      this.provServerParamsHospitalTier.pageNumber = 0
      this.provServerParamsHospitalTier.pageSize = 3
      this.getHospitalTier()
    },
    searchHospitalTier(query, loading) {
      this.provServerParamsHospitalTier.pageNumber = 0
      this.provServerParamsHospitalTier.pageSize = 3
      this.provServerParamsHospitalTier.searchField = 'tier'
      this.provServerParamsHospitalTier.searchValue = query
      this.getHospitalTier()
    },
    selectedHospitalTierToTemporary(hospitalTierSelectedData) {
      // const tierContent = this.$store.state['hospital'].tier.content
      // const tier = tierContent.find(e => e.id === this.hospital.hospitalTierId)
      // console.log('hospitalTierSelectedData: ', hospitalTierSelectedData)
      this.hospitalTierSelectedData = hospitalTierSelectedData
    },
    async handleNextProv(kind) {
      let lastPage
      switch (kind) {
        case 'tier':
          lastPage = this.$store.state['hospital'].tier.totalPages - 1
          if (this.provServerParamsHospitalTier.pageNumber < lastPage) {
            this.provServerParamsHospitalTier.pageNumber += 1
          }
          //this.onSearchTier = true
          this.getHospitalTier()
          break;

        default:
          break;
      }
    },
    async handlePrevProv(kind) {
      switch (kind) {
        case 'tier':
          if (this.provServerParamsHospitalTier.pageNumber !== 0) {
            this.provServerParamsHospitalTier.pageNumber -= 1
          }
          this.getHospitalTier()
          break;

        default:
          break;
      }
    },
    getHospitalTier() {
      this.$store.dispatch(
        "hospital/getHospitalTier",
        this.provServerParamsHospitalTier
      )
        .then(
          () => {
            if (Object.keys(this.hospitalTierSelectedData).length > 0) {
              this.hospitalTierSelectedData.type = 'tier'
              this.$store.commit('hospital/pushSelectedData', this.hospitalTierSelectedData)
            }
          }
        )
        .catch(
          (error) => {
            console.debug(error);
          }
        )
      // this.formInProgressProvince = false
    },
    loadItems() {
      this.rows = [
        {
          id: 1,
          hospitalName: "RS Pondok Indah",
          hospitalDistrict: "Jakarta Selatan",
          hospitalTier: "C",
        },
        {
          id: 2,
          hospitalName: "Brawijaya Women & Children Hospital",
          hospitalDistrict: "Jakarta Selatan",
          hospitalTier: "C"
        },
        {
          id: 3,
          hospitalName: "RS Pusat Pertamina",
          hospitalDistrict: "Jakarta Selatan",
          hospitalTier: "C",
        },
      ];
    },
    getHospitalTotal(status) {
      this.$store.dispatch(
        "hospital/getHospitalTotal",
        {
          status
        }
      )
        .catch(
          (error) => {
            console.debug(error);
          }
        )
      // this.formInProgressProvince = false
    },
    getTotal() {
      this.getHospitalTotal("PUBLISHED")
      this.getHospitalTotal("DRAFTS")
      this.getHospitalTotal("TRASHED")
    }
  },
  mounted() {
    // this.loadItems();
    this.getHospitalList()
    this.getTotal()
  },
  computed: {
    paginatedHospitalTier() {
      return this.$store.getters["hospital/getTier"];
    },
  }
};
</script>
