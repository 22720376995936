import api from "./api";

class RoleService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create('auth')
  }

  getRoleById(serverParams) {
    return this.api.get("/role/" + serverParams);
  }

  getListRole(serverParams) {
    return this.api.get("/role", { params: serverParams });
  }

  postRole(serverParams) {
    return this.api.post("/role", { ...serverParams });
  }

  deleteRoleById(serverParams) {
    return this.api.delete("/delete/role/" + serverParams);
  }

  putRole(id, serverParams) {
    return this.api.put("/role/" + id, { ...serverParams });
  }

  getRefPermission(serverParams) {
    return this.api.get("/ref-permissions", { params: serverParams });
  }
}

export default new RoleService();