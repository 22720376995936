<template>
  <div class="product-form">
    <FormUsers
      form-type="add"
      :detail-data="{}"
      @onValidateSuccess="doSave"
      :on-loading="onProgress"
    />
  </div>
</template>
<script>
import FormUsers from "./Form.vue";
import userService from "@/service/user.service";

export default {
  data() {
    return {
      onProgress: false
    };
  },
  components: {
    FormUsers,
  },
  methods: {
    async doSave(payload) {

      this.onProgress = true;
      payload = {
        username: payload.username,
        email: payload.username,
        fullname: payload.username,
        enabled: true,
        role: payload.role,
        password: payload.password,
      };
      userService
        .postUser(payload)
        .then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: "/users/manage/user" });
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Error Notification",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
        .finally(() => (this.onProgress = false));
    },
  },
};
</script>
