import axios from "axios";
import qs from "qs"

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
    paramsSerializer: (params) => qs.stringify(params)
    // paramsSerializer: (params) => qs.stringify(params, {
    //     arrayFormat: 'repeat'
    // })
});

api.create = (configOrServiceName) => {

    if (typeof configOrServiceName == 'string') {
        //ternary if kalau pakai backend local
        let baseURL = (process.env['VUE_APP_' + configOrServiceName.toUpperCase() + '_SERVICE']) ?
            process.env['VUE_APP_' + configOrServiceName.toUpperCase() + '_SERVICE'] :
            process.env.VUE_APP_API + '/' + configOrServiceName;

        configOrServiceName = { baseURL, service: configOrServiceName }
    }


    /** @ts-ignore @type {import("axios").AxiosRequestConfig} */
    const config = { ...api.defaults, ...configOrServiceName }

    /**
     * @type {ApiAxiosInstance}
     */
    const apiService = axios.create(config)
    apiService.defaults.headers = api.defaults.headers
    apiService.getUrl = function (path, param) {
        let url = this.defaults.baseURL.replace(/\/+$/, '') + '/' + path.replace(/^\/+/, '')

        if (param) {
            url += '?' + qs.stringify(param, { indices: false })
        }

        return url;
    }
    // @ts-ignore
    // apiService.interceptors.request.handlers = api.interceptors.request.handlers
    // // @ts-ignore
    // apiService.interceptors.response.handlers = api.interceptors.response.handlers
    return apiService
}

export default api;