import api from "./api";

class PopUpService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getFileUrl(name){
        return this.api.getUrl("/file/" + name)
    }

    getPopUpList(params) {
        return this.api.get("/cms/popup-banner", { params });
    }

    postPopUp(data) {
        return this.api.post("/cms/popup-banner", data);
    }

    getPopUpById(id) {
        return this.api.get("/cms/popup-banner/" + id);
    }

    putPopUp(id, data) {
        return this.api.put("/cms/popup-banner/" + id, data);
    }

    deletePopUpById(id) {
        return this.api.delete("/cms/delete/popup-banner/" + id);
    }

    popUpViewFile(name) {
        return this.api.get("/cms/popup-banner/view/" + name);
    }

    PopUpReposisi(data) {
        return this.api.put("/cms/reposition/popup-banner", data);
    }

    changePopUpStatus(id, status) {
        return this.api.put(`/cms/update/popup-banner-status/${id}?status=${status}`)
    }
}

export default new PopUpService();