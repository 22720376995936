import FaqCategoryService from "../service/faqcategory.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        
        //category
        async getListFaqCategory(_ctx, serverParams) {
            return FaqCategoryService.getFaqCategoryList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postFaqCategory(_ctx, serverParams) {
            return FaqCategoryService.postFaqCategory(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getFaqCategoryById(_ctx, serverParams) {
            return FaqCategoryService.getFaqCategoryById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateFaqCategoryById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return FaqCategoryService.putFaqCategory(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteFaqCategoryById(_ctx, serverParams) {
            return FaqCategoryService.deleteFaqCategoryById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
