<template>
  <div class="pb-5">
    <form>
      <div class="panel">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <h4 class="fw-normal mb-3">Payment Detail</h4>
              <p class="mb-4">
                Payment methods can be made via credit card, Mandiri debit, BCA
                debit, and GoPay
              </p>
              <div class="row mb-5">
                <div class="col-md-10">
                  <div v-for="(item, index) in selectedProduct" :key="index">
                    <p class="small fw-bolder mb-2">{{ item.category }}</p>
                    <div class="row justify-content-between">
                      <div class="col-auto">
                        <p class="mb-0">{{ item.name }}</p>
                      </div>
                      <div class="col-auto">
                        <money-format
                          :value="item.price"
                          locale="id"
                          currency-code="IDR"
                        >
                        </money-format>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div class="row justify-content-between">
                    <div class="col-auto">
                      <p class="mb-0 fw-bolder text-uppercase">TOTAL</p>
                    </div>
                    <div class="col-auto">
                      <money-format
                        :value="totalPrice"
                        locale="id"
                        currency-code="IDR"
                      >
                      </money-format>
                    </div>
                  </div>
                </div>
              </div>

              <h4 v-if="isReadOnly" class="fw-normal mb-3">Payment History</h4>
              <div v-if="isReadOnly" class="panel">
                <div class="panel-body px-3">
                  <!-- <p class="fw-bolder mb-1">November 2022</p> -->
                  <div class="row justify-content-between align-items-center">
                    <div class="col-md-6">
                      <div
                        class="row justify-content-between align-items-center"
                      >
                        <div class="col-auto">
                          <p class="mb-0">
                            <money-format
                              :value="historyData.total"
                              locale="id"
                              currency-code="IDR"
                            >
                            </money-format>
                            /{{ paymentType }}
                          </p>
                        </div>
                        <div class="col-auto">
                          <p class="mb-0">
                            {{ historyData.jenisPembayaran }}-{{
                              historyData.noKartu
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div
                        class="row justify-content-between align-items-center"
                      >
                        <div class="col-auto">
                          <p class="mb-0">{{ historyData.tanggal }}</p>
                        </div>
                        <div class="col-auto">
                          <span
                            class="badge bg-success fs-10px fw-bolder rounded-pill"
                            >Success</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 class="fw-normal mb-3">Payment Type</h4>
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="form-check form-check-button mb-2"
                    v-for="(item, index) in paymentList"
                    :key="index"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="paymentType"
                      :id="`paymentType-${index}`"
                      :value="item.label"
                      v-model="paymentType"
                      :class="{
                        disabled: isReadOnly,
                      }"
                      @change="handlePaymentType($event)"
                    />
                    <label
                      class="form-check-label d-flex justify-content-between"
                      for=""
                    >
                      <span class="fw-normal">
                        {{ item.label }}
                      </span>
                      <span>
                        <money-format
                          :value="item.price"
                          locale="id"
                          currency-code="IDR"
                        >
                        </money-format>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isReadOnly" class="form-action">
        <div class="row justify-content-end align-items-center">
          <div class="col-auto">
            <p class="mb-0 fw-bolder">Sent payment link to:</p>
          </div>
          <div class="col-auto">
            <div class="form-check form-check-button">
              <input
                class="form-check-input"
                type="radio"
                name="paymentSentTo"
                id="paymentSentTo1"
                value="SMS"
                v-model="paymentSentTo"
                :class="{
                  disabled: isReadOnly,
                }"
              />
              <label class="form-check-label py-2" for="">SMS</label>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-check form-check-button">
              <input
                class="form-check-input"
                type="radio"
                name="paymentSentTo"
                id="paymentSentTo2"
                value="email"
                v-model="paymentSentTo"
                :class="{
                  disabled: isReadOnly,
                }"
              />
              <label class="form-check-label py-2" for="">Email</label>
            </div>
          </div>
          <div class="col-auto">
            <button @click.prevent="doSave()" class="btn btn-primary">
              Sent
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import registrationService from "@/service/registration.service";
import MoneyFormat from "vue-money-format";
import productFilter from "./functions/productFilter";
import paymentFilterProduct from "./functions/paymentFilterProduct";
export default {
  components: {
    "money-format": MoneyFormat,
  },
  data() {
    return {
      status: false,
      paymentType: "",
      paymentSentTo: "SMS",
      paymentList: [],
      selectedProduct: [],
      totalPrice: 0,
      calculateParams: null,
      fixPriceList: null,
      weighContribution: {
        home: null,
        office: null,
      },
      weighGroup: null,
      paymentFrequency: null,
      finalProductPrice: null,
      historyData: {
        total: 0,
        frequensi: "",
        jenisPembayaran: "",
        noKartu: "",
        tanggal: "",
      },
    };
  },
  methods: {
    async handleDetail() {
      let registrationData = null;
      let params = [];
      let age = 0;
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            let dataProduct = res.data.data.customerProduct;
            registrationData = res.data.data.customerRegistration;
            this.status = res.data.data.customerRegistration?.status;
            this.paymentType =
              dataProduct.paymentFrequencyLabel !== null
                ? dataProduct.paymentFrequencyLabel
                : "";
            params.push({
              id:
                dataProduct.idProdukAddons2 !== null
                  ? dataProduct.idProdukAddons2
                  : null,
              label: "idProdukAddons2",
            });
            params.push({
              id:
                dataProduct.idProdukAddons !== null
                  ? dataProduct.idProdukAddons
                  : null,
              label: "idProdukAddons",
            });
            params.push({
              id:
                dataProduct.idProdukExtra !== null
                  ? dataProduct.idProdukExtra
                  : null,
              label: "idProdukExtra",
            });
            if (dataProduct.idProdukExtra === null) {
              params.push({
                id:
                  dataProduct.idProdukUtama !== null
                    ? dataProduct.idProdukUtama
                    : null,
                label: "idProdukUtama",
              });
            }
            this.calculateParams = params;
            age = productFilter.getAge(
              res.data.data.customerRegistration.tanggalLahir
            );
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );

      paymentFilterProduct.setGroupValue(
        registrationData.customerHospitalHome,
        registrationData.customerHospitalOffice
      );
      const result = await paymentFilterProduct.getDetailProduct(params, age);
      console.log(result);
      this.selectedProduct = result.produk;
      this.paymentList = result.paymentType;
      this.totalPrice = result.total;
      this.finalProductPrice = result.final;
    },
    async getPaymentHistory() {
      await registrationService
        .getPaymentHistory(this.$route.params.id)
        .then((response) => {
          let hisdata = response.data.data;
          if (hisdata !== null) {
            this.historyData = {
              total: Math.ceil(hisdata.jumlah),
              frequensi: "",
              jenisPembayaran: hisdata.tipePembayaran,
              noKartu: hisdata.accountNumber,
              tanggal: paymentFilterProduct.formateDate(
                hisdata.tanggalPembayaran
              ),
            };
          }
        })
        .catch((error) => {
          this.$swal({
            title: error.response?.data?.responseMessage || "Terjadi Kesalahan",
            showCloseButton: true,
          });
        });
    },
    handlePaymentType(event) {
      const result = paymentFilterProduct.setSelectedFrequency(
        this.finalProductPrice,
        event.target.value
      );
      this.selectedProduct = result.produk;
      this.totalPrice = result.total;
    },
    async doSave() {
      await registrationService
        .getPaymentLink(this.$route.params.id, {
          type: this.paymentSentTo,
        })
        .then((response) => {
          this.$swal({
            title: "Permintaan berhasil dikirim",
            showCloseButton: true,
          });
        })
        .catch((error) => {
          this.$swal({
            title: error.response?.data?.responseMessage || "Terjadi Kesalahan",
            showCloseButton: true,
          });
        });
    },
  },
  async mounted() {
    await paymentFilterProduct.getWeightContribution();
    await paymentFilterProduct.getPaymentFrequency();
    await this.handleDetail();
    await this.getPaymentHistory();
  },
  computed: {
    isReadOnly() {
      if (this.status === "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
