var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_vm._m(0),(_vm.$can(_vm.$permissionsAll[61]))?_c('div',{staticClass:"col-auto mb-3"},[_c('input',{ref:"fileUpload",attrs:{"id":"fileUpload","accept":"application/pdf","type":"file","hidden":""},on:{"change":_vm.postPdf}}),_c('button',{staticClass:"btn btn-outline-primary",on:{"click":_vm.chooseFiles}},[_vm._v("Upload New PDF")])]):_vm._e()]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  initialSortBy: { field: 'pdfTitle', type: 'asc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'pdfTitle'
          )?_c('input',{ref:"pdfTitle",staticClass:"form-control",attrs:{"placeholder":"Search PDFs"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.pdfTitle.value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Copy Link"},on:{"click":function($event){$event.preventDefault();return _vm.onCopy(props.row.pdfFile)}}},[_c('i',{staticClass:"fa-solid fa-copy"})])]),(_vm.$can(_vm.$permissionsAll[62]))?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.onDelete(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("All PDFs")])])}]

export { render, staticRenderFns }