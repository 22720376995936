<template>
    <div class="product-form">
        <FormUsers v-if="!!detail" form-type="edit" :detail-data="detail" @onValidateSuccess="doSave"
            :on-loading="onProgress" />
    </div>
</template>
<script>
import redeemService from '@/service/redeem.service';
import FormUsers from "./Form.vue";

export default {
    data() {
        return {
            onProgress: true,
            detail: null,
        };
    },
    components: {
        FormUsers,
    },
    async mounted() {
        await this.loadItems(this.$route.params.id);
        // console.log(this.detail, 'detailData');
    },
    methods: {
        async doSave(payload) {
            // Submit here
            this.onProgress = true;
            let { redeem } = payload
            redeem.expiredDate = `${redeem.expiredDate} 00:00:00`
            redeemService
                .putRedeem(this.$route.params.id, redeem)
                .then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: "/redeem/list" });
                    });
                })
                .catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Notification",
                        text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                    });
                })
                .finally(() => (this.onProgress = false));
        },
        loadItems(id) {
            redeemService
                .getRedeemById(id)
                .then((rsp) => {
                    rsp.data.data.expiredDate = this.getStringBeforeFirstSpace(rsp.data.data.expiredDate.trim())
                    this.detail = rsp.data.data
                    // console.log('this.detail : ', rsp.data.data.expiredDate)
                })
                .catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Notification",
                        text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                    });
                })
                .finally(() => (this.onProgress = false));
        },
        getStringBeforeFirstSpace(str) {
            // Cek apakah terdapat spasi dalam string
            var spaceIndex = str.indexOf(' ');
            // Jika tidak ada spasi atau string kosong, kembalikan string asli
            if (spaceIndex === -1 || str.trim() === '') {
                return str;
            } else {
                // Ambil substring sebelum spasi pertama
                return str.substring(0, spaceIndex);
            }
        }
    },
};
</script>