<template>
    <div class="product-form">
        <FormQuestion form-type="add" @onValidateSuccess="doSave" :on-loading="onProgress" />
    </div>
</template>
<script>
import FormQuestion from './Form.vue'
import SioService from "../../../service/sio.service";
export default {
    components: {
        FormQuestion
    },
    data() {
        return {
            onProgress: false,
        };
    },
    methods: {
        async doSave(payload) {
            // console.log(payload, 'payload')
            this.onProgress = true
            if (payload.title) {
                payload = {
                    "id": "",
                    "createdBy": "",
                    "updatedBy": "",
                    "created": "",
                    "updated": "",
                    "deleted": 0,
                    "recordStatus": "ACTIVE",
                    "name": payload.title.replace(/<\/?[^>]+>/ig, ""),
                    "question": payload.title.replace(/<\/?[^>]+>/ig, "")
                }
                SioService
                    .postSio(payload)
                    .then((rsp) => {
                        this.onProgress = false
                        this.$swal({
                            title: "Data berhasil disimpan",
                            showCloseButton: true,
                        }).then(() => {
                            this.$router.push({ path: '/sections/sio-questionnaire' })
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })
            }
        }
    }
}
</script>