<template>
  <div class="product-form">
    <FormFaq form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormFaq from "./Form.vue";
import faqService from "@/service/faq.service";
export default {
  data() {
    // this.loadItems(this.$route.params.id)
    return {
      detail: {
        id: this.$route.params.id,
        title: "Yayasan Kanker Jakarta",
        category: "General Question",
        description: "News",
        showOnHomepage: true
      },
    };
  },
  components: {
    FormFaq,
  },

  methods: {
    async doSave(payload) {

      let faq = payload.faq;
      payload = {
        "id": this.$route.params.id,
        "createdBy": faq.createdBy,
        "updatedBy": "",
        "created": faq.created,
        "updated": "",
        "deleted": 0,
        "status": faq.recordStatus,
        "title": faq.title,
        "content": faq.description.replace(/<\/?[^>]+>/ig, ""),
        "showOnHomepage": faq.showOnHomepage,
        "faqCategory": {
          "id": faq.category.id,
        }
      }
      faqService
        .putFaq(this.$route.params.id, payload)
        .then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            if (faq.recordStatus === "PUBLISHED") {
              this.$router.push("/pages/faq/list");
            } else if (faq.recordStatus === "DRAFTS") {
              this.$router.push("/pages/faq/list/drafts");
            }
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    loadItems(id) {
      faqService.getFaqById(id).then((rsp) => {
        var faq = rsp.data.data;
        this.detail = {
          id: this.$route.params.id,
          title: faq.title,
          category: faq.faqCategory,
          description: faq.content,
          showOnHomepage: faq.showOnHomepage,
          publishDate: faq.created,
          lastModified: faq.updated,
          author: faq.createdBy,
        }
      }).catch((e) => {
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
        });
      })
    },
  },
  async mounted() {
    await this.loadItems(this.$route.params.id);
  },
};
</script>
