<template>
    <div class="product-form">
        <FormProduct form-type="add" @onValidateSuccess="doSave" />
    </div>
</template>
<script>
import FormProduct from './Form.vue'
import productService from "@/service/product.service";

export default {
    components: {
        FormProduct,
    },
    data() {
        return {
            onProgress: false,
            payload: {
                idProduct: "",
                name: "",
                riplayInformation: "",
                description: "",
                productCategory: {},
                contributionDetails: [],
                productConfiguration: {
                    code: "",
                    coveragePeriod: "",
                    wakaf: true,
                    benefitLimit: {},
                    sumInsurance: {},
                    coInsurance: {},
                    epolicy: false
                },
                premis: [],
                benefits: []
            }
        };
    },
    methods: {
        async doSave(payload) {
            // Submit here
            this.onProgress = true
            if (payload) {
                let { product, status } = payload
                let premis = [], ekstra = []
                for (const [k, v] of Object.entries(product.configurations)) {
                    premis.push({
                        ...v,
                        innerLimit: Number(v.innerLimit.replace(/\D/g, ''))
                    })
                }

                for (const [k, v] of Object.entries(product.ekstra)) {
                    ekstra.push({ id: v.item.id })
                }
                let payloadData = {
                    name: product.name,
                    productCategory: product.category,
                    description: product.description,
                    riplayInformation: product.riplay,
                    contributionDetails: product.contributions,
                    cashlessFacility: product.cashlessFacility,
                    annualLimit: product.annualLimit,
                    roomBoard: product.roomBoard,
                    kelasKamar: product.kelasKamar,
                    kamarPerawatan: product.kamarPerawatan,
                    biayaDokterUmum: product.biayaDokterUmum,
                    biayaDokterSpesialis: product.biayaDokterSpesialis,
                    biayaLainnya: product.biayaLainnya,
                    pembedahanPulangHari: product.pembedahanPulangHari,
                    rawatJalan: product.rawatJalan,
                    extraAddons: ekstra,
                    displayOrder: product.displayOrder,
                    productConfiguration: {
                        code: product.code,
                        epolicy: product.ePolicy,
                        benefitLimit: product.benefit,
                        sumInsurance: product.sumInsurance,
                        coInsurance: product.insurance,
                        wakaf: product.wakaf,
                        coveragePeriod: product.coverage
                    },
                    premis: premis,
                    benefits: product.benefits,
                    status
                }
                this.payload = { ...payloadData }
                productService
                    .postProduct(this.payload)
                    .then((rsp) => {
                        this.onProgress = false
                        this.$swal({
                            title: "Data berhasil disimpan",
                            showCloseButton: true,
                        }).then(() => {
                            if (status === "PUBLISHED") {
                                this.$router.push("/products/list");
                            } else if (status === "DRAFTS") {
                                this.$router.push("/products/list/drafts");
                            }
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })
            }

        },
    }
}
</script>