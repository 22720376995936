import api from "./api";

class SubmitionService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create("registration");
  }

  getSubmitionList(serverParams) {
    return this.api.get("submition", { params: serverParams });
  }
  getSinglePage(id) {
    return this.api.get(`page/${id}`);
  }
  updatePage(id, data) {
    return this.api.put(`/page/${id}`, data);
  }
  sendData(id, kind) {
    return this.api.get(`/${kind}/${id}`);
  }
}

export default new SubmitionService();
