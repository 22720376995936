import api from "./api";

class SioService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getSioQuestionList(serverParams) {
        return this.api.get("/cms/sioQuestionnaire", { params: serverParams });
    }

    postSio(serverParams) {
        return this.api.post("/cms/sioQuestionnaire", { ...serverParams });
    }

    getSioById(serverParams) {
        return this.api.get("/cms/sioQuestionnaire/" + serverParams);
    }

    putSio(id, serverParams) {
        return this.api.put("/cms/sioQuestionnaire/" + id, { ...serverParams });
    }

    deleteSioById(serverParams) {
        return this.api.delete("/delete/sioQuestionnaire/" + serverParams);
    }

    sioReposisi(data) {
        return this.api.put("/cms/reposition/sio", data);
    }
}

export default new SioService();