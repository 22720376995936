<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionFive"
  >
    <div class="mb-15px">
      <div class="alert alert-warning">
        Unknown Section Type: {{ value.sectionType }}
      </div>
    </div>
  </panel>
</template>

<script>
export default {
  name: "UNKNOWN",
  title: "Unknown Type",
  props: ["value"],
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>