<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">RIPLAY Information</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <panel title="Penjelasan Manfaat Asuransi" variant="inverse" body-class="p-0" :is-collapse="true">
          <vue-editor v-model="product.riplay" :editor-toolbar="customToolbar" />
          <span class="text-danger" v-if="$v.product.riplay.$error">Riplay is
            required</span>
        </panel>
        <panel title="Informasi Lainnya" variant="inverse" body-class="p-0" :is-collapse="true">
          <vue-editor v-model="product.information" :editor-toolbar="customToolbar" />
          <span class="text-danger" v-if="$v.product.information.$error">Information is
            required</span>
        </panel>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import riplayInformationService from "@/service/riplayinformation.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
  data() {
    const defaultToolbarEditor = defaultToolbar;
    return {
      customToolbar: defaultToolbarEditor,
      riplay: {
        count: 0,
        riplay: {
          id: '',
          title: 'Penjelasan Manfaat Asuransi',
        },
        information: {
          id: '',
          title: 'Informasi Lainnya',
        },
      },
      product: {
        riplay: '',
        information: '',
      },
      body: {
        title: '',
        content: ''
      },
      serverParams: {
        searchField: '',
        searchValue: '',
        sort: '',
        direction: 'ASC',
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  methods: {
    doSave() {
      this.$v.product.$touch();
      if (!this.$v.product.$invalid) {
        const payload = {
          riplay: this.product.riplay,
          information: this.product.information,
        };
        // console.log(this.riplay.information.id)

        if (this.riplay.information.id === '' || this.riplay.riplay.id === '') {
          for (const v in payload) {
            this.body = {
              title: this.riplay[v].title,
              content: payload[v]
            };
            this.postRiplay(this.body)
          }
        } else {
          for (const v in payload) {
            this.body = {
              id: this.riplay[v].id,
              title: this.riplay[v].title,
              content: payload[v]
            };
            this.putRiplay(this.body)
          }
        }

        // console.log("onValidateSuccess", payload);
      }
    },
    loadItems() {
      riplayInformationService
        .getRiplayInformationServiceList(this.serverParams)
        .then((rsp) => {
          this.riplay.count = rsp.data?.data ? rsp.data?.data.totalElements : 0;
          if (this.riplay.count > 0) {
            this.product.riplay = rsp.data.data?.content.find((rsp) => rsp.title == this.riplay.riplay.title).content
            this.riplay.riplay.id = rsp.data.data?.content.find((rsp) => rsp.title == this.riplay.riplay.title).id
            this.product.information = rsp.data.data?.content.find((rsp) => rsp.title == this.riplay.information.title).content
            this.riplay.information.id = rsp.data.data?.content.find((rsp) => rsp.title == this.riplay.information.title).id
          }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response?.data?.responseCode || "00"
              }`,
            text: e.response?.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    putRiplay(data) {
      let { id } = data
      riplayInformationService
        .putRiplayInformationService(id, data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Success Edit Riplay Information'
          });
          this.loadItems()
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    postRiplay(data) {
      riplayInformationService
        .postRiplayInformationService(data)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Success Add Riplay Information'
          });
          this.loadItems()
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
  },
  mounted() {
    this.loadItems()
  },

  validations: {
    product: {
      riplay: { required },
      information: { required }
    },
  }
};
</script>
