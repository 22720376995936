<template>
  <div class="hospital-form">
    <FormHospital
      form-type="edit"
      :detail-data="detail"
      @onValidateSuccess="doSave"
    />
  </div>
</template>
<script>
import FormHospital from "./Form.vue";
import hospitalService from "../../service/hospital.service";
export default {
  components: {
    FormHospital,
  },
  data() {
    return {
      detail: {},
      body: {
        name: null,
        tier: null,
        multiplier: null,
        map: null,
        latitude: null,
        longitude: null,
        address: null,
        province: null,
        city: null,
        district: null,
        postalCode: null,
        phoneNumber: null,
      },
    };
  },
  methods: {
    doSave(payload) {
      this.body.name = payload.name;
      this.body.hospitalTierId = payload.hospitalTierId;
      this.body.address = payload.address;
      this.body.provinceId = payload.province;
      this.body.cityId = payload.city;
      this.body.districtId = payload.district;
      this.body.postalCode = payload.postalCode;
      this.body.latitude = payload.position.lat;
      this.body.longitude = payload.position.lng;
      this.body.phoneNumber = payload.phoneNumber;
      this.body.status = payload.status;
      hospitalService.updateHospitalById(this.$route.params.id, this.body)
        .then(
          (response) => {
            //console.log('body: ', this.body)
            this.$swal({
              title: "Data berhasil diupdate",
              showCloseButton: true,
            }).then(() => {
              if(payload.status === 'DRAFTS'){
                this.$router.push("/hospitals/list/drafts");
              }else{
                //console.log('cekcek')
                this.$router.push("/hospitals/list");
              }
            });
          }
        ).catch((error) => {
            this.$swal({
            title: error.response?.data?.responseMessage || "Terjadi Kesalahan",
            showCloseButton: true,
          });
      });
    },
    getHospitalById(){
      hospitalService.getHospitalById(this.$route.params.id)
        .then((response) => {
            this.detail = response.data;
            // this.detail = response.data.data;
        })
        .catch((error) => {
           console.log(error);
        })
    }
  },
  created() {
    this.getHospitalById();
  },
};
</script>
