import Env from "./plugins/env";
import Vue from "vue";
import VueX from "vuex";
import routes from "./config/AppRoutes";

// plugins
import VueRouter from "vue-router";
import VueBootstrap from "bootstrap-vue";
import VueNVD3 from "vue-nvd3";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueEventCalendar from "vue-event-calendar";
import VueSparkline from "vue-sparklines";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotification from "vue-notification";
import VuePanel from "./plugins/panel/";
//import VueUploadImage from "./plugins/upload-image/";
import UploadImage from "@/components/upload/Image.vue";
import VueDateTimePicker from "vue-bootstrap-datetimepicker";
import VueSelect from "vue-select";
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import VueMaskedInput from "vue-maskedinput";
import VueInputTag from "vue-input-tag";
//import VueSlider from "vue-slider-component";
import VueGoodTable from "vue-good-table";
// import VueCountdown from "@chenfengyuan/vue-countdown";
//import VueColorpicker from "vue-pop-colorpicker";
import VueCustomScrollbar from "vue-custom-scrollbar";
//import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import { VueEditor } from "vue2-editor";
import Geocoder from "@pderas/vue2-geocoder";
import CKEditor from 'ckeditor4-vue';
import RoleService from "@/service/role.service";

// plugins css
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nvd3/build/nv.d3.min.css";
import "vue-event-calendar/dist/style.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "ionicons/dist/css/ionicons.min.css";
import "vue-good-table/dist/vue-good-table.css";
import "vue-select/dist/vue-select.css";
//import "vue-slider-component/theme/antd.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "vue-select/dist/vue-select.css";

// color admin css
import "./scss/vue.scss";
import "bootstrap-social/bootstrap-social.css";

import { store } from "./stores";
import App from "./App.vue";
import Guard from "./plugins/guard";
import Permissions from "@/config/Permissions";

import JsonExcel from "vue-json-excel";
Vue.config.productionTip = false;
Vue.use(Env);
Vue.use(VueX);
Vue.use(Guard);

const router = new VueRouter({
  mode: "history",
  routes,
});


Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(VueBootstrap);
Vue.use(VueNVD3);
Vue.use(VueEventCalendar, { locale: "en" });
Vue.use(VueSparkline);
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePanel);
//Vue.use(VueUploadImage);
Vue.component('UploadImage', UploadImage);
Vue.use(VueDateTimePicker);
Vue.use(VueGoodTable);
// Vue.use(VueColorpicker);
Vue.use(CKEditor);
Vue.use(VueGoogleMaps, {
  load: {
    key: process?.env?.VUE_APP_GMAP_KEY || 'AIzaSyD6hoGyKmhX5TUFJB9ucNsNvb-wm8wZxfI',
    libraries: 'places',
    region: 'ID',
    language: 'id',
  }
});
Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "3px",
});
Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: "address", // or 'lat-lng'
  googleMapsApiKey: process?.env?.VUE_APP_GMAP_KEY || "AIzaSyD6hoGyKmhX5TUFJB9ucNsNvb-wm8wZxfI",
});
Vue.component("downloadExcel", JsonExcel);
Vue.component("v-select", VueSelect);
Vue.component("datepicker", VueDatepicker);
Vue.component("masked-input", VueMaskedInput);
Vue.component("input-tag", VueInputTag);
//Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);
// moved in src/pages/Analytics/index.vue
// Vue.component("apexchart", VueApexCharts);
Vue.component("date-range-picker", DateRangePicker);
// Vue.component(VueCountdown.name, VueCountdown);
Vue.component("v-select", vSelect);
Vue.component("vue-editor", VueEditor);

router.beforeEach((to, from, next) => {
  if (!!store.state.auth.user) {
    if (to.path === "/login") {
      console.log('Already login in router');
      return next("/dashboard");
    } else {
      // Check permission on all matched route
      const hasPermission = to.matched.filter(m => m.meta?.permission)
      if (hasPermission) {
        const denied = hasPermission.find(
          m => !router.app.$can(m.meta.permission)
        )
        if (denied) {
          router.app.$nextTick(() => {
            router.app.$notify({
              title: 'Access Denied',
              text: `Akses tidak memiliki hak akses ${to.fullPath}`,
              type: 'error'
            })
          })
          return next("/dashboard");
        }
      }
      return next();
    }
  } else {
    if (to.path === "/login" || to.path.includes('/password')) {
      next();
    } else {
      next("/login");
    }
  }
})

store.dispatch('auth/boot').then(
  () => new Vue({
    render: (h) => h(App),
    router,
    store,
  }).$mount("#app")
)

