import QueryString from "qs";
import api from "./api";

class ActivityService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('activity')
        this.api.defaults.paramsSerializer = v => QueryString.stringify(v, { indices: false })
    }

    getCmsLogActivity(params) {
        return this.api.get('cms-log-activity', { params });
    }

    getPageViewGroupBy(params) {
        return this.api.get('pageview-group-by', { params });
    }

    getCmsLogActivityDownloadUrl(type, query) {
        return this.api.getUrl(`cms-log-activity-${type}`, { query });
    }

    getMicrositeIssue(params) {
        return this.api.get('microsite-issue', { params });
    }
    getMicrositeIssueDownloadUrl(type, query) {
        return this.api.getUrl(`microsite-issue-${type}`, { query });
    }
    getPerfomanceMatric(params) {
        return this.api.get('perfomance-stats', { params });
    }
}

export default new ActivityService();