import ProductService from "../service/product.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListProduct(_ctx, serverParams) {
            return ProductService.getProductList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postProduct(_ctx, serverParams) {
            return ProductService.postProduct(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getProductById(_ctx, serverParams) {
            return ProductService.getProductById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateProductById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return ProductService.putProduct(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteProductById(_ctx, serverParams) {
            return ProductService.deleteProductById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
