<template>
    <div>
        <h1 class="page-header"><span v-if="isEdit">Edit</span> Notification Category</h1>
        <div class="row">
            <div class="col-md-6">
                <div class="panel">
                    <div class="panel-body">
                        <form @submit.prevent="doSave">
                            <div class="row align-items-end">
                                <div class="col">
                                    <label class="form-label"><span v-if="!isEdit">Add</span><span v-else>Edit</span>
                                        Category Name</label>
                                    <input type="text" class="form-control" placeholder="Add New Category Name"
                                        v-model="category" />
                                    <br v-if="!$v.category.$error">
                                    <span class="text-danger" v-if="$v.category.$error">Category Name
                                        required</span>
                                </div>
                                <div class="col">
                                    <label class="form-label">Group</label>
                                    <v-select :options="optionsGroup" placeholder="Select Group" label="name"
                                        v-model="group"></v-select>
                                    <br v-if="!$v.group.$error">
                                    <span class="text-danger" v-if="$v.group.$error">Group required</span>
                                </div>
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-outline-primary">
                                        <span v-if="!isEdit">Add</span>
                                        <span v-else>Save</span>
                                    </button>
                                    <br>
                                    <label class="form-label"></label>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-outline-primary" :disabled="!isEdit"
                                        @click.prevent="onCancel()">
                                        Cancel
                                    </button>
                                    <br>
                                    <label class="form-label"></label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
                    :isLoading.sync="isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
        }" :columns="columns" :rows="rows" :sort-options="{
            enabled: false,
            initialSortBy: { field: 'categoryName', type: 'asc' },
        }" styleClass="vgt-table" slot="outsideBody">
                    <template slot="column-filter" slot-scope="{ column, updateFilters }">
                        <input ref="name" class="form-control" placeholder="Search category" v-if="column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'name'
            " @input="(value) => updateFilters(column, $refs.name.value)" />
                        <select ref="groupCategory" class="form-select" placeholder="Search Source" v-if="column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'groupCategory'
            " @change="(value) => updateFilters(column, $refs.groupCategory.value)">
                            <option value="">All Group</option>
                            <option v-for="v in optionsGroup" :value="v" selected>{{ v }}</option>
                        </select>
                    </template>
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'">
                            <div class="row g-2 align-items-center justify-content-end">
                                <div class="col-auto">
                                    <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)"
                                        v-b-tooltip.hover title="Edit">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button type="button" @click.prevent="showAlertConfirm(props.row.id)"
                                        class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationCategoryService from "@/service/notificationcategory.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            id: "",
            category: "",
            isEdit: false,
            isLoading: false,
            columns: [
                {
                    label: "Category Name",
                    field: "name",
                    thClass: "text-nowrap",
                    tdClass: "text-nowrap align-middle",
                    filterOptions: {
                        customFilter: true,
                        enabled: true,
                        filterValue: "",
                    },
                },
                {
                    label: "Group",
                    field: "groupCategory",
                    thClass: "text-nowrap",
                    tdClass: "text-nowrap align-middle",
                    filterOptions: {
                        customFilter: true,
                        enabled: true,
                        filterValue: "",
                    },
                },
                {
                    label: "Action",
                    field: "action",
                    width: "200px",
                    sortable: false,
                    thClass: "text-center text-nowrap",
                    tdClass: "text-center text-nowrap",
                },
            ],
            rows: [],
            totalRecords: 0,
            serverParams: {
                searchField: "",
                searchValue: "",
                sort: "",
                direction: "ASC",
                pageNumber: 0,
                pageSize: 10
            },
            payload: {
                name: "",
                groupCategory: ''
            },
            errorCode: "",
            errorMessage: "",
            error: "",
            forbidenError: false,
            optionsGroup: ['EMAIL', 'SMS'],
            group: ''
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ pageNumber: params.currentPage - 1 });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ pageNumber: 0 });
            this.updateParams({ pageSize: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: 'name'
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            if (
                params.columnFilters.groupCategory != "" ||
                params.columnFilters.name != ""
            ) {
                this.updateParams({
                    name: params.columnFilters.name,
                    groupCategory: params.columnFilters.groupCategory
                });
            } else {
                let { name, groupCategory, ...data } = this.serverParams;
                this.serverParams = data;
            }
            this.loadItems();
        },
        loadItems() {
            NotificationCategoryService
                .getNotificationCategoryList(this.serverParams)
                .then(async (rsp) => {
                    this.rows = rsp.data?.data ? rsp.data?.data.content : [];
                    this.totalRecords = rsp.data?.data ? rsp.data?.data.totalElements : 0
                })
                .catch((e) => {
                    this.$notify({
                        type: "error",
                        title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
                            }`,
                        text: e.response.data?.responseMessage || "Gagal mengambil data",
                    });
                });
        },
        doSave() {
            this.$v.category.$touch();
            this.$v.group.$touch();
            if (!this.$v.category.$invalid && !this.$v.group.$invalid) {
                if (this.isEdit) {
                    this.payload.id = this.id
                    this.payload.name = this.category
                    this.payload.description = this.category
                    this.payload.groupCategory = this.group
                    NotificationCategoryService
                        .putNotificationCategory(this.id, this.payload)
                        .then((rsp) => {
                            this.$notify({
                                type: 'succes',
                                title: 'Succes Notification',
                                text: 'Success Update'
                            });
                            this.$v.category.$reset();
                            this.$v.group.$reset();
                            this.loadItems()
                            this.group = ''
                            this.category = ''
                            this.id = ''
                        }).catch((e) => {
                            this.$notify({
                                type: "error",
                                title: "Error Message",
                                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                            });
                        })
                } else {
                    this.payload.name = this.category
                    this.payload.description = this.category
                    this.payload.groupCategory = this.group
                    NotificationCategoryService
                        .postNotificationCategory(this.payload)
                        .then((rsp) => {
                            this.$notify({
                                type: 'succes',
                                title: 'Succes Notification',
                                text: 'Success Add'
                            });
                            this.$v.category.$reset();
                            this.$v.group.$reset();
                            this.loadItems()
                            this.group = ''
                            this.category = ''
                            this.id = ''
                        }).catch((e) => {
                            this.$notify({
                                type: "error",
                                title: "Error Message",
                                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                            });
                        })
                }
            }
        },
        async doEdit(data) {
            this.isEdit = true
            this.group = data.groupCategory
            this.category = data.name
            this.id = data.id
        },
        onDelete(id) {
            NotificationCategoryService.deleteNotificationCategoryById(id).then((rsp) => {
                this.$notify({
                    type: "succes",
                    title: "Success message",
                    text: "Delete Success",
                });
                this.loadItems();
            }).catch((e) => {
                this.$notify({
                    type: "error",
                    title: "Error Message",
                    text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                });
                this.loadItems();
            });
        },
        onCancel() {
            this.isEdit = false
            this.group = ''
            this.category = ''
            this.id = ''
        },
        showAlertConfirm(id) {
            this.$swal
                .fire({
                    title: "",
                    text: "Apakah Data Akan Di Hapus?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya",
                })
                .then(async (result) => {
                    if (result.value) {
                        await this.onDelete(id);
                    }
                });
        },
    },
    mounted() {
        this.loadItems();
    },

    validations: {
        category: { required },
        group: { required }
    }
};
</script>