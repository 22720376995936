<template>
  <div>
    <div class="panel">
      <div class="panel-body p-0">
        <div class="vgt-wrap">
          <div class="vgt-inner-wrap">
            <div class="vgt-responsive mb-0">
              <table class="vgt-table">
                <thead>
                  <tr>
                    <th scope="col">FAQ Title</th>
                    <th scope="col">Category</th>
                    <th scope="col">Author</th>
                    <th scope="col">Published Date</th>
                    <th scope="col" class="text-center">Action</th>
                  </tr>
                  <tr>
                    <th class="filter-th">
                      <input class="form-control" placeholder="Search title" name="faqTitle" v-model="faqTitle" id="title"
                        @input="updateFilters" />
                    </th>
                    <th class="filter-th">
                      <v-select :options="optionsCategory" placeholder="Category" :reduce="(label) => label.label"
                        label="label" @input="updateFiltersSelect" />
                    </th>
                    <th class="filter-th">
                      <input placeholder="Author" class="form-control" name="faqAuthor" v-model="faqAuthor" id="faqAuthor"
                        @change="updateFilters" />
                    </th>
                    <th class="filter-th">
                      <input placeholder="Published Date" class="form-control" name="faqPublishDate"
                        v-model="faqPublishDate" id="faqPublishDate" @change="updateFilters" />
                    </th>
                    <th class="filter-th"></th>
                  </tr>
                </thead>
                <draggable v-model="rows" tag="tbody" ghost-class="ghost" :move="checkMove" @start="dragging = true"
                  @end="onEndDrag">
                  <tr v-for="item in rows" :key="item.id" class="bg-white">
                    <td>{{ item.title }}</td>
                    <td>{{ item.faqCategory.name }}</td>
                    <td>{{ item.createdBy }}</td>
                    <td>{{ item.created }}</td>
                    <td>
                      <div class="row g-2 align-items-center justify-content-end">
                        <div class="col-auto" v-if="$can($permissionsAll[41])">
                          <router-link :to="`/pages/faq/edit/${item.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                            title="Edit">
                            <i class="fas fa-pencil"></i>
                          </router-link>
                        </div>
                        <div class="col-auto" v-if="$can($permissionsAll[41])">
                          <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move">
                            <i class="fa-solid fa-arrows-up-down"></i>
                          </button>
                        </div>
                        <div class="col-auto">
                          <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                            @click="showAlertConfirm(item.id)">
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import faqService from "@/service/faq.service";
import faqCategoryService from "@/service/faqcategory.service";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      isLoading: false,
      dragging: false,
      rows: [],
      category: "",
      faqAuthor: "",
      faqPublishDate: "",
      optionsCategory: [],
      faqTitle: "",
      serverParams: {
        sort: [
          {
            field: "title",
            type: "ASC",
          },
        ],
        pageNumber: 0,
        pageSize: 100,
      },
      faqPosition: 0,
      faqItemPosition: 0,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    updateFilters(evt) {
      let field = evt.target.id
      let filterVal = evt.target.value
      let updateParam = {}
      updateParam[field] = filterVal
      this.updateParams(updateParam)
    },
    updateFiltersSelect(val) {
      this.updateParams({
        category: val
      })
    },
    updateParams(newParams) {
      this.serverParams = Object.assign({}, this.serverParams, newParams)
      this.loadItems()
    },

    loadItems() {
      let searchParam = {
        status: "PUBLISHED",
        sort: "sequence",
        direction: "",
        pageNumber: 0,
        pageSize: 100,
      }

      let filterData = Object.assign({}, this.serverParams, searchParam)
      faqService.getFaqList(filterData).then((rsp) => {
        this.rows = rsp.data.data?.content
      }).catch((err) => {
        this.$notify({
          type: "error",
          title: "Error message",
          text: err.response.data?.responseMessage
        });
      })
      this.isLoading = false
    },

    loadFaqCategory() {
      let paramsGetData = {
        "searchField": "",
        "searchValue": "",
        "sort": "created",
        "direction": "ASC",
        "pageNumber": 0,
        "pageSize": 100
      }
      faqCategoryService.getFaqCategoryList(paramsGetData).then((rsp) => {
        let dataCat = rsp.data.data?.content
        dataCat.forEach((item) => {
          this.optionsCategory.push({
            label: item.name,
            code: item.id,
          });
        });
        this.isLoading = false
      }).catch((err) => {
        this.$notify({
          type: "error",
          title: "Error message",
          text: err.response.data?.responseMessage
        })
        this.isLoading = false
      })
    },

    checkMove() {

    },
    onEndDrag(evt) {
      const updateSequentials = [];
      for (let i = 0; i < this.rows.length; i++) {
        // console.log(i + 1, this.rows[i].title, this.rows[i].id);
        const row = this.rows[i];
        updateSequentials.push({ idFaq: row.id, newSequence: i + 1 })
      }
      // console.log("Orders changed", updateSequentials);
      let listPosition = {
        "list": updateSequentials
      }
      faqService
        .updateFaqPosition(listPosition).then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Reposisi Success",
          });
          this.loadItems()
        }).catch((err) => {
          this.$notify({
            type: "error",
            title: "Error message",
            text: err.response.data?.responseMessage
          });
        })
      this.isLoading = false
      this.loadItems()
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Trash?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleDelete(id);
          }
        });
    },

    async handleDelete(id) {
      let payloadDelete = {
        "status": "TRASHED",
      }
      await faqService.updateFaqStatus(id, payloadDelete).then((rsp) => {
        this.$swal({
          title: "Data berhasil Dipindah Ke Trash",
          showCloseButton: true,
        }).then(() => {
          this.$emit('reloadData', 'trashed');
          this.$router.push("/pages/faq/list/trashed");
        })
      }).catch((err) => {

      })
    },
  },
  mounted() {
    this.loadItems()
    this.loadFaqCategory()
  },
};
</script>
