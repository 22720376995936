var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5"},[_c('form',[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('h4',{staticClass:"fw-normal mb-3"},[_vm._v("Main Product")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-form"},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.plans.heading),function(item,index){return _c('th',{key:index,staticClass:"text-center",class:{
                        'bg-primary-dark text-white': _vm.innerLimit === item,
                      }},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Select")]),_vm._l((_vm.plans.heading),function(item,index){return _c('td',{key:index,staticClass:"text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerLimit),expression:"innerLimit"}],staticClass:"form-check-input",class:{
                          disabled: _vm.isReadOnly,
                        },attrs:{"type":"radio","name":"innerLimit","id":("innerLimit-" + (item.id))},domProps:{"value":item.id,"checked":_vm._q(_vm.innerLimit,item.id)},on:{"change":[function($event){_vm.innerLimit=item.id},function($event){return _vm.handleRadioSelect($event)}]}})])})],2),_vm._l((_vm.plans.rows),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.label))]),_vm._l((item.items),function(value,indexValue){return _c('td',{key:indexValue},[_vm._v(" "+_vm._s(value)+" ")])})],2)})],2)])])])])])]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('h4',{staticClass:"fw-normal mb-3"},[_vm._v("Extra Product")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-form"},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.extra.heading),function(item,index){return _c('th',{key:index,staticClass:"text-center",class:{
                        'bg-primary-dark text-white': _vm.extraLimit === item,
                      }},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Select")]),_vm._l((_vm.extra.heading),function(item,index){return _c('td',{key:index,staticClass:"text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.extraLimit),expression:"extraLimit"}],staticClass:"form-check-input",class:{
                          disabled: _vm.isReadOnly,
                        },attrs:{"type":"radio","name":"extraLimit","id":("extraLimit-" + (item.id))},domProps:{"value":item.id,"checked":_vm._q(_vm.extraLimit,item.id)},on:{"change":function($event){_vm.extraLimit=item.id}}})])})],2),_vm._l((_vm.extra.rows),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.label))]),_vm._l((item.items),function(value,indexValue){return _c('td',{key:indexValue},[_vm._v(" "+_vm._s(value)+" ")])})],2)})],2)])])])])])]),(!_vm.isReadOnly)?_c('div',{staticClass:"form-action"},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.doSave()}}},[_vm._v(" Save ")])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }