<template>
  <div
    class="app app-header-fixed app-sidebar-fixed"
    v-if="!appOptions.appEmpty"
    v-bind:class="{
      'app-sidebar-minified': appOptions.appSidebarMinified,
      'app-content-full-height': appOptions.appContentFullHeight,
      'app-without-sidebar': appOptions.appSidebarNone,
      'app-with-end-sidebar': appOptions.appSidebarEnd,
      'app-with-two-sidebar': appOptions.appSidebarTwo,
      'app-with-wide-sidebar': appOptions.appSidebarWide,
      'app-with-light-sidebar': appOptions.appSidebarLight,
      'app-sidebar-mobile-toggled': appOptions.appSidebarMobileToggled,
      'app-sidebar-end-toggled': appOptions.appSidebarEndToggled,
      'app-sidebar-end-collapsed': !appOptions.appSidebarEndToggled,
      'app-sidebar-end-mobile-toggled': appOptions.appSidebarEndMobileToggled,
      'app-header-fixed': !appOptions.appHeaderNone,
      'app-without-header': appOptions.appHeaderNone,
      'app-with-top-menu': appOptions.appTopMenu,
      'has-scroll': appOptions.appBodyScrollTop,
    }"
  >
    <template>
      <Header />
      <TopMenu v-if="appOptions.appTopMenu" />
      <Sidebar v-if="!appOptions.appSidebarNone" />
      <SidebarRight v-if="appOptions.appSidebarTwo" />
    </template>
    <div
      id="content"
      class="app-content"
      v-bind:class="appOptions.appContentClass"
    >
      <router-view></router-view>
      <vue-ins-progress-bar></vue-ins-progress-bar>
      <notifications position="bottom right" />
    </div>
  </div>
  <div class="h-100" v-else>
    <router-view></router-view>
    <vue-ins-progress-bar></vue-ins-progress-bar>
    <notifications position="bottom right" />
  </div>
</template>

<script>
import Sidebar from "./components/sidebar/Sidebar.vue";
import SidebarRight from "./components/sidebar-right/SidebarRight.vue";
import Header from "./components/header/Header.vue";
import TopMenu from "./components/top-menu/TopMenu.vue";
import AppOptions from "./config/AppOptions.vue";
import { mapState } from "vuex";

export default {
  name: "app",
  components: {
    Sidebar,
    SidebarRight,
    Header,
    TopMenu,
  },
  data() {
    return {
      appOptions: AppOptions,
    };
  },
  computed: mapState("auth", ["login"]),
  methods: {
    handleScroll: function () {
      AppOptions.appBodyScrollTop = window.scrollY;
    },
  },
  watch: {
    // login: {
    //   immediate: true,
    //   handler(login) {
    //     if (login) {
    //       this.$store.commit("auth/onTokenExpired", () =>
    //         this.$swal({
    //           title: "Logged Out",
    //           text: "You are idle too long, please login again",
    //           icon: "warning",
    //           showCancelButton: true,
    //           cancelButtonText: "Ignore",
    //           confirmButtonText: "Login again",
    //         }).then(({ isConfirmed }) => {
    //           if (isConfirmed) {
    //             //location.href = "/login";
    //           }
    //         })
    //       );
    //     } else {
    //       this.$store.commit("auth/onTokenExpired", null);
    //     }
    //   },
    // },
  },
  mounted() {
    this.$insProgress.finish();
  },
  created() {
    AppOptions.appBodyScrollTop = window.scrollY;

    window.addEventListener("scroll", this.handleScroll);

    this.$insProgress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$insProgress.finish();
    });
  },
};
</script>
