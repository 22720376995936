<template>
  <form @submit.prevent="doSave('PUBLISHED')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} Organization</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Charity Title"
            v-model="organization.title"
          />
          <span class="text-danger" v-if="$v.organization.title.$error"
            >Title is required</span
          >
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="mb-15px">
              <label class="form-label">Nazhir</label>
              <input
                type="text"
                class="form-control"
                v-model="organization.description"
              />
              <span
                class="text-danger"
                v-if="$v.organization.description.$error"
                >Nazhir is required</span
              >
            </div>
            <div class="mb-15px">
              <label class="form-label">Website URL</label>
              <input
                type="url"
                class="form-control"
                placeholder="http://"
                v-model="organization.website"
              />
              <span class="text-danger" v-if="$v.organization.website.$error"
                >Url is required</span
              >
            </div>
            <label class="form-label">Logo</label>
            <b-form-file
              v-model="organization.logo"
              :placeholder="
                !organization.logoFileName
                  ? 'Choose a file...'
                  : organization.logoFileName
              "
              accept="image/*"
              :multiple="false"
            ></b-form-file>
            <p class="mb-0 mt-1 small">54px x 28px .png file</p>
            <span class="text-danger" v-if="$v.organization.logo.$error"
              >Logo is required</span
            >
          </div>
        </div>
      </div>
      <div v-if="formType === 'edit'" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ organization.publishDate | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ organization.lastModified | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ organization.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="doSave('DRAFTS')"
            :class="{ disabled: onLoading }"
          >
            <span
              v-show="onLoading"
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button
            type="submit"
            class="btn btn-primary"
            :class="{ disabled: onLoading }"
          >
            <span
              v-show="onLoading"
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import { required, minLength, between } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      customToolbar: defaultToolbarEditor,
      organization: {
        title: detail ? detail.title : null,
        description: detail ? detail.description : null,
        website: detail ? detail.website : null,
        logo: detail ? detail.logo : null,
        logoFileName: detail ? detail.logoFileName : null,
        publishDate: detail ? detail.publishDate : null,
        author: detail ? detail.createdBy : null,
        lastModified: detail ? detail.updated : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.organization = newVal;
    },
  },
  methods: {
    doSave(type) {
      this.$v.organization.$touch();
      if (!this.$v.organization.$invalid) {
        const payload = {
          organization: this.organization,
          status: type,
        };

        this.$emit("onValidateSuccess", payload);
      }
    },

    dateFormat(date) {
      let arrDate = date.split(" ");
      let newDate = new Date(arrDate[0]);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return newDate.toLocaleDateString("id", options);
    },
  },
  filters: {
    getDate(date) {
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
  validations: {
    organization: {
      title: { required },
      description: { required },
      website: { required },
      logo: { required },
    },
  },
};
</script>
