import productService from "@/service/product.service";
import dayjs from "dayjs";
class PaymentFilterProduct {
  defaultFrequency = null;
  weighGroup = null;
  body = null;
  paymentFrequency = null;
  weighContribution = {
    home: null,
    office: null,
  };
  finalProductPrice = null;
  totalPrice = null;
  selectedProduct = null;
  paymentList = null;

  async getPaymentFrequency() {
    await productService
      .getPaymentFrequency({
        searchField: "",
        searchValue: "",
        sort: "code",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      })
      .then((res) => {
        let obj = {};
        this.paymentFrequency = res.data.data.content;
      })
      .catch(() => {
        alert("Terjadi kesalahan");
      });
  }

  async getWeightContribution() {
    await productService
      .getProductWeight({
        searchField: "",
        searchValue: "",
        sort: "name",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      })
      .then((res) => {
        let obj = {};
        res.data.data.forEach((weight) => {
          obj[weight.tier] = weight.value;
        });
        this.weighGroup = obj;
      })
      .catch(() => {
        alert("Terjadi kesalahan");
      });
  }

  async getDetailProduct(val, age) {
    let priceList = [];
    let promises = [];
    for (let i = 0; i < val.length; i++) {
      if (val[i].id !== null) {
        promises.push(
          productService
            .getProductById(val[i].id)
            .then((res) => {
              priceList.push({
                label: val[i].label,
                name: res.data.data.name,
                category: res.data.data.productCategory.productCategoryGroup,
                price: this.filterPremis(res.data.data.premis, age),
              });
            })
            .catch(() => {
              priceList.push({
                label: val[i].label,
                name: "Product Not Found",
                category: "Category Not Found",
                price: 0,
              });
            })
        );
      }
    }
    await Promise.all(promises).then(() => {
      let result = [];
      let freqTemporary = [];
      this.fixPriceList = priceList;
      priceList.forEach((price) => {
        freqTemporary = [];
        this.paymentFrequency.forEach((freq) => {
          let hitungGroupA =
            price.price * this.weighContribution.home.tier * freq.value;
          let hitungGroupB =
            price.price * this.weighContribution.office.tier * freq.value;

          let bobotGroupA =
            hitungGroupA * (this.weighContribution.home.weight / 100);
          let bobotGroupB =
            hitungGroupB * (this.weighContribution.office.weight / 100);

          let total = bobotGroupA + bobotGroupB;
          freqTemporary.push({
            frequencyName: freq.code,
            price: Math.ceil(total),
          });
        });
        result.push({
          code: price.label,
          name: price.name,
          category: price.category,
          detailFrequency: freqTemporary,
        });
      });
      this.setSelectedFrequency(result, "Bulanan");
      this.setPaymentType(result);
      this.finalProductPrice = result;
    });
    return {
      produk: this.selectedProduct,
      paymentType: this.paymentList,
      total: this.totalPrice,
      final: this.finalProductPrice,
    };
  }

  setSelectedFrequency(lists, freq) {
    let obj = {};
    let arr = [];
    let numToSum = [];
    lists.forEach((list) => {
      const row = list.detailFrequency.find(
        (obj) => obj.frequencyName === freq
      );
      numToSum.push(row.price);
      arr.push({
        category: list.category,
        name: list.name,
        price: row.price,
      });
    });
    const sum = numToSum.reduce((partialSum, a) => partialSum + a, 0);
    this.totalPrice = sum;
    this.selectedProduct = arr;
    return {
      produk: this.selectedProduct,
      total: this.totalPrice,
    };
  }

  setGroupValue(home, office) {
    let groupValue = {
      home: null,
      office: null,
    };
    if (home !== null && office !== null) {
      if (home.multiplierHospital === office.multiplierHospital) {
        groupValue.home = {
          tier: home.multiplierHospital,
          weight: 50,
        };
        groupValue.office = {
          tier: office.multiplierHospital,
          weight: 50,
        };
      } else if (home.multiplierHospital > office.multiplierHospital) {
        groupValue.home = {
          tier: home.multiplierHospital,
          weight: this.weighGroup.HIGH,
        };
        groupValue.office = {
          tier: office.multiplierHospital,
          weight: this.weighGroup.LOW,
        };
      } else if (home.multiplierHospital < office.multiplierHospital) {
        groupValue.home = {
          tier: home.multiplierHospital,
          weight: this.weighGroup.LOW,
        };
        groupValue.office = {
          tier: office.multiplierHospital,
          weight: this.weighGroup.HIGH,
        };
      }
    }
    this.weighContribution = groupValue;
  }

  filterPremis(data, age) {
    const premi = data.find((obj) => age >= obj.minAge && age <= obj.maxAge);
    if (premi === undefined) {
      return null;
    } else {
      return premi.innerLimit;
    }
  }
  setPaymentType(lists) {
    let arr = [];
    let numToSum = [];
    this.paymentFrequency.forEach((freq) => {
      numToSum = [];
      lists.forEach((list) => {
        const row = list.detailFrequency.find(
          (obj) => obj.frequencyName === freq.code
        );
        numToSum.push(row.price);
      });
      const sum = numToSum.reduce((partialSum, a) => partialSum + a, 0);
      arr.push({
        label: freq.code,
        price: sum,
      });
    });
    this.paymentList = arr;
  }

  formateDate(date) {
    return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
      "h:mm a"
    )}`;
  }
}

export default new PaymentFilterProduct();
