import api from "./api";

class HospitalService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create("hospital");
  }

  // hospital

  createHospital(hospital) {
    return this.api
      .post("hospital", hospital)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateHospitalById(id, hospital) {
    return this.api
      .put(`hospital/${id}`, hospital)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getHospitalLists(serverParams) {
    return this.api
      .get("hospital", { params: serverParams })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getNearHospital(serverParams) {
    return this.api
      .get("find-near-hospitals", { params: serverParams })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  changeHospitalStatus(id, data) {
    return this.api
      .put(`hospital/update/hospital-status/${id}?status=${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getHospitalById(id) {
    return this.api
      .get(`hospital/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteHospital(id) {
    return this.api
      .delete(`delete/hospital/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  checkHospitalName(serverParams) {
    return this.api
      .get(`hospital/check/name`, { params: serverParams })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  checkHospitalNameById(id, serverParams) {
    return this.api
      .get(`hospital/check/name/${id}`, { params: serverParams })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  importFile(formData) {
    return this.api
      .post(`hospital/import-hospital`, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getHistoryImportHistory(serverParams) {
    return this.api.get("/hospital/import-history", { params: serverParams });
  }

  // tier

  createTier(tier) {
    return this.api
      .post("hospital/tier", tier)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateTier(id, tier) {
    return this.api
      .put(`hospital/tier/${id}`, tier)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getTierById(id) {
    return this.api
      .get(`hospital/tier/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteTier(id) {
    return this.api
      .delete(`hospital/delete/tier/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getHospitalTierList(serverParams) {
    return this.api
      .get("hospital/tier", { params: serverParams })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  // group
  createHospitalGroup(serverParams) {
    return this.api
      .post("hospital/group", serverParams)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getHospitalGroupLists(serverParams) {
    return this.api
      .get("hospital/group", { params: serverParams })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getHospitalGroupById(id) {
    return this.api
      .get(`hospital/group/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateHospitalGroupById(id, hospital) {
    return this.api
      .put(`hospital/group/${id}`, hospital)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default new HospitalService();
