<template>
  <div class="pb-5">
    <form>
      <div class="panel">
        <div class="panel-body">
          <h4 class="fw-normal mb-3">Main Product</h4>
          <div class="row">
            <div class="col-md-8">
              <div class="table-responsive">
                <table class="table table-form">
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        class="text-center"
                        :class="{
                          'bg-primary-dark text-white': innerLimit === item,
                        }"
                        v-for="(item, index) in plans.heading"
                        :key="index"
                      >
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Select</td>
                      <td
                        class="text-center"
                        v-for="(item, index) in plans.heading"
                        :key="index"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="innerLimit"
                          :id="`innerLimit-${item.id}`"
                          :value="item.id"
                          v-model="innerLimit"
                          :class="{
                            disabled: isReadOnly,
                          }"
                          @change="handleRadioSelect($event)"
                        />
                      </td>
                    </tr>
                    <tr v-for="(item, index) in plans.rows" :key="index">
                      <td>{{ item.label }}</td>
                      <td
                        v-for="(value, indexValue) in item.items"
                        :key="indexValue"
                      >
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="panel-body">
          <h4 class="fw-normal mb-3">Extra Product</h4>
          <div class="row">
            <div class="col-md-8">
              <div class="table-responsive">
                <table class="table table-form">
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        class="text-center"
                        :class="{
                          'bg-primary-dark text-white': extraLimit === item,
                        }"
                        v-for="(item, index) in extra.heading"
                        :key="index"
                      >
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Select</td>
                      <td
                        class="text-center"
                        v-for="(item, index) in extra.heading"
                        :key="index"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="extraLimit"
                          :id="`extraLimit-${item.id}`"
                          :value="item.id"
                          v-model="extraLimit"
                          :class="{
                            disabled: isReadOnly,
                          }"
                        />
                      </td>
                    </tr>
                    <tr v-for="(item, index) in extra.rows" :key="index">
                      <td>{{ item.label }}</td>
                      <td
                        v-for="(value, indexValue) in item.items"
                        :key="indexValue"
                      >
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isReadOnly" class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button @click.prevent="doSave()" class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import productService from "@/service/product.service";
import registrationService from "@/service/registration.service";
import productFilter from "./functions/productFilter";
export default {
  data() {
    return {
      status: false,
      innerLimit: null,
      extraLimit: null,
      age: 0,
      weighContribution: {
        home: null,
        office: null,
      },
      plans: {
        heading: [],
        rows: [],
      },
      extra: {
        heading: [],
        rows: [],
      },
      productParams: {
        params: {
          pageSize: 10,
          pageNumber: 0,
          sort: "name",
          direction: "ASC",
          searchField: "",
          searchValue: "",
          status: "PUBLISHED",
          categoryGroup: "MAIN",
        },
        content: [],
        totalElments: 0,
      },
      body: null,
    };
  },
  computed: {
    isReadOnly() {
      if (this.status === "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.innerLimit !== null) {
          const body = await productFilter.getDetailProduct(
            [
              {
                id: this.innerLimit,
                label: "idProdukUtama",
              },
              {
                id: this.extraLimit,
                label: "idProdukExtra",
              },
            ],
            this.age,
            "Step3"
          );
          await registrationService
            .stepThirdUpdate(this.$route.params.id, body)
            .then((response) => {
              this.$swal({
                title: "Data berhasil diupdate",
                showCloseButton: true,
              });
            })
            .catch((error) => {
              this.$swal({
                title:
                  error.response?.data?.responseMessage || "Terjadi Kesalahan",
                showCloseButton: true,
              });
            });
        } else {
          alert("Pilih Produk!");
        }
      } else {
        console.log("False");
      }
    },
    async handleRadioSelect(event) {
      await this.getDetailProductById(event.target.value);
      this.extraLimit = null;
    },
    async getProductList() {
      await productService
        .getProductList(this.productParams.params)
        .then((res) => {
          if (
            res.data.responseCode == "200" &&
            res.data.responseMessage == "Data's Empty!"
          ) {
            return;
          } else {
            this.plans.heading = productFilter.setHeaderTitle(
              res.data.data.content
            );
            this.plans.rows = productFilter.setBenefitList(
              res.data.data.content
            );
          }
        })
        .catch(() => {
          alert("Something Wrong");
        });
    },
    async getDetailProductById(id) {
      await productService
        .getProductById(id)
        .then((res) => {
          let extra = res.data.data.extraAddons;
          if (extra !== null) {
            this.extra.heading = productFilter.setHeaderTitle(extra);
            this.extra.rows = productFilter.setBenefitList(extra);
          }
        })
        .catch(() => {
          alert("Something Wrong");
        });
    },
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            let registrationData = res.data.data.customerRegistration;
            let data = res.data.data.customerProduct;
            this.innerLimit =
              data.idProdukUtama !== null ? data.idProdukUtama : null;
            this.extraLimit =
              data.idProdukExtra !== null ? data.idProdukExtra : null;
            this.status = res.data.data.customerRegistration?.status;
            this.age = productFilter.getAge(
              res.data.data.customerRegistration.tanggalLahir
            );
            productFilter.setOriginalDataProduct(data);
            productFilter.setGroupValue(
              registrationData.customerHospitalHome,
              registrationData.customerHospitalOffice
            );
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );

      if (this.innerLimit !== null) {
        await this.getDetailProductById(this.innerLimit);
      }
    },
  },
  validations() {
    return {};
  },
  async mounted() {
    await productFilter.getPaymentFrequency();
    await productFilter.getWeightContribution();
    await this.getProductList();
    await this.handleDetail();
  },
};
</script>
<style scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
