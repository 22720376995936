class HandleMaps {
  async geocoder(geocode, address) {
    let result = null;
    let addressParams = {
      address_line_1: address.alamat1,
      address_line_2: address.alamat2,
      city: address.city,
      state: address.provinsi,
      zip_code: address.zipcode,
      country: address.country,
    };
    const res = await new Promise((resolve, reject) => {
      geocode.send(addressParams, (response) => {
        resolve(response);
      });
    });
    var address = res.results[0].address_components;
    var zipcode = address[address.length - 1].long_name;
    result = {
      coordinate: {
        lat: res.results[0].geometry.location.lat,
        lng: res.results[0].geometry.location.lng,
      },
      postal_code: zipcode,
    };
    return result;
  }
}

export default new HandleMaps();
