<template>
  <form @submit.prevent="doSave('PUBLISHED')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} Banner</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <label class="form-label">Desktop version
            <span class="fw-normal">(1280px x 550px, max 1 mb)</span></label>
          <UploadImage height="253px" v-model="banner.images.desktop" :src="banner.images.desktopSrc" />
          <span class="text-danger" v-if="$v.banner.images.desktop.$error">Image Desktop is required</span>
        </div>
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Banner Title" v-model="banner.title" />
          <span class="text-danger" v-if="$v.banner.title.$error">Banner Tittle is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="mb-15px">
              <label class="form-label">Description</label>
              <ckeditor class="form-control" placeholder="Text" v-model="banner.description" />
              <!-- <textarea class="form-control" placeholder="Text" v-model="banner.description" rows="5"></textarea> -->
              <span class="text-danger" v-if="$v.banner.description.$error">Banner Description is required</span>
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #1 Text</label>
              <input type="text" class="form-control" placeholder="Text" v-model="banner.cta.text1" />
              <span class="text-danger" v-if="$v.banner.cta.text1.$error">CTA #1 Text is required</span>
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #1 URL</label>
              <input type="text" class="form-control" placeholder="Text" v-model="banner.cta.url1" />
              <span class="text-danger" v-if="$v.banner.cta.url1.$error">CTA #1 Url is required</span>
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #2 Text</label>
              <input type="text" class="form-control" placeholder="Text" v-model="banner.cta.text2" />
              <span class="text-danger" v-if="$v.banner.cta.text2.$error">CTA #2 Text is required</span>
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #2 URL</label>
              <input type="text" class="form-control" placeholder="Text" v-model="banner.cta.url2" />
              <span class="text-danger" v-if="$v.banner.cta.url2.$error">CTA #2 Url is required</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="mb-15px">
          <label class="form-label">Mobile version
            <span class="fw-normal">(390px x 500px, max 1 mb)</span></label>
          <UploadImage height="425px" v-model="banner.images.mobile" :src="banner.images.mobileSrc" />
          <span class="text-danger" v-if="$v.banner.images.mobile.$error">CTA #2 Url is required</span>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click="doSave('DRAFTS')"
            :class="{ disabled: onLoading }">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import { required, minLength, between } from "vuelidate/lib/validators";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      customToolbar: defaultToolbarEditor,
      banner: {
        title: detail ? detail.title : null,
        description: detail ? detail.description : null,
        images: {
          desktop: detail ? detail.images.desktop : null,
          mobile: detail ? detail.images.mobile : null,
          desktopSrc: detail ? detail.images.desktopSrc : "",
          mobileSrc: detail ? detail.images.mobileSrc : "",
        },
        cta: {
          text1: "",
          url1: "",
          text2: "",
          url2: "",
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.banner = newVal;
    },
  },
  methods: {
    doSave(type) {
      this.$v.banner.$touch();
      if (!this.$v.banner.$invalid) {
        const payload = {
          banner: this.banner,
          status: type,
        };

        this.$emit("onValidateSuccess", payload);
      }
    },
  },

  validations: {
    banner: {
      title: { required },
      description: { required },
      images: {
        desktop: {
          required() {
            return this.formType == "edit"
              ? true
              : required.apply(this, arguments);
          },
        },
        mobile: {
          required() {
            return this.formType == "edit"
              ? true
              : required.apply(this, arguments);
          },
        },
      },
      cta: {
        text1: { required },
        url1: { required },
        text2: { required },
        url2: { required },
      },
    },
  },
};
</script>
