<template>
    <div class="product-form">
        <FormBanner form-type="add" @onValidateSuccess="doSave" :on-loading="onProgress" />
    </div>
</template>
<script>
import FormBanner from './Form.vue'
import BannerService from "../../../service/banner.service";
import ConvertToBase64 from "@/pages/Helper/base64";

export default {
    components: {
        FormBanner,
    },
    data() {
        return {
            onProgress: false,
        };
    },
    methods: {
        async doSave(payload) {
            // Submit here
            // console.log(await ConvertToBase64(payload.images.desktop[0]));
            this.onProgress = true
            let { banner, status } = payload
            if (banner) {
                let { cta } = banner
                payload = {
                    recordStatus: "ACTIVE",
                    title: banner.title,
                    description: banner.description,
                    imageUrlMainBase64: banner.images.desktop ? await ConvertToBase64(banner.images.desktop[0]) : null,
                    imageUrlMobileBase64: banner.images.mobile ? await ConvertToBase64(banner.images.mobile[0]) : null,
                    ctaUrl1: cta.url1,
                    ctaText1: cta.text1,
                    ctaUrl2: cta.url2,
                    ctaText2: cta.text2,
                    status: status
                }
                BannerService
                    .postBanner(payload)
                    .then((rsp) => {
                        this.onProgress = false
                        this.$swal({
                            title: "Data berhasil disimpan",
                            showCloseButton: true,
                        }).then(() => {
                            if (status === "PUBLISHED") {
                                this.$router.push({ path: '/sections/banner' })
                            } else if (status === "DRAFTS") {
                                this.$router.push({ path: '/sections/banner/drafts' })
                            }
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })
            }
        }
    }
}
</script>