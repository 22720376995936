import api from "./api";

class MicrositeService {

  constructor() {
    this.api = api.create('registration')
  }

  pagePreview(data) {
    return this.api.post(`cms/page-preview`, data);
  }
}

export default new MicrositeService();