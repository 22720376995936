<template>
  <div class="pb-5">
    <form>
      <panel
        header-class="rounded-0"
        variant="inverse"
        title="Alamat Tempat Tinggal"
        :is-collapse="true"
        :show-collapse="true"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="mb-15px">
              <label class="form-label">Masukan Alamat</label>
              <div class="row">
                <div class="col">
                  <GmapAutocomplete
                    @place_changed="setPlace($event, 'HOME')"
                    @focus="onFocus = true"
                    style="width: 100%; height: 35px"
                    class="form-control pac-target-input"
                  />
                </div>
              </div>
            </div>
            <div class="hospital-list">
              <panel
                v-for="(item, index) in rows"
                class="mb-2"
                header-class="fw-bolder border-0 bg-transparent"
                variant="default"
                body-class="p-0"
                :is-collapse="true"
                :show-collapse="false"
                :key="index"
              >
                <template #header>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="group1"
                      :id="`group1-${item.id}`"
                      :value="item.id"
                      v-model="group1"
                      :class="{
                        disabled: isReadOnly,
                      }"
                      @change="handleRadioSelect($event, 'HOME')"
                    />
                    <label class="form-check-label">{{
                      `${item.area} - ${item.code}`
                    }}</label>
                  </div>
                </template>
                <div class="table-responsive mb-0">
                  <table class="table table-form">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Hospital Name</th>
                        <th>Tier</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(hospital, index) in item.hospitals"
                        :key="index"
                      >
                        <td>
                          <input
                            class="form-check-input"
                            type="radio"
                            name="hospitals1"
                            :id="`hospitals1+${hospital.id}+${hospital.groupId}+${hospital.hospitalTier}`"
                            :value="hospital.id"
                            v-model="hospitals1"
                            :class="{
                              disabled: isReadOnly,
                            }"
                            @change="handleHospitalRadioSelect($event, 'HOME')"
                          />
                        </td>
                        <td>{{ hospital.hospitalName }}</td>
                        <td>{{ hospital.hospitalTier }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </panel>
            </div>
          </div>
          <div class="col-md-6">
            <GmapMap
              :center="myCoordinates"
              :zoom="zoom"
              style="width: 100%; height: 400px"
              ref="home"
              @dragend="handleDrag"
            >
              <GmapMarker
                :position="myCoordinates"
                :icon="icon"
                :clickable="true"
                :draggable="true"
              />
              <GmapMarker
                v-for="(m, index) in homeHospitalMarkers"
                :key="index"
                :position="m.position"
                :icon="m.icon"
                :clickable="true"
              />

              <gmap-info-window
                :options="{
                  maxWidth: 300,
                  pixelOffset: { width: 0, height: -35 },
                }"
                :position="infoWindow.position"
                :opened="infoWindow.open"
                @closeclick="infoWindow.open = false"
              >
                <div v-html="infoWindow.template"></div>
              </gmap-info-window>
            </GmapMap>
          </div>
        </div>
      </panel>
      <panel
        variant="inverse"
        title="Alamat Kantor"
        :is-collapse="true"
        :show-collapse="true"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="mb-15px">
              <label class="form-label">Masukin Alamat</label>
              <div class="row">
                <div class="col">
                  <GmapAutocomplete
                    @place_changed="setOfficePlace"
                    @focus="onFocus = true"
                    style="width: 100%; height: 35px"
                    class="form-control pac-target-input"
                  />
                </div>
              </div>
            </div>
            <div class="hospital-list">
              <panel
                v-for="(item, index) in officeRows"
                class="mb-2"
                header-class="fw-bolder border-0 bg-transparent"
                variant="default"
                body-class="p-0"
                :is-collapse="true"
                :show-collapse="false"
                :key="index"
              >
                <template #header>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="group2"
                      :id="`group2-${item.id}`"
                      :value="item.id"
                      v-model="group2"
                      :class="{
                        disabled: isReadOnly,
                      }"
                      @change="handleRadioSelect($event, 'OFFICE')"
                    />
                    <label class="form-check-label">{{
                      `${item.area} - ${item.code}`
                    }}</label>
                  </div>
                </template>
                <div class="table-responsive mb-0">
                  <table class="table table-form">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Hospital Name</th>
                        <th>Tier</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(hospital, index) in item.hospitals"
                        :key="index"
                      >
                        <td>
                          <input
                            class="form-check-input"
                            type="radio"
                            name="hospitals2"
                            :id="`hospitals2+${hospital.id}+${hospital.groupId}+${hospital.hospitalTier}`"
                            :value="hospital.id"
                            v-model="hospitals2"
                            :class="{
                              disabled: isReadOnly,
                            }"
                            @change="
                              handleHospitalRadioSelect($event, 'OFFICE')
                            "
                          />
                        </td>
                        <td>{{ hospital.hospitalName }}</td>
                        <td>{{ hospital.hospitalTier }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </panel>
            </div>
          </div>
          <div class="col-md-6">
            <GmapMap
              :center="officeCoordinates"
              :zoom="zoom"
              style="width: 100%; height: 400px"
              ref="office"
              @dragend="handleDrag"
            >
              <GmapMarker
                :position="officeCoordinates"
                :icon="icon"
                :clickable="true"
                :draggable="true"
              />
              <GmapMarker
                v-for="(m, index) in officeHospitalMarkers"
                :key="index"
                :position="m.position"
                :icon="m.icon"
                :clickable="true"
              />

              <gmap-info-window
                :options="{
                  maxWidth: 300,
                  pixelOffset: { width: 0, height: -35 },
                }"
                :position="infoWindow.position"
                :opened="infoWindow.open"
                @closeclick="infoWindow.open = false"
              >
                <div v-html="infoWindow.template"></div>
              </gmap-info-window>
            </GmapMap>
          </div>
        </div>
      </panel>
      <div v-if="!isReadOnly" class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button @click.prevent="doSave()" class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import hospitalService from "@/service/hospital.service";
import registrationService from "@/service/registration.service";
import _ from "lodash";
import handleMaps from "./functions/handleMaps";
import hospitalFilter from "./functions/hospitalFilter";
export default {
  data() {
    return {
      timer: null,
      status: false,
      address1: "",
      province1: "",
      city1: "",
      district1: "",
      hospitals1: null,
      group1: null,
      address2: null,
      province2: null,
      city2: null,
      district2: null,
      hospitals2: null,
      group2: null,
      homeLocation: {
        lat: null,
        longitude: null,
      },
      officeLocation: {
        lat: null,
        longitude: null,
      },
      homeHospital: {
        position: {
          lat: null,
          lng: null,
        },
      },
      workHospital: {
        position: {
          lat: null,
          lng: null,
        },
      },
      hospitalGroup: {
        params: {
          pageSize: 10,
          pageNumber: 0,
          sort: "name",
          direction: "ASC",
          searchField: "name",
          searchValue: "",
        },
        content: [],
        totalElments: 0,
      },
      dropdownList: {
        province1: null,
        province2: null,
        city1: null,
        city2: null,
        district1: null,
      },
      // Maps
      icon: "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
      markers: [],
      map: null,
      myCoordinates: {
        lat: 0,
        lng: 0,
      },
      officeCoordinates: {
        lat: 0,
        lng: 0,
      },
      onFocus: false,
      zoom: 10,
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },
      autoComplete: "",
      homeHospitalMarkers: [],
      officeHospitalMarkers: [],
      // End Maps
      rows: [],
      officeRows: [],
      homeHospitalDetail: null,
      officeHospitalDetail: null,
    };
  },
  computed: {
    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 0,
          lng: 0,
        };
      }
      return {
        lat: this.map.getCenter().lat().toFixed(4),
        lng: this.map.getCenter().lng().toFixed(4),
      };
    },
    isReadOnly() {
      if (this.status === "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    this.$refs.home.$mapPromise.then((map) => (this.map = map));
    this.$refs.office.$mapPromise.then((map) => (this.map = map));
    await this.handleDetail();
  },
  methods: {
    openInfoWindowTemplate(index) {
      const { position, key } = this.markers[index];
      this.infoWindow.position = { lat: position.lat, lng: position.lng };
      this.infoWindow.template = `<b>${key}</b>`;
      this.infoWindow.open = true;
    },
    handleDrag() {
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
      let zoom = this.map.getZoom();
      localStorage.center = JSON.stringify(center);
      localStorage.zoom = zoom;
    },
    setPlace(place, data) {
      if (data === "HOME") {
        this.homeLocation = {
          lat: place.geometry.location.lat().toString(),
          longitude: place.geometry.location.lng().toString(),
        };
        this.myCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.getNearbyHospitals("home");
      } else {
        this.officeLocation = {
          lat: place.geometry.location.lat().toString(),
          longitude: place.geometry.location.lng().toString(),
        };
        this.officeCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.getNearbyHospitals("office");
      }
      this.panToMarker(data);
    },
    panToMarker(pointer) {
      if (pointer === "HOME") {
        this.$refs.home.panTo(this.myCoordinates);
      } else {
        this.$refs.office.panTo(this.officeCoordinates);
      }
      if (localStorage.zoom) {
        this.zoom = parseInt(localStorage.zoom);
      }
    },
    setOfficePlace(place) {
      this.officeLocation.lat = place.geometry.location.lat().toString();
      this.officeLocation.longitude = place.geometry.location.lng().toString();
      this.officeCoordinates.lat = place.geometry.location.lat();
      this.officeCoordinates.lng = place.geometry.location.lng();
      this.$refs.mapAddress.panTo({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      if (localStorage.zoom) {
        this.zoom = parseInt(localStorage.zoom);
      }
      this.getNearbyHospitals("office");
    },
    async doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.homeHospitalDetail !== null &&
          this.officeHospitalDetail !== null
        ) {
          registrationService
            .hospitalUpdate(this.$route.params.id, {
              ...this.homeHospitalDetail,
              ...this.officeHospitalDetail,
            })
            .then((response) => {
              this.$swal({
                title: "Data berhasil diupdate",
                showCloseButton: true,
              });
            })
            .catch((error) => {
              this.$swal({
                title:
                  error.response?.data?.responseMessage || "Terjadi Kesalahan",
                showCloseButton: true,
              });
            });
        } else {
          alert("Pilih RS!");
        }
      } else {
        console.log("False");
      }
    },
    async getHospitalGroup() {
      await hospitalService
        .getHospitalGroupLists(this.hospitalGroup.params)
        .then((res) => {
          this.setHospitalGroup(res.data.content);
        })
        .catch(() => {
          alert("something wrong");
        });
    },
    setHospitalGrp(data, pointer) {
      let hospitals = [];
      let rows = [];
      for (const key in data) {
        let sparated = key.split("+");
        if (data.hasOwnProperty(key)) {
          hospitals = [];
          data[key].forEach((hospital) => {
            hospitals.push({
              id: hospital.id,
              hospitalName: hospital.name,
              multiplier: hospital.multiplier,
              hospitalTier: hospital.tier,
              groupId: hospital.groupIds,
              vgtSelected: false,
              map: {
                icon: "http://maps.google.com/mapfiles/kml/paddle/orange-circle.png",
                position: {
                  lat: Number(hospital.latitude),
                  lng: Number(hospital.longitude),
                },
              },
            });
          });
          rows.push({
            id: sparated[0],
            area: sparated[2],
            code: sparated[1],
            tier: sparated[3],
            hospitals: hospitals,
          });
        }
        if (pointer === "home") {
          this.rows = rows;
        } else {
          this.officeRows = rows;
        }
      }
    },
    pushSelectedData(data) {
      let list = this.dropDownServerParams[data.pointer].content;
      if (
        this[data.pointer] !== null &&
        this.selectedDropDown[data.pointer] !== null
      ) {
        var foundIndex = list.findIndex((x) => x.id == this[data.pointer]);
        if (foundIndex < 0) {
          this.dropDownServerParams[data.pointer].content.push(
            this.selectedDropDown[data.pointer]
          );
        }
      }
    },
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          async (res) => {
            let registrationData = res.data.data.customerRegistration;
            this.status = res.data.data.customerRegistration?.status;
            await this.geoCoder(res.data.data.customerRegistration);
            this.hospitals1 =
              registrationData.customerHospitalHome !== null
                ? registrationData.customerHospitalHome.idHospital
                : null;
            this.group1 =
              registrationData.customerHospitalHome !== null
                ? registrationData.customerHospitalHome.idHospitalGroup
                : null;

            // Office
            this.hospitals2 =
              registrationData.customerHospitalOffice !== null
                ? registrationData.customerHospitalOffice.idHospital
                : null;
            this.group2 =
              registrationData.customerHospitalOffice !== null
                ? registrationData.customerHospitalOffice.idHospitalGroup
                : null;
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
    setHospitalMarkers(data) {
      data.forEach((hospital) => {
        if (hospital.lokasiTerdekat === "home") {
          this.homeHospitalMarkers.push({
            icon: "http://maps.google.com/mapfiles/kml/paddle/orange-circle.png",
            position: {
              lat: hospital.hospital.lat,
              lng: hospital.hospital.longitude,
            },
          });
        } else {
          this.officeHospitalMarkers.push({
            icon: "http://maps.google.com/mapfiles/kml/paddle/blu-circle.png",
            position: {
              lat: hospital.hospital.lat,
              lng: hospital.hospital.longitude,
            },
          });
        }
      });
    },
    handleRadioSelect(event, pointer) {
      if (pointer === "HOME") {
        var index = this.rows.findIndex((p) => p.id === event.target.value);
        this.homeHospitalMarkers = [];
        if (this.rows.length > 0) {
          this.rows[index].hospitals.forEach((hospital) => {
            this.homeHospitalMarkers.push(hospital.map);
          });
        }
        if (this.homeHospitalMarkers.length > 0) {
          this.panToMarker(pointer);
        }
        this.hospitals1 = null;
      } else {
        var officeIndex = this.officeRows.findIndex(
          (p) => p.id === event.target.value
        );
        this.officeHospitalMarkers = [];
        if (this.officeRows.length > 0) {
          this.officeRows[officeIndex].hospitals.forEach((hospital) => {
            this.officeHospitalMarkers.push(hospital.map);
          });
        }
        if (this.officeHospitalMarkers.length > 0) {
          this.panToMarker(pointer);
        }
        this.hospitals2 = null;
      }
    },
    handleHospitalRadioSelect(event, pointer) {
      let sparated = event.target.id.split("+");
      if (pointer === "HOME") {
        var index = this.rows.findIndex(
          (p) => p.id === sparated[2] && p.tier === sparated[3]
        );
        this.homeHospitalMarkers = [];
        if (this.rows.length > 0) {
          this.rows[index].hospitals.forEach((hospital) => {
            this.homeHospitalMarkers.push(hospital.map);
          });
        }
        if (this.homeHospitalMarkers.length > 0) {
          this.panToMarker(pointer);
        }
        this.group1 = sparated[2];
        var groupIndex = this.rows.findIndex(
          (p) => p.id === sparated[2] && p.tier === sparated[3]
        );
        this.tireChecking(sparated[1], groupIndex, "home");
      } else {
        var officeIndex = this.officeRows.findIndex(
          (p) => p.id === sparated[2] && p.tier === sparated[3]
        );
        this.officeHospitalMarkers = [];
        if (this.officeRows.length > 0) {
          this.officeRows[officeIndex].hospitals.forEach((hospital) => {
            this.officeHospitalMarkers.push(hospital.map);
          });
        }
        if (this.officeHospitalMarkers.length > 0) {
          this.panToMarker(pointer);
        }
        this.group2 = sparated[2];
        var officeGroupIndex = this.officeRows.findIndex(
          (p) => p.id === sparated[2] && p.tier === sparated[3]
        );
        this.tireChecking(sparated[1], officeGroupIndex, "office");
      }
    },
    async getNearbyHospitals(pointer) {
      await hospitalService
        .getNearHospital(
          pointer === "home" ? this.homeLocation : this.officeLocation
        )
        .then((res) => {
          if (res.length > 0) {
            this.setHospitalGrp(
              hospitalFilter.restructureHospital(res),
              pointer
            );
          } else {
            if (pointer === "home") {
              this.rows = [];
            } else {
              this.officeRows = [];
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    handleSearchHospital() {
      if (
        this.address1 == null ||
        this.province1 == null ||
        this.district1 == null ||
        this.city1 == null
      ) {
        alert("Lengkapi Data");
      } else {
        let data = {
          alamat: this.address1,
          customerCity: {
            name: this.selectedDropDown.province1.label,
          },
          customerProvince: {
            name: this.selectedDropDown.city1.label,
          },
        };
        this.geoCoder(data);
      }
    },
    async geoCoder(data) {
      let address = {
        alamat1: data.alamat !== null ? data.alamat : "",
        alamat2: "",
        city: data.customerCity !== null ? data.customerCity.name : "",
        provinsi:
          data.customerProvince !== null ? data.customerProvince.name : "",
        zipcode: "",
        country: "Indonesia",
      };
      let results = await handleMaps.geocoder(this.$geocoder, address);
      this.homeLocation = {
        lat: results.coordinate.lat.toString(),
        longitude: results.coordinate.lng.toString(),
      };
      this.officeLocation = {
        lat: results.coordinate.lat.toString(),
        longitude: results.coordinate.lng.toString(),
      };
      this.myCoordinates = {
        lat: results.coordinate.lat,
        lng: results.coordinate.lng,
      };
      this.officeCoordinates = {
        lat: results.coordinate.lat,
        lng: results.coordinate.lng,
      };
      this.$refs.home.panTo(this.myCoordinates);
      this.$refs.office.panTo(this.officeCoordinates);
      if (localStorage.zoom) {
        this.zoom = parseInt(localStorage.zoom);
      }

      await this.getNearbyHospitals("home");
      await this.getNearbyHospitals("office");
    },
    tireChecking(idHospital, groupINdex, pointer) {
      if (pointer === "home") {
        var homeHosIndex = this.rows[groupINdex]["hospitals"].findIndex(
          (p) => p.id === idHospital
        );
        var homeHossRisult = this.rows[groupINdex]["hospitals"][homeHosIndex];
        this.homeHospitalDetail = {
          idHospitalHome: homeHossRisult.id,
          namaHospitalHome: homeHossRisult.hospitalName,
          idHospitalGroupsHome: homeHossRisult.groupId,
          multiplierHome: homeHossRisult.multiplier,
        };
      } else {
        var officeHosIndex = this.officeRows[groupINdex]["hospitals"].findIndex(
          (p) => p.id === idHospital
        );
        var officeHossRisult =
          this.officeRows[groupINdex]["hospitals"][officeHosIndex];
        this.officeHospitalDetail = {
          idHospitalOffice: officeHossRisult.id,
          namaHospitalOffice: officeHossRisult.hospitalName,
          idHospitalGroupsOffice: officeHossRisult.groupId,
          multiplierOffice: officeHossRisult.multiplier,
        };
      }
    },
  },
  validations() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.hospital-list {
  max-height: 540px;
  overflow: auto;
}
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>