import productService from "@/service/product.service";
class ProductFilter {
  defaultFrequency = null;
  selectedProduct = null;
  weighGroup = null;
  originalBody = null;
  body = null;
  paymentFrequency = null;
  weighContribution = {
    home: null,
    office: null,
  };
  setOriginalDataProduct(data) {
    this.originalBody = {
      idProdukUtama: data.idProdukUtama,
      idProdukExtra: data.idProdukExtra,
      idProdukAddons: data.idProdukAddons,
      idProdukAddons2: data.idProdukAddons2,
      namaProdukUtama: data.namaProdukUtama,
      namaProdukExtra: data.namaProdukExtra,
      namaProdukAddons: data.namaProdukAddons,
      namaProdukAddons2: data.namaProdukAddons2,
      hargaProdukUtama: Math.ceil(data.hargaProdukUtama),
      hargaProdukExtra: Math.ceil(data.hargaProdukExtra),
      hargaProdukAddons: Math.ceil(data.hargaProdukAddons),
      hargaProdukAddons2: Math.ceil(data.hargaProdukAddons2),
    };
  }

  getAge(birthdayDate) {
    var dob = new Date(birthdayDate);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    return age;
  }
  getProductPlansDetail(products, selectedProduct) {
    let result = [];
    selectedProduct.forEach((id) => {
      let data = products.find((product) => product.id === id);
      if (data !== undefined) {
        result.push({
          id: id,
          label: data.subCategoryName,
        });
      }
    });
    return result;
  }
  setHeaderTitle(producList) {
    let heading = [];
    producList.forEach((product) => {
      heading.push({
        id: product.id,
        name: product.name,
      });
    });
    return heading;
  }

  setBenefitList(productList) {
    let benefitName = [];
    let rows = [];
    let row = {};
    let item = [];
    productList.forEach((product) => {
      product["benefits"].forEach((benefit) => {
        benefitName.push(benefit.label);
      });
    });
    let uniqueChars = [...new Set(benefitName)];
    uniqueChars.forEach((label, indexLabel) => {
      row = {};
      item = [];
      productList.forEach((product, productIndex) => {
        let obj = product["benefits"].find((x) => x.label === label);
        row["label"] = label;
        if (obj === undefined) {
          item.push("-");
        } else {
          item.push(obj.value);
        }
      });
      row.items = item;
      rows.push(row);
    });
    return rows;
  }

  async getPaymentFrequency() {
    await productService
      .getPaymentFrequency({
        searchField: "",
        searchValue: "",
        sort: "code",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      })
      .then((res) => {
        this.paymentFrequency = res.data.data.content;
        const defaultFreq = res.data.data.content.find(
          (obj) => obj.defaultSelection === true
        );
        if (defaultFreq !== undefined) {
          this.defaultFrequency = defaultFreq.code;
        } else {
          this.defaultFrequency = "Bulanan";
        }
      })
      .catch(() => {
        alert("Terjadi kesalahan");
      });
  }

  async getWeightContribution() {
    await productService
      .getProductWeight({
        searchField: "",
        searchValue: "",
        sort: "name",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      })
      .then((res) => {
        let obj = {};
        res.data.data.forEach((weight) => {
          obj[weight.tier] = weight.value;
        });
        this.weighGroup = obj;
      })
      .catch(() => {
        alert("Terjadi kesalahan");
      });
  }

  async getDetailProduct(val, age, from) {
    this.selectedProduct = val;
    let priceList = [];
    let promises = [];
    for (let i = 0; i < val.length; i++) {
      if (val[i].id !== null) {
        promises.push(
          productService.getProductById(val[i].id).then((res) => {
            priceList.push({
              id: res.data.data.id,
              label: val[i].label,
              name: res.data.data.name,
              category: res.data.data.productCategory.productCategoryGroup,
              price: this.filterPremis(res.data.data.premis, age),
            });
          })
        );
      }
    }
    await Promise.all(promises).then(() => {
      let result = [];
      let freqTemporary = [];
      this.fixPriceList = priceList;
      priceList.forEach((price) => {
        freqTemporary = [];
        this.paymentFrequency.forEach((freq) => {
          let hitungGroupA =
            price.price * this.weighContribution.home.tier * freq.value;
          let hitungGroupB =
            price.price * this.weighContribution.office.tier * freq.value;

          let bobotGroupA =
            hitungGroupA * (this.weighContribution.home.weight / 100);
          let bobotGroupB =
            hitungGroupB * (this.weighContribution.office.weight / 100);

          let total = bobotGroupA + bobotGroupB;
          freqTemporary.push({
            frequencyName: freq.code,
            price: Math.ceil(total),
          });
        });
        result.push({
          id: price.id,
          code: price.label,
          name: price.name,
          category: price.category,
          detailFrequency: freqTemporary,
        });
      });
      if (from === "Step3") {
        this.body = this.setSelectedFrequency(result, this.defaultFrequency);
      } else if (from === "Step4") {
        this.body = this.setRiderSelectedFrequency(
          result,
          this.defaultFrequency
        );
      }
    });
    return this.body;
  }

  setSelectedFrequency(lists, freq) {
    this.selectedProduct.forEach((product) => {
      if (product.id === null) {
        if (product.label === "idProdukExtra") {
          this.originalBody.idProdukExtra = null;
          this.originalBody.namaProdukExtra = null;
          this.originalBody.hargaProdukExtra = null;
        }
      } else {
        const productRow = lists.find((obj) => obj.id === product.id);
        if (productRow !== undefined) {
          const row = productRow.detailFrequency.find(
            (obj) => obj.frequencyName === freq
          );
          if (productRow.category === "MAIN") {
            this.originalBody.idProdukUtama = productRow.id;
            this.originalBody.namaProdukUtama = productRow.name;
            this.originalBody.hargaProdukUtama = row.price;
          } else if (productRow.category === "EXTRA") {
            this.originalBody.idProdukExtra = productRow.id;
            this.originalBody.namaProdukExtra = productRow.name;
            this.originalBody.hargaProdukExtra = row.price;
          }
        }
      }
    });
    return this.originalBody;
  }

  setRiderSelectedFrequency(lists, freq) {
    this.originalBody.idProdukAddons = null;
    this.originalBody.namaProdukAddons = null;
    this.originalBody.hargaProdukAddons = null;
    this.originalBody.idProdukAddons2 = null;
    this.originalBody.namaProdukAddons2 = null;
    this.originalBody.hargaProdukAddons2 = null;

    for (let i = 0; i < lists.length; i++) {
      const row = lists[i].detailFrequency.find(
        (obj) => obj.frequencyName === freq
      );
      if (i === 0) {
        this.originalBody.idProdukAddons = lists[i].id;
        this.originalBody.namaProdukAddons = lists[i].name;
        this.originalBody.hargaProdukAddons = row.price;
      } else {
        this.originalBody[`idProdukAddons${i + 1}`] = lists[i].id;
        this.originalBody[`namaProdukAddons${i + 1}`] = lists[i].name;
        this.originalBody[`hargaProdukAddons${i + 1}`] = row.price;
      }
    }

    return this.originalBody;
  }

  setGroupValue(home, office) {
    let groupValue = {
      home: null,
      office: null,
    };
    if (home !== null && office !== null) {
      if (home.multiplierHospital === office.multiplierHospital) {
        groupValue.home = {
          tier: home.multiplierHospital,
          weight: 50,
        };
        groupValue.office = {
          tier: office.multiplierHospital,
          weight: 50,
        };
      } else if (home.multiplierHospital > office.multiplierHospital) {
        groupValue.home = {
          tier: home.multiplierHospital,
          weight: this.weighGroup.HIGH,
        };
        groupValue.office = {
          tier: office.multiplierHospital,
          weight: this.weighGroup.LOW,
        };
      } else if (home.multiplierHospital < office.multiplierHospital) {
        groupValue.home = {
          tier: home.multiplierHospital,
          weight: this.weighGroup.LOW,
        };
        groupValue.office = {
          tier: office.multiplierHospital,
          weight: this.weighGroup.HIGH,
        };
      }
    }
    this.weighContribution = groupValue;
  }

  filterPremis(data, age) {
    const premi = data.find((obj) => age >= obj.minAge && age <= obj.maxAge);
    if (premi === undefined) {
      return null;
    } else {
      return premi.innerLimit;
    }
  }
}

export default new ProductFilter();
