import api from "./api";

class RewardService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }
    
    getRewardById(id) {
        return this.api.get("/cms/reward/" + id);
    }

    putReward(id, serverParams) {
        return this.api.put("/cms/reward/" + id, { ...serverParams });
    }

    getRewardList(serverParams) {
        return this.api.get("/cms/reward", { params: serverParams });
    }

    postReward(serverParams) {
        return this.api.post("cms/reward", { ...serverParams });
    }

    deleteRewardById(serverParams) {
        return this.api.delete("/cms/delete/reward/" + serverParams);
    }

    // updateBmiStatus(id, serverParams) {
    //     return this.api.put("/cms/update/redeem-status/" + id, {}, { params: serverParams });
    // }
    
    // updateBmiPosition(serverParams) {
    //     return this.api.put("/cms/reposition/redeem", { ...serverParams });
    // }
}

export default new RewardService();