var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: true,
      dropdownAllowAll: false,
    },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
      enabled: false,
      initialSortBy: { field: 'lastUpdated', type: 'desc' },
    },"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
    var column = ref.column;
    var updateFilters = ref.updateFilters;
return [(
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'nama'
        )?_c('input',{ref:"nama",staticClass:"form-control",class:{
          disabled: _vm.isSearchById,
        },attrs:{"placeholder":"Search name"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.nama.value); }}}):_vm._e(),(
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'nomorSpaj'
        )?_c('input',{ref:"nomorSpaj",staticClass:"form-control",class:{
          disabled: _vm.isSearchById,
        },attrs:{"placeholder":"Search SPAJ"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.nomorSpaj.value); }}}):_vm._e(),(
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'id'
        )?_c('input',{ref:"id",staticClass:"form-control",attrs:{"placeholder":"Search id"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.id.value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(props.row.submissionStatus < 7)?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/submissions/forms/" + (props.row.id) + "/step-" + (props.row.submissionStatus)),"title":"Edit"}},[_c('i',{staticClass:"fas fa-pencil"})])],1):_vm._e(),(props.row.submissionStatus === 7)?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/submissions/forms/" + (props.row.id) + "/step-8"),"title":"View Documents"}},[_c('i',{staticClass:"fas fa-file"})])],1):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Detail Data"},on:{"click":function($event){return _vm.handleDetail(props.row.id)}}},[_c('i',{staticClass:"fas fa-eye"})])]),_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]),_c('div',{staticClass:"col-auto"},[_c('b-dropdown',{class:{
                disabled: _vm.onLoading,
              },attrs:{"variant":"outline-secondary","size":"sm"}},[_c('template',{slot:"button-content"},[_vm._v(" More "),_c('i',{staticClass:"fa fa-chevron-down"})]),(props.row.coreStatus.DMS)?_c('a',{staticClass:"dropdown-item",class:{
                  disabled:
                    _vm.onLoading ||
                    !props.row.coreStatus.DMS.error ||
                    props.row.coreStatus.DMS.error === null,
                },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.sendApi(props.row.id, 'resendDMS')}}},[_vm._v("Send API DMS")]):_vm._e(),(props.row.coreStatus.BPM)?_c('a',{staticClass:"dropdown-item",class:{
                  disabled:
                    _vm.onLoading ||
                    !props.row.coreStatus.BPM.error ||
                    props.row.coreStatus.BPM.error === null,
                },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.sendApi(props.row.id, 'resendBPM')}}},[_vm._v("Send API BPM")]):_vm._e(),(props.row.coreStatus.ECARE)?_c('a',{staticClass:"dropdown-item",class:{
                  disabled:
                    _vm.onLoading ||
                    !props.row.coreStatus.ECARE.error ||
                    props.row.coreStatus.ECARE.error === null,
                },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.sendApi(props.row.id, 'resendEcare')}}},[_vm._v("Send API E-Care")]):_vm._e()],2)],1)])]):_vm._e(),(props.column.field === 'lastUpdated')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] ? _vm.moment(props.formattedRow[props.column.field]).format( "MMMM DD YYYY, h:mm:ss a" ) : "")+" ")]):_vm._e(),(props.column.field === 'coreSystemStatus')?_c('span',[(props.row.coreStatus.BPM)?_c('div',[_c('h6',[_vm._v(" BPM ("+_vm._s(props.row.coreStatus.BPM.error ? "Error" : "Success")+") ")]),_c('ul',_vm._l((props.row.coreStatus.BPM.message),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),(props.row.coreStatus.DMS)?_c('div',[_c('h6',[_vm._v(" DMS ("+_vm._s(props.row.coreStatus.DMS.error ? "Error" : "Success")+") ")]),_c('ul',_vm._l((props.row.coreStatus.DMS.message),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),(props.row.coreStatus.ECARE)?_c('div',[_c('h6',[_vm._v(" ECARE ("+_vm._s(props.row.coreStatus.ECARE.error ? "Error" : "Success")+") ")]),_c('ul',_vm._l((props.row.coreStatus.ECARE.message),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }