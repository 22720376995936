import api from "./api";

class MenuService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getMenuList(serverParams) {
        return this.api.get("/cms/menu", { params: serverParams });
    }

    postMenu(serverParams) {
        return this.api.post("/cms/menu", { ...serverParams });
    }

    getMenuById(serverParams) {
        return this.api.get("/cms/menu/" + serverParams);
    }

    putMenu(id, serverParams) {
        return this.api.put("/cms/menu/" + id, { ...serverParams });
    }

    deleteMenuById(serverParams) {
        return this.api.delete("/cms/delete/menu/" + serverParams);
    }

    putMenuLocation(serverParams) {
        return this.api.put("/cms/update/menu-location", { ...serverParams });
    }

    getMenuLocation() {
        return this.api.get("/cms/menu-location");
    }

    deleteChildMenuById(serverParams) {
        return this.api.delete("/cms/delete/menu/child/" + serverParams);
    }
}

export default new MenuService();