<template>
  <div class="product-form">
    <FormOrganization form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" :on-loading="onProgress" />
  </div>
</template>
<script>
import FormOrganization from "./Form.vue";
import base64 from '../../Helper/base64'
import CharityService from "../../../service/charity.service";
export default {
  data() {
    return {
      onProgress: false,
      detail: {
        id: this.$route.params.id,
        title: "",
        description: "",
        website: null,
        logo: null,
      },
      tmpImageName: "",
    };
  },
  components: {
    FormOrganization,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      // console.log(payload, 'payload')
      this.onProgress = true
      if (payload) {
        let { organization, status } = payload
        let payload1 = {
          id: this.$route.params.id,
          organizationName: organization.title,
          description: organization.description.replace(/<\/?[^>]+>/ig, ""),
          websiteUrl: organization.website,
          status: status,
        }
        // console.log('before', organization.logo)
        if (organization.logo && this.tmpImageName != organization.logo.name) {
          payload1.logo = await this.logo(organization.logo);
        }
        // console.log('after', organization.logo)
        CharityService
          .putCharity(this.$route.params.id, payload1)
          .then((rsp) => {
            this.onProgress = false
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              if (status === "PUBLISHED") {
                this.$router.push({ path: '/sections/charity-organizations' })
              } else if (status === "DRAFTS") {
                this.$router.push({ path: '/sections/charity-organizations/drafts' })
              }
            });
          }).catch((e) => {
            this.$notify({
              type: 'error',
              title: 'Error Notification',
              text: e.response?.data?.responseMessage || 'Terjadi Kesalahan'
            });
          });
      }
    },
    loadItems(id) {
      CharityService
        .getCharityById(id)
        .then((rsp) => {
          this.detail = {
            id: id,
            title: rsp.data.data.organizationName,
            description: rsp.data.data.description,
            website: rsp.data.data.websiteUrl,
            logoFileUrl: rsp.data.data.logoFileUrl,
            logoFileType: rsp.data.data.logoFileType,
            logoFileName: rsp.data.data.logoFileName,
            publishDate: rsp.data.data.created,
            author: rsp.data.data.createdBy,
            lastModified: rsp.data.data.updated
          };
          this.tmpImageName = rsp.data.data.logoFileName;
          this.loadImage(rsp.data.data.logoFileName)
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || 'Terjadi Kesalahan',
          });
        })
    },
    loadImage(name) {
      CharityService
        .charityViewFile(name)
        .then(rsp => {
          this.detail.logo = new File([rsp.data], name, { type: rsp.headers['content-type'] })
        }).catch(e => {
          // console.log(e)
        })
    },
    async logo(value) {
      // this.base64
      // console.log(await base64(value))
      return await base64(value)
    }

  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
};
</script>
