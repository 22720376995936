<template>
  <div>
    <div class="alert alert-success fade show mb-0">
      <h5 class="mb-0 fw-900">
        Validasi email sukses.
        <span class="fw-normal">Lanjutkan membuat password baru.</span>
      </h5>
    </div>

    <div class="w-300px mx-auto mt-50px">
      <div class="form-group mb-3">
        <label class="form-label">Buat password</label>
        <div
          :class="{
            'is-invalid': $v.password.$invalid,
            'is-valid': !$v.password.$invalid,
          }"
          class="input-password"
        >
          <input
            v-model.trim="$v.password.$model"
            :class="{
              'is-invalid': $v.password.$invalid,
              'is-valid': !$v.password.$invalid,
            }"
            class="form-control"
            :type="passwordFieldType"
          />
          <span class="btn btn-toggle-password"
            ><i @click="showPassword" class="fas fa-eye"></i
          ></span>
        </div>
        <strong
          class="pwd-length"
          :class="{
            'invalid-feedback': !$v.password.required || !$v.password.minLength,
            'valid-feedback': $v.password.required && $v.password.minLength,
          }"
          >Minimum 8 karakter</strong
        >
        <strong
          :class="{
            'invalid-feedback':
              !$v.password.required || !$v.password.pasMinOneUpp,
            'valid-feedback': $v.password.required && $v.password.pasMinOneUpp,
          }"
          class="pwd-uppercase"
          >Minimum 1 huruf kapital</strong
        >
        <strong
          :class="{
            'invalid-feedback':
              !$v.password.required || !$v.password.pasMinOneLow,
            'valid-feedback': $v.password.required && $v.password.pasMinOneLow,
          }"
          class="pwd-lowercase"
          >Minimum 1 huruf kecil</strong
        >
        <strong
          :class="{
            'invalid-feedback':
              !$v.password.required || !$v.password.pasMinOneNum,
            'valid-feedback': $v.password.required && $v.password.pasMinOneNum,
          }"
          class="pwd-number"
          >Minimum 1 angka</strong
        >
      </div>

      <div class="form-group mb-3">
        <label class="form-label">Ketik ulang password</label>
        <div
          :class="{
            'is-invalid': $v.retypePassword.$invalid,
            'is-valid': !$v.retypePassword.$invalid,
          }"
          class="input-password"
        >
          <input
            v-model="retypePassword"
            :class="{
              'is-invalid': $v.retypePassword.$invalid,
              'is-valid': !$v.retypePassword.$invalid,
            }"
            class="form-control"
            :type="retypeFieldType"
          />
          <span class="btn btn-toggle-password"
            ><i @click="showRetypePassword" class="fas fa-eye"></i
          ></span>
        </div>
        <strong
          :class="{
            'invalid-feedback':
              !$v.retypePassword.required || !$v.retypePassword.sameAsPassword,
            'valid-feedback':
              $v.retypePassword.required && $v.retypePassword.sameAsPassword,
          }"
          class="pwd-notMatch"
          >Password tidak sama</strong
        >
      </div>

      <button
        :class="{
          disabled: $v.retypePassword.$invalid,
        }"
        class="btn btn-primary d-block w-100"
        @click="handleSubmit()"
      >
        Buat password
      </button>
    </div>

    <!-- modal -->
    <b-modal
      id="modalLupaPasswordSukses"
      centered
      no-close-on-backdrop
      content-class="modal-question"
    >
      <template slot="modal-header">
        <h4 class="modal-title">Reset Password</h4>
      </template>
      <p>{{alertMessage}}</p>
      <p v-if="!serverError">Silakan login dengan email dan password baru</p>
      <template v-if="!serverError" slot="modal-footer">
        <div class="w-100">
          <b-button variant="primary" @click="toLogin()" href="#/login"> Log in </b-button>
        </div>
      </template>
    </b-modal>

    <!-- modal -->
  </div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
import {
  minLength,
  required,
  helpers,
  sameAs,
} from "vuelidate/lib/validators/";
const pasMinOneUpp = helpers.regex("pasMinOneUpp", /(?=.*?[A-Z])/);
const pasMinOneLow = helpers.regex("pasMinOneLow", /(?=.*?[a-z])/);
const pasMinOneNum = helpers.regex("pasMinOneNum", /(?=.*?[0-9])/);
export default {
  data() {
    return {
      passwordFieldType: "password",
      retypeFieldType: "password",
      password: "",
      retypePassword: "",
      alertMessage:"",
      serverError:false
    };
  },
  methods: {
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    showRetypePassword() {
      this.retypeFieldType =
        this.retypeFieldType === "password" ? "text" : "password";
    },
    handleSubmit() {
      let data = {
        password: this.password,
        confirmPassword: this.retypePassword,
        verificationId: this.$route.params.id,
      };
      if (
        localStorage.getItem("referralEngineToken") &&
        localStorage.getItem("referralEngineRefreshToken")
      ) {
        this.$store.dispatch("auth/resetPassword", data).then(
          () => {
            this.$bvModal.show("modalLupaPasswordSukses");
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.$store.dispatch("auth/resetForgotPassword", data).then(
          () => {
            this.alertMessage = "Password berhasil dirubah"
            this.$bvModal.show("modalLupaPasswordSukses");
          },
          (error) => {
            if (error.response.data.errorDetails) {
              this.serverError = true
              this.alertMessage = error.response.data.errorDetails[0]
              this.password = ""
              this.retypePassword = ""
              this.$v.$reset()
              this.$bvModal.show("modalLupaPasswordSukses");
            }
          }
        );
      }
    },
    toLogin() {
      if (
        localStorage.getItem("accessToken") &&
        localStorage.getItem("refreshToken")
      ) {
        this.$store.dispatch("auth/logout").then(
          (response) => {
            if (response.statusCode === 200) {
              this.$router.push("/login");
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.$router.push("/login");
      }
    },
  },
  created() {
    AppOptions.appSidebarNone = true;
    AppOptions.appNavbarNotification = false;
    AppOptions.appNavbarUser = false;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appSidebarNone = false;
    AppOptions.appNavbarNotification = true;
    AppOptions.appNavbarUser = true;
    next();
  },
  validations: {
    password: {
      required,
      pasMinOneUpp,
      pasMinOneLow,
      pasMinOneNum,
      minLength: minLength(8),
    },
    retypePassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>
