import api from "./api";

class SumInsuranceService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }
    
    getSumInsuranceList(serverParams) {
        return this.api.get("product/sumInsurance", { params: serverParams });
    }

    postSumInsurance(serverParams) {
        return this.api.post("product/sumInsurance", { ...serverParams });
    }

    getSumInsuranceById(serverParams) {
        return this.api.get("product/sumInsurance/" + serverParams);
    }

    putSumInsurance(id, serverParams) {
        return this.api.put("product/sumInsurance/" + id, { ...serverParams });
    }

    deleteSumInsuranceById(serverParams) {
        return this.api.delete("product/delete/sumInsurance/" + serverParams);
    }
}

export default new SumInsuranceService();