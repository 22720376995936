<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionTwo"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Apa Itu Amore Health"
        v-model="value.sectionTitle"
      />
    </div>
    <panel
      v-for="(item, index) in value.pageSubSections"
      :key="index"
      :title="`Item ${index + 1}`"
      variant="grey"
      :is-collapse="true"
      :XXcheckbox-hidden="true"
      :show-collapse="index > 0 ? false : true"
      v-model="item.isShow"
      :checkbox-name="`sliderItem-${index}`"
    >
      <div class="mb-15px">
        <label class="form-label">Title</label>
        <input
          type="text"
          class="form-control"
          placeholder="Apa Itu Amore Health"
          v-model="item.subSectionTitle"
        />
      </div>
      <div class="mb-15px">
        <vue-editor
          v-model="item.content"
          :editor-toolbar="$options.defaultToolbar"
        />
      </div>
      <label class="form-label">Icon</label>
      <b-form-file
        @input="handleIconUpload(item, $event)"
        :placeholder="item.iconFileName"
        accept="image/*"
        :multiple="false"
      ></b-form-file>
      <p class="mb-0 mt-1 small">64px x 64px .png file</p>
    </panel>
    <div class="mb-15px">
      <label class="form-label">CTA Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="CTA Title"
        v-model="value.ctaTitle"
      />
    </div>
    <label class="form-label">CTA Link Page</label>
    <input type="url" class="form-control" v-model="value.ctaLinkPage" />
  </panel>
</template>
<script>
import { defaultToolbar } from "@/config/Helpers";
import base64Converter from "@/pages/Helper/base64";

export default {
  defaultToolbar,
  name: "HORIZONTAL_SLIDER",
  title: "Horizontal Slider",
  props: ["value"],
  methods: {
    async handleIconUpload(item, val) {
      if (val) {
        item.icon = await base64Converter(val);
      }
    },
  },
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>