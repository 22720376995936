<template>
  <div>
    <h1 class="page-header"><span v-if="isEdit">Edit</span> Product Category</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <form @submit.prevent="doSave">
              <div class="row align-items-end">
                <div class="col">
                  <label class="form-label"><span v-if="!isEdit">Add</span><span v-else>Edit</span> Category
                    Name</label>
                  <input type="text" class="form-control" placeholder="Add New Category Name" v-model="category" />
                </div>
                <div class="col">
                  <label class="form-label">Group</label>
                  <v-select :options="optionParent" placeholder="Select Group" label="name"
                    v-model="categoryParent"></v-select>
                </div>
                <div class="col-auto" v-if="$can($permissionsAll[14]) && !isEdit">
                  <button type="submit" class="btn btn-outline-primary">
                    <span>Add</span></button>
                </div>
                <div class="col-auto" v-else-if="$can($permissionsAll[15]) && isEdit">
                  <button type="submit" class="btn btn-outline-primary">
                    <span>Save</span></button>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-outline-primary" :disabled="!isEdit" @click.prevent="onCancel()">
                    Cancel
                  </button>
                </div>
              </div>
              <br v-if="!$v.category.$error">
              <span class="text-danger" v-if="$v.category.$error">Category Name
                required</span>
            </form>
          </div>
        </div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
      enabled: true,
      dropdownAllowAll: false,
    }" :columns="columns" :rows="rows" :sort-options="{
      enabled: false,
      initialSortBy: { field: 'categoryName', type: 'asc' },
    }" styleClass="vgt-table" slot="outsideBody">
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <input ref="categoryName" class="form-control" placeholder="Search category" v-if="column.filterOptions &&
      column.filterOptions.customFilter &&
      column.field === 'categoryName'
      " @input="(value) => updateFilters(column, $refs.categoryName.value)" />
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <div class="row g-2 align-items-center justify-content-end">
                <div class="col-auto" v-if="$can($permissionsAll[15])">
                  <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)" v-b-tooltip.hover
                    title="Edit">
                    <i class="fas fa-pencil"></i>
                  </button>
                </div>
                <div class="col-auto" v-if="$can($permissionsAll[16])">
                  <button type="button" @click.prevent="showAlertConfirm(props.row.id)" class="btn btn-lg text-dark"
                    v-b-tooltip.hover title="Delete">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import productCategoryService from "@/service/productcategory.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      id: "",
      category: "",
      isEdit: false,
      isLoading: false,
      columns: [
        {
          label: "Category Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Group",
          field: "productCategoryGroup",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10
      },
      payload: {
        code: "",
        name: "",
        productCategoryGroup: ""
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      optionParent: [],
      categoryParent: ''
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ pageNumber: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageNumber: 0 });
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: 'name'
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      if (
        params.columnFilters['parent.name'] != "" ||
        params.columnFilters.name != ""
      ) {
        this.updateParams({
          name: params.columnFilters.name,
        });
      } else {
        let { name, category, ...data } = this.serverParams;
        this.serverParams = data;
      }
      this.loadItems();
    },
    loadItems() {
      productCategoryService
        .getProductCategoryList(this.serverParams)
        .then(async (rsp) => {
          let arrData = rsp.data?.data ? rsp.data?.data.content : [];
          let rowsNew = []
          for (let i = 0; i < arrData.length; i++) {
            let e = arrData[i];
            rowsNew.push({
              ...e,
              parent: e.idProductCategory
            })

            if (e.idParentProductCategory != null &&
              typeof e.idParentProductCategory === 'string') {
              rowsNew[i].parent = await this.loadPerParentCategory(rowsNew, e)
            }
          }
          this.rows = rowsNew
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    doSave() {
      const payload = {
        category: this.category,
      };

      // console.log(this.categoryParent, 'payload')
      this.$v.category.$touch();
      if (!this.$v.category.$invalid) {
        if (this.isEdit) {
          if (this.categoryParent) {
            this.payload.productCategoryGroup = this.categoryParent
          } else {
            this.payload.productCategoryGroup = null
          }
          this.payload.id = this.id
          this.payload.name = this.category
          this.payload.code = this.category
          productCategoryService
            .putProductCategory(this.id, this.payload)
            .then((rsp) => {
              this.$notify({
                type: 'succes',
                title: 'Succes Notification',
                text: 'Success Update'
              });
              this.$v.category.$reset();
              this.loadItems()
              this.loadParent()
              this.categoryParent = ''
              this.category = ''
              this.id = ''
              this.isEdit = false
            }).catch((e) => {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
            })
        } else {
          if (this.categoryParent != '') {
            this.payload.productCategoryGroup = this.categoryParent
          }

          this.payload.name = this.category
          this.payload.code = this.category
          productCategoryService
            .postProductCategory(this.payload)
            .then((rsp) => {
              this.$notify({
                type: 'succes',
                title: 'Succes Notification',
                text: 'Success Add'
              });
              this.$v.category.$reset();
              this.loadItems()
              this.loadParent()
              this.categoryParent = ''
              this.category = ''
              this.id = ''
            }).catch((e) => {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
            })
        }
      }
    },

    async doEdit(data) {
      this.isEdit = true
      this.categoryParent = data.productCategoryGroup
      this.category = data.name
      this.id = data.id
    },

    onDelete(id) {
      console.log(id)
      productCategoryService.deleteProductCategoryById(id).then((rsp) => {
        this.$notify({
          type: "succes",
          title: "Success message",
          text: "Delete Success",
        });
        this.loadItems();
      }).catch((e) => {
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
        });
        this.loadItems();
      });
    },
    loadParent() {
      productCategoryService.getProductParentCategoryList(this.serverParams)
        .then((rsp) => {
          this.optionParent = rsp.data?.data ? rsp.data.data : [];
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data1",
          });
        });
    },
    loadPerParentCategory(index, data) {
      return productCategoryService
        .getProductCategoryById(data.idParentProductCategory)
        .then((rsp) => {
          return rsp.data?.data
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    loadPerCategory(id) {
      return productCategoryService
        .getProductCategoryById(id)
        .then((rsp) => {
          return rsp.data.data
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    onCancel() {
      this.isEdit = false
      this.categoryParent = ''
      this.category = ''
      this.id = ''
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },
  },
  mounted() {
    this.loadItems();
    this.loadParent();
  },

  validations: {
    category: { required },
    categoryParent: { required }
  }
};
</script>
