import api from "./api";

class RegistrationService {
  constructor() {
    this.api = api.create("web");
  }

  getRegistrationCustomer(serverParams) {
    return this.api.get("/customer", { params: serverParams });
  }
  getSubmissionDashboard(serverParams) {
    return this.api.get("/business/analytics", { params: serverParams });
  }
  getSubmitionList(serverParams) {
    return this.api.get("cms/submissions", { params: serverParams });
  }
  deleteSubmitionId(id) {
    return this.api.delete(`cms/submission/delete/${id}`);
  }
  getProfesiList(serverParams) {
    return this.api.get("cms/profesi", { params: serverParams });
  }
  getSingleSubmission(id) {
    return this.api.get(`cms/submission/${id}`);
  }
  createPage(page) {
    return this.api.post("registration/submission", page).then((response) => {
      return response.data;
    });
  }
  registrationUpdate(id, data) {
    return this.api.put(`submission/step-first/${id}`, data);
  }
  hospitalUpdate(id, data) {
    return this.api.put(`submission/step-second/${id}`, data);
  }
  stepThirdUpdate(id, data) {
    return this.api.put(`submission/step-fourth/${id}`, data);
  }
  stepFifthUpdate(id, data) {
    return this.api.put(`submission/step-fifth/${id}`, data);
  }
  stepSeventhUpdate(id, data) {
    return this.api.put(`submission/step-seventh/${id}`, data);
  }
  getPaymentLink(id, data) {
    return this.api.get(`cms/submission/getPayment/${id}`, { params: data });
  }
  getPaymentHistory(id) {
    return this.api.get(`cms/submission/getPayment/${id}`);
  }
  getCsvData(data){
    return this.api.get("cms/submission/csv", { params: data });
  }
}

export default new RegistrationService();
