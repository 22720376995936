<template>
  <!-- begin sidebar nav -->
  <div class="menu" v-if="menus">
    <div class="menu-search mb-n3" v-if="appOptions.appSidebarSearch">
      <input type="text" v-on:keyup="handleSidebarFilter" class="form-control" placeholder="Sidebar menu filter..." />
    </div>
    <template v-for="(menu, index) in menus">
      <sidebar-nav-list ref="sidebarNavList" v-bind:menu="menu" v-bind:scrollTop="scrollTop"
        v-bind:key="`${menu.path}-${index}`" v-bind:status="menu.status" v-on:collapse-other="handleCollapseOther(menu)"
        v-on:show-float-submenu="handleShowFloatSubmenu"
        v-on:hide-float-submenu="handleHideFloatSubmenu"></sidebar-nav-list>
    </template>

    <!-- BEGIN minify-button -->
    <div class="menu-item d-flex">
      <a href="javascript:;" class="app-sidebar-minify-btn ms-auto" v-on:click="handleSidebarMinify()"><i
          class="fa fa-angle-double-left"></i></a>
    </div>
    <!-- END minify-button -->
  </div>
  <!-- end sidebar nav -->
</template>

<script>
import { jwtDecode } from 'jwt-decode';
import SidebarMenu from "./SidebarMenu.vue";
import SidebarNavList from "./SidebarNavList.vue";
import AppOptions from "../../config/AppOptions.vue";

function filterMenu(menu) {
  const value = localStorage.getItem('token');
  const decoded = jwtDecode(value)
  let arr = JSON.parse(decoded.permissions);
  arr = arr.map(val => val.name)
  console.log(decoded.permissions)

  if (menu.permission) {
    /**
     * existing
     */
    // if (!$can(menu.permission)) {
    //   return false;
    // }

    /**
     * new
     */
    if (!arr.includes(menu.permission)) {
      return false;
    }
  }

  if (Array.isArray(menu.children)) {
    /**
     * existing
     */
    // menu.children = menu.children.filter(filterMenu);

    /**
     * new
     */
    menu.children = menu.children.filter(val => arr.includes(val.permission));
    if (menu.children.length == 0) {
      console.debug('Excluding menu because children is empty', menu)
      return false;
    }
  } 
  return true;
}

export default {
  name: "SidebarNav",
  props: ["scrollTop"],
  components: {
    SidebarNavList,
  },
  watch: {
    "$store.state.auth.user": {
      immediate: true,
      handler() {
        console.debug('Recalculate menu');
        this.menus = SidebarMenu.filter(filterMenu)
      }
    }
  },
  data() {
    return {
      menus: [],
      appOptions: AppOptions,
      decodePermission: [],
      roleName: ''
    };
  },
  mounted() {
    this.roleName = this.$store.state?.auth.user?.user?.role?.name.toLowerCase();
  },
  methods: {
    handleShowFloatSubmenu: function (menu, offset) {
      this.$emit("show-float-submenu", menu, offset);
    },
    handleHideFloatSubmenu: function () {
      this.$emit("hide-float-submenu");
    },
    handleCollapseOther: function (menu) {
      for (var i = 0; i < this.menus.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu);
      }
    },
    handleSidebarMinify: function () {
      this.appOptions.appSidebarMinified = !this.appOptions.appSidebarMinified;
    },
    handleSidebarFilter: function (e) {
      var value = e.target.value;
      value = value.toLowerCase();

      if (value) {
        for (var x = 0; x < this.menus.length; x++) {
          var title = this.menus[x].title.toLowerCase();
          var children = this.menus[x].children;

          if (title.search(value) > -1) {
            this.$refs.sidebarNavList[x].show();

            if (children) {
              this.$refs.sidebarNavList[x].searchExpand();
            }
          } else {
            if (children) {
              var hasActive = false;
              for (var y = 0; y < children.length; y++) {
                var title2 = children[y].title.toLowerCase();

                if (title2.search(value) > -1) {
                  hasActive = true;
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show();
                  this.$refs.sidebarNavList[x].searchExpand();
                } else {
                  if (hasActive) {
                    this.$refs.sidebarNavList[x].searchExpand();
                  } else {
                    this.$refs.sidebarNavList[x].hide();
                  }
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide();
                }
              }
            } else {
              this.$refs.sidebarNavList[x].hide();
            }
          }
        }
      } else {
        for (var a = 0; a < this.menus.length; a++) {
          this.$refs.sidebarNavList[a].show();

          var submenu = this.menus[a].children;
          if (submenu) {
            for (var b = 0; b < submenu.length; b++) {
              this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show();
            }
          }
        }
      }
      // console.log("------");
    },
  },
};
</script>
