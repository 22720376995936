<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionSeven"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Testimoni"
        v-model="value.sectionTitle"
      />
    </div>
    <panel
      v-for="(item, index) in value.pageSubSections"
      :key="index"
      :title="`Testimoni ${index + 1}`"
      variant="grey"
      :is-collapse="true"
      :XXcheckbox-hidden="true"
      :show-collapse="index > 0 ? false : true"
      v-model="item.isShow"
      :checkbox-name="`testimoniItem-${index}`"
    >
      <div class="mb-15px">
        <label class="form-label">Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Massimo Vignelli"
          v-model="item.subSectionName"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-15px">
            <label class="form-label">Product</label>
            <input
              type="text"
              class="form-control"
              placeholder="Inner Plan Limit 500"
              v-model="item.product"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-15px">
            <label class="form-label">Rating</label>
            <input
              type="text"
              class="form-control"
              placeholder="Apa Itu Amore Health"
              v-model="item.rating"
            />
          </div>
        </div>
      </div>
      <vue-editor v-model="item.content" :editor-toolbar="$options.defaultToolbar" />
      <div class="mb-15px">
        <label class="form-label">Foto Profile</label>
        <b-form-file
          @input="handleIconUpload(item, $event)"
          :placeholder="item.iconFileName"
          accept="image/*"
          :multiple="false"
        ></b-form-file>
        <p class="mb-0 mt-1 small">64px x 64px .png file</p>
      </div>
    </panel>
  </panel>
</template>
<script>
import { defaultToolbar } from "@/config/Helpers"
import base64Converter from "@/pages/Helper/base64";

export default {
  defaultToolbar,
  name: "TESTIMONI",
  title: "Testimoni",
  props: ["value"],
  methods: {
    async handleIconUpload(item, val) {
      if (val) {
        item.icon = await base64Converter(val);
      }
    },
  },
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>