<template>
    <form @submit.prevent="doSave" class="pb-5 mb-4" autocomplete="off">
        <div class="row">
            <div class="col-12">
                <h1 class="page-header">{{ formTitle }} Redeem</h1>
            </div>
            <!-- <div class="col-lg-9 col-md-8">
                <div class="mb-15px">
                    <input type="text" class="form-control form-control-lg" placeholder="Title" v-model="redeem.title"
                        name="username" autocomplete="off" value="" />
                    <span class="text-danger" v-if="$v.redeem.title.$error">Title Redeem is required</span>
                </div>
            </div> -->
        </div>
        <div class="panel col-lg-9">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Redeem Code</label>
                        <!-- <input type="text" class="form-control" placeholder="Redeem Code" v-model="redeem.code"
                            name="username" autocomplete="off" value="" maxlength="10" /> -->
                        <input type="text" class="form-control" placeholder="Redeem Code" v-model="redeem.codeRedeem"
                            name="codeRedeem" autocomplete="off" value="" />
                        <span class="text-danger" v-if="$v.redeem.codeRedeem.$error">Code is
                            required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Quantities</label>
                        <input type="number" class="form-control" placeholder="Quantities" v-model="redeem.quantity"
                            name="quantity" autocomplete="off" value="" />
                        <span class="text-danger" v-if="$v.redeem.quantity.$error">Quantity is
                            required</span>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Keterangan</label>
                        <textarea rows="5" class="form-control" placeholder="Keterangan" v-model="redeem.description" />
                        <span class="text-danger" v-if="$v.redeem.description.$error">Code is
                            required</span>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Expired Date</label>
                        <input type="date" v-model="redeem.expiredDate" class="form-control" :min="minExpirationDate"/>
                        <span class="text-danger" v-if="!$v.redeem.expiredDate.required && $v.redeem.expiredDate.$error">Expired Date required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Status</label>
                        <div class="pt-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="status" id="status1" value="ACTIVE"
                                    v-model="redeem.status" />
                                <label class="form-check-label" for="">Active</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="status" id="status2" value="INACTIVE"
                                    v-model="redeem.status" />
                                <label class="form-check-label" for="">Inactive</label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="!$v.redeem.status.required && $v.redeem.status.$error">Status required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Nominal Type</label>
                        <div class="pt-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeRedeem" id="typeRedeem1" value="CASHBACK"
                                    v-model="redeem.typeRedeem" />
                                <label class="form-check-label" for="">Cashback</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeRedeem" id="typeRedeem2" value="DISCOUNT"
                                    v-model="redeem.typeRedeem" />
                                <label class="form-check-label" for="">Discount</label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="!$v.redeem.typeRedeem.required && $v.redeem.typeRedeem.$error">Redeem type required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Nominal Type</label>
                        <div class="pt-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeNominal" id="typeNominal1" value="RUPIAH"
                                    v-model="redeem.typeNominal" />
                                <label class="form-check-label" for="">Rupiah</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeNominal" id="typeNominal2" value="PERCENT"
                                    v-model="redeem.typeNominal" />
                                <label class="form-check-label" for="">Percent</label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="!$v.redeem.typeNominal.required && $v.redeem.typeNominal.$error">Nominal type required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Nominal</label>
                        <input type="number" class="form-control" placeholder="Nominal" v-model="redeem.nominal"
                            name="nominal" autocomplete="off" value="" />
                        <span class="text-danger" v-if="$v.redeem.nominal.$error">Nominal is
                            required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Remark</label>
                        <input type="text" class="form-control" placeholder="remark" v-model="redeem.remark"
                            name="remark" autocomplete="off" value="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-action">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
                        <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
export default {
    props: {
        formType: {
            type: String,
            default: "add",
        },
        detailData: {
            type: Object,
            default: null,
        },
        onLoading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Menambahkan 1 hari ke tanggal hari ini
        this.minExpirationDate = today.toISOString().split('T')[0]; // format tanggal ISO (YYYY-MM-DD)
    },
    data() {
        return {
            redeem: {
                codeRedeem: null,
                quantity: null,
                expiredDate: null,
                status: null,
                typeRedeem: null,
                typeNominal: null,
                nominal: null,
                remark: null
            },
            minExpirationDate: ''
        };
    },

    watch: {
        detailData: {
            immediate: true,
            handler(newVal) {
                this.redeem = newVal
            },
        }
    },
    computed: {
        formTitle() {
            return this.formType === "add" ? "Add New" : "Edit";
        }
    },
    methods: {
        doSave() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const payload = {
                    redeem: this.redeem
                };

                this.$emit("onValidateSuccess", payload);
            }
        },
    },
    validations: {
        redeem: {
            codeRedeem: { required },
            quantity: { required },
            expiredDate: { required },
            status: { required },
            typeRedeem: { required },
            typeNominal: { required },
            nominal: { required },
        }
    },
};
</script>