const sectionCtx = require.context('./', false, /\.vue$/, 'sync');
const sections = {}

sectionCtx.keys().forEach(
    id => {
        const component = sectionCtx(id).default
        sections[component.alias || component.name] = component
    }
)

// Alias
sections.SYARAT = sections.PARAGRAPH
sections.TEXT = sections.PARAGRAPH

export default sections