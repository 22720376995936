<template>
  <div>
    <component
      v-for="(v, k) of page.pageSections"
      :is="getComponent(v.sectionType)"
      :key="k"
      :value="v"
      ref="sections"
    />
  </div>
</template>

<script>
import sections from "./../sections";
const dummyContent = `<p>Pergantian biaya dengan pilihan antara Silver Plan: kamar lebih dari 2 tempat tidur/ Rp 500 Ribu per hari atau Gold Plan: kamar dengan 2 tempat tidur terendah / Rp 750 Ribu</p>`;
const initalData = [
  {
    sectionType: "BANNER", // or BANNER ?
    sectionName: "one",
    sequence: 1,
    isShow: true,
    sectionTitle: "Produk Kami",
    content: "<p>Asuransi Jiwa, perlindungan ganda jiwa dan raga.</p>",

    // isShow: true,
    // title: "",
    // content: "",
    // status: "",
    // images: {
    //   desktop: null,
    //   desktopSrc: "",
    //   mobile: null,
    //   mobileSrc: "",
    // },
  },
  {
    sectionType: "PRODUCT",
    sectionName: "two",
    sequence: 2,
    isShow: true,
    sectionTitle: "Plan Dasar",
    content: dummyContent,
    // title: "",
    // content: "",
  },
  {
    sectionType: "LIST_PRODUCT",
    sectionName: "three",
    sectionTitle: "Keunggulan dan Manfaat",
    sequence: 3,
    isShow: true,
    // title: "",
    pageSubSections: [
      {
        isShow: true,
        sequence: 1,
        subSectionName: "product-list-1",
        subSectionTitle: "Harga Terjangkau",
      },
      {
        isShow: true,
        sequence: 2,
        subSectionName: "product-list-2",
        subSectionTitle: "Ratusan Pilihan Rumah Sakit",
      },
      {
        isShow: true,
        sequence: 3,
        subSectionName: "product-list-3",
        subSectionTitle: "Ratusan Pilihan Rumah Sakit",
      },
      {
        isShow: true,
        sequence: 4,
        subSectionName: "product-list-4",
        subSectionTitle: "Rumah Sakit Sesuai Daerah Pilihan Anda",
      },
      {
        isShow: true,
        sequence: 5,
        subSectionName: "product-list-5",
        subSectionTitle: "Booster Untuk Menunjang Kesehatan",
      },
      {
        isShow: true,
        sequence: 6,
        subSectionName: "product-list-6",
        subSectionTitle: "Menjaga Stabilitas Kesehatan",
      },
    ],
  },
  {
    sectionType: "PDF",
    sectionName: "four",
    sectionTitle: "Download informasi selengkapnya disini",
    sequence: 4,
    isShow: true,
    // title: "",
    // sequence: 0,
    // cta: {
    //   title: "",
    //   link: "",
    // },
    // contentUrl: "",
  },
  {
    sectionType: "KALKULATOR",
    sectionName: "five",
    sectionTitle: "Kalkulator Premi",
    sectionSubTitle: "Hitung simulasi Anda dan dapatkan pelayanan terbaik!",
    sequence: 5,
    isShow: true,
  },
];

export default {
  props: ["page"],
  watch: {
    page: {
      immediate: true,
      handler(v) {
        if (!this.page.id && !v.pageSections?.length) {
          this.$nextTick(() => {
            v.pageSections = JSON.parse(JSON.stringify(initalData));
          });
        }
      },
    },
  },
  methods: {
    getComponent(type) {
      if (type == "PRODUCT") {
        // Product section in Home and Product Template is differs
        return sections.PRODUCT_CONTENT;
      }
      return sections[type] || sections.UNKNOWN;
    },
    invalid() {
      return false;
    },
  },
};
</script>