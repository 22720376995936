<template>
  <!-- <form @submit.prevent="doSave" class="pb-5 mb-4"> -->
  <form class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} Hospital</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Hospital Name"
            v-model="hospital.name"
            @keyup="checkingName()"
          />
          <span
            class="text-danger"
            v-if="!$v.hospital.name.required && $v.hospital.name.$error"
            >Hospital Name is required</span
          >
          <span class="text-danger" v-if="!isNameAvailable">{{
              errorMessage.name
            }}</span>
        </div>
        <div class="row">
          <div class="col-md-9">
            <div class="panel">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Hospital Tier</label>
                       <!-- <v-select
                        v-model="hospital.tier"
                        :options="paginatedHospitalTier.content"
                        :reduce="(option) => option.id"
                        :filterable="false"
                         @input="hospitalTierSelected()"
                        @search="searchTier"
                      > -->
                      <v-select
                        v-model="hospital.hospitalTierId"
                        :options="paginatedHospitalTier.content"
                        :reduce="(option) => option.id"
                        :filterable="false"
                        @open="openSelectHospitalTier"
                        @search="searchHospitalTier"
                        @option:selected="selectedHospitalTierToTemporary"
                      >
                        <li slot="list-footer" class="pagination">
                            <button
                              :disabled="!hasTierPrevPage"
                              @click.prevent="handlePrevProv('tier')"
                            >
                              Prev
                            </button>
                            <button
                              :disabled="!hasTierNextPage"
                              @click.prevent="handleNextProv('tier')"
                            >
                              Next
                            </button>
                        </li>
                      </v-select>
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.hospitalTierId.required && $v.hospital.hospitalTierId.$error
                        "
                        >Hospital Tier is required</span
                      >
                    </div>
                     
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Multiplier</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Multiplier"
                        v-model="hospital.multiplier"
                        readonly
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-15px">
                      <label class="form-label">Map</label>
                      <div class="mb-15px">
                        <div class="input-group mb-3">
                          <GmapAutocomplete
                            @place_changed="setPlace"
                            @focus="onFocus = true"
                            style="width: 100%; height: 35px"
                            class="form-control pac-target-input"
                          />
                          <!-- <input
                            type="text"
                            class="form-control border-end-0"
                            placeholder="Map"
                          />
                          <span class="input-group-text bg-white border-start-0"
                            ><i class="fas fa-search"></i
                          ></span> -->
                        </div>
                      </div>
                      <div class="position-relative">
                        <div class="card customer-map">
                          <GmapMap
                            :center="myCoordinates"
                            :zoom="zoom"
                            style="width: 100%; height: 400px"
                            ref="mapRef"
                           
                          >
                            <GmapMarker
                              v-if="
                                hospital.position.lat === null &&
                                hospital.position.lng === null
                              "
                              :position="myCoordinates"
                              :icon="icon"
                              :clickable="true"
                              :draggable="true"
                              @drag="handleDrag"
                              
                            />
                            <GmapMarker
                              v-if="
                                hospital.position.lat !== null &&
                                hospital.position.lng !== null
                              "
                              :position="hospital.position"
                              :icon="icon"
                              :clickable="true"
                              :draggable="true"
                              @drag="handleDrag"
                            />

                            <gmap-info-window
                              :options="{
                                maxWidth: 300,
                                pixelOffset: { width: 0, height: -35 },
                              }"
                              :position="infoWindow.position"
                              :opened="infoWindow.open"
                              @closeclick="infoWindow.open = false"
                            >
                              <div v-html="infoWindow.template"></div>
                            </gmap-info-window>
                          </GmapMap>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Latitude</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Latitude"
                        v-model="hospital.position.lat"
                        readonly
                        disabled
                      />
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.position.lat.required &&
                          $v.hospital.position.lat.$error
                        "
                        >Location must be selected</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Longitude</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Longitude"
                        v-model="hospital.position.lng"
                        readonly
                        disabled
                      />
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.position.lng.required &&
                          $v.hospital.position.lng.$error
                        "
                        >Location must be selected</span
                      >
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-15px">
                      <label class="form-label">Address</label>
                      <textarea
                        rows="5"
                        class="form-control"
                        placeholder="Address"
                        v-model="hospital.address"
                      />
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.address.required &&
                          $v.hospital.address.$error
                        "
                        >Address is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Province</label>
                      <!--  @option:selected="selectedProvince" -->
                        <v-select
                          v-model="hospital.province"
                          :options="paginatedProvince.content"
                          :reduce="(option) => option.id"
                          :filterable="false"
                          @input="provinceSelected"
                          @open="openSelectProvince"
                          @search="searchProvince"
                          @option:selected="selectedProvToTemporary"
                        >
                          <li slot="list-footer" class="pagination">
                            <button
                              :disabled="!hasProvPrevPage"
                              @click.prevent="handlePrevProv('province')"
                            >
                              Prev
                            </button>
                            <button
                              :disabled="!hasProvNextPage"
                              @click.prevent="handleNextProv('province')"
                            >
                              Next
                            </button>
                          </li>
                        </v-select>
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.province.required &&
                          $v.hospital.province.$error
                        "
                        >Province is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Region/City</label>
                        <v-select
                          v-model="hospital.city"
                          :options="paginatedCity.content"
                          :reduce="(option) => option.id"
                          :filterable="false"
                          @input="citySelected"
                          @open="openSelectCity"
                          @search="searchCity"
                          @option:selected="selectedCityToTemporary"
                        >
                        <li slot="list-footer" class="pagination">
                            <button
                              :disabled="!hasCityNextPage"
                              @click.prevent="handlePrevProv('city')"
                            >
                              Prev
                            </button>
                            <button
                              :disabled="!hasCityPrevPage"
                              @click.prevent="handleNextProv('city')"
                            >
                              Next
                            </button>
                          </li>
                        </v-select>
                      <span
                        class="text-danger"
                        v-if="!$v.hospital.city.required && $v.hospital.city.$error"
                        >City is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                     <label class="form-label">District</label>
                        <v-select
                          v-model="hospital.district"
                          :options="paginatedDistrict.content"
                          :reduce="(option) => option.id"
                          :filterable="false"
                          @open="openSelectDistrict"
                          @search="searchDistrict"
                          @option:selected="selectedDistrictToTemporary"
                        >
                          <li slot="list-footer" class="pagination">
                            <button
                              :disabled="!hasDistrictPrevPage"
                              @click.prevent="handlePrevProv('district')"
                            >
                              Prev
                            </button>
                            <button
                              :disabled="!hasDistrictNextPage"
                              @click.prevent="handleNextProv('district')"
                            >
                              Next
                            </button>
                          </li>
                        </v-select>
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.district.required &&
                          $v.hospital.district.$error
                        "
                        >District is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Postal Code</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Postal Code"
                        v-model="hospital.postalCode"
                      />
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.postalCode.required &&
                          $v.hospital.postalCode.$error
                        "
                        >Postal Code is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Phone Number</label>
                      <input
                        type="tel"
                        class="form-control"
                        placeholder="Phone Number"
                        v-model="hospital.phoneNumber"
                      />
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.phoneNumber.required &&
                          $v.hospital.phoneNumber.$error
                        "
                        >Phone Number is required</span
                      >
                      <span
                        class="text-danger"
                        v-if="
                          !$v.hospital.phoneNumber.phoneRx &&
                          $v.hospital.phoneNumber.$error
                        "
                        >Wrong number format {{ $v.hospital.phoneNumber.phoneRx}}</span
                      >
                       <!-- <span
                        class="text-danger"
                        >Wrong number format {{ $v.hospital.phoneNumber.phoneRx}}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end" v-if="hospital.status !== 'PUBLISHED'">
        <div class="col-auto">
          <button type="submit" class="btn btn-outline-primary"  @click.prevent="doSave('DRAFTS')">
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary"  @click.prevent="doSave('PUBLISHED')">
            Publish
          </button>
        </div>
      </div>
      <div class="row justify-content-end" v-if="hospital.status === 'PUBLISHED'">
        <div class="col-auto">
          <button type="submit" class="btn btn-outline-primary"  @click.prevent="doSave('PUBLISHED')">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import regionalService from '@/service/regional.service';
import { required, helpers, requiredIf } from "vuelidate/lib/validators";
import hospitalService from "../../service/hospital.service";
const phoneRx = helpers.regex(
  "phoneRx",
  // /^(\()?(\+62|62|0|\+0287|0287)(\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}$/g
  /^(\()?(\+62|62|0|\+0287|0287|\(\+0287\)|\(0287\))(\d+)$/
);
export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    //this.$refs.mapRef.$mapPromise.then((map) => (this.map = map));
  },
  data() {
    //console.log('this.detailData: ', this.detailData)
    //const detail = this.detailData;
    // console.log('tier : ', detail.tier)
    return {
      setProvPlaceSearch: false,
      setCityPlaceSearch: false,
      setDistrictPlaceSearch: false,
      searchMapData: {
        provinceSetPlace: "",
        citySetPlace: "",
        districtSetPlace: "",
      },
      saveAsDraft: 'Save As Draft',
      publish: 'Publish',
      publish: 'Publish',
      timer: null,
      isNameAvailable: true,
      errorMessage: {
        name: "",
      },
      onSearchProvince: false,
      onSearchCity: false,
      onSearchDistrict: false,
     // formInProgressProvince: false,
      optionsTier: ["A", "B", "C"],
      optionsProvince: [
        "Jakarta",
        "Jawa Barat",
        "Jawa Tengah",
        "Jawa Timur",
        "Bali",
      ],
      optionsCity: ["Jakarta Pusat", "Jakarta Barat", "Jakarta Selatan"],
      optionsDistrict: ["Jakarta", "Kebon Jeruk", "Cengkareng"],
      hospital: {
        name: null,
        hospitalTierId: null,
        multiplier: null,
        //map: null,
        position: {
          // lat: detail ? detail.latitude : null,
          // lng: detail ? detail.longitude : null,
          lat: null,
          lng:  null,
        },
        address: null,
        province: null,
        city: null,
        district: null,
        postalCode: null,
        phoneNumber: null,
        status: null,
      },
      // Maps
      icon: "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
      markers: [],
     // map: null,
      myCoordinates: {
        lat: 0,
        lng: 0,
      },
      onFocus: false,
      zoom: 10,
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },
      autoComplete: "",
      // End Maps

      provServerParamsProvince: {
        // searchField: 'name',
        // searchValue: '',
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsCity: {
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsDistrict: {
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsHospitalTier: {
        pageSize: 10,
        pageNumber: 0,
      },
      provSelected: {},
      citySelectedData: {},
      districtSelectedData: {},
      hospitalTierSelectedData: {},
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add" : "Edit";
    },
    // mapCoordinates() {
    //   if (!this.map) {
    //     return {
    //       lat: 0,
    //       lng: 0,
    //     };
    //   }
    //   return {
    //     lat: this.map.getCenter().lat().toFixed(4),
    //     lng: this.map.getCenter().lng().toFixed(4),
    //   };
    // },
    filtered() {
      return this.optionsProvince.filter((country) =>
        country.includes(this.search)
      );
    },
    paginatedProvince() {
      return this.$store.getters["regional/getProvince"];
    },
    paginatedCity() {
      return this.$store.getters["regional/getCity"];
    },
    paginatedDistrict() {
      return this.$store.getters["regional/getDistrict"];
    },
    paginatedHospitalTier() {
      return this.$store.getters["hospital/getTier"];
    },
    hasProvNextPage() {
      return true
    },
    hasProvPrevPage() {
      return true
    },
    hasCityNextPage() {
      return true
    },
    hasCityPrevPage() {
      return true
    },
    hasDistrictNextPage() {
      return true
    },
    hasDistrictPrevPage() {
      return true
    },
    hasTierNextPage() {
      return true
    },
    hasTierPrevPage() {
      return true
    },
    setPlaceData(){
      return {
        provinceSet: this.searchMapData.provinceSetPlace,
        citySet: this.searchMapData.citySetPlace,
        districtSet: this.searchMapData.districtSetPlace,
      }
    },
  },
  created() {
    if (localStorage.center) {
      this.myCoordinates = JSON.parse(localStorage.center);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.myCoordinates.lat = position.coords.latitude;
          this.myCoordinates.lng = position.coords.longitude;
        },
        (error) => {
          // console.log(error.message);
        }
      );
    }

    if (localStorage.zoom) {
      this.zoom = parseInt(localStorage.zoom);
    }

    // this.getProvince();
    //this.getHospitalTier();
  },
  methods: {
    setStatus(status) {
      this.hospital.status = status
    },
    getTierById(id){
      hospitalService.getTierById(id).then((res) => {
        this.hospitalTierSelectedData =  {
          label: res.data.tier,
          id: res.data.id,
          multiplier: res.data.multiplier,
        }
      }).then(() => {
        this.getHospitalTier()
      })
    },
    getProvinceById(id){
      regionalService.getProvinceById(id).then((res) => {
        this.provSelected =  {
          label: res.data.name,
          id: res.data.id,
        }
      }).then(() => {
        this.getProvince()
      })
    },
    getCityById(id){
      regionalService.getCityById(id).then((res) => {
        this.citySelectedData =  {
          label: res.data.name,
          id: res.data.id,
        }
      }).then(() => {
        this.getCity()
      })
    },
    getDistrictById(id){
      regionalService.getDistrictById(id).then((res) => {
        this.districtSelectedData =  {
          label: res.data.name,
          id: res.data.id,
        }
      }).then(() => {
        this.getDistrict()
      })
    },
    setHospitalData() {
        this.hospital.name = this.detailData.name
        this.hospital.hospitalTierId = this.detailData.hospitalTier?.id
        this.hospital.multiplier = this.detailData.hospitalTier?.multiplier
        // this.hospital.map.lat = parseFloat(this.detailData.latitude)
        // this.hospital.map.lng = parseFloat(this.detailData.longitude)
        this.hospital.position.lat = parseFloat(this.detailData.latitude)
        this.hospital.position.lng = parseFloat(this.detailData.longitude)
       
        this.hospital.address = this.detailData.address
        this.hospital.province = this.detailData.province?.id
        this.hospital.city = this.detailData.city?.id
        this.hospital.district = this.detailData.district?.id
        this.hospital.postalCode = this.detailData.postalCode
        this.hospital.phoneNumber = this.detailData.phoneNumber
        this.hospital.status = this.detailData.status
      
       
    },
    doSave(status) {
      this.hospital.status = status
      this.$v.hospital.$touch();
      if (!this.$v.hospital.$invalid && this.isNameAvailable) {
        this.$emit("onValidateSuccess", this.hospital);
      } else {
        this.hospital.status = null
        // console.log(this.$v.hospital)
        console.debug("False")
      }
    },
    openInfoWindowTemplate(index) {
      const { position, key } = this.markers[index];
      this.infoWindow.position = { lat: position.lat, lng: position.lng };
      this.infoWindow.template = `<b>${key}</b>`;
      this.infoWindow.open = true;
    },
    handleDrag(place) {
      this.hospital.position.lat =  place.latLng.lat()
      this.hospital.position.lng =  place.latLng.lng()
      // let center = {
      //   lat: this.map.getCenter().lat(),
      //   lng: this.map.getCenter().lng(),
      // };
      // let zoom = this.map.getZoom();
      // localStorage.center = JSON.stringify(center);
      // localStorage.zoom = zoom;
    },
    setPlace(place) {
     
      this.hospital.position.lat = place?.geometry?.location?.lat();
      this.hospital.position.lng = place?.geometry?.location?.lng();
      this.hospital.address = place?.formatted_address;
      this.$refs.mapRef.panTo({
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      });


      if(place.address_components[place.address_components.length - 1].long_name === "Indonesia"){
        place.address_components.forEach(p => {
          switch(p.types[0] ) {
            case 'administrative_area_level_3':
              if(p.long_name.includes("Kecamatan")){
                p.long_name = p.long_name.replace('Kecamatan ','');
              }
               if(p.long_name.includes("Desa")){
                p.long_name = p.long_name.replace('Desa ','');
              }
              this.searchMapData.districtSetPlace = p.long_name
              break;
            case 'administrative_area_level_2':
              if(p.long_name.includes("Kabupaten")){
                p.long_name = p.long_name.replace('Kabupaten ','');
              }
              if(p.long_name.includes("Kota")){
                 p.long_name = p.long_name.replace('Kota ','');
              }
              this.searchMapData.citySetPlace = p.long_name
              break;
            case 'administrative_area_level_1':
              if(p.long_name.includes("Daerah Istimewa")){
                p.long_name = p.long_name.replace('Daerah Istimewa ','');
              }
              if(p.long_name.includes("Daerah Khusus Ibukota")){
                 p.long_name = p.long_name.replace('Daerah Khusus Ibukota ','');
              }
              this.searchMapData.provinceSetPlace = p.long_name
              break;
          
            default:
              break;
          }
        })
        if(this.searchMapData.provinceSetPlace){
          this.setProvPlaceSearch = true
          this.hospital.province = null
          this.provSelected = {}
          this.searchProvince(this.searchMapData.provinceSetPlace)
          // if(this.searchMapData.citySetPlace){
          //   this.setCityPlaceSearch = true
          //   this.hospital.city = null
          //   this.citySelectedData = {}
          //   //this.searchCity(this.searchMapData.citySetPlace)
          //   if(this.searchMapData.districtSetPlace){  
          //     this.setDistrictPlaceSearch = true
          //     this.hospital.district = null
          //     this.districtSelectedData = {}
          //     //this.searchDistrict(this.searchMapData.districtSetPlace)
          //   }
          // }
         
         // this.provSelected = {}
          // console.log('content: ', this.$store.getters['regional/getProvince'].content)
          // // console.log('cek: ', this.$store.getters['regional/getProvince'].numberOfElements)
          // if(this.$store.getters['regional/getProvince'].numberOfElements > 0){
          //   // console.log('data : ', this.$store.getters['regional/getProvince'])
          //   const length = this.$store.getters['regional/getProvince'].content.length
          //   this.hospital.province = this.$store.getters['regional/getProvince'].content[length - 1].id
          //   this.provSelected = this.$store.getters['regional/getProvince'].content[length - 1]
          //   console.log('this.hospital.province: ', this.hospital.province)
          //   console.log('this.provSelected: ', this.provSelected)
          // }
       
        }
      }else{
        this.hospital.province = null
        this.provSelected = {}
        this.hospital.address = null
      }

      
      
    },

    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    searchProvince(query, loading) {
      this.provServerParamsProvince.pageNumber = 0
      this.provServerParamsProvince.searchField = 'name'
      this.provServerParamsProvince.searchValue = query
      this.getProvince()
      
    },
    searchCity(query, loading) {
      this.provServerParamsCity.pageNumber = 0
      this.provServerParamsCity.searchField = 'name'
      this.provServerParamsCity.searchValue = query
      this.getCity()
    },
    searchDistrict(query, loading) {
      this.provServerParamsDistrict.pageNumber = 0
      this.provServerParamsDistrict.searchField = 'name'
      this.provServerParamsDistrict.searchValue = query
      this.getDistrict()
    },
    searchHospitalTier(query, loading){
      this.provServerParamsHospitalTier.pageNumber = 0
      this.provServerParamsHospitalTier.searchField = 'tier'
      this.provServerParamsHospitalTier.searchValue = query
      this.getHospitalTier()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    getProvince() {
      this.$store.dispatch(
        "regional/getProvinceLists",
        this.provServerParamsProvince
      ).then(
        () => {
          if(Object.keys(this.provSelected).length > 0){
            this.provSelected.type = 'province'
            this.$store.commit('regional/pushSelectedData', this.provSelected)
          }
        }
      ).catch(  
        (error) => {
          console.debug(error);
        }
      )
     // this.formInProgressProvince = false
    },
    getCity() {
      const payload = this.provServerParamsCity
      if(this.hospital.province){
        payload.idProvince = this.hospital.province
      }
      this.$store.dispatch("regional/getCityLists", payload)
      .then(
        () => {
           if(Object.keys(this.citySelectedData).length > 0){
            this.citySelectedData.type = 'city'
            this.$store.commit('regional/pushSelectedData', this.citySelectedData)
          }
        }
      )
      .catch(  
        (error) => {
          console.debug(error);
        }
      )
    },
    getDistrict() {
      const payload = this.provServerParamsDistrict
      if(this.hospital.city){
        payload.idCity = this.hospital.city
      }
      
      this.$store.dispatch("regional/getDistrictLists", payload)
      .then(
        () => {
          if(Object.keys(this.districtSelectedData).length > 0){
            this.districtSelectedData.type = 'district'
            this.$store.commit('regional/pushSelectedData', this.districtSelectedData)
          }
        }
      )
      .catch(  
        (error) => {
          console.debug(error);
        }
      )
    },
    getHospitalTier() {
      this.$store.dispatch(
        "hospital/getHospitalTier",
        this.provServerParamsHospitalTier
      )
      .then(
        () => {
          if(Object.keys(this.hospitalTierSelectedData).length > 0){
            this.hospitalTierSelectedData.type = 'tier'
            this.$store.commit('hospital/pushSelectedData', this.hospitalTierSelectedData)
          }
        }
      )
      .catch(  
        (error) => {
          console.debug(error);
        }
      )
     // this.formInProgressProvince = false
    },
    selectedProvToTemporary(provSelected){
      this.provSelected = provSelected
    },
    selectedCityToTemporary(citySelectedData){
      this.citySelectedData = citySelectedData
    },
    selectedDistrictToTemporary(districtSelectedData){
      this.districtSelectedData = districtSelectedData
    },
    selectedHospitalTierToTemporary(hospitalTierSelectedData) {
      // const tierContent = this.$store.state['hospital'].tier.content
      // const tier = tierContent.find(e => e.id === this.hospital.hospitalTierId)
      this.hospital.multiplier = hospitalTierSelectedData.multiplier
      this.hospitalTierSelectedData = hospitalTierSelectedData
    },
    openSelectProvince(){
      this.provServerParamsProvince.searchValue = ""
      this.provServerParamsProvince.pageNumber = 0
      this.getProvince()
      //this.provSelected = {}  
    },
    openSelectCity(){
      this.provServerParamsCity.searchValue = ""
      this.provServerParamsCity.pageNumber = 0
      this.getCity()
      //this.provSelected = {}  
    },
    openSelectDistrict(){
      this.provServerParamsDistrict.searchValue = ""
      this.provServerParamsDistrict.pageNumber = 0
      this.getDistrict()
      //this.provSelected = {}  
    },
    openSelectHospitalTier(){
      this.provServerParamsHospitalTier.searchValue = ""
      this.provServerParamsHospitalTier.pageNumber = 0
      this.getHospitalTier()
    },
    provinceSelected() {
       this.hospital.city = null
       this.hospital.district = null 
       this.citySelectedData = {}
       this.districtSelectedData = {}
    },
    citySelected() {
      this.hospital.district = null
      this.districtSelectedData = {}
    },
    handleNextProv(kind) {
      let lastPage
      
      switch (kind) {
        case 'province':
            lastPage = this.$store.state['regional'].province.totalPages - 1
            if(  this.provServerParamsProvince.pageNumber < lastPage ) {
              this.provServerParamsProvince.pageNumber += 1
            }
            //this.onSearchProvince = true
            this.getProvince()
          break;
        case 'city':
          if (this.hospital.province !== null) {
            lastPage = this.$store.state['regional'].city.totalPages - 1
            if(this.provServerParamsCity.pageNumber < lastPage ) {
              this.provServerParamsCity.pageNumber += 1
            }
            // await this.provinceSelected()
            this.getCity()
          }
          break;
        case 'district':
           if (this.hospital.city !== null) {
            lastPage = this.$store.state['regional'].district.totalPages - 1
            if(this.provServerParamsDistrict.pageNumber < lastPage ) {
              this.provServerParamsDistrict.pageNumber += 1
            }
            this.getDistrict()
           }
          break;
        case 'tier':
            lastPage = this.$store.state['hospital'].tier.totalPages - 1
            if(this.provServerParamsHospitalTier.pageNumber < lastPage ) {
              this.provServerParamsHospitalTier.pageNumber += 1
            }
            //this.onSearchTier = true
            this.getHospitalTier()
          break;
      
        default:
          break;
      }
     
     
    },
    handlePrevProv(kind) {
       switch (kind) {
        case 'province':
            if(this.provServerParamsProvince.pageNumber !== 0){
                this.provServerParamsProvince.pageNumber -= 1
            }
            this.getProvince()
          break;
        case 'city':
          if (this.hospital.province !== null) { 
            if(this.provServerParamsCity.pageNumber !== 0){
                this.provServerParamsCity.pageNumber -= 1
            }
            // await this.provinceSelected()
            this.getCity()
          }
          break;
        case 'district':
          if (this.hospital.city !== null) { 
            if(this.provServerParamsDistrict.pageNumber !== 0){
                this.provServerParamsDistrict.pageNumber -= 1
            }
            this.getDistrict()
          }
          break;
        case 'tier':
          if(this.provServerParamsHospitalTier.pageNumber !== 0){
              this.provServerParamsHospitalTier.pageNumber -= 1
            }
          this.getHospitalTier()
          break;
      
        default:
          break;
      }
    },
    checkingName() {
      this.isNameAvailable = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = this.formType === "add" ? setTimeout(async () => {
        hospitalService.checkHospitalName({ name: this.hospital.name || "" }).then(
          () => {
            this.isNameAvailable = true;
          }
        ).catch( (err) => {
            if (err.response.data?.responseCode === "409") {
              this.isNameAvailable = false;
              // this.errorMessage.name = err.response.data?.responseMessage;
              this.errorMessage.name = 'Hospital Name Already Exist'
            } else {
              alert("Terjadi Kesalahan");
            }
        });
      }, 800) :
      setTimeout(async () => {
        hospitalService.checkHospitalNameById(this.$route.params.id, { name: this.hospital.name || "" }).then(
          () => {
            this.isNameAvailable = true;
          }
        ).catch( (err) => {
            if (err.response.data?.responseCode === "409") {
              this.isNameAvailable = false;
              this.errorMessage.name = err.response.data?.responseMessage;
            } else {
              alert("Terjadi Kesalahan");
            }
        });
      }, 800)
    },
  },
  validations() {
    return {
      hospital: {
        name: {
          required,
        },
        hospitalTierId: {
          required,
        },
        position: {
          lat: {
            required,
          },
          lng: {
            required,
          },
        },
        address: {
          required,
        },
        province: {
          required,
        },
        city: {
          required,
        },
        district: {
          required,
        },
        postalCode: {
          required,
        },
        phoneNumber: {
          required,
          phoneRx
        },
      },
    };
  },
  watch: {
    // detailData: function (newValue) {
    //   console.log('newValue: ', newValue)
    //   this.setHospitalData()
    // },
    detailData: {
      handler(newValue, oldValue) {
        this.setHospitalData()
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo({lat:  parseFloat(this.detailData.latitude), lng:  parseFloat(this.detailData.longitude)})
        })
        this.getTierById(this.detailData.hospitalTier?.id)
        this.getProvinceById(this.detailData.province?.id)
        this.getCityById(this.detailData.city?.id)
        this.getDistrictById(this.detailData.district?.id)
      },
      deep: true
    },
    // setPlaceData: {
    //   handler(newValue, oldValue) {
    //     console.log('newValue: ', newValue)
    //     console.log('content: ', this.$store.getters['regional/getProvince'].content)
    //   },
    //   deep: true
    // },
    paginatedProvince: {
      handler(newValue, oldValue) {
        if(this.setProvPlaceSearch){
          if(newValue?.totalElements > 0) {
            this.hospital.province = newValue.content[0].id
            this.provSelected = newValue.content[0]
            this.setProvPlaceSearch = false

            //city
            if(this.searchMapData.citySetPlace){
              this.setCityPlaceSearch = true
              this.hospital.city = null
              this.citySelectedData = {}
              this.searchCity(this.searchMapData.citySetPlace)
            }
          }
        }
        // console.log('content: ', this.$store.getters['regional/getProvince'].content)
      },
      deep: true
    },
    paginatedCity: {
      handler(newValue, oldValue) {
        if(this.setCityPlaceSearch){
          if(newValue?.totalElements > 0) {
            this.hospital.city = newValue.content[0].id
            this.citySelectedData = newValue.content[0]
            this.setCityPlaceSearch = false

            //district
            if(this.searchMapData.districtSetPlace){  
              this.setDistrictPlaceSearch = true
              this.hospital.district = null
              this.districtSelectedData = {}
              this.searchDistrict(this.searchMapData.districtSetPlace)
            }
          
          }
        }
        // console.log('content: ', this.$store.getters['regional/getProvince'].content)
      },
      deep: true
    },
    paginatedDistrict: {
      handler(newValue, oldValue) {
        if(this.setDistrictPlaceSearch){
          if(newValue?.totalElements > 0) {
            this.hospital.district = newValue.content[0].id
            this.districtSelectedData = newValue.content[0]
            this.setDistrictPlaceSearch = false
          }
        }
        // console.log('content: ', this.$store.getters['regional/getProvince'].content)
      },
      deep: true
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>
