<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <h1 class="page-header">Import Product</h1>
    <div v-if="upload.status != null">
      <div v-if="upload.status === false">
        <b-alert variant="danger" show dismissible>
          <div class="d-flex align-items-center">
            <i class="fa-solid fa-circle-xmark"></i>
            <span>
              <strong>Import Product Failed.</strong>
              {{ upload.message }}
            </span>
          </div>
        </b-alert>
      </div>
      <div v-else-if="$v.category.$error === false">
        <b-alert variant="danger" show dismissible>
          <div class="d-flex align-items-center">
            <i class="fa-solid fa-circle-xmark"></i>
            <span>
              <strong>Import Product required.</strong>
            </span>
          </div>
        </b-alert>
      </div>
      <div v-else>
        <b-alert variant="success" show dismissible>
          <div class="d-flex align-items-center">
            <i class="fa-solid fa-circle-check"></i>
            <span><strong>Import Product Success.</strong> Kindly check <router-link to="/products">All
                Products</router-link>
              page.</span>
          </div>
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-9 col-md-8">
        <div class="row align-items-center mb-4">
          <div class="col-auto">
            <Upload v-model="importFile" />
          </div>
          <div class="col-auto">
            <p class="mb-0">or <a href="#" class="text-primary">download template</a></p>
          </div>
          <div class="col-12">
            <p class="mt-2" v-if="importFile">Selected File: {{ importFile.name }}</p>
          </div>
        </div>

        <h3 class="fs-18px mb-15px fw-normal">Import History</h3>
        <div class="table-responsive bg-white">
          <table class="table table-form">
            <thead>
              <tr>
                <th width="40%">File Name</th>
                <th>Imported by</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in rows" :key="index">
                <td>{{ item.filename }}</td>
                <td>{{ item.createdBy }}</td>
                <td>{{ dateFormat(item.created) }}</td>
                <td>{{ item.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Upload from '../../components/upload/csv.vue'
import productService from "@/service/product.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
  components: {
    Upload,
  },
  data() {
    return {
      importFile: null,
      upload: {
        message: '',
        status: null
      },
      rows: [],
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 100,
        name: [],
        by: [],
        date: [],
        status: []
      }
    };
  },
  methods: {
    doSave() {
      this.$v.importFile.$touch();
      if (!this.$v.importFile.$invalid) {
        const formData = new FormData()
        formData.append('file', this.importFile, this.importFile.name)
        productService
          .importProduct(formData)
          .then((rsp) => {
            this.upload = {
              status: true,
              message: 'test'
            }
          }).catch((e) => {
            this.upload = {
              status: false,
              message: e.response.data?.message || "Gagal mengirim data"
            }
          });
      }
    },
    loadItems() {
      // console.log(this.serverParams)
      productService
        .getHistoryImportProduct(this.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.content;
        })
        .catch((e) => {
          this.rows = [];
          if (e.response.status == 404) {
            this.$notify({
              type: "warning",
              title: "Tidak Ditemukan",
              text: e.response.data?.responseMessage || "Gagal mengambil data",
            });
          } else {
            this.$notify({
              type: "error",
              title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
                }`,
              text: e.response.data?.responseMessage || "Gagal mengambil data",
            });
          }
        });
    },
    dateFormat(date) {
      let arrDate = date.split(' ')
      let newDate = new Date(arrDate[0])
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return newDate.toLocaleDateString("id", options);
    }
  },
  watch: {
    'importFile'(newVal) {
      if (newVal) {
        this.doSave()
      }
    }
  },
  mounted() {
    this.loadItems();
  },
  validations: {
    importFile: {
      required,
    },
  }
};
</script>
