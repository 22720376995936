// combine the previous two functions to return a base64 encode image from url
let base64Converter = async function (imageFile) {

  if (!imageFile) {
    console.debug("base64Converter without input!", new Error().stack)
    return Promise.resolve("")
  }

  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.readAsDataURL(imageFile);
    fr.onload = () => {
      resolve(fr.result);
    };
  });
};

export default base64Converter;
