import areaService from "@/service/area.service";
export default {
  namespaced: true,
  state: {
    province:{
      size:0,
      number:0,
      totalPages:0,
      totalElements:0,
      numberOfElements:0,
      content:[]
    },
    city: [],
    district: [],
  },
  actions: {
    createArea(_ctx, area) {
      return areaService.createArea(area).catch((error) => {
        return Promise.reject(error)
      })
    },
    // async getProvinceLists({ commit }, serverParams) {
    //   return regionalService
    //     .getProvinceList(serverParams)
    //     .then((result) => {
    //       commit("setProvinceData", result.data.data);
    //     })
    //     .catch((err) => {
    //       return err;
    //     });
    // },
    // async getCityLists({ commit }, serverParams) {
    //   return regionalService
    //     .getCityList(serverParams)
    //     .then((result) => {
    //       commit("setCityData", result.data.data.content);
    //     })
    //     .catch((err) => {
    //       return err;
    //     });
    // },
    // async getDistrictLists({ commit }, serverParams) {
    //   return regionalService
    //     .getDistrictList(serverParams)
    //     .then((result) => {
    //       commit("setDistrictData", result.data.data.content);
    //     })
    //     .catch((err) => {
    //       return err;
    //     });
    // },
  },
  getters: {
    // getProvince(state) {
    //   return state.province;
    // },
    // getCity(state) {
    //   return state.city;
    // },
    // getDistrict(state) {
    //   return state.district;
    // },
  },
  mutations: {
    // setProvinceData(state, data) {
    //   state.province.size = data.size;
    //   state.province.number = data.number;
    //   state.province.totalPages = data.totalPages;
    //   state.province.totalElements = data.totalElements;
    //   state.province.numberOfElements = data.numberOfElements;
    //   state.province.content = [];
    //   for (let i = 0; i < data.content.length; i++) {
    //     state.province.content.push({
    //       label: data.content[i].name,
    //       id: data.content[i].id,
    //     });
    //   }
    // },
    // setCityData(state, data) {
    //   state.city = [];
    //   for (let i = 0; i < data.length; i++) {
    //     state.city.push({
    //       label: data[i].name,
    //       id: data[i].id,
    //     });
    //   }
    // },
    // setDistrictData(state, data) {
    //   state.district = [];
    //   for (let i = 0; i < data.length; i++) {
    //     state.district.push({
    //       label: data[i].name,
    //       id: data[i].id,
    //     });
    //   }
    // },
  },
};
