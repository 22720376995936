<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Organizations</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="$can($permissionsAll[58])">
        <router-link to="/sections/charity-organizations/add">
          <a class="btn btn-outline-primary"> Add New Organization</a>
        </router-link>
      </div>
    </div>
    <ul class="nav nav-tabs nav-tabs--wp">
      <li class="nav-item">
        <router-link to="/sections/charity-organizations" class="nav-link" active-class="active" exact>
          Published <span class="badge">{{ totalRecords.PUBLISHED }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/sections/charity-organizations/drafts" class="nav-link" active-class="active" exact>
          Drafts <span class="badge">{{ totalRecords.DRAFTS }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/sections/charity-organizations/trashed" class="nav-link" active-class="active" exact>
          Trashed <span class="badge">{{ totalRecords.TRASHED }}</span>
        </router-link>
      </li>
    </ul>
    <router-view @reloadData="reloadData"></router-view>
  </div>
</template>

<script>
import charityService from "@/service/charity.service";

export default {
  data() {
    return {
      totalRecords: {
        PUBLISHED: 0,
        DRAFTS: 0,
        TRASHED: 0
      },
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
        status: ''
      },
    };
  },
  methods: {
    loadItems(type) {
      this.serverParams.status = type;
      charityService
        .getCharityList(this.serverParams)
        .then((rsp) => {
          this.totalRecords[type] = rsp.data?.data ? rsp.data.data.totalElements : 0
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },

    reloadData(e) {
      this.loadItems(e)
    }
  },
  mounted() {
    this.loadItems('PUBLISHED');
    this.loadItems('DRAFTS');
    this.loadItems('TRASHED');
  },
};
</script>
