<template>
  <div class="pb-5">
    <form>
      <div class="panel">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">No. KTP</label>
                <masked-input pattern="1111 1111 1111 1111" v-model="nik" :readonly="isReadOnly" class="form-control"
                  placeholder="0000 0000 0000 0000"></masked-input>
                <span class="text-danger" v-if="!$v.nik.required && $v.nik.$error">No KTP required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Foto KTP</label>
                <upload-image :class="{
                  disabled: isReadOnly,
                }" :src="identityImage.imageSrc" v-model="photos.id" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Foto Selfie</label>
                <upload-image :class="{
                  disabled: isReadOnly,
                }" :src="selfieImage.imageSrc" v-model="photos.selfie" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Nama sesuai KTP</label>
                <input type="text" :readonly="isReadOnly" v-model="name" class="form-control" />
                <span class="text-danger" v-if="!$v.name.required && $v.name.$error">Name required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Jenis Kelamin</label>
                <div class="pt-2">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" id="gender1" value="LAKI-LAKI"
                      v-model="gender" :class="{
                        disabled: isReadOnly,
                      }" />
                    <label class="form-check-label" for="">Laki-laki</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" id="gender2" value="PEREMPUAN"
                      v-model="gender" :class="{
                        disabled: isReadOnly,
                      }" />
                    <label class="form-check-label" for="">Perempuan</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Tanggal Lahir</label>
                <input type="date" :readonly="isReadOnly" v-model="birthDate" class="form-control" />
                <span class="text-danger" v-if="!$v.birthDate.required && $v.birthDate.$error">Birth Date required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Alamat</label>
                <input type="text" v-model="address" placeholder="Alamat" class="form-control" :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.address.required && $v.address.$error">Alamat required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Provinsi</label>
                <v-select v-model="province" :options="dropDownServerParams.province.content"
                  :reduce="(option) => option.id" @option:selected="
                    handleSelectedValue($event, {
                      category: 'province',
                      pointer: 'province',
                    })
                    " @search="
    handleOnSearch($event, {
      category: 'province',
      pointer: 'province',
    })
    " @input="
    handleInput(
      $event,
      { category: 'province', pointer: 'province' },
      { city: 'city' }
    )
    " :class="{
    disabled: isReadOnly,
  }">
                  <li slot="list-footer" class="pagination">
                    <button :disabled="!hasPrev({ category: 'province', pointer: 'province' })
                      " @click.prevent="
    handlePrev({
      category: 'province',
      pointer: 'province',
    })
    ">
                      Prev
                    </button>
                    <button :disabled="!hasNext({ category: 'province', pointer: 'province' })
                      " @click.prevent="
    handleNext({
      category: 'province',
      pointer: 'province',
    })
    ">
                      Next
                    </button>
                  </li>
                </v-select>
                <span class="text-danger" v-if="!$v.province.required && $v.province.$error">Provinsi required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Kabupaten/Kota</label>
                <v-select v-model="city" :options="dropDownServerParams.city.content" :reduce="(option) => option.id"
                  @option:selected="
                    handleSelectedValue($event, {
                      category: 'city',
                      pointer: 'city',
                    })
                    " @search="
    handleOnSearch($event, {
      category: 'city',
      pointer: 'city',
    })
    " :searchable="province !== null" :class="{
    disabled: isReadOnly,
  }">
                  <li slot="list-footer" class="pagination">
                    <button :disabled="!hasPrev({ category: 'city', pointer: 'city' })
                      " @click.prevent="
    handlePrev({ category: 'city', pointer: 'city' })
    ">
                      Prev
                    </button>
                    <button :disabled="!hasNext({ category: 'city', pointer: 'city' })
                      " @click.prevent="
    handleNext({ category: 'city', pointer: 'city' })
    ">
                      Next
                    </button>
                  </li>
                </v-select>
                <span class="text-danger" v-if="!$v.city.required && $v.city.$error">City required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Kode Pos</label>
                <input type="tel" v-model="postcode" placeholder="Kode Pos" maxlength="5" class="form-control"
                  :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.postcode.required && $v.postcode.$error">Postcode required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">No. Handphone</label>
                <input type="tel" v-model="phone" placeholder="No. Handphone" maxlength="13" class="form-control"
                  :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.phone.required && $v.phone.$error">No Handphone required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Email</label>
                <input type="email" v-model="email" placeholder="Email" class="form-control" :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.email.required && $v.email.$error">Email required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Pekerjaan</label>
                <v-select :options="dropDownServerParams.profesiParams.content" :reduce="(option) => option.id"
                  v-model="job" placeholder="Pekerjaan" :class="{
                    disabled: isReadOnly,
                  }" @option:selected="handleSelectedProfesi($event)" />
                <span class="text-danger" v-if="!$v.job.required && $v.job.$error">Job required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Nama Pemilik Bank</label>
                <input type="text" v-model="namaPemilikBank" placeholder="Pemilik Bank" class="form-control"
                  :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.namaPemilikBank.required && $v.namaPemilikBank.$error
                  ">Nama Pemilik Bank required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Nama Bank</label>
                <v-select v-model="namaBank" :options="dropDownServerParams.bankParams.content"
                  :reduce="(option) => option.label" @option:selected="handleSelectedBank($event)"
                  @input="handleBankInput($event)" :class="{
                    disabled: isReadOnly,
                  }">
                  <li slot="list-footer" class="pagination">
                    <button :disabled="!hasPrev({
                      category: 'bankParams',
                      pointer: 'bankParams',
                    })
                      " @click.prevent="handlePrevBank()">
                      Prev
                    </button>
                    <button :disabled="!hasNext({
                      category: 'bankParams',
                      pointer: 'bankParams',
                    })
                      " @click.prevent="handleNextBank()">
                      Next
                    </button>
                  </li>
                </v-select>
                <span class="text-danger" v-if="!$v.namaBank.required && $v.namaBank.$error">Nama Bank required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Code Bank</label>
                <input type="text" v-model="codeBank" placeholder="Code Bank" class="form-control"
                  :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.codeBank.required && $v.codeBank.$error">Code Bank required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Nomor Rekening</label>
                <input type="text" v-model="nomorRekening" placeholder="Nomor Rekening" class="form-control"
                  :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.nomorRekening.required && $v.nomorRekening.$error">Nomor Rekening
                  required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Cabang Bank</label>
                <input type="text" v-model="cabangBank" placeholder="Cabang Bank" class="form-control"
                  :readonly="isReadOnly" />
                <span class="text-danger" v-if="!$v.cabangBank.required && $v.cabangBank.$error">Cabang Bank
                  required</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-15px">
                <label class="form-label">Referral Code</label>
                <v-select :options="dropDownServerParams.referralParams.content" v-model="referralCode"
                  :reduce="(option) => option.referralCode"
                  :getOptionLabel="(option) => option.referralCode + ' - ' + option.name" :class="{
                    disabled: isReadOnly,
                  }" clearable>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isReadOnly" class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button @click.prevent="doSave()" class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import registrationService from "@/service/registration.service";
import regionalService from "@/service/regional.service";
import bankService from "../../../service/bank.service";
import referralService from "@/service/referral.service";
import { required, requiredIf, helpers } from "vuelidate/lib/validators";
export default {

  data() {
    return {
      status: false,
      timer: null,
      nik: "",
      photos: {
        id: null,
        selfie: null,
      },
      identityImage: {
        image: null,
        imageSrc: "",
      },
      selfieImage: {
        image: null,
        imageSrc: "",
      },
      name: "",
      gender: "",
      birthDate: null,
      address: null,
      province: null,
      city: null,
      postcode: null,
      phone: null,
      email: null,
      job: null,
      namaPemilikBank: null,
      namaBank: null,
      codeBank: null,
      nomorRekening: null,
      cabangBank: null,
      referralCode: null,
      dropDownServerParams: {
        province: {
          params: {
            pageSize: 10,
            pageNumber: 0,
            sort: "name",
            direction: "ASC",
            searchField: "name",
            searchValue: "",
          },
          content: [],
          totalElments: 0,
        },
        city: {
          params: {
            pageSize: 10,
            pageNumber: 0,
            sort: "name",
            direction: "ASC",
            searchField: "name",
            searchValue: "",
            idProvince: null,
          },
          content: [],
          totalElments: 0,
        },
        profesiParams: {
          params: {
            pageSize: 10,
            pageNumber: 0,
            sort: "nama",
            direction: "ASC",
            searchField: "",
            searchValue: "",
          },
          content: [],
          totalElments: 0,
        },
        bankParams: {
          params: {
            pageSize: 5,
            pageNumber: 0,
            sort: "name",
            direction: "ASC",
            searchField: "",
            searchValue: "",
          },
          content: [],
          totalElments: 0,
        },
        referralParams: {
          params: {
            pageSize: 100,
            pageNumber: 0,
            sort: "name",
            direction: "ASC",
            searchField: "name",
            searchValue: "",
          },
          content: [],
          totalElments: 0,
        }
      },
      selectedDropDown: {
        province: {
          id: null,
          label: null,
          code: null,
        },
        city: {
          id: null,
          label: null,
          code: null,
        },
        profesi: {
          id: null,
          label: null,
        },
        bank: {
          id: null,
          label: null,
        },
      },
      profesiParams: {
        params: {
          pageSize: 10,
          pageNumber: 0,
          sort: "nama",
          direction: "ASC",
          searchField: "",
          searchValue: "",
        },
        content: [],
        totalElments: 0,
      },
    };
  },
  methods: {
    async doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let body = {
          nik: this.nik,
          nama: this.name,
          jenisKelamin: this.gender,
          tanggalLahir: this.birthDate,
          alamat: this.address,
          provinsi: {
            id: this.selectedDropDown.province.id,
            name: this.selectedDropDown.province.label,
            code: this.selectedDropDown.province.id,
          },
          kota: {
            id: this.selectedDropDown.city.id,
            name: this.selectedDropDown.city.label,
            code: this.selectedDropDown.city.id,
          },
          customerProfesi: {
            id: this.selectedDropDown.profesi.id,
            name: this.selectedDropDown.profesi.label,
          },
          nomorHandphone: this.phone,
          email: this.email,
          namaPerusahaan: "JOS",
          namaPemilikBank: this.namaPemilikBank,
          namaBank: this.namaBank,
          codeBank: this.codeBank,
          nomorRekening: this.nomorRekening,
          cabangBank: this.cabangBank,
          referralCode: this.referralCode ? this.referralCode : null
        };
        registrationService
          .registrationUpdate(this.$route.params.id, body)
          .then((response) => {
            //console.log('body: ', this.body)
            this.$swal({
              title: "Data berhasil diupdate",
              showCloseButton: true,
            });
          })
          .catch((error) => {
            this.$swal({
              title:
                error.response?.data?.responseMessage || "Terjadi Kesalahan",
              showCloseButton: true,
            });
          });
      } else {
        console.log("False");
      }
    },
    async getProfesiList() {
      await registrationService
        .getProfesiList(this.dropDownServerParams.profesiParams.params)
        .then((res) => {
          let arrData = res.data?.data ? res.data?.data.content : [];
          this.dropDownServerParams.profesiParams.content = [];
          for (let i = 0; i < arrData.length; i++) {
            this.dropDownServerParams.profesiParams.content.push({
              label: res.data.data.content[i].nama,
              id: res.data.data.content[i].id,
            });
          }
        })
        .catch(() => {
          alert("Something Wrong");
        });
    },
    async getBankList() {
      await bankService
        .getMasterBankList(this.dropDownServerParams.bankParams.params)
        .then((res) => {
          let arrData = res.data?.data ? res.data?.data.content : [];
          this.dropDownServerParams.bankParams.content = [];
          this.dropDownServerParams.bankParams.totalElments = res.data?.data
            ? res.data?.data.totalElements
            : 0;
          for (let i = 0; i < arrData.length; i++) {
            this.dropDownServerParams.bankParams.content.push({
              label: res.data.data.content[i].name,
              id: res.data.data.content[i].id,
              code: res.data.data.content[i].code,
            });
          }
          this.pushSelectedBankData();
        })
        .catch(() => {
          alert("Something Wrong");
        });
    },
    async getDropDownData(data) {
      let methodName = "";
      if (data.category === "province") {
        methodName = "getProvinceList";
      } else if (data.category === "city") {
        methodName = "getCityList";
      }
      await regionalService[methodName](
        this.dropDownServerParams[data.pointer].params
      )
        .then((res) => {
          this.dropDownServerParams[data.pointer].content = [];
          for (let i = 0; i < res.data.data.content.length; i++) {
            this.dropDownServerParams[data.pointer].content.push({
              label: res.data.data.content[i].name,
              id: res.data.data.content[i].id,
            });
          }
          this.dropDownServerParams[data.pointer].totalElments =
            res.data.data.totalElements;
          this.pushSelectedData(data);
        })
        .catch(() => {
          alert("Something Wrong");
        });
    },
    pushSelectedData(data) {
      let list = this.dropDownServerParams[data.pointer].content;
      if (
        this[data.pointer] !== null &&
        this.selectedDropDown[data.pointer] !== null
      ) {
        var foundIndex = list.findIndex((x) => x.id == this[data.pointer]);
        if (foundIndex < 0) {
          this.dropDownServerParams[data.pointer].content.push(
            this.selectedDropDown[data.pointer]
          );
        }
      }
    },
    pushSelectedBankData() {
      let list = this.dropDownServerParams.bankParams.content;
      if (this.namaBank !== null && this.selectedDropDown.bank !== null) {
        var foundIndex = list.findIndex((x) => x.label == this.namaBank);
        if (foundIndex < 0) {
          this.dropDownServerParams.bankParams.content.push(
            this.selectedDropDown.bank
          );
        }
      }
    },
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            let registrationData = res.data.data.customerRegistration;
            this.referralCode =
              registrationData.referralCode !== null
                ? registrationData.referralCode
                : null;
            this.status =
              registrationData.status !== null ? registrationData.status : null;
            this.nik =
              registrationData.nik !== null ? registrationData.nik : null;
            this.identityImage.imageSrc =
              registrationData.ktpFileUrl !== null
                ? registrationData.ktpFileUrl
                : "";
            this.selfieImage.imageSrc =
              registrationData.ktpWajahUrl !== null
                ? registrationData.ktpWajahUrl
                : "";
            this.name =
              registrationData.nama !== null ? registrationData.nama : "";
            this.address =
              registrationData.alamat !== null ? registrationData.alamat : null;
            this.gender =
              registrationData.jenisKelamin !== null
                ? registrationData.jenisKelamin
                : "";
            this.birthDate =
              registrationData.tanggalLahir !== null
                ? registrationData.tanggalLahir
                : null;
            this.email =
              registrationData.email !== null ? registrationData.email : "";
            this.phone =
              registrationData.nomorHandphone !== null
                ? registrationData.nomorHandphone
                : "";
            this.dropDownServerParams.city.params.idProvince =
              registrationData.customerProvince !== null
                ? registrationData.customerProvince.id
                : null;
            this.selectedDropDown.province = {
              id:
                registrationData.customerProvince !== null
                  ? registrationData.customerProvince.id
                  : null,
              label:
                registrationData.customerProvince !== null
                  ? registrationData.customerProvince.name
                  : null,
              code:
                registrationData.customerProvince !== null
                  ? registrationData.customerProvince.code
                  : null,
            };
            this.selectedDropDown.city = {
              id:
                registrationData.customerCity !== null
                  ? registrationData.customerCity.id
                  : null,
              label:
                registrationData.customerCity !== null
                  ? registrationData.customerCity.name
                  : null,
              code:
                registrationData.customerCity !== null
                  ? registrationData.customerCity.code
                  : null,
            };
            this.selectedDropDown.profesi = {
              id:
                registrationData.customerProfesi !== null
                  ? registrationData.customerProfesi.id
                  : null,
              label:
                registrationData.customerProfesi !== null
                  ? registrationData.customerProfesi.nama
                  : null,
            };
            this.province =
              registrationData.customerProvince !== null
                ? registrationData.customerProvince.id
                : null;
            this.city =
              registrationData.customerCity !== null
                ? registrationData.customerCity.id
                : null;
            this.job =
              registrationData.customerProfesi !== null
                ? registrationData.customerProfesi.id
                : null;
            this.namaPemilikBank =
              registrationData.namaPemilikBank !== null
                ? registrationData.namaPemilikBank
                : null;
            this.namaBank =
              registrationData.namaBank !== null
                ? registrationData.namaBank
                : null;
            this.codeBank =
              registrationData.codeBank !== null
                ? registrationData.codeBank
                : null;
            this.nomorRekening =
              registrationData.nomorRekening !== null
                ? registrationData.nomorRekening
                : null;
            this.cabangBank =
              registrationData.cabangBank !== null
                ? registrationData.cabangBank
                : null;
            this.selectedDropDown.bank = {
              code:
                registrationData.cabangBank !== null
                  ? registrationData.cabangBank
                  : null,
              label:
                registrationData.namaBank !== null
                  ? registrationData.namaBank
                  : null,
            };
            this.geoCoder(registrationData);
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
    geoCoder(data) {
      this.$geocoder.setDefaultMode("address"); // this is default
      var addressObj = {
        address_line_1: data.alamat !== null ? data.alamat : "",
        address_line_2: "",
        city: data.customerCity !== null ? data.customerCity.name : "",
        state: data.customerProvince !== null ? data.customerProvince.name : "",
        zip_code: "",
        country: "Indonesia",
      };
      this.$geocoder.send(addressObj, (response) => {
        var address = response.results[0].address_components;
        var zipcode = address[address.length - 1].long_name;
        this.postcode = zipcode;
      });
    },
    hasNext(data) {
      let currentPage =
        (this.dropDownServerParams[data.pointer].params.pageNumber + 1) *
        this.dropDownServerParams[data.pointer].params.pageSize;
      if (currentPage < this.dropDownServerParams[data.pointer].totalElments) {
        return true;
      } else {
        return false;
      }
    },
    hasPrev(data) {
      if (this.dropDownServerParams[data.pointer].params.pageNumber === 0) {
        return false;
      } else {
        return true;
      }
    },
    async handleNext(data) {
      this.dropDownServerParams[data.pointer].params.pageNumber += 1;
      await this.getDropDownData(data);
    },
    async handlePrev(data) {
      this.dropDownServerParams[data.pointer].params.pageNumber -= 1;
      await this.getDropDownData(data);
    },
    async handleNextBank() {
      this.dropDownServerParams.bankParams.params.pageNumber += 1;
      await this.getBankList();
    },
    async handlePrevBank() {
      this.dropDownServerParams.bankParams.params.pageNumber -= 1;
      await this.getBankList();
    },
    async handleNextSecondProv() {
      this.dropDownServerParams.province2.pageNumber += 1;
      await this.getSecondProvince();
    },
    handleSelectedValue(param, data) {
      this.selectedDropDown[data.pointer] = param;
    },
    handleSelectedProfesi(param) {
      this.selectedDropDown.profesi = param;
    },
    handleSelectedBank(param) {
      this.selectedDropDown.bank = param;
      let obj = this.dropDownServerParams.bankParams.content.find(
        (o) => o.label === param.label
      );
      this.codeBank = obj.code;
    },
    handleOnSearch(query, data) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.dropDownServerParams[data.pointer].params.searchValue = query;
      this.timer = setTimeout(async () => {
        await this.getDropDownData(data);
      }, 800);
    },
    async handleInput(val, data, impact) {
      if (data.category === "province") {
        this[impact.city] = null;
        this.dropDownServerParams[impact.city].params.pageNumber = 0;
        this.selectedDropDown[impact.city] = null;
        if (val !== null) {
          this.dropDownServerParams[impact.city].params.idProvince = val;
          await this.getDropDownData({
            category: "city",
            pointer: impact.city,
          });
        } else {
          this.dropDownServerParams[impact.city].content = [];
          this.dropDownServerParams[impact.city].totalElments = 0;
        }
      }
    },
    handleBankInput(val) {
      if (val === null) {
        this.selectedDropDown.bank = null;
        this.codeBank = null;
      }
    },
    getReferralList() {
      this.isLoading = true
      // Cancel previous call
      referralService
        .getReferralList(this.dropDownServerParams.referralParams.params)
        .then((rsp) => {
          this.dropDownServerParams.referralParams.content = rsp.data.data ? rsp.data.data.content : [];
          this.dropDownServerParams.referralParams.totalElments = rsp.data.totalElements ? rsp.data.totalElements : 0;
        })
        .catch((e) => {
          console.log(e)
          this.isLoading = false
          this.$notify({
            type: "error",
            title: "Error Notification",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    async handleNextReferral() {
      this.dropDownServerParams.referralParams.params.pageNumber += 1;
      await this.getReferralList();
    },
    async handlePrevReferral() {
      this.dropDownServerParams.referralParams.params.pageNumber -= 1;
      await this.getReferralList();
    },

  },
  validations() {
    return {
      nik: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      name: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      gender: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      birthDate: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      address: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      province: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      city: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      postcode: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      phone: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      email: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      job: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      namaPemilikBank: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      namaBank: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      codeBank: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      nomorRekening: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
      cabangBank: {
        required: requiredIf(function () {
          return !this.isReadOnly;
        }),
      },
    };
  },
  computed: {
    isReadOnly() {
      if (this.status === "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
    paginatedProvince() {
      return this.$store.getters["regional/getProvince"];
    },
    paginatedCity() {
      return this.$store.getters["regional/getCity"];
    },
    hasProvNextPage() {
      let currentPage =
        (this.dropDownServerParams.pageNumber + 1) *
        this.dropDownServerParams.pageSize;
      if (currentPage < this.$store.state.regional.province.totalElements) {
        return true;
      } else {
        return false;
      }
    },
    hasPrevProvPage() {
      if (this.dropDownServerParams.pageNumber === 0) {
        return false;
      } else {
        return true;
      }
    },
    hasCityNextPage() {
      let currentPage =
        (this.dropDownServerParams.pageNumber + 1) *
        this.dropDownServerParams.pageSize;
      if (currentPage < this.$store.state.regional.city.totalElements) {
        return true;
      } else {
        return false;
      }
    },
    hasPrevCityPage() {
      if (this.dropDownServerParams.pageNumber === 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  async mounted() {
    this.getReferralList();
    await this.handleDetail();
    await this.getDropDownData({ category: "province", pointer: "province" });
    if (this.province !== null) {
      await this.getDropDownData({ category: "city", pointer: "city" });
    }
    await this.getProfesiList();
    await this.getBankList();
  },
};
</script>
<style scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
