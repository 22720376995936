<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showProductPageSectionThree"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Manfaat Amore Health"
        v-model="value.sectionTitle"
      />
    </div>
    <panel
      v-for="(item, index) in value.pageSubSections"
      :key="index"
      :title="`Item ${item.sequence}`"
      variant="grey"
      :is-collapse="true"
      :XXcheckbox-hidden="true"
      :show-collapse="index > 0 ? false : true"
      v-model="item.isShow"
      :checkbox-name="`listItem-${index}`"
    >
      <div class="mb-15px">
        <label class="form-label">Title</label>
        <input
          type="text"
          class="form-control"
          placeholder="Apa Itu Amore Health"
          v-model="item.subSectionTitle"
        />
      </div>
      <div class="mb-15px">
        <label class="form-label">Icon</label>
        <b-form-file
          @input="handleIconUpload(item, $event)"
          :placeholder="item.iconFileName"
          accept="image/*"
          :multiple="false"
        ></b-form-file>
        <p class="mb-0 mt-1 small">64px x 64px .png file</p>
      </div>
      <vue-editor
        v-model="item.content"
        :editor-toolbar="$options.defaultToolbar"
      />
    </panel>
  </panel>
</template>
<script>
import base64Converter from "@/pages/Helper/base64";
import { defaultToolbar } from "@/config/Helpers";
export default {
  defaultToolbar,
  name: "LIST_PRODUCT",
  title: "List",
  props: ["value"],
  methods: {
    async handleIconUpload(item, val) {
      if (val) {
        item.icon = await base64Converter(val);
      }
    },
  },
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>