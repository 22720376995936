import api from "./api";
import { IdentityService } from "./identify.service";

class UserService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('auth')
    }

    getListUser(serverParams) {
        return this.api.get("/user", { params: serverParams });
    }

    getUserById(serverParams) {
        return this.api.get("/user/" + serverParams);
    }

    postUser(serverParams) {
        return this.api.post("/user", { ...serverParams });
    }

    deleteUserById(serverParams) {
        return this.api.delete("/delete/user/" + serverParams);
    }

    putUser(id, serverParams) {
        return this.api.put("/user/" + id, { ...serverParams });
    }

    checkLogin() {
        return this.api.get("/auth");
    }

    login(payload) {
        return this.api.post("/auth", payload);
    }

    logout() {
        return this.api.delete("/auth");
    }
}

export default new UserService();