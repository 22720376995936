<template>
    <div class="product-form">
        <FormOrganization form-type="add" @onValidateSuccess="doSave" :on-loading="onProgress" />
    </div>
</template>
<script>
import FormOrganization from './Form.vue'
import base64 from '../../Helper/base64'
import CharityService from "../../../service/charity.service";
export default {
    components: {
        FormOrganization,
    },
    data() {
        return {
            onProgress: false,
        };
    },
    methods: {
        async doSave(payload) {
            // Submit here
            this.onProgress = true
            let reg = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
            // console.log(payload, 'payload')
            if (payload) {
                let { organization, status } = payload
                this.logo(organization.logo)
                payload = {
                    organizationName: organization.title,
                    description: organization.description.replace(/<\/?[^>]+>/ig, ""),
                    websiteUrl: organization.website,
                    logo: await this.logo(organization.logo),
                    status: status
                }
                CharityService
                    .postCharity(payload)
                    .then((rsp) => {
                        this.onProgress = false
                        this.$swal({
                            title: "Data berhasil disimpan",
                            showCloseButton: true,
                        }).then(() => {
                            if (status === "PUBLISHED") {
                                this.$router.push({ path: '/sections/charity-organizations' })
                            } else if (status === "DRAFTS") {
                                this.$router.push({ path: '/sections/charity-organizations/drafts' })
                            }
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error Notification',
                            text: e.response?.data?.responseMessage || 'Terjadi Kesalahan'
                        });
                    })
            }
        },
        async logo(value) {
            // this.base64
            // console.log(await base64(value))
            return await base64(value)
        }
        // getNow: function () {
        //     const today = new Date().getTime()
        //     // const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        //     console.log(today, 'date');
        //     // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //     // const dateTime = date + ' ' + time;
        //     // return dateTime;
        // }
    }
}
</script>