<template>
  <form
    @submit.prevent="doSave"
    class="pb-5 mb-4"
    autocomplete="off"
    v-if="ready"
  >
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} User</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Username"
            v-model="user.username"
            name="username"
            autocomplete="off"
            value=""
          />
          <span class="text-danger" v-if="$v.user.username.$error"
            >Username is required</span
          >
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="form-label">Role</label>
            <v-select v-model="user.role" :options="roles" label="name" />
            <span class="text-danger" v-if="$v.user.role.$error"
              >Role is required</span
            >
          </div>
        </div>
        <div class="row mt-4" v-if="usePassword">
          <div class="col-md-4">
            <label class="form-label"
              >Password
              <template v-if="this.formType != 'add'"
                >(Ignore if unchanged)</template
              >
            </label>
            <input
              v-model="user.password"
              type="password"
              label="Password"
              class="form-control"
              name="password"
              autocomplete="off"
              value=""
            />
            <span class="text-danger" v-if="$v.user.password.$error"
              >Password is required</span
            >
          </div>
        </div>
        <div class="row mt-4" v-if="authDriver == 'remote'">
          <div class="col">
            <span class="alert alert-info">
              User dengan role <b>Selain admin</b>, harus sudah terdaftar di
              Generali Active Directory
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button
            type="submit"
            class="btn btn-primary"
            :class="{ disabled: onLoading }"
          >
            <span
              v-show="onLoading"
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import roleService from "@/service/role.service";
import { required, minLength, between } from "vuelidate/lib/validators";
export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    // console.log(this.detailData, 'detailData')
    await this.loadItems();
    if (this.detailData) {
      this.user = this.detailData;
    }
    this.$nextTick(() => (this.ready = true));
  },
  data() {
    return {
      authDriver: null,
      ready: false,
      roles: [],
      user: {
        username: "",
        password: "",
        role: {},
      },
      serverParams: {
        searchField: '',
        searchValue: '',
        sort: 'name',
        direction: 'ASC',
        pageNumber: 1,
        perSize: 50,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    usePassword() {
      return this.user.role?.id == "admin" || this.authDriver == "local";
    },
  },
  methods: {
    doSave() {
      this.$v.user.$touch();

      if (!this.$v.user.$invalid) {
        const payload = {
          username: this.user.username,
          role: this.user.role,
        };

        if (this.usePassword && this.user.password) {
          payload.password = this.user.password;
        }

        this.$emit("onValidateSuccess", payload);
      }
    },
    loadItems() {
      return roleService
        .getListRole(this.serverParams)
        .then((rsp) => {
          this.authDriver = rsp.data.data.authDriver;
          this.roles = rsp.data.data.content;
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
  },
  validations: {
    user: {
      username: { required },
      password: {},
      role: { required },
    },
  },
};
</script>
