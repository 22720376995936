<template>
  <div>
    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
        initialSortBy: { field: 'lastUpdated', type: 'desc' },
      }"
      styleClass="vgt-table"
      slot="outsideBody"
    >
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input
          ref="nama"
          class="form-control"
          placeholder="Search name"
          :class="{
            disabled: isSearchById,
          }"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'nama'
          "
          @input="(value) => updateFilters(column, $refs.nama.value)"
        />
        <input
          ref="nomorSpaj"
          class="form-control"
          placeholder="Search SPAJ"
          :class="{
            disabled: isSearchById,
          }"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'nomorSpaj'
          "
          @input="(value) => updateFilters(column, $refs.nomorSpaj.value)"
        />
        <input
          ref="id"
          class="form-control"
          placeholder="Search id"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'id'
          "
          @input="(value) => updateFilters(column, $refs.id.value)"
        />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto" v-if="props.row.submissionStatus < 7">
              <router-link
                :to="`/submissions/forms/${props.row.id}/step-${props.row.submissionStatus}`"
                class="btn btn-lg text-dark"
                v-b-tooltip.hover
                title="Edit"
              >
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="props.row.submissionStatus === 7">
              <router-link
                :to="`/submissions/forms/${props.row.id}/step-8`"
                class="btn btn-lg text-dark"
                v-b-tooltip.hover
                title="View Documents"
              >
                <i class="fas fa-file"></i>
              </router-link>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-lg text-dark"
                v-b-tooltip.hover
                title="Detail Data"
                @click="handleDetail(props.row.id)"
              >
                <i class="fas fa-eye"></i>
              </button>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-lg text-dark"
                v-b-tooltip.hover
                title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <div class="col-auto">
              <b-dropdown
                :class="{
                  disabled: onLoading,
                }"
                variant="outline-secondary"
                size="sm"
              >
                <template slot="button-content">
                  More <i class="fa fa-chevron-down"></i>
                </template>
                <a
                  v-if="props.row.coreStatus.DMS"
                  @click.prevent="sendApi(props.row.id, 'resendDMS')"
                  href="javascript:;"
                  :class="{
                    disabled:
                      onLoading ||
                      !props.row.coreStatus.DMS.error ||
                      props.row.coreStatus.DMS.error === null,
                  }"
                  class="dropdown-item"
                  >Send API DMS</a
                >
                <a
                  v-if="props.row.coreStatus.BPM"
                  @click.prevent="sendApi(props.row.id, 'resendBPM')"
                  href="javascript:;"
                  :class="{
                    disabled:
                      onLoading ||
                      !props.row.coreStatus.BPM.error ||
                      props.row.coreStatus.BPM.error === null,
                  }"
                  class="dropdown-item"
                  >Send API BPM</a
                >
                <a
                  v-if="props.row.coreStatus.ECARE"
                  @click.prevent="sendApi(props.row.id, 'resendEcare')"
                  href="javascript:;"
                  class="dropdown-item"
                  :class="{
                    disabled:
                      onLoading ||
                      !props.row.coreStatus.ECARE.error ||
                      props.row.coreStatus.ECARE.error === null,
                  }"
                  >Send API E-Care</a
                >
              </b-dropdown>
            </div>
          </div>
        </span>
        <span v-if="props.column.field === 'lastUpdated'">
          {{
            props.formattedRow[props.column.field]
              ? moment(props.formattedRow[props.column.field]).format(
                  "MMMM DD YYYY, h:mm:ss a"
                )
              : ""
          }}
        </span>
        <span v-if="props.column.field === 'coreSystemStatus'">
          <div v-if="props.row.coreStatus.BPM">
            <h6>
              BPM ({{ props.row.coreStatus.BPM.error ? "Error" : "Success" }})
            </h6>
            <ul>
              <li
                v-for="(item, index) in props.row.coreStatus.BPM.message"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>

          <div v-if="props.row.coreStatus.DMS">
            <h6>
              DMS ({{ props.row.coreStatus.DMS.error ? "Error" : "Success" }})
            </h6>
            <ul>
              <li
                v-for="(item, index) in props.row.coreStatus.DMS.message"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>

          <div v-if="props.row.coreStatus.ECARE">
            <h6>
              ECARE ({{ props.row.coreStatus.ECARE.error ? "Error" : "Success" }})
            </h6>
            <ul>
              <li
                v-for="(item, index) in props.row.coreStatus.ECARE.message"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import registrationService from "@/service/registration.service";
import submition from "@/service/submition.service";
import internalCoreService from "../../service/internalcore.service";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: false,
      onLoading: false,
      isSearchById: false,
      columns: [
        {
          label: "Name",
          field: "nama",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "SPAJ",
          field: "nomorSpaj",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          width: "125px",
          sortable: true,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Customer Registration Id",
          field: "id",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Submission status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Payment status",
          field: "paymentStatus",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Last Step Status",
          field: "menu",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
        },
        {
          label: "Core System Status",
          field: "coreSystemStatus",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Last updated",
          field: "lastUpdated",
          sortable: true,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      moment: moment,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "name",
            type: "ASC",
          },
          {
            field: "lastUpdated",
            type: "ASC",
          },
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async sendApi(id, kind) {
      this.onLoading = true;
      // this.$refs.ddown.hideMenu();
      await submition
        .sendData(id, kind)
        .then(() => {
          this.$swal({
            title: "Permintaan telah dikirim",
            showCloseButton: true,
          });
        })
        .catch(() => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        })
        .finally(() => {
          this.onLoading = false;
        });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      if (params.columnFilters.id !== "") {
        this.isSearchById = true;
      } else {
        this.isSearchById = false;
      }
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (this.isSearchById !== true) {
          await registrationService
            .getSubmitionList(this.setPaginationParams())
            .then(
              async (response) => {
                if (
                  response.data.responseCode == "200" &&
                  response.data.responseMessage == "Data's Empty!"
                ) {
                  this.rows = [];
                  this.totalRecords = 0;
                } else {
                  const embededRow = await this.setStatusCore(
                    response.data?.data?.content
                  );
                  this.rows = _.orderBy(
                    embededRow,
                    this.serverParams.sort[0].field,
                    this.serverParams.sort[0].type
                  );
                  this.totalRecords = response.data?.data?.totalElements;
                }
              },
              () => {
                alert("Something Wrong");
                this.rows = [];
                this.totalRecords = 0;
              }
            );
        } else {
          await this.$store
            .dispatch(
              "registration/getSingleSubmission",
              this.serverParams.columnFilters.id
            )
            .then(
              async (res) => {
                if (
                  res.data.responseCode == "200" &&
                  res.data.responseMessage == "Data Not Found"
                ) {
                  this.rows = [];
                  this.totalRecords = 0;
                } else {
                  let arrayData = [];
                  arrayData.push(res.data.data.customerRegistration);
                  const embededRow = await this.setStatusCore(arrayData);
                  this.rows = _.orderBy(
                    embededRow,
                    this.serverParams.sort[0].field,
                    this.serverParams.sort[0].type
                  );
                  this.totalRecords = 1;
                }
              },
              () => {
                this.$swal({
                  title: "Terjadi Kesalahan",
                  showCloseButton: true,
                });
              }
            );
        }
      }, 800);
    },
    showAlertConfirm(id) {
      this.$swal
          .fire({
              title: "",
              text: "Apakah Data Akan Di Hapus?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ya",
          })
          .then(async (result) => {
              if (result.value) {
                await this.onDelete(id);
              }
          });
    },
    onDelete(id) {
        registrationService
            .deleteSubmitionId(id)
            .then((rsp) => {
                this.$notify({
                    type: "succes",
                    title: "Success message",
                    text: "Delete Success",
                });
                this.loadItems();
            })
            .catch((e) => {
                this.$swal({
                    icon: "warning",
                    text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                });
            });
    },
    setPaginationParams() {
      let body = {
        searchField: "",
        searchValue: "",
        pageNumber: this.serverParams.page - 1,
        pageSize: this.serverParams.perPage,
        status: "COMPLETE",
        menu: "Thank You",
      };
      if (this.serverParams.sort[0].type !== "none") {
        (body.direction = this.serverParams.sort[0].type.toUpperCase()),
          (body.sort = this.serverParams.sort[0].field);
      }
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters.hasOwnProperty(key)) {
          if (this.serverParams.columnFilters[key] !== "") {
            if (key === "nama") {
              body.name = this.serverParams.columnFilters[key];
            }
            if (key === "id") {
              body.id = this.serverParams.columnFilters[key];
            }
            if (key === "nomorSpaj") {
              body.nomorSpaj = this.serverParams.columnFilters[key];
            }
          }
        }
      }
      return body;
    },
    async handleDetail(id) {
      await this.$store.dispatch("registration/getSingleSubmission", id).then(
        () => {
          this.$router.push(`/submissions/forms/${id}/step-1`);
        },
        () => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
      );
    },
    async setStatusCore(data) {
      let promises = [];
      let rowTemporary = [];
      for (let i = 0; i < data.length; i++) {
        promises.push(
          internalCoreService.getStatus(data[i].id).then((res) => {
            const status = this.grouping(res.data.data);
            data[i]["coreStatus"] = status;
            rowTemporary.push(data[i]);
          })
        );
      }
      await Promise.all(promises);
      return rowTemporary;
    },
    grouping(data) {
      let indikator = {
        error: 0,
        success: 0,
        statusIsNull: 0,
      };
      let result = {};
      let messageTemp = [];
      if (data.length !== 0) {
        let grouped = _.groupBy(data, "coreType");
        for (const key in grouped) {
          indikator.error = 0;
          indikator.success = 0;
          messageTemp = [];
          grouped[key].forEach((val) => {
            if (val.status === "SUCCESS") {
              indikator.success++;
            } else {
              indikator.error++;
              if (val.status === null) {
                messageTemp.push("Core type status is null");
              } else {
                messageTemp.push(val.message);
              }
            }
          });
          if (indikator.success !== 0) {
            result[key] = {
              error: false,
              message: ["Success"],
            };
          } else {
            result[key] = {
              error: true,
              message: messageTemp,
            };
          }
        }
      } else {
        result = {
          ECARE: { error: true, message: ["Empty or Null Value"] },
          BPM: { error: true, message: ["Empty or Null Value"] },
          DMS: { error: true, message: ["Empty or Null Value"] },
        };
      }
      return result;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
<style lang="scss" scoped>
.document-logo {
  height: auto;
  width: 100px;
}
.form-check-label {
  height: 50px;
}
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
