<template>
  <div class="product-form">
    <FormPopUp form-type="edit" :detail-data="detail" :on-loading="onProgress" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPopUp from './FormPopUp.vue'

import ConvertToBase64 from "@/pages/Helper/base64";
import popUpService from '@/service/popUp.service';


export default {
  data() {
    return {
      onProgress: false,
      detail: {
        id: this.$route.params.id,
        title: "",
        url: "",
        description: "",
        images: {
          desktop: null,
          mobile: null,
        },
      },
    };
  },
  components: {
    FormPopUp,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      // console.log(payload, 'payload');
      this.onProgress = true
      let { banner, status } = payload
      if (banner && status) {
        payload = {
          id: banner.id,
          title: banner.title,
          url: banner.url,
          mainImageDesktop: banner.images.desktop.length != 0 ? await ConvertToBase64(banner.images.desktop[0]) : null,
          mainImageMobile: banner.images.mobile.length != 0 ? await ConvertToBase64(banner.images.mobile[0]) : null,
          status
        }
        popUpService
          .putPopUp(banner.id, payload)
          .then((rsp) => {
            this.onProgress = false
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.$router.push({ path: '/redeem/popup' })
            });
          }).catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          })
      }
    },
    loadItems(id) {
      popUpService
        .getPopUpById(id)
        .then(async (rsp) => {
          // console.log(rsp)
          this.detail = {
            id: this.$route.params.id,
            title: rsp.data.data.title,
            url: rsp.data.data.url,
            images: {
              desktop: [],
              mobile: [],
              desktopSrc: rsp.data.data.mainImageDesktopFileName ? popUpService.getFileUrl(rsp.data.data.mainImageDesktopFileName) : '',
              mobileSrc: rsp.data.data.mainImageMobileFileName ? popUpService.getFileUrl(rsp.data.data.mainImageMobileFileName) : ''
            },
          }
        }).catch((e) => {
          console.error(e)
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
  async mounted() {
    await this.loadItems(this.$route.params.id)
  },
};
</script>
