<template>
  <div>
    <!-- BEGIN page-header -->
    <h1 class="page-header">Dashboard</h1>
    <!-- END page-header -->

    <!-- BEGIN row -->
    <div class="panel">
      <div class="panel-heading">
        <div class="row">
          <label class="form-label col-form-label col-auto">Date range</label>
          <div class="form-group col-auto">
            <date-range-picker ref="picker" class="btn btn-white" :opens="dateRange.opens"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" :singleDatePicker="dateRange.singleDatePicker"
              :timePicker="dateRange.timePicker" :timePicker24Hour="dateRange.timePicker24Hour"
              :showWeekNumbers="dateRange.showWeekNumbers" :showDropdowns="dateRange.showDropdowns"
              :autoApply="dateRange.autoApply" v-model="dateRange.range" @update="updateValues"
              :linkedCalendars="dateRange.linkedCalendars">
              <template v-slot:input="picker">
                <i class="fa fa-calendar fa-fw ms-n1"></i>
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                <b class="caret ms-1 opacity-5"></b>
              </template>
            </date-range-picker>
          </div>
          <div class="col-auto">
            <button type="button" :class="{ disabled: isLoading, 'btn btn-outline-primary': true }"
              class="btn btn-outline-primary" @click.prevent="loadItems">
              <span v-show="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              Generate
            </button>
          </div>
          <div class="col-auto">
            <b-dropdown variant="outline-secondary">
              <template slot="button-content">
                Download <i class="fa fa-chevron-down"></i>
              </template>
              <a href="javascript:;" class="dropdown-item">
                <download-excel :data="[numbers]" :fields="json_fields" type="csv">
                  CSV
                </download-excel>
              </a>
              <a href="javascript:;" class="dropdown-item">
                <download-excel :data="[numbers]" :fields="json_fields" type="xls">
                  Excel
                </download-excel>
              </a>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xl">
        <div class="widget widget-stats bg-primary">
          <div class="stats-info">
            <h4 class="text-uppercase">Total Visitor</h4>
            <p>{{ numbers.totalVisitor }}</p>
          </div>
          <div class="stats-link">
            <!-- <a href="#"> -->
            <router-link to="/analytics/performance-metric">
              <div class="row align-items-center justify-content-between">
                <div class="col-auto">
                  View Metric
                </div>
                <div class="col-auto">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </router-link>
            <!-- </a> -->
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl">
        <div class="widget widget-stats bg-orange">
          <div class="stats-info">
            <h4 class="text-uppercase">Total Submissions</h4>
            <p>{{ numbers.totalSubmissions }}</p>
          </div>
          <div class="stats-link">
            <router-link to="/submission">
              <div class="row align-items-center justify-content-between">
                <div class="col-auto">
                  View Metric
                </div>
                <div class="col-auto">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl">
        <div class="widget widget-stats bg-yellow">
          <div class="stats-info text-dark">
            <h4 class="text-uppercase text-dark">TOTAL PEMBELIAN POLIS</h4>
            <p>{{ numbers.totalPolis }}</p>
          </div>
          <div class="stats-link">
            <router-link to="/submission">
              <div class="row align-items-center justify-content-between">
                <div class="col-auto">
                  View Metric
                </div>
                <div class="col-auto">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl">
        <div class="widget widget-stats bg-lime">
          <div class="stats-info">
            <h4 class="text-uppercase">TOTAL ERROR</h4>
            <p>{{ numbers.totalError }}</p>
          </div>
          <div class="stats-link">
            <router-link to="/analytics/dropoff-issue">
              <div class="row align-items-center justify-content-between">
                <div class="col-auto">
                  View Metric
                </div>
                <div class="col-auto">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl">
        <div class="widget widget-stats bg-green" style="min-height: 122.4px">
          <div class="stats-info">
            <h4 class="text-uppercase">TOTAL APE (Rp)</h4>
            <p>{{ numbers.totalSumPolis }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- END row -->
    <!-- BEGIN panel -->
    <panel title="All Visitor by Time" :noButton="true">
      <b-tabs pills nav-class="nav-pills--underline bg-white">
        <b-tab active v-if="lineChartDaily.data.labels.length > 0">
          <template slot="title"> Daily </template>
          <line-chart :data="lineChartDaily.data" :options="lineChartDaily.options" class="h-300px"></line-chart>
        </b-tab>
        <b-tab v-if="lineChartWeek.data.labels.length > 0">
          <template slot="title"> Weekly </template>
          <line-chart :data="lineChartWeek.data" :options="lineChartWeek.options" class="h-300px"></line-chart>
        </b-tab>
        <b-tab v-if="lineChartMonthly.data.labels.length > 0">
          <template slot="title">Monthly </template>
          <line-chart :data="lineChartMonthly.data" :options="lineChartMonthly.options" class="h-300px"></line-chart>
        </b-tab>
      </b-tabs>
    </panel>
    <!-- END panel -->
    <!-- <panel title="Google Analytics 4 Report" :noButton="true">
      <iframe class="col-md-12 col-xl" height="3542"
        src="https://lookerstudio.google.com/embed/reporting/6daadc1c-765f-4085-a5c4-b1ee1e782ad8/page/kIV1C"
        frameborder="0" style="border:0" allowfullscreen></iframe>
    </panel> -->
  </div>
</template>

<script>
import LineChart from "../components/vue-chartjs/LineChart";
import moment from "moment";
import dashboardService from "@/service/dashboard.service";
import activityService from "@/service/activity.service";
import { GoogleSpreadsheet } from 'google-spreadsheet';

export default {
  components: {
    LineChart,
  },
  data() {
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontColor = "#2d353c";
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontFamily =
      "Open Sans, Helvetica Neue,Helvetica,Arial,sans-serif";
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontStyle = "600";

    let today = new Date();
    var eventsData = [
      {
        date: `${today.getFullYear()}/${today.getMonth() + 1}/15`,
        title: "Sales Reporting",
        badge: "bg-gradient-teal",
        time: "9:00am",
      },
      {
        date: `${today.getFullYear()}/${today.getMonth() + 1}/24`,
        title: "Have a meeting with sales team",
        badge: "bg-gradient-blue",
        time: "2:45pm",
      },
    ];

    return {
      json_fields: {
        "totalVisitor": "totalVisitor",
        "totalSubmissions": "totalSubmissions",
        "totalPolis": "totalPolis",
        "totalError": "totalError",
        "totalSumPolis": "totalSumPolis"
      },
      isLoading: false,
      numbers: {
        totalVisitor: 0,
        totalSubmissions: 0,
        totalPolis: 0,
        totalError: 0,
        totalSumPolis: 0,
      },
      dateRange: {
        opens: "right",
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        linkedCalendars: false,
        range: {
          startDate: moment(new Date('01/01/2023')),
          endDate: moment(),
          prevStartDate: moment(new Date('01/01/2023')),
          prevEndDate: moment().subtract("days", 8).format("D MMMM YYYY"),
        },
      },
      lineChartDaily: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Total Visitor",
              backgroundColor: "transparent",
              borderColor: "#FF5B57",
              pointBackgroundColor: "#FFFFFF",
              pointRadius: 3,
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tension: 100,
          hover: {
            mode: "nearest",
            intersect: true,
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 50,
                },
              },
            ],
          },
        },
        noData: {
          text: "No Data",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        }
      },
      lineChartWeek: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Total Visitor",
              backgroundColor: "transparent",
              borderColor: "#FF5B57",
              pointBackgroundColor: "#FFFFFF",
              pointRadius: 3,
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tension: 100,
          hover: {
            mode: "nearest",
            intersect: true,
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 100,
                },
              },
            ],
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
      },
      lineChartMonthly: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Total Visitor",
              backgroundColor: "transparent",
              borderColor: "#FF5B57",
              pointBackgroundColor: "#FFFFFF",
              pointRadius: 3,
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tension: 100,
          hover: {
            mode: "nearest",
            intersect: true,
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 100,
                },
              },
            ],
          },
        },
      },
      events: {
        data: eventsData,
        displayData: eventsData,
      },
    };
  },
  computed: {
    numbers_array: () => {
      // console.log(this.numbers)
      let numbers = {
        totalVisitor: 0,
        totalSubmissions: 0,
        totalPolis: 0,
        totalError: 0,
        totalSumPolis: 0,
      }
      return [numbers]
    },
  },
  methods: {
    updateValues(d) {
      var startDate = moment(d.startDate);
      var endDate = moment(d.endDate);
      var gap = endDate.diff(startDate, "days");

      this.dateRange.range.prevStartDate = moment(startDate)
        .subtract("days", gap)
        .format("D MMMM YYYY");
      this.dateRange.range.prevEndDate = moment(startDate)
        .subtract("days", 1)
        .format("D MMMM YYYY");
      // console.log(this.dateRange);
    },
    formatDate(d) {
      var monthsName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      d = new Date(d);
      d = monthsName[d.getMonth()] + " " + d.getDate();
      return d;
    },
    loadItems() {
      let payload =
      {
        startDate: moment(this.dateRange.range.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.dateRange.range.endDate).format("YYYY-MM-DD"),
      }
      this.isLoading = true
      this.timer = setTimeout(() => {
        this.loadSubmissionCount(payload)
        this.loadPolisCount(payload)
        this.loadError(payload)
        this.loadPolisSum(payload)
        this.getVusitorByTime('daily', payload)
        this.getVusitorByTime('weekly', payload)
        this.getVusitorByTime('monthly', payload)
        this.getVisitorGS(payload)
        this.isLoading = false
      }, 800);
    },
    loadSubmissionCount(payload) {
      return dashboardService
        .getSubmissionCount(payload)
        .then((rsp) => {
          this.numbers.totalSubmissions = rsp.data.data ? rsp.data.data : 0
        });
    },
    loadPolisCount(payload) {
      return dashboardService
        .getPaymentCountSucces(payload)
        .then((rsp) => {
          this.numbers.totalPolis = rsp.data.data ? rsp.data.data : 0
        });
    },
    loadError(payload) {
      let object = {
        query: JSON.stringify({
          bool: {
            filter: {
              range: {
                issueTime: {
                  gte: moment(payload.startDate)
                    .startOf("day")
                    .format("YYYYMMDDTHHmmss.SSSZ"),
                  lte: moment(payload.endDate)
                    .endOf("day")
                    .format("YYYYMMDDTHHmmss.SSSZ"),
                }
              }
            }
          }
        })
      }
      Object.assign(payload, object)

      return activityService
        .getMicrositeIssue(payload)
        .then((rsp) => {
          this.numbers.totalError = rsp.data.data ? rsp.data.data.totalElements : [];
        })
    },
    loadPolisSum(payload) {
      return dashboardService
        .getPaymentSumSuccess(payload)
        .then((rsp) => {
          this.numbers.totalSumPolis = rsp.data.data ? this.numberWithCommas(rsp.data.data) : 0
          Promise.resolve(rsp.data)
        })
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    },
    getVisitorGS(payload) {
      return dashboardService.getTotalVisitor(payload)
        .then((r) => this.numbers.totalVisitor = this.numberWithCommas(r.data.data))
        .catch((e) => e);
    },
    async getVusitorByTime(time, payload) {
      try {
        switch (time) {
          case 'daily':
            let dailyVisitor = (await dashboardService.getDailyVisitor(payload)).data?.data;
            var filter = []
            var data = []
            for (let [k, v] of Object.entries(dailyVisitor)) {
              filter.push(v.date)
              data.push(v.visitor)
            }
            this.lineChartDaily.data.labels = filter
            this.lineChartDaily.data.datasets[0].data = data
            break;
          case 'monthly':
            let monthlyVisitor = (await dashboardService.getMontlyVisitor(payload)).data?.data;
            var filter = []
            var data = []
            for (let [k, v] of Object.entries(monthlyVisitor)) {
              filter.push(v.month)
              data.push(v.visitor)
            }
            this.lineChartMonthly.data.labels = filter
            this.lineChartMonthly.data.datasets[0].data = data
            break;
          case 'weekly':
            let weeklyVisitor = (await dashboardService.getWeeklyVisitor(payload)).data?.data;
            var filter = []
            var data = []
            for (let [k, v] of Object.entries(weeklyVisitor)) {
              filter.push('Week ke ' + v.week)
              data.push(v.visitor)
            }
            this.lineChartWeek.data.labels = filter
            this.lineChartWeek.data.datasets[0].data = data
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  filters: {
    date: function (value) {
      if (value) {
        return moment(String(value)).format("D MMMM YYYY");
      }
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
