<template>
  <div class="pb-5">
    <form>
      <div class="panel">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-5">
              <div
                class="form-check form-check-button mb-2"
                v-for="(item, index) in documentList"
                :key="index"
              >
                <input
                  class="form-check-input"
                  :class="{ 'd-none': !isSelect }"
                  type="checkbox"
                  name="document"
                  :id="`document-${index}`"
                  :value="item.label"
                  :disabled="!isSelect"
                  v-model="document"
                />
                <label
                  class="form-check-label-docs py-2 d-flex align-items-center justify-content-between"
                  :class="{ 'ps-3': !isSelect }"
                  :for="`document-${index}`"
                >
                  <span class="fw-normal">
                    {{ item.label }}
                  </span>
                  <a
                    :href="item.url"
                    target="_blank"
                    v-b-tooltip.hover
                    title="Preview"
                    :class="{
                      disabled: isReadOnly,
                    }"
                  >
                    <i
                      :class="`fas ${item.isShow ? 'fa-eye' : 'fa-eye-slash'}`"
                    ></i>
                  </a>
                </label>
              </div>
            </div>
          </div>
          <button v-if="isSelect" type="submit" class="btn btn-primary mt-2">
            Send to Customer’s Email
          </button>
        </div>
      </div>
      <div class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button
              :class="{ disabled: onLoading }"
              @click.prevent="sendApi()"
              class="btn btn-primary"
            >
              <span
                v-show="onLoading"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              Resend Email
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import submition from "@/service/submition.service";
export default {
  data() {
    return {
      isSelect: false,
      status: false,
      onLoading: false,
      document: [],
      documentList: [
        {
          label: "Summary Pembelian Polis (SPAJ)",
          isShow: true,
          url: `${process.env.VUE_APP_FRONTEND}/print-spaj?id=${this.$route.params.id}&print=false`,
        },
        {
          label: "Riplay Polis (Ilustrasi)",
          isShow: true,
          url: `${process.env.VUE_APP_FRONTEND}/print-riplay?id=${this.$route.params.id}&print=false`,
        },
        {
          label: "Haldoc Document",
          isShow: true,
          url: `${process.env.VUE_APP_FRONTEND}/web/cms/pdf/view/Panduan`,
        },
      ],
    };
  },
  methods: {
    getId() {
      return this.$route.params.id;
    },
    async sendApi() {
      this.onLoading = true;
      await submition
        .sendData(this.$route.params.id, "resendEmail")
        .then(() => {
          this.$swal({
            title: "Permintaan telah dikirim",
            showCloseButton: true,
          });
        })
        .catch(() => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        })
        .finally(() => {
          this.onLoading = false;
        });
    },
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            this.status = res.data.data.customerRegistration?.status;
            this.documentList[2].url = res.data.data.haloDocUrl;
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
  },
  computed: {
    isReadOnly() {
      if (this.status !== "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    await this.handleDetail();
  },
};
</script>

<style lang="scss" scoped>
.document-logo {
  height: auto;
  width: 100px;
}
.form-check-label {
  height: 50px;
}
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>