<template>
    <div class="product-form">
        <FormQuestion form-type="add" @onValidateSuccess="doSave" :on-loading="onProgress" />
    </div>
</template>
<script>
import FormQuestion from './Form.vue'
import BmiService from "../../../service/bmi.service";
export default {
    components: {
        FormQuestion
    },
    data() {
        return {
            onProgress: false,
        };
    },
    methods: {
        async doSave(payload) {
            // console.log(payload, 'payload')
            this.onProgress = true
            payload = payload.organization
            BmiService
                .postBmi(payload)
                .then((rsp) => {
                    this.onProgress = false
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: '/sections/bmi' })
                    });
                }).catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Message",
                        text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                    });
                })
        }
    }
}
</script>