import api from "./api";

class FaqCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }
    
    getFaqCategoryList(serverParams) {
        return this.api.get("/cms/faqCategory", { params: serverParams });
    }

    postFaqCategory(serverParams) {
        return this.api.post("cms/faqCategory", { ...serverParams });
    }

    getFaqCategoryById(serverParams) {
        return this.api.get("/cms/faqCategory/" + serverParams);
    }

    putFaqCategory(id, serverParams) {
        return this.api.put("/cms/faqCategory/" + id, { ...serverParams });
    }

    deleteFaqCategoryById(serverParams) {
        return this.api.delete("/cms/delete/faqCategory/" + serverParams);
    }
}

export default new FaqCategoryService();