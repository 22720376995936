<template>
  <div>
    <div class="panel">
      <div class="panel-body p-0">
        <div class="table-responsive mb-0">
          <table class="table table-form">
            <thead>
              <tr>
                <th scope="col">Banner Name</th>
                <th scope="col">Published Date</th>
                <th scope="col">Author</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <draggable v-model="rows" tag="tbody" ghost-class="ghost" :move="checkMove" @start="dragging = true"
              @end="dragEnd">
              <tr v-for="item in rows" :key="item.id" class="bg-white">
                <td>{{ item.title }}</td>
                <td>{{ dateFormat(item.publishedDate) }}</td>
                <td>{{ item.createdBy }}</td>
                <td>
                  <div class="row g-2 align-items-center justify-content-end">
                    <div class="col-auto" v-if="$can($permissionsAll[51])">
                      <router-link :to="`/sections/banner/edit/${item.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                        title="Edit">
                        <i class="fas fa-pencil"></i>
                      </router-link>
                    </div>
                    <div class="col-auto" v-if="$can($permissionsAll[51])">
                      <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move">
                        <i class="fa-solid fa-arrows-up-down"></i>
                      </button>
                    </div>
                    <div class="col-auto">
                      <button type="button" @click.prevent="showAlertConfirm(item.id)" class="btn btn-lg text-dark"
                        v-b-tooltip.hover title="Move to Trash">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import bannerService from "@/service/banner.service";
import debounce from "lodash.debounce";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      isLoading: false,
      dragging: false,
      rows: [],
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
        status: ''
      },
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    loadItems() {
      this.serverParams.status = 'DRAFTS'
      bannerService
        .getBannerList(this.serverParams)
        .then((rsp) => {
          this.rows = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    checkMove() {
      // console.log("dragging");
    },
    dragEnd: debounce(function () {
      const updateSequentials = [];
      for (let i = 0; i < this.rows.length; i++) {
        // console.log(i + 1, this.rows[i].title, this.rows[i].id);
        const row = this.rows[i];
        updateSequentials.push({ idBanner: row.id, sequence: i + 1 })
      }
      // console.log("Orders changed", updateSequentials);
      let payload = {
        list: updateSequentials
      }
      this.reposisiBanner(payload)
      // todo: update `sequential` fields all rows
    }, 1000),
    onDelete(id) {
      bannerService
        .changeBannerStatus(id, 'TRASHED')
        .then(() => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });

          this.$emit('reloadData', 'DRAFTS');
          this.$router.push("/sections/banner/trashed");
          this.$emit('reloadData', 'TRASHED');
          this.loadItems();
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Trash?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },

    reposisiBanner(payload) {
      bannerService
        .bannerReposisi(payload)
        .then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Banner Succes reposisi Success",
          });
          this.loadItems()
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },

    dateFormat(date) {
      if (date) {
        let newDate = new Date(date)
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour:"numeric",minute:"numeric",second:"numeric" };
        return newDate.toLocaleDateString("sv-SE", options);
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.loadItems();
  },
};
</script>
