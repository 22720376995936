<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} Post</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Post Title" v-model="post.title" />
          <span class="text-danger" v-if="$v.post.title.$error">Image Desktop is
            required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Slug</label>
                  <div class="input-group">
                    <span class="input-group-text bg-white border-end-0 text-muted">http://generali.co.id/</span>
                    <input type="text" class="form-control border-start-0 ps-0" placeholder="Post Title"
                      v-model="slug" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Post Category</label>
                  <v-select v-model="post.category" :options="optionsCategory" label="name"></v-select>
                  <span class="text-danger" v-if="$v.post.category.$error">Category is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <vue-editor v-model="post.content" :editor-toolbar="customToolbar" />
                  <span class="text-danger" v-if="$v.post.content.$error">Content is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Main Image</label>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">(dekstop version : 1280x550 px, max. 1mb)</p>
                  <UploadImage :src="post.images.desktopSrc" v-model="post.images.desktop"
                    @image-err-message="imgErrMessageDesktop" />
                  <span class="text-danger" v-if="$v.post.images.desktop.$error">Image is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">(mobile version : 600x300 px, max. 1mb)</p>
                  <UploadImage :src="post.images.mobileSrc" v-model="post.images.mobile"
                    @image-err-message="imgErrMessageMobile" />
                  <span class="text-danger" v-if="$v.post.images.mobile.$error">Image is required</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="post.meta.title" placeholder="Meta Title" class="form-control" />
            <span class="text-danger" v-if="$v.post.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="post.meta.description"
              placeholder="Meta Description"></textarea>
            <span class="text-danger" v-if="$v.post.meta.description.$error">Meta Description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="post.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.post.meta.keywords.$error">Meta Keyword is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image
              <span class="fw-normal text-muted">(Optional)</span></label>
            <UploadImage :src="post.meta.imageSrc" v-model="post.meta.image" @image-err-message="imgErrMessageSeo" />
            <!-- <span class="text-danger" v-if="$v.post.meta.image.$error">Meta Image is required</span> -->
          </div>
        </panel>
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ post.sidebar.created }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ post.sidebar.updated }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ post.sidebar.updatedBy }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Sources</p>
              <p class="mb-0">{{ post.source }}</p>
            </div>
            <a href="#" class="btn btn-outline-primary" target="_blank" @click.prevent="savePreview">Preview Post</a>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click="saveDraft" :class="{ disabled: onLoading }">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>

        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import base64Converter from "../../pages/Helper/base64";
import postCategoryService from "../../service/postcategory.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() { },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    this.loadItems();
    return {
      optionsCategory: this.optionsCategory,
      customToolbar: defaultToolbarEditor,
      post: {
        title: detail ? detail.title : null,
        category: detail ? detail.category : null,
        slug: detail ? detail.slug : null,
        content: detail ? detail.content : null,
        images: {
          desktop: detail ? detail.images.desktop : null,
          mobile: detail ? detail.images.mobile : null,
          desktopSrc: detail ? detail.images.desktopSrc : '',
          mobileSrc: detail ? detail.images.mobileSrc : '',
        },
        meta: {
          title: detail ? detail.meta.title : null,
          description: detail ? detail.meta.description : null,
          keywords: detail ? detail.meta.keywords : [],
          image: detail ? detail.meta.image : null,
          imageSrc: detail ? detail.meta.imageSrc : '',
        },
        sidebar: {
          created: detail ? detail.content : null,
          updated: detail ? detail.updated : null,
          updatedBy: detail ? detail.updatedBy : null,
        },
        source: detail ? detail.source : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    processState() {
      return this.formType === "add" ? "add" : "edit";
    },
    slug: function () {
      if (this.post.title) {
        return this.slugify(this.post.title);
      }
    },
  },
  validations() {
    return {
      post: {
        title: {
          required,
        },
        category: {
          required,
        },
        images: {
          desktop: {
            required
          },
          mobile: {
            required
          },
        },
        content: {
          required,
        },
        meta: {
          title: {
            required,
          },
          description: {
            required,
          },
          keywords: {
            required
          },
          // image: {
          //   required
          // },
        },
      },
    };
  },
  watch: {
    detailData: function (newVal) {
      this.post = newVal;
    },
  },
  methods: {
    async doSave() {

      this.$v.post.$touch();
      if (!this.$v.post.$invalid) {
        this.post.slug = this.slug;
        if (this.processState === "edit") {
          this.post.images.desktop =
            typeof this.post.images.desktop === "object"
              ? await base64Converter(this.post.images.desktop[0])
              : "";

          this.post.images.mobile =
            typeof this.post.images.mobile === "object"
              ? await base64Converter(this.post.images.mobile[0])
              : "";

          this.post.meta.image = (this.post.meta.image) ?
            typeof this.post.meta.image === "object"
              ? await base64Converter(this.post.meta.image[0])
              : "" : "";
        }
        const payload = {
          post: this.post,
        };

        this.$emit("onValidateSuccess", payload);
      }
    },
    loadItems() {
      postCategoryService
        .getArticleCategoryList({})
        .then((rsp) => {
          this.optionsCategory = [];
          if (rsp.status == 200) {
            this.optionsCategory = rsp.data.data.content;
          }
        })
        .catch((err) => {
          if (err.response?.status == 404) {
            this.$notify({
              type: "error",
              title: "Article Category Notification",
              text: "Data tidak ditemukan",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: err.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
            this.loadItems();
          }
        });
    },
    slugify(text, ampersand = "and") {
      if (text == null) {
        return null;
      }

      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    saveDraft() {
      this.post.status = "DRAFTS";
      this.doSave();
    },

    savePreview() {
      this.post.status = (this.post?.status) ? this.post.status : "DRAFTS";
      this.post.preview = true
      this.doSave();
    },
    imgErrMessageDesktop(mssg) {
      this.$notify({
        type: "error",
        title: "Image Desktop Notification",
        text: mssg,
      });
    },
    imgErrMessageMobile(mssg) {
      this.$notify({
        type: "error",
        title: "Image Mobile Notification",
        text: mssg,
      });
    },
    imgErrMessageSeo(mssg) {
      this.$notify({
        type: "error",
        title: "SEO Image Notification",
        text: mssg,
      });
    },
  },
};
</script>
