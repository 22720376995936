export default {
  namespaced: true,
  state: {
    parameter : {
        hospitalHome: {
            tier:null,
            multiplier:null,
            weightCode:null
        },
        productHome:{
            basePricing:0,
            factor:0,
            bobot:0
        },
        hospitalOffice: {
            tierValue:0,
        },
        productOffice:{
            basePricing:0,
            factor:0,
            bobot:0
        },
    }
  },
  actions: {
    
  },
  getters: {
    
  },
  mutations: {
    
  },
};
