import api from "./api";

class BannerService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getFileUrl(name){
        return this.api.getUrl("/file/" + name)
    }

    getBannerList(params) {
        return this.api.get("/cms/banner", { params });
    }

    postBanner(data) {
        return this.api.post("/cms/banner", data);
    }

    getBannerById(id) {
        return this.api.get("/cms/banner/" + id);
    }

    putBanner(id, data) {
        return this.api.put("/cms/banner/" + id, data);
    }

    deleteBannerById(id) {
        return this.api.delete("/cms/delete/banner/" + id);
    }

    bannerViewFile(name) {
        return this.api.get("/cms/banner/view/" + name);
    }

    bannerReposisi(data) {
        return this.api.put("/cms/reposition/banner", data);
    }

    changeBannerStatus(id, status) {
        return this.api.put(`/cms/update/banner-status/${id}?status=${status}`)
    }
}

export default new BannerService();