<template>
  <div class="product-form">
    <FormQuestion form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" :on-loading="onProgress" />
  </div>
</template>
<script>
import FormQuestion from "./Form.vue";
import SioService from "../../../service/sio.service";
export default {
  data() {
    return {
      onProgress: false,
      detail: {
        id: this.$route.params.id,
        title: "",
      },
    };
  },
  components: {
    FormQuestion,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      // console.log(payload);
      this.onProgress = true
      payload = {
        "id": this.$route.params.id,
        "createdBy": "",
        "updatedBy": "",
        "created": "",
        "updated": "",
        "deleted": 0,
        "recordStatus": "ACTIVE",
        "name": payload.title.replace(/<\/?[^>]+>/ig, ""),
        "question": payload.title.replace(/<\/?[^>]+>/ig, ""),
      }

      SioService
        .putSio(this.$route.params.id, payload)
        .then((rsp) => {
          this.onProgress = false
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/sections/sio-questionnaire' })
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    loadItems(id) {
      SioService
        .getSioById(id)
        .then((rsp) => {
          this.detail = {
            id: this.$route.params.id,
            title: rsp.data.data.question,
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
  },
  async mounted() {
    await this.loadItems(this.$route.params.id)
  },
};
</script>
