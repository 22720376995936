<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionTen"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Download informasi selengkapnya disini"
        v-model="value.sectionTitle"
      />
    </div>

    <div class="mb-15px">
      <label class="form-label">Brosure PDF</label>
      <input
        type="text"
        class="form-control"
        placeholder="Link Brosure"
        v-model="value.ctaLinkPage"
      />
    </div>

    <div class="mb-15px">
      <label class="form-label">Riplay </label>
      <input
        type="text"
        class="form-control"
        placeholder="Riplay"
        v-model="value.contentUrl"
      />
    </div>
  </panel>
</template>
<script>
export default {
  name: "PDF", // Must be LINK
  title: "PDF Section",
  props: ["value"],
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>