import api from "./api";

class BankMasterService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web/cms')
    }
    
    getProductCategoryList(serverParams) {
        return this.api.get("product/category", { params: serverParams });
    }

    postBankMaster(serverParams) {
        return this.api.post("bank-master", { ...serverParams });
    }

    importBankMaster(formData) {
        return this.api.post("bank-master/import-bank",formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          });
    }

    getProductCategoryById(serverParams) {
        return this.api.get("product/category/" + serverParams);
    }

    putBankMaster(id, serverParams) {
        return this.api.put("bank-master/" + id, { ...serverParams });
    }

    deleteBankMasterById(serverParams) {
        return this.api.delete("delete/bank-master/" + serverParams);
    }

    getMasterBankList(serverParams) {
        return this.api.get("bank-master", { params: serverParams });
    }
}

export default new BankMasterService();