<template>
  <div class="product-form">
    <FormUsers v-if="!!detail" form-type="edit" :detail-data="detail" @onValidateSuccess="doSave"
      :on-loading="onProgress" />
  </div>
</template>
<script>
import FormUsers from "./Form.vue";
import roleService from "@/service/role.service";

export default {
  data() {
    return {
      onProgress: false,
      detail: null,
    };
  },
  mounted() {
    return this.loadItems(this.$route.params.id);
  },
  components: {
    FormUsers,
  },

  methods: {
    doSave(payload) {
      // Submit here
      this.onProgress = true;
      // console.log(payload1, 'payload1')
      return roleService
        .putRole(this.detail.id, payload)
        .then(() => {
          this.$notify({
            type: "succes",
            title: "Succes Notification",
            text: "Role Succes Add",
          });
          this.$router.push({ path: "/users/manage/role" });
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Notification",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
        .finally(() => (this.onProgress = false));
    },
    loadItems(id) {
      roleService
        .getRoleById(id)
        .then((rsp) => {
          let permissions = []
          for (const [k, v] of Object.entries(rsp.data.data.permission)) {
            permissions.push(v.permission)
          }
          
          this.detail = {
            id: rsp.data.data.id,
            name: rsp.data.data.name,
            permissions: permissions,
          };
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Notification",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
  },
};
</script>
