var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","isLoading":_vm.isLoading,"totalRows":_vm.totalRecords,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  //initialSortBy: { field: 'name', type: 'ASC' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          )?_c('input',{ref:"name",staticClass:"form-control",attrs:{"placeholder":"Search hospitals"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.name.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'district.name'
          )?_c('input',{ref:"district",staticClass:"form-control",attrs:{"placeholder":"Search district"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.district.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'hospitalTier.tier'
          )?_c('v-select',{attrs:{"placeholder":"Cari hospital Tier","options":_vm.paginatedHospitalTier.content,"reduce":function (option) { return option.id; },"filterable":false},on:{"open":_vm.openSelectHospitalTier,"search":_vm.searchHospitalTier,"option:selected":_vm.selectedHospitalTierToTemporary,"input":function (value) { return updateFilters(column, value); }}},[_c('li',{staticClass:"pagination",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.handlePrevProv('tier')}}},[_vm._v(" Prev ")]),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.handleNextProv('tier')}}},[_vm._v(" Next ")])])]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.$can(_vm.$permissionsAll[20]))?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/hospitals/edit/" + (props.row.id)),"title":"Edit"}},[_c('i',{staticClass:"fas fa-pencil"})])],1):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }