<template>
  <div>
    <h1 class="page-header"><span v-if="isEdit">Edit</span> Hospital Tier</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <form @submit.prevent="doSave">
              <div class="row align-items-end">
                <div class="col">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label">Hospital Tier</label>
                      <input type="text" class="form-control" placeholder="Hospital Tier" v-model="hospital.tier" />
                      <span class="text-danger" v-if="!$v.hospital.tier.required && $v.hospital.tier.$error
                        ">Hospital tier is required</span>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Multiplier</label>
                      <input type="number" class="form-control" placeholder="Multiplier" v-model="hospital.multiplier"
                        step="0.001" />
                      <!-- pattern="^\d+(?:\.\d{1,2})?$" -->
                      <span class="text-danger" v-if="!$v.hospital.multiplier.required &&
                        $v.hospital.multiplier.$error
                        ">Hospital multiplier is required</span>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <label class="form-label">JSON Value</label>
                      <input type="number" class="form-control" placeholder="JSON Value" v-model="hospital.jsonValue"
                        step="0.001" />
                      <!-- pattern="^\d+(?:\.\d{1,2})?$" -->
                      <span class="text-danger" v-if="!$v.hospital.jsonValue.required &&
                        $v.hospital.jsonValue.$error
                        ">Hospital JSON Value is required</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto" v-if="$can($permissionsAll[23]) && !isEdit">
                  <button type="submit" class="btn btn-outline-primary">
                    <span>Add</span>
                  </button>
                </div>
                <div class="col-auto" v-else-if="$can($permissionsAll[24]) && isEdit">
                  <button type="submit" class="btn btn-outline-primary">
                    <span>Save</span>
                  </button>
                </div>

                <div class="col-auto">
                  <button type="button" class="btn btn-outline-primary" :disabled="!isEdit" @click.prevent="onCancel()">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
          }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  //initialSortBy: { field: 'tier', type: 'ASC' },
}" styleClass="vgt-table" slot="outsideBody">
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <input ref="tier" class="form-control" placeholder="Search hospital tier" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'tier'
              " @input="(value) => updateFilters(column, $refs.tier.value)" />
            <!-- @input="
                (value) => updateFilters(column, $refs.multiplier.value)
              " -->
            <input ref="multiplier" class="form-control" placeholder="Search multiplier" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'multiplier'
              " @input="(value) => updateFilters(column, $refs.multiplier.value)" />
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <div class="row g-2 align-items-center justify-content-end">
                <div class="col-auto" v-if="$can($permissionsAll[24])">
                  <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)" v-b-tooltip.hover
                    title="Edit">
                    <i class="fas fa-pencil"></i>
                  </button>
                </div>
                <div class="col-auto" v-if="$can($permissionsAll[25])">
                  <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                    @click="showAlertConfirm(props.row.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import hospitalService from "../../service/hospital.service";
export default {
  data() {
    return {
      hospital: {
        id: "",
        tier: "",
        multiplier: "",
        jsonValue: "",
      },
      isEdit: false,
      isLoading: false,
      columns: [
        {
          label: "Hospital Tier",
          field: "tier",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          //firstSortType: "ASC",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Multiplier",
          field: "multiplier",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: false,
            enabled: false,
            filterValue: "",
          },
        },
        {
          label: "JSON Value",
          field: "tierValue",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: false,
            enabled: false,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            // field: "tier",
            // type: "ASC",
            field: "created",
            type: "DESC",
          },
        ],
        page: 1,
        perPage: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    setNumberDecimal(event) {
      // this.value = parseFloat(this.value).toFixed(2);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      // this.loadItems();
      this.loadItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      // this.loadItems();
      this.loadItemsFromApi();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      // this.loadItems();
      this.loadItemsFromApi();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.serverParams.page = 1;
      // this.loadItems();
      this.loadItemsFromApi();
    },
    setPaginationParams() {
      let body = {
        pageNumber: this.serverParams.page - 1,
        pageSize: this.serverParams.perPage,
      };
      if (this.serverParams.sort[0].type !== "none") {
        body.direction = this.serverParams.sort[0].type.toUpperCase();
        body.sort = this.serverParams.sort[0].field;
      } else {
        body.sort = "created";
        body.direction = "DESC";
      }
      for (const key in this.serverParams.columnFilters) {
        if (this.serverParams.columnFilters.hasOwnProperty(key)) {
          if (this.serverParams.columnFilters[key]) {
            // body[key] = [this.serverParams.columnFilters[key]]
            body[key] = this.serverParams.columnFilters[key];
            // if (key === "name") {
            //   body.name = [this.serverParams.columnFilters[key]];
            // } else if (key === "district.name") {
            //   body.district = [this.serverParams.columnFilters[key]];
            // } else if (key === "hospitalTier.tier") {
            //     const tier = this.$store.getters["hospital/getTier"].content.find((e) => e.id === this.serverParams.columnFilters[key])
            //     body.tier = [tier.label]

            // }
          }
        }
      }
      return body;
    },
    getHospitalTierList() {
      hospitalService
        .getHospitalTierList(this.setPaginationParams())
        .then((response) => {
          this.rows = response?.data?.content;
          this.totalRecords = response?.data?.totalElements;
        })
        .catch(() => {
          alert("Data Not Found");
          this.rows = [];
          this.totalRecords = 0;
        });
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Delete",
          text: "Hapus data?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Hapus data!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleDelete(id);
          }
        });
    },
    async handleDelete(id) {
      await hospitalService.deleteTier(id).then(
        () => {
          this.$swal({
            title: "Data berhasil Dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItemsFromApi();
          });
        },
        () => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
      );
    },
    loadItemsFromApi() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(this.getHospitalTierList(), 800);
    },
    loadItems() {
      this.rows = [
        {
          id: 1,
          tier: "A",
          multiplier: "1",
        },
        // {
        //   id: 2,
        //   hospitalTier: "B",
        //   hospitalMultiplier: "0.8",
        // },
        // {
        //   id: 3,
        //   hospitalTier: "C",
        //   hospitalMultiplier: "0.6",
        // },
      ];
    },
    onCancel() {
      this.isEdit = false;
      this.hospital.tier = "";
      this.hospital.multiplier = "";
      this.hospital.id = "";
    },
    doSave() {
      this.$v.hospital.$touch();
      const payload = {
        tier: this.hospital.tier,
        //multiplier: this.hospital.multiplier,
        multiplier: parseFloat(this.hospital.multiplier).toFixed(2),
        tierValue: parseFloat(this.hospital.jsonValue),
      };

      if (!this.$v.hospital.$invalid) {
        if (this.isEdit) {
          //console.log("onEditAction", payload)
          return hospitalService
            .updateTier(this.hospital.id, payload)
            .then((data) => {
              this.$swal({
                title: "Data berhasil disimpan",
                showCloseButton: true,
              });
            })
            .then(() => {
              this.loadItemsFromApi();
            })
            .catch((error) => {
              this.$swal({
                title:
                  error.response.data.responseMessage || "Terjadi Kesalahan",
                showCloseButton: true,
              });
            });
        } else {
          // return this.$store
          //   .dispatch("hospital/createTier", payload)
          return hospitalService
            .createTier(payload)
            .then((data) => {
              this.$swal({
                title: "Data berhasil disimpan",
                showCloseButton: true,
              });
            })
            .then(() => {
              this.loadItemsFromApi();
            })
            .catch((error) => {
              this.$swal({
                title:
                  error.response.data.responseMessage || "Terjadi Kesalahan",
                showCloseButton: true,
              });
            });
        }
      } else {
        // console.log(this.$v.page)
      }
    },

    doEdit(data) {
      console.log(data);
      this.isEdit = true;
      this.hospital.id = data.id;
      this.hospital.tier = data.tier;
      this.hospital.multiplier = data.multiplier;
      this.hospital.jsonValue = data.tierValue;
    },
  },
  mounted() {
    //this.loadItems();
    this.getHospitalTierList();
  },
  validations() {
    return {
      hospital: {
        tier: {
          required,
        },
        multiplier: {
          required,
        },
        jsonValue: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
.space {
  position: relative;
}
</style>
