<template>
  <panel
    title="Section 3 - Video"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionThree"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Apa Itu Amore Health"
        v-model="value.sectionTitle"
      />
    </div>
    <div class="mb-15px">
      <label class="form-label">Subtitle</label>
      <input
        type="text"
        class="form-control"
        placeholder="#LOREMIPSUM"
        v-model="value.sectionSubtitle"
      />
    </div>
    <div class="mb-15px">
      <label class="form-label">Video URL</label>
      <input
        type="url"
        class="form-control"
        placeholder="https://www.youtube.com/watch?v=_anVJoFUCtk"
        v-model="value.videoUrl"
      />
    </div>
    <div class="mb-15px">
      <label class="form-label">CTA Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="CTA Title"
        v-model="value.ctaTitle"
      />
    </div>
    <div class="mb-15px">
      <label class="form-label">CTA Link Page</label>
      <input
        type="url"
        class="form-control"
        placeholder="https://"
        v-model="value.ctaLinkPage"
      />
    </div>
    <div class="mb-15px">
      <label class="form-label">Description</label>
      <vue-editor
        v-model="value.sectionDescription"
        :editor-toolbar="$options.defaultToolbar"
      />
    </div>
  </panel>
</template>

<script>
import { defaultToolbar } from "@/config/Helpers";
export default {
  defaultToolbar,
  name: "VIDEO",
  title: "Video",
  props: ["value"],
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>