<template>
  <div>
    <h1 class="page-header">All Menus</h1>
    <div class="panel">
      <NavTab />
      <div class="panel-body">
        <p class="mb-3">This microsite supports 2 menus. Select which menu appears in each location.</p>
        <div class="row align-items-center mb-15px">
          <div class="col-md-2">
            <label class="form-label">Header navigation</label>
          </div>
          <div class="col-md-4">
            <v-select v-model="navigation.header" placeholder="" :options="navigation.headerOption" label="name" />
          </div>
        </div>
        <div class="row align-items-center mb-15px">
          <div class="col-md-2">
            <label class="form-label">Footer navigation</label>
          </div>
          <div class="col-md-4">
            <v-select v-model="navigation.footer" placeholder="" :options="navigation.footerOption" label="name" />
          </div>
        </div>
        <button class="btn btn-primary" type="button" @click.prevent="showAlertConfirm()">Save
          Changes</button>
      </div>
    </div>
  </div>
</template>
<script>
import NavTab from "./Nav.vue";
import menuService from "@/service/menu.service";

export default {
  components: {
    NavTab,
  },
  data() {
    return {
      navigation: {
        header: "",
        footer: "",
        headerOption: [],
        footerOption: []
      },
      payload: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 100
      }
    };
  },
  methods: {
    loadItems() {
      this.loadMenu();
      this.loadLocation();
    },
    loadMenu() {
      menuService
        .getMenuList(this.payload)
        .then((rsp) => {
          this.navigation.headerOption = rsp.data ? rsp.data.data.content : []
          this.navigation.footerOption = rsp.data ? rsp.data.data.content : []
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"}`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    loadLocation() {
      menuService
        .getMenuLocation()
        .then((rsp) => {
          this.navigation.header = rsp.data ? rsp.data.data.content[0].headerNavigation : []
          this.navigation.footer = rsp.data ? rsp.data.data.content[0].footerNavigation : []
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"}`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Simpan?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doSave();
          }
        });
    },
    doSave() {
      // console.log(this.navigation.footer)
      let payload = {
        idHeaderMenu: (this.navigation.header) ? this.navigation.header.id : '',
        idFooterMenu: (this.navigation.footer) ? this.navigation.footer.id : ''
      }
      menuService
        .putMenuLocation(payload)
        .then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          })
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"}`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    }
  },
  mounted() {
    this.loadItems();
  },
};
</script>
