<template>
  <div class="product-form">
    <FormUsers
      form-type="add"
      @onValidateSuccess="doSave"
      :on-loading="onProgress"
    />
  </div>
</template>
<script>
import FormUsers from "./Form.vue";
import roleService from "@/service/role.service";

export default {
  components: {
    FormUsers,
  },
  data() {
    return {
      onProgress: false,
    };
  },
  methods: {
    async doSave(payload) {
      this.onProgress = true;
      if (!payload.name) return;
      roleService
        .postRole(payload)
        .then((r) => {
          this.$notify({
            type: "succes",
            title: "Succes Notification",
            text: "Role Succes Add",
          });
          this.$router.push({ path: "/users/manage/role" });
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Notification",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
        .finally(() => (this.onProgress = false));
    },
  },
};
</script>
