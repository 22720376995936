<template>
  <panel title="General Page">
    <div class="col-md-12">
      <dektop-mobile-image :required="!isEdit" :value="page" />

      <div class="row">
        <div class="col-md-12">
          <div class="mb-15px">
            <vue-editor
              v-model="page.content"
              :editor-toolbar="$options.defaultToolbar"
            />
          </div>
          <span
            class="text-danger"
            v-if="!$v.page.content.required && $v.page.content.$error"
            >Main Content is required</span
          >
        </div>
      </div>
    </div>
  </panel>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import DektopMobileImage from "@/components/upload/DektopMobileImage.vue";

export default {
  components: { DektopMobileImage },
  props: ["page"],

  computed: {
    isEdit() {
      return !!this.page?.id;
    },
  },
  methods: {
    invalid() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },
  validations() {
    return {
      page: {
        content: {
          required,
        },
      },
    };
  },
};
</script>