<template>
  <div>
    <div class="panel">
      <div class="panel-body p-0">
        <div class="vgt-wrap">
          <div class="vgt-inner-wrap">
            <div class="vgt-responsive mb-0">
              <table class="vgt-table">
                <thead>
                  <tr>
                    <th scope="col">FAQ Title</th>
                    <th scope="col">Category</th>
                    <th scope="col">Author</th>
                    <th scope="col">Published Date</th>
                    <th scope="col" class="text-center">Action</th>
                  </tr>
                  <tr>
                    <th class="filter-th">
                      <input class="form-control" placeholder="Search title" name="faqTitle" v-model="faqTitle" id="title"
                        @input="updateFilters" />
                    </th>
                    <th class="filter-th">
                      <v-select :options="optionsCategory" placeholder="Category" :reduce="(label) => label.label"
                        label="label" @input="updateFiltersSelect" />
                    </th>
                    <th class="filter-th">
                      <input placeholder="Author" class="form-control" name="faqAuthor" v-model="faqAuthor" id="faqAuthor"
                        @change="updateFilters" />
                    </th>
                    <th class="filter-th">
                      <input placeholder="Published Date" class="form-control" name="faqPublishDate"
                        v-model="faqPublishDate" id="faqPublishDate" @change="updateFilters" />
                    </th>
                    <th class="filter-th"></th>
                  </tr>
                </thead>
                <draggable v-model="rows" tag="tbody" ghost-class="ghost" :move="checkMove" @start="dragging = true"
                  @end="dragging = false">
                  <tr v-for="item in rows" :key="item.id" class="bg-white">
                    <td>{{ item.title }}</td>
                    <td>{{ item.faqCategory.name }}</td>
                    <td>{{ item.createdBy }}</td>
                    <td>{{ item.created }}</td>
                    <td>
                      <div class="row g-2 align-items-center justify-content-end">
                        <div class="col-auto" v-if="$can($permissionsAll[41])">
                          <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Undo"
                            @click.prevent="showUndoConfirm(item.id)">
                            <i class="fa-solid fa-rotate-left"></i>
                          </button>
                        </div>
                        <div class="col-auto" v-if="$can($permissionsAll[43])">
                          <button type="button" @click.prevent="showAlertConfirm(item.id)" class="btn btn-lg text-dark"
                            v-b-tooltip.hover title="Delete">
                            <i class="fas fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import faqService from "@/service/faq.service";
import faqCategoryService from "@/service/faqcategory.service";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      isLoading: false,
      dragging: false,
      rows: [],
      category: "",
      faqAuthor: "",
      faqPublishDate: "",
      optionsCategory: [],
      faqTitle: "",
      serverParams: {
        sort: [
          {
            field: "title",
            type: "ASC",
          },
        ],
        pageNumber: 0,
        pageSize: 100,
      },
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    updateFilters(evt) {
      let field = evt.target.id
      let filterVal = evt.target.value
      let updateParam = {}
      updateParam[field] = filterVal
      this.updateParams(updateParam)
    },
    updateFiltersSelect(val) {
      this.updateParams({
        category: val
      })
    },
    updateParams(newParams) {
      this.serverParams = Object.assign({}, this.serverParams, newParams)
      this.loadItems()
    },

    loadItems() {
      let searchParam = {
        status: "TRASHED",
        sort: "created",
        direction: "DESC",
        pageNumber: 0,
        pageSize: 100,
      }
      let filterData = Object.assign({}, this.serverParams, searchParam)
      faqService.getFaqList(filterData).then((rsp) => {
        // console.log(rsp, 'data')
        this.rows = rsp.data.data?.content
      }).catch((err) => {
        this.$notify({
          type: "error",
          title: "Error message",
          text: err.response.data?.responseMessage
        });
      })
      this.isLoading = false
    },

    loadFaqCategory() {
      let paramsGetData = {
        "searchField": "",
        "searchValue": "",
        "sort": "created",
        "direction": "ASC",
        "pageNumber": 0,
        "pageSize": 100,
      }
      faqCategoryService.getFaqCategoryList(paramsGetData).then((rsp) => {
        let dataCat = rsp.data?.data?.content
        dataCat.forEach((item) => {
          this.optionsCategory.push({
            label: item.name,
            code: item.id,
          });
        });
        this.isLoading = false
      }).catch((err) => {
        // console.log(err, 'test')
        this.$notify({
          type: "error",
          title: "Error message",
          text: err.response.data?.responseMessage
        })
        this.isLoading = false
      })
    },

    checkMove(evt) {
      // console.log(evt.draggedContext.element.id);
      // console.log(evt.draggedContext.index + 1);
      // console.log(evt.draggedContext.futureIndex + 1);
      // console.log("dragging");
    },

    onDelete(id) {
      faqService
        .deleteFaqById(id)
        .then(() => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.$emit('reloadData', 'TRASHED')
          this.loadItems();
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },

    showUndoConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Drafts?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleUndo(id);
          }
        });
    },
    handleUndo(id) {
      faqService
        .updateFaqStatus(id, { status: "DRAFTS" })
        .then(
          () => {
            this.$swal({
              title: "Data berhasil Dipindah Ke DRAFTS",
              showCloseButton: true,
            }).then(() => {
              this.$emit('reloadData', 'DRAFTS');
              this.$router.push("/pages/faq/list/drafts");
              this.$emit('reloadData', 'TRASHED');
              this.loadItems();
            });
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
  },
  mounted() {
    this.loadItems()
    this.loadFaqCategory()
  },
};
</script>
