<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">SMS Notification</h1>
        <!-- END page-header -->
      </div>

      <div class="col-auto mb-3" v-if="$can($permissionsAll[83])">
        <router-link to="/notification/sms/add">
          <a class="btn btn-outline-primary"> Add New Sms </a>
        </router-link>
      </div>
    </div>

    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'questionTitle', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search notification" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.name.value)" />
        <input ref="description" class="form-control" placeholder="Search notification" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'description'
          " @input="(value) => updateFilters(column, $refs.description.value)" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto" v-if="$can($permissionsAll[82])">
              <router-link :to="`/notification/sms/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="$can($permissionsAll[84])">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import smsService from "@/service/sms.service";
import SmsService from "@/service/sms.service";
export default {
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "SMS Notification",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Description",
          field: "description",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
        name: '',
        description: ''
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ pageNumber: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageNumber: 0 });
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: ''
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams({
        name: params.columnFilters.name,
        description: params.columnFilters.description,
      });
      this.loadItems();
    },
    loadItems() {
      SmsService
        .getSmsList(this.serverParams)
        .then(async (rsp) => {
          let arrData = rsp.data?.data ?
            rsp.data.data.content : [];
          this.rows = arrData
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },

    onDelete(id) {
      smsService
        .deleteSmsById(id)
        .then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Hapus!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
