<template>
  <div class="product-form">
    <FormUsers
      v-if="!!detail"
      form-type="edit"
      :detail-data="detail"
      @onValidateSuccess="doSave"
      :on-loading="onProgress"
    />
  </div>
</template>
<script>
import FormUsers from "./Form.vue";
import userService from "@/service/user.service";

export default {
  data() {
    return {
      onProgress: true,
      detail: null,
    };
  },
  components: {
    FormUsers,
  },
  async mounted() {
    await this.loadItems(this.$route.params.id);
    // console.log(this.detail, 'detailData');
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onProgress = true;
      payload = {
        id: this.$route.params.id,
        email: payload.username,
        fullname: payload.username,
        enabled: true,
        role: payload.role,
        password: payload.password,
        username: payload.username,
      };
      userService
        .putUser(this.$route.params.id, payload)
        .then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: "/users/manage/user" });
          });
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Notification",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
        .finally(() => (this.onProgress = false));
    },
    loadItems(id) {
      userService
        .getUserById(id)
        .then((rsp) => {
          this.detail = {
            id: this.$route.params.id,
            username: rsp.data.data.username,
            role: rsp.data.data.role,
          };
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Notification",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
        .finally(() => (this.onProgress = false));
    },
  },
};
</script>
