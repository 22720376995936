import api from "./api";

class NotificationCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('notification')
    }

    getNotificationCategoryList(serverParams) {
        return this.api.get("/notification-category", { params: serverParams });
    }

    postNotificationCategory(serverParams) {
        return this.api.post("/notification-category", { ...serverParams });
    }

    putNotificationCategory(id, serverParams) {
        return this.api.put("/notification-category/" + id, { ...serverParams });
    }

    deleteNotificationCategoryById(serverParams) {
        return this.api.delete("/notification-category/" + serverParams);
    }
}

export default new NotificationCategoryService();