var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          )?_c('input',{ref:"pageName",staticClass:"form-control",attrs:{"placeholder":"Search pages"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.pageName.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'pageTemplate'
          )?_c('select',{ref:"pageTemplate",staticClass:"form-select",attrs:{"placeholder":"Search Category"},on:{"change":function (value) { return updateFilters(column, _vm.$refs.pageTemplate.value); }}},[_c('option',{attrs:{"value":"","selected":"selected"}},[_vm._v("Select")]),_c('option',{attrs:{"value":"GENERAL_PAGE","selected":""}},[_vm._v("General page")]),_c('option',{attrs:{"value":"PRODUCT_PAGE"}},[_vm._v("Product page")])]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[(_vm.$can(_vm.$permissionsAll[39]))?_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Undo"},on:{"click":function($event){return _vm.showUndoConfirm(props.row.id)}}},[_c('i',{staticClass:"fa-solid fa-rotate-left"})])]),(_vm.$can(_vm.$permissionsAll[93]))?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Delete"},on:{"click":function($event){return _vm.showDeleteConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-times"})])]):_vm._e()]):_vm._e()]):(props.column.field == 'created')?_c('span',[_vm._v(" "+_vm._s(_vm._f("getDate")(props.formattedRow[props.column.field]))+" ")]):(props.column.field == 'updated')?_c('span',[_vm._v(" "+_vm._s(_vm._f("getDate")(props.formattedRow[props.column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }