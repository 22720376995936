import api from "./api";

class PostCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }
    
    getArticleCategoryList(serverParams) {
        return this.api.get("/cms/article/category", { params: serverParams });
    }

    postArticleCategory(serverParams) {
        return this.api.post("cms/article/category", { ...serverParams });
    }

    getArticleCategoryById(serverParams) {
        return this.api.get("/cms/article/category/" + serverParams);
    }

    putArticleCategory(id, serverParams) {
        return this.api.put("/cms/article/category/" + id, { ...serverParams });
    }

    deleteArticleCategoryById(serverParams) {
        return this.api.delete("/cms/delete/article/category/" + serverParams);
    }
}

export default new PostCategoryService();