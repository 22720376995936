<template>
  <div>
    <vue-good-table mode="remote" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
      @on-page-change="onPageChange" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'postName', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="postName" class="form-control" placeholder="Search post" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'title'
          " @input="(value) => updateFilters(column, $refs.postName.value)" />
        <select ref="postSource" class="form-select" placeholder="Search Source" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'source'
          " @change="(value) => updateFilters(column, $refs.postSource.value)
    ">
          <option value="" selected>All Sources</option>
          <option value="CMS">CMS</option>
          <option value="CMP">CMP</option>
        </select>
        <select ref="postCategory" class="form-select" placeholder="Search category" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'articleCategory.name'
          " @change="(value) => updateFilters(column, $refs.postCategory.value)
    ">
          <option value="" selected>All category</option>
          <option v-for="(item, index) in optionsCategory" :value="item.name">
            {{ item.name }}
          </option>
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto" v-if="$can($permissionsAll[76])">
              <router-link :to="`/posts/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="$can($permissionsAll[76])">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Duplicate"
                @click="showDuplicateConfirm(props.row.id)">
                <i class="fas fa-copy"></i>
              </button>
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import postService from "@/service/post.service";
import postCategoryService from "@/service/postcategory.service";

export default {
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Post Name",
          field: "title",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Source",
          field: "source",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Category",
          field: "articleCategory.name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
        status: "DRAFTS"
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      optionsCategory: [],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      // console.log('page')
      this.updateParams({ pageNumber: params.currentPage - 1 });
      this.loadItems();
    },
    onPerPageChange(params) {
      // console.log('perpage')
      this.updateParams({ pageNumber: 0 });
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams({
        category: params.columnFilters['articleCategory.name'],
        source: params.columnFilters.source,
        name: params.columnFilters.title
      });
      this.loadItems();
    },
    loadItems() {
      postService.getArticleList(this.serverParams).then((rsp) => {
        this.$emit('reloadData', 'DRAFTS')
        this.rows = rsp.data?.data?.content ? rsp.data.data.content : [];
        this.totalRecords = rsp?.data?.data?.totalElements ? rsp.data.data.totalElements : 0;
      }).catch((e) => {
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
        });
      });
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Pindah Ke Trash?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleDelete(id);
          }
        });
    },

    async handleDelete(id) {
      let payloadDelete = {
        "status": "TRASHED",
      }
      await postService.updateArticleByField(id, payloadDelete).then((rsp) => {
        this.$emit('reloadData', 'DRAFTS')
        this.$swal({
          title: "Data berhasil Dipindah Ke Trash",
          showCloseButton: true,
        }).then(() => {
          this.$router.push("/posts/list/drafts")
        })
      }).catch((err) => {

      })
    },

    showDuplicateConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Duplikasi Data?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Duplikasi!",
        })
        .then(async (result) => {
          if (result.value) {
            await this.handleDuplicate(id);
          }
        });
    },

    async handleDuplicate(id) {
      await postService.duplicateArticle(id).then(
        () => {
          this.$swal({
            title: "Data berhasil di duplikasi",
            showCloseButton: true,
          }).then(() => {
            this.$emit('reloadData', 'DRAFTS')
            this.$router.push("/posts/list/drafts");
          });
        },
        () => {
          this.$swal({
            title: "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
      );
    },
    loadArticleCategory() {
      let categoryParam = {
        sort: "name",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 100,
      };
      postCategoryService
        .getArticleCategoryList(categoryParam)
        .then((rsp) => {
          this.optionsCategory = rsp.data.data?.content;
        })
        .catch(() => {
          alert("error")
        });
    },
  },
  mounted() {
    this.loadArticleCategory()
  },
};
</script>
