var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"page-header"},[(_vm.isEdit)?_c('span',[_vm._v("Edit")]):_vm._e(),_vm._v(" Hospital Tier")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doSave.apply(null, arguments)}}},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label"},[_vm._v("Hospital Tier")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hospital.tier),expression:"hospital.tier"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Hospital Tier"},domProps:{"value":(_vm.hospital.tier)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hospital, "tier", $event.target.value)}}}),(!_vm.$v.hospital.tier.required && _vm.$v.hospital.tier.$error
                        )?_c('span',{staticClass:"text-danger"},[_vm._v("Hospital tier is required")]):_vm._e()]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label"},[_vm._v("Multiplier")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hospital.multiplier),expression:"hospital.multiplier"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Multiplier","step":"0.001"},domProps:{"value":(_vm.hospital.multiplier)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hospital, "multiplier", $event.target.value)}}}),(!_vm.$v.hospital.multiplier.required &&
                        _vm.$v.hospital.multiplier.$error
                        )?_c('span',{staticClass:"text-danger"},[_vm._v("Hospital multiplier is required")]):_vm._e()])]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label"},[_vm._v("JSON Value")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hospital.jsonValue),expression:"hospital.jsonValue"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"JSON Value","step":"0.001"},domProps:{"value":(_vm.hospital.jsonValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hospital, "jsonValue", $event.target.value)}}}),(!_vm.$v.hospital.jsonValue.required &&
                        _vm.$v.hospital.jsonValue.$error
                        )?_c('span',{staticClass:"text-danger"},[_vm._v("Hospital JSON Value is required")]):_vm._e()])])]),(_vm.$can(_vm.$permissionsAll[23]) && !_vm.isEdit)?_c('div',{staticClass:"col-auto"},[_vm._m(0)]):(_vm.$can(_vm.$permissionsAll[24]) && _vm.isEdit)?_c('div',{staticClass:"col-auto"},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"button","disabled":!_vm.isEdit},on:{"click":function($event){$event.preventDefault();return _vm.onCancel()}}},[_vm._v(" Cancel ")])])])])])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
            enabled: true,
            dropdownAllowAll: false,
          },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  //initialSortBy: { field: 'tier', type: 'ASC' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'tier'
              )?_c('input',{ref:"tier",staticClass:"form-control",attrs:{"placeholder":"Search hospital tier"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.tier.value); }}}):_vm._e(),(column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'multiplier'
              )?_c('input',{ref:"multiplier",staticClass:"form-control",attrs:{"placeholder":"Search multiplier"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.multiplier.value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.$can(_vm.$permissionsAll[24]))?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Edit"},on:{"click":function($event){return _vm.doEdit(props.row)}}},[_c('i',{staticClass:"fas fa-pencil"})])]):_vm._e(),(_vm.$can(_vm.$permissionsAll[25]))?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"submit"}},[_c('span',[_vm._v("Add")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"submit"}},[_c('span',[_vm._v("Save")])])}]

export { render, staticRenderFns }