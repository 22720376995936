<template>
  <div>
    <h1 class="page-header">Manage Hospital Area</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <form @submit.prevent="doSave">
              <div class="mb-15px">
                <label class="form-label">Province</label>
                <!--  @option:selected="selectedProvince" -->
                <v-select v-model="hospital.province" :options="paginatedProvince.content" :reduce="(option) => option.id"
                  :filterable="false" @input="provinceSelected" @open="openSelectProvince" @search="searchProvince"
                  @option:selected="selectedProvToTemporary">
                  <li slot="list-footer" class="pagination">
                    <button :disabled="!hasProvNextPage" @click.prevent="handlePrev('province')">
                      Prev
                    </button>
                    <button :disabled="!hasProvPrevPage" @click.prevent="handleNext('province')">
                      Next
                    </button>
                  </li>
                </v-select>
                <span class="text-danger" v-if="!$v.hospital.province.required &&
                  $v.hospital.province.$error
                  ">Province is required</span>
              </div>
              <div class="mb-15px">
                <label class="form-label">Region/City</label>
                <v-select v-model="hospital.city" :options="paginatedCity.content" :reduce="(option) => option.id"
                  :filterable="false" @input="citySelected" @open="openSelectCity" @search="searchCity"
                  @option:selected="selectedCityToTemporary">
                  <li slot="list-footer" class="pagination">
                    <button :disabled="!hasCityNextPage" @click.prevent="handlePrev('city')">
                      Prev
                    </button>
                    <button :disabled="!hasCityPrevPage" @click.prevent="handleNext('city')">
                      Next
                    </button>
                  </li>
                </v-select>
                <!-- <span
                  class="text-danger"
                  v-if="!$v.hospital.city.required && $v.hospital.city.$error"
                  >City is required</span
                > -->
              </div>
              <div class="mb-15px">
                <label class="form-label">District</label>
                <v-select v-model="hospital.district" :options="paginatedDistrict.content" :reduce="(option) => option.id"
                  :filterable="false" @open="openSelectDistrict" @search="searchDistrict"
                  @option:selected="selectedDistrictToTemporary">
                  <li slot="list-footer" class="pagination">
                    <button :disabled="!hasDistrictNextPage" @click.prevent="handlePrev('district')">
                      Prev
                    </button>
                    <button :disabled="!hasDistrictNextPage" @click.prevent="handleNext('district')">
                      Next
                    </button>
                  </li>
                </v-select>
                <!-- <span
                  class="text-danger"
                  v-if="
                    !$v.hospital.district.required &&
                    $v.hospital.district.$error
                  "
                  >District is required</span
                > -->
              </div>
              <button type="submit" class="btn btn-primary" v-if="$can($permissionsAll[32])">
                Add Area <i class="fa-solid fa-chevron-right"></i><i class="fa-solid fa-chevron-right"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="panel panel-area">
          <div class="panel-header">
            <div class="row justify-content-between">
              <div class="col-auto">
                <p>Registered Area</p>
              </div>
              <div class="col-auto">
                <p>{{ totalCity }} regions/cities, {{ totalDistrict }} districts</p>
              </div>
            </div>
          </div>
          <div class="panel-search">
            <input type="text" class="form-control" placeholder="Search area" v-model="searchData" v-on:change="search" />
          </div>
          <div class="panel-body p-0">
            <ul>
              <li v-for="(item, index) in registeredArea" :key="index">
                <span class="province">{{ item.name }}
                  <a v-if="$can($permissionsAll[34])" @click="showAlertConfirm(item.id)"><i
                      class="fa-solid fa-circle-minus"></i></a></span>
                <ul v-if="item.city">
                  <li v-for="(city, indexCity) in item.city" :key="indexCity">
                    <span class="city">{{ city.name }}
                      <a v-if="$can($permissionsAll[34])" @click="showAlertConfirm(city.id)"><i
                          class="fa-solid fa-circle-minus"></i></a></span>
                    <ul v-if="city.district">
                      <li v-for="(district, indexDistrict) in city.district" :key="indexDistrict">
                        <span class="district">{{ district.name }}
                          <a v-if="$can($permissionsAll[34])" @click="showAlertConfirm(district.id)"><i
                              class="fa-solid fa-circle-minus"></i></a></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { required } from "vuelidate/lib/validators";
import hospitalService from "../../service/hospital.service";
import areaService from "../../service/area.service";
export default {
  data() {
    // return {
    //   optionsProvince: [
    //     "Jakarta",
    //     "Jawa Barat",
    //     "Jawa Tengah",
    //     "Jawa Timur",
    //     "Bali",
    //   ],
    //   optionsCity: ["Jakarta Pusat", "Jakarta Barat", "Jakarta Selatan"],
    //   optionsDistrict: ["Jakarta", "Kebon Jeruk", "Cengkareng"],
    //   registeredArea: [
    //     {
    //       province: "DKI Jakarta",
    //       city: [
    //         {
    //           name: "Jakarta Selatan",
    //           district: ["Kebayoran Lama", "Kebayoran Baru", "Pesanggrahan"],
    //         },
    //         {
    //           name: "Jakarta Barat",
    //           district: ["Kebun Jeruk", "Kembangan", "Palmerah"],
    //         },
    //       ],
    //     },
    //     {
    //       province: "DKI Jakarta",
    //       city: [
    //         {
    //           name: "Jakarta Selatan",
    //           district: ["Kebayoran Lama", "Kebayoran Baru", "Pesanggrahan"],
    //         },
    //         {
    //           name: "Jakarta Barat",
    //           district: ["Kebun Jeruk", "Kembangan", "Palmerah"],
    //         },
    //       ],
    //     },
    //   ],
    //   hospital: {
    //     province: "",
    //     city: "",
    //     district: "",
    //   },
    // };
    return {
      searchData: "",
      totalCity: 0,
      totalDistrict: 0,
      disabledProvPrev: true,
      optionsProvince: [],
      optionsCity: [],
      optionsDistrict: [],
      hospital: {
        province: null,
        city: null,
        district: null
      },
      provServerParamsProvince: {
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsCity: {
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsDistrict: {
        pageSize: 10,
        pageNumber: 0,
      },
      registeredArea: [],
      onSearchProvince: false,
      onSearchCity: false,
      onSearchDistrict: false,
      provSelected: {},
      citySelectedData: {},
      districtSelectedData: {},
      allAreaProvince: {},
      allAreaCity: {},
      allAreaDistrict: {},
      allArea: {}

    }
  },
  methods: {
    search() {
      //console.log('search :', this.searchData)
      this.getAllArea()
    },
    loadItems() {
      this.rows = [
        {
          id: 1,
          hospitalTier: "A",
          hospitalMultiplier: "1",
        },
        {
          id: 2,
          hospitalTier: "B",
          hospitalMultiplier: "0.8",
        },
        {
          id: 3,
          hospitalTier: "C",
          hospitalMultiplier: "0.6",
        },
      ]
    },
    doSave() {
      // const payload = {
      //   province: this.hospital.province,
      //   city: this.hospital.city,
      //   district: this.hospital.district,
      // }

      // console.log("onAddAction", payload)
      this.$v.hospital.$touch();
      if (!this.$v.hospital.$invalid) {
        const payload = {
          idProvince: parseInt(this.hospital.province),
          idCity: parseInt(this.hospital.city),
          idDistrict: parseInt(this.hospital.district),
        }
        //console.log('payload: ', payload)
        return areaService.createArea(
          payload
        ).then(
          (data) => {
            // this.areaList()
            this.getAllArea()
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            })
          }
        ).catch((error) => {
          this.$swal({
            title: error.response.data.responseMessage || "Terjadi Kesalahan",
            showCloseButton: true,
          });
        }
        )
      } else {
        // console.log(this.$v.page);
      }
    },
    searchProvince(query, loading) {
      this.provServerParamsProvince.pageNumber = 0
      this.provServerParamsProvince.searchField = 'name'
      this.provServerParamsProvince.searchValue = query
      // if(!query && Object.keys(this.provSelected).length > 0){
      //   this.provServerParamsProvince.searchValue = this.provSelected.label
      // }
      this.getProvince()

    },
    searchCity(query, loading) {
      //loading((this.onSearchCity = true));
      // if (query.length == 0) {
      //   if (this.provServerParamsCity.searchValue && this.provServerParamsCity.searchField) {
      //     delete this.provServerParamsCity.searchField;
      //     delete this.provServerParamsCity.searchValue;
      //   }
      // } else {
      //   this.provServerParamsCity.pageNumber = 0
      //   this.provServerParamsCity.searchField = 'name'
      //   this.provServerParamsCity.searchValue = query;
      // }
      //this.provinceSelected(false).finally(() =>  loading((this.onSearchCity = false)));
      // this.provinceSelected(false)
      this.provServerParamsCity.pageNumber = 0
      this.provServerParamsCity.searchField = 'name'
      this.provServerParamsCity.searchValue = query
      this.getCity()
    },
    searchDistrict(query, loading) {
      // loading((this.onSearchDistrict = true));
      // if (query.length == 0) {
      //   if (this.provServerParamsDistrict.searchValue && this.provServerParamsDistrict.searchField) {
      //     delete this.provServerParamsDistrict.searchField;
      //     delete this.provServerParamsDistrict.searchValue;
      //   }
      // } else {
      //   this.provServerParamsDistrict.pageNumber = 0
      //   this.provServerParamsDistrict.searchField = 'name'
      //   this.provServerParamsDistrict.searchValue = query;
      // }
      //this.citySelected(false).finally(() =>  loading((this.onSearchDistrict = false)));
      // this.citySelected(false)
      this.provServerParamsDistrict.pageNumber = 0
      this.provServerParamsDistrict.searchField = 'name'
      this.provServerParamsDistrict.searchValue = query
      this.getDistrict()
    },
    getProvince() {
      this.$store.dispatch(
        "regional/getProvinceLists",
        this.provServerParamsProvince
      ).then(
        () => {
          if (Object.keys(this.provSelected).length > 0) {
            this.provSelected.type = 'province'
            this.$store.commit('regional/pushSelectedData', this.provSelected)
          }
        }
      ).catch(
        (error) => {
          console.debug(error);
        }
      )
      // this.formInProgressProvince = false
    },
    getCity() {
      const payload = this.provServerParamsCity
      if (this.hospital.province) {
        payload.idProvince = this.hospital.province
      }

      this.$store.dispatch("regional/getCityLists", payload)
        .then(
          () => {
            if (Object.keys(this.citySelectedData).length > 0) {
              this.citySelectedData.type = 'city'
              this.$store.commit('regional/pushSelectedData', this.citySelectedData)
            }
          }
        )
        .catch(
          (error) => {
            console.debug(error);
          }
        )
    },
    getDistrict() {
      const payload = this.provServerParamsDistrict
      if (this.hospital.city) {
        payload.idCity = this.hospital.city
      }

      this.$store.dispatch("regional/getDistrictLists", payload)
        .then(
          () => {
            if (Object.keys(this.districtSelectedData).length > 0) {
              this.districtSelectedData.type = 'district'
              this.$store.commit('regional/pushSelectedData', this.districtSelectedData)
            }
          }
        )
        .catch(
          (error) => {
            console.debug(error);
          }
        )
    },
    getAllArea() {
      areaService.getAreaList({
        pageSize: 1000000,
        name: this.searchData
      }).then((resp) => {
        if (resp.data.responseMessage === "Data's empty!") {
          this.allArea = []
        } else {
          this.allArea = resp.data.data.content
        }
        // this.allAreaProvince = resp.data.data.content
      }).catch(
        (error) => {
          console.debug(error);
        }
      )
    },
    getAllAreaProvince() {
      areaService.getAreaList({
        pageSize: 1000,
        regionType: "PROVINCE"
      }).then((resp) => {
        this.allAreaProvince = resp.data.data.content
      }).catch(
        (error) => {
          console.debug(error);
        }
      )
    },
    getAllAreaCity() {
      areaService.getAreaList({
        pageSize: 1000,
        regionType: "CITY"
      }).then((resp) => {
        //this.totalCity = resp.data.data.totalElements
        this.allAreaCity = resp.data.data.content
      }).catch(
        (error) => {
          console.debug(error);
        }
      )
    },
    getAllAreaDistrict() {
      areaService.getAreaList({
        pageSize: 1000,
        regionType: "DISTRICT"
      }).then((resp) => {
        //this.totalDistrict = resp.data.data.totalElements
        this.allAreaDistrict = resp.data.data.content
      }).catch(
        (error) => {
          console.debug(error);
        }
      )
    },
    async areaList() {
      // await Promise.all([this.getAllAreaProvince(), this.getAllAreaCity(), this.getAllAreaDistrict()])
      this.getAllAreaProvince()
      this.getAllAreaCity()
      this.getAllAreaDistrict()
    },
    selectedProvToTemporary(provSelected) {
      this.provSelected = provSelected
    },
    selectedCityToTemporary(citySelectedData) {
      this.citySelectedData = citySelectedData
    },
    selectedDistrictToTemporary(districtSelectedData) {
      this.districtSelectedData = districtSelectedData
    },
    openSelectProvince() {
      this.provServerParamsProvince.searchValue = ""
      this.provServerParamsProvince.pageNumber = 0
      this.getProvince()
      //this.provSelected = {}  
    },
    openSelectCity() {
      this.provServerParamsCity.searchValue = ""
      this.provServerParamsCity.pageNumber = 0
      this.getCity()
      //this.provSelected = {}  
    },
    openSelectDistrict() {
      this.provServerParamsDistrict.searchValue = ""
      this.provServerParamsDistrict.pageNumber = 0
      this.getDistrict()
      //this.provSelected = {}  
    },
    // async provinceSelected(needReset = true) {
    //   if (this.hospital.province !== null) {
    //     await this.$store.dispatch("regional/getCityLists", {
    //       ...this.provServerParamsCity,
    //       idProvince: this.hospital.province,
    //     })

    //    if(needReset){
    //      this.hospital.city = null
    //      this.hospital.district = null
    //    }
    //     //this.formInProgressCity = false
    //   }
    // },
    provinceSelected() {
      this.hospital.city = null
      this.hospital.district = null
      this.citySelectedData = {}
      this.districtSelectedData = {}
    },
    citySelected() {
      // if (this.hospital.city !== null) {
      //   await this.$store.dispatch("regional/getDistrictLists", {
      //     ...this.provServerParamsDistrict,
      //     idCity: this.hospital.city,
      //   })
      //   if(needReset){
      //     this.hospital.district = null
      //   }
      //   //this.formInProgressDistrict = false
      // }
      this.hospital.district = null
      this.districtSelectedData = {}
    },
    handleNext(kind) {
      let lastPage
      switch (kind) {
        case 'province':
          lastPage = this.$store.state['regional'].province.totalPages - 1
          if (this.provServerParamsProvince.pageNumber < lastPage) {
            this.provServerParamsProvince.pageNumber += 1
          }
          //this.onSearchProvince = true
          this.getProvince()
          break;
        case 'city':
          if (this.hospital.province !== null) {
            lastPage = this.$store.state['regional'].city.totalPages - 1
            if (this.provServerParamsCity.pageNumber < lastPage) {
              this.provServerParamsCity.pageNumber += 1
            }
            // await this.provinceSelected()
            this.getCity()
          }
          break;
        case 'district':
          if (this.hospital.city !== null) {
            lastPage = this.$store.state['regional'].district.totalPages - 1
            if (this.provServerParamsDistrict.pageNumber < lastPage) {
              this.provServerParamsDistrict.pageNumber += 1
            }
            this.getDistrict()
          }
          break;

        default:
          break;
      }


    },
    handlePrev(kind) {
      switch (kind) {
        case 'province':
          if (this.provServerParamsProvince.pageNumber !== 0) {
            this.provServerParamsProvince.pageNumber -= 1
            this.disabledProvPrev = false
          } else {
            this.disabledProvPrev = true
          }

          this.getProvince()

          break;
        case 'city':
          if (this.hospital.province !== null) {
            if (this.provServerParamsCity.pageNumber !== 0) {
              this.provServerParamsCity.pageNumber -= 1
            }
            // await this.provinceSelected()
            this.getCity()
          }
          break;
        case 'district':
          if (this.hospital.city !== null) {
            if (this.provServerParamsDistrict.pageNumber !== 0) {
              this.provServerParamsDistrict.pageNumber -= 1
            }
            this.getDistrict()
          }
          break;

        default:
          break;
      }
    },
    // mappingArea(area){
    //   const {province, city, district} = area
    //   // let tempProvinceCity = []
    //   // city.forEach((c) => {
    //   //   province.forEach((p) => {
    //   //     if(c.idParent === p.code){
    //   //       p
    //   //     }
    //   //   })
    //   // })
    //   province.map((p) => {
    //     p.city = []
    //     city.forEach((c) => {
    //       if(p.code === c.idParent){
    //         p.city.push(c)
    //       }
    //     })
    //     return p
    //   })
    //   this.registeredArea = province
    // },
    mappingArea(areas) {
      const { area } = areas
      let provinces = []
      let cities = []
      let districts = []
      if (!this.searchData) {
        provinces = []
        area.forEach(element => {
          switch (element.regionType) {
            case 'PROVINCE':
              provinces.push(element)
              break;
            case 'CITY':
              cities.push(element)
              break;
            case 'DISTRICT':
              districts.push(element)
              break;
          }
        });
        provinces.map((p) => {
          p.city = []
          cities.forEach((c) => {
            c.district = []
            districts.forEach((d) => {
              if (c.code === d.idParent) {
                c.district.push(d)
              }
            })
            if (p.code === c.idParent) {
              p.city.push(c)
            }
          })
          return p
        })
        this.registeredArea = provinces
        this.totalCity = cities.length
        this.totalDistrict = districts.length
      } else {
        this.registeredArea = area
      }


      // let tempProvinceCity = []
      // city.forEach((c) => {
      //   province.forEach((p) => {
      //     if(c.idParent === p.code){
      //       p
      //     }
      //   })
      // })
      // province.map((p) => {
      //   p.city = []
      //   city.forEach((c) => {
      //     if(p.code === c.idParent){
      //       p.city.push(c)
      //     }
      //   })
      //   return p
      // })
      // this.registeredArea = province
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin?",
          text: "Data Dihapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Pindah!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteArea(id);
          }
        });
    },
    deleteArea(id) {
      areaService.deleteArea(id).then(
        () => {
          this.$swal({
            title: "Data berhasil Dihapus",
            showCloseButton: true,
          })
          this.getAllArea()
        }
      ).catch(() => {
        this.$swal({
          title: "Terjadi Kesalahan",
          showCloseButton: true,
        });
      });
    }
  },
  computed: {
    // getAllDataArea(){
    //   return {
    //     province: this.allAreaProvince,
    //     city: this.allAreaProvince,
    //     district: this.allAreaProvince,
    //   }
    // },
    getAllDataArea() {
      return {
        area: this.allArea,
      }
    },
    // searchInput() {
    //   return this.searchData
    // },
    filtered() {
      return this.optionsProvince.filter((country) =>
        country.includes(this.search)
      )
    },
    paginatedProvince() {
      return this.$store.getters["regional/getProvince"]
    },
    paginatedCity() {
      return this.$store.getters["regional/getCity"]
    },
    paginatedDistrict() {
      return this.$store.getters["regional/getDistrict"]
    },
    hasProvNextPage() {
      return true
    },
    hasProvPrevPage() {
      return true
    },
    hasCityNextPage() {
      return true
    },
    hasCityPrevPage() {
      return true
    },
    hasDistrictNextPage() {
      return true
    },
    hasDistrictPrevPage() {
      return true
    },
  },
  created() {
    // this.getProvince()
  },
  mounted() {
    // this.areaList()
    this.getAllArea()
    //console.log('allAreaProvince: ', this.allAreaProvince)
    // console.log('this.allAreaProvince: ', this.allAreaProvince)
    // console.log('this.allAreaCity: ', this.allAreaCity)
    // console.log('this.allAreaDistrict: ', this.allAreaDistrict)
    //this.loadItems()

    //this.getProvince();
    //this.optionsProvince = JSON.parse(JSON.stringify(this.$store.getters['regional/getProvince'])).content[0].name
    //console.log('cek mount: ', JSON.parse(JSON.stringify(this.$store.getters['regional/getProvince'])))
  },
  watch: {
    // allAreaProvince: function (newValue) {
    //   console.log('newValue: ', this.allAreaProvince)

    // },
    getAllDataArea: {
      handler(newValue, oldValue) {
        // console.log('newValue: ', newValue)
        this.mappingArea(newValue)
      },
      deep: true
    },
    // searchInput: {
    //   handler(newValue, oldValue) {
    //     console.log('newValue: ', newValue)
    //   },
    //   deep: true
    // }
  },
  validations() {
    return {
      hospital: {
        province: {
          required,
        },
        // city: {
        //   required,
        // },
        // district: {
        //   required,
        // },
      },
    };
  },
}
</script>
<style scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}</style>
