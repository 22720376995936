import FaqService from "../service/faq.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        
        //category
        async getListFaq(_ctx, serverParams) {
            return FaqService.getFaqList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postFaq(_ctx, serverParams) {
            return FaqService.postFaq(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getFaqById(_ctx, serverParams) {
            return FaqService.getFaqById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateFaqById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return FaqService.putFaq(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteFaqById(_ctx, serverParams) {
            return FaqService.deleteFaqById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
