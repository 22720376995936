import RegistrationService from "../service/registration.service";

export default {
  namespaced: true,
  state: {
    submitionsData: {},
    completed: 0,
    incompleted: 0,
  },
  actions: {
    async getRegistration(_ctx, serverParams) {
      return RegistrationService.getRegistrationCustomer(serverParams)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return err;
        });
    },
    async getSingleSubmission({ commit }, id) {
      return await RegistrationService.getSingleSubmission(id).then(
        (result) => {
          commit("setSubmissionData", result.data?.data);
          return Promise.resolve(result);
        },
        (error) => {
          commit("setSubmissionData", {});
          return Promise.reject(error);
        }
      );
    },
    getCompletedData({ commit }) {
      return RegistrationService.getSubmitionList({ status: "COMPLETE" })
        .then((result) => {
          if (
            result.data.responseCode == "200" &&
            result.data.responseMessage == "Data's Empty!"
          ) {
            commit("setCompletedData", 0);
          } else {
            commit("setCompletedData", result.data?.data?.totalElements);
          }
        })
        .catch(() => {
          commit("setCompletedData", 0);
        });
    },
    getIncompletedData({ commit }) {
      return RegistrationService.getSubmitionList({ status: "INCOMPLETE" })
        .then((result) => {
          if (
            result.data.responseCode == "200" &&
            result.data.responseMessage == "Data's Empty!"
          ) {
            commit("setIncompletedData", 0);
          } else {
            commit("setIncompletedData", result.data?.data?.totalElements);
          }
        })
        .catch(() => {
          commit("setIncompletedData", 0);
        });
    },
    // async postSio(_ctx, serverParams) {
    //     return BannerService.postBanner(serverParams).then((result) => {
    //         return result
    //     }).catch((err) => {
    //         return err
    //     });
    // },
    // async getSioById(_ctx, serverParams) {
    //     return BannerService.getSioById(serverParams).then((result) => {
    //         return result
    //     }).catch((err) => {
    //         return err
    //     });
    // },
    // async updateSioById(_ctx, serverParams) {
    //     const { id, ...payload } = serverParams
    //     return BannerService.putBanner(id, payload).then((result) => {
    //         return result
    //     }).catch((err) => {
    //         return err
    //     });
    // },
    // async deleteSioById(_ctx, serverParams) {
    //     return BannerService.deleteBannerById(serverParams).then((result) => {
    //         return result
    //     }).catch((err) => {
    //         return err
    //     });
    // },
  },
  mutations: {
    setSubmissionData(state, data) {
      state.submitionsData = data;
    },
    setCompletedData(state, data) {
      state.completed = data;
    },
    setIncompletedData(state, data) {
      state.incompleted = data;
    },
  },
};
