<template>
  <div>
    <iframe class="col-md-12 col-xl" height="3542"
      src="https://lookerstudio.google.com/embed/reporting/6daadc1c-765f-4085-a5c4-b1ee1e782ad8/page/kIV1C"
      frameborder="0" style="border:0" allowfullscreen></iframe>
    <!-- <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <h1 class="page-header">Performance Metric</h1>
      </div>
    </div>

    <div class="panel">
      <div class="panel-heading">
        <div class="row w-100">
          <label class="form-label col-form-label col-auto">Date range</label>
          <div class="form-group col-auto">
            <date-range-picker
              class="btn btn-white"
              v-model="filters.visitTime"
            >
            </date-range-picker>
          </div>
          <label class="form-label col-form-label col-auto">Data Type</label>
          <div class="form-group col-2">
            <v-select
              :options="[
                { label: 'All', code: null },
                { label: 'Page', code: 'PAGES' },
                { label: 'Post', code: 'ARTICLE' },
                { label: 'Banner', code: 'BANNER' },
                { label: 'Faq', code: 'FAQ' },
              ]"
              v-model="filters.componentType"
              placeholder="Type"
            />
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-outline-primary">
              Generate
            </button>
          </div>
          <div class="col-auto">
            <b-dropdown variant="outline-secondary">
              <template slot="button-content">
                Download <i class="fa fa-chevron-down"></i>
              </template>
              <a href="javascript:;" class="dropdown-item">CSV</a>
              <a href="javascript:;" class="dropdown-item">Excel</a>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
        initialSortBy: { field: 'visitTime', type: 'desc' },
      }"
      styleClass="vgt-table"
      slot="outsideBody"
    >
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input
          ref="content"
          class="form-control"
          placeholder="Search content"
          v-if="
            column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'content'
          "
          @input="(value) => updateFilters(column, $refs.content.value)"
        />
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'idContent'">
          <template v-if="props.row.detail">
            <a
              target="_BLANK"
              v-if="props.row.detail.link"
              :href="props.row.detail.link"
              v-text="props.row.detail.title"
            />
            <span v-else v-text="props.row.detail.title" />
          </template>
          <template v-else>
            {{ props.row.componentType }} : {{ props.row.idContent }}
          </template>
        </span>
        <span v-else-if="props.column.field === 'created'">
          {{ props.row.detail ? props.row.detail.created : "-" }}
        </span>
        <span v-else-if="props.column.field === 'avgTime'">
          {{ props.row[props.column.field].toFixed(0) }}s
        </span>
        <span v-else-if="props.column.field === 'rate'">
          {{ props.row.rate.toFixed(2) }} %
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table> -->
  </div>
</template>

<script>
import moment from "moment";
import activityService from "@/service/activity.service";
import postService from "@/service/post.service";
import pageService from "@/service/page.service";
import bannerService from "@/service/banner.service";
import faqService from "@/service/faq.service";

const columnPagePost = [
  {
    label: "Created Date",
    field: "created",
    tdClass: "text-nowrap align-middle",
  },
  {
    label: "Content",
    field: "idContent",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
  {
    label: "Pageview / Impression",
    field: "viewsCount",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
  {
    label: "Unq. Pageview",
    field: "uniqVisitor",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
  {
    label: "Avg. Engaged Time",
    field: "avgTime",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
  {
    label: "ER%",
    field: "rate",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
  {
    label: "Avg. Scroll Depth",
    field: "scrollDepth",
    thClass: "text-nowrap",
    tdClass: "align-middle",
    type: "decimal",
  },
];
const columnFaq = [
  {
    label: "Created Date",
    field: "created",
    tdClass: "text-nowrap align-middle",
  },
  {
    label: "Content",
    field: "idContent",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
  {
    label: "View / Impression",
    field: "viewsCount",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
  {
    label: "Unq. View",
    field: "uniqVisitor",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
];
const columnBanner = [
  ...columnFaq,
  {
    label: "Clicks",
    field: "clicks",
    thClass: "text-nowrap",
    tdClass: "align-middle",
  },
];
export default {
  cacheContents: {},
  data() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    return {
      isLoading: false,
      download: { csv: "#", excel: "#" },
      rows: [],
      filters: {
        visitTime: { startDate, endDate },
        componentType: null,
      },
      serverParams: {
        sort: ["visitTime,desc"],
        page: 1,
        perPage: 10,
        query: null,
        startDate: null,
        endDate: null,
        componentType: null,
      },
    };
  },
  computed: {
    columns() {
      switch (this.filters.componentType?.code) {
        case "FAQ":
          return columnFaq;
        case "BANNER":
          return columnBanner;
        default:
          return columnPagePost;
      }
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(filters) {
        const params = {
          startDate: moment(filters.visitTime.startDate)
            .startOf("day")
            .valueOf(),
          endDate: moment(filters.visitTime.endDate).endOf("day").valueOf(),
        };

        if (filters.componentType) {
          params.componentType = filters.componentType.code;
        }
        this.$nextTick(() => (this.serverParams = params));
      },
    },
    serverParams: {
      deep: true,
      handler(params) {
        if (this.$data._debounceQueryTimer) {
          clearTimeout(this.$data._debounceQueryTimer);
          console.debug("loadItems: cancel previous stack");
        }

        this.$data._debounceQueryTimer = setTimeout(() => {
          this.rows = [];
          this.isLoading = true;
          // Just one level clone, not nested!
          const cloned = { ...params };
          // ES Pageable start from 0
          if (cloned.page > 0) {
            cloned.page = cloned.page - 1;
          }
          // Query is JSON encoded
          // cloned.query = cloned.query && JSON.stringify(cloned.query);

          // this.download.csv = activityService.getMicrositeIssueDownloadUrl(
          //   "csv",
          //   cloned.query
          // );
          // this.download.excel = activityService.getMicrositeIssueDownloadUrl(
          //   "excel",
          //   cloned.query
          // );
          this.rows = [];
          activityService
            .getPerfomanceMatric(cloned)
            .then((rsp) =>
              Promise.all(
                (rsp.data?.data || []).map((v) =>
                  this.fetchContent(v.componentType, v.idContent).then(
                    (detail) => {
                      v.detail = detail;
                      return v;
                    }
                  )
                )
              )
            )
            .then((rows) => this.$nextTick(() => (this.rows = rows)))
            .catch((e) => {
              this.$notify({
                type: "error",
                title: "Error Notification",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
            })
            .finally(() => (this.isLoading = false));

          this.$data._debounceQueryTimer = null;
        }, 700);
      },
    },
  },
  methods: {
    async fetchContent(type, id) {
      if (this.$options.cacheContents[id]) {
        return this.$options.cacheContents[id];
      }
      let result;
      switch (type) {
        case "PAGES":
          result = pageService.getSinglePage(id).then(({ data: { data } }) => {
            data.link = env.APP_FRONTEND + "/pages/" + data.slug;
            return data;
          });

          break;
        case "ARTICLE":
          result = postService.getArticleById(id).then(({ data: { data } }) => {
            data.link = env.APP_FRONTEND + "/article-detail/" + data.slug;
            return data;
          });
          break;
        case "BANNER":
          result = bannerService
            .getBannerById(id)
            .then(({ data: { data } }) => data);
          break;
        case "FAQ":
          result = faqService.getFaqById(id).then(({ data: { data } }) => data);
          break;
      }

      if (result) {
        const detail = await (result.catch((e) => null));
        if (detail) {
          detail.created = moment(detail.created).format("D MMMM YYYY HH:mm:ss");
        }
        this.$options.cacheContents[id] = detail;
      }
      return this.$options.cacheContents[id];
    },
    onPageChange(params) {
      // this.serverParams.page = params.currentPage;
    },
    onPerPageChange(params) {
      // this.serverParams.page = 1;
      // this.serverParams.perPage = params.currentPerPage;
    },

    onSortChange(params) {
      // this.serverParams.sort = params.map(({ field, type }) =>
      // type == "none" ? field : `${field},${type}`
      // );
    },
    onColumnFilter(params) {
      if (params.columnFilters) {
        Object.assign(this.filters, params.columnFilters);
      }
    },
  },
};
</script>
