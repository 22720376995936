import hospitalService from "../service/hospital.service";
export default {
  namespaced: true,
  state: {
    tier:{
      last: null,
      first: null,
      empty: null,
      size:0,
      number:0,
      totalPages:0,
      totalElements:0,
      numberOfElements:0,
      content:[]
    },
    totalPublished: 0,
    totalDrafts: 0,
    totalTrashed: 0
    
  },
  actions: {
    createHospital(_ctx, hospital) {
      return hospitalService.createHospital(hospital).catch((error) => {
        return Promise.reject(error)
      })
    },
    createTier(_ctx, hospital) {
      return hospitalService.createTier(hospital).catch((error) => {
        return Promise.reject(error)
      })
    },
    getHospitalTier({ commit }, serverParams) {
      return hospitalService
        .getHospitalTierList(serverParams)
        .then((result) => {
          // console.log('cek: ', result)
          commit("setHospitalTierData", result.data);
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    getHospitalLists({ commit }, serverParams) {
      return hospitalService.getHospitalLists(serverParams)
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    getHospitalTotal({ commit }, serverParams) {
      return hospitalService.getHospitalLists(serverParams)
      .then((result) => {
        commit("setTotal", { total: result?.data?.totalElements || 0,  status: serverParams.status});
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
  },
  getters: {
    getTier(state) {
      return state.tier;
    },
    getTotalPublished(state) {
      return state.totalPublished;
    },
    getTotalDrafts(state) {
      return state.totalDrafts;
    },
    getTotalTrashed(state) {
      return state.totalTrashed;
    }
  },
  mutations: {
    setHospitalTierData(state, data) {
      state.tier.last = data.last;
      state.tier.first = data.first;
      state.tier.empty = data.empty;
      state.tier.size = data.size;
      state.tier.number = data.number;
      state.tier.totalPages = data.totalPages;
      state.tier.totalElements = data.totalElements;
      state.tier.numberOfElements = data.numberOfElements;
      state.tier.content = [];
      for (let i = 0; i < data.content.length; i++) {
        state.tier.content.push({
          label: data.content[i].tier,
          id: data.content[i].id,
          multiplier: data.content[i].multiplier,
        });
      }
    },
    pushSelectedData(state, data) {
      const {type, ...dataPush } = data
      switch (type) {
        case 'tier':
          const tier = state.tier.content.filter((e) => {
            return e.id !== dataPush.id;
          })
          tier.splice(0, 0, dataPush)
          state.tier.content = tier
          break;
      
        default:
          break;
      }
    },
    setTotal(state, data) {
      const { total, status } = data
      if(status === 'PUBLISHED'){
        state.totalPublished = total
      }else if(status === 'DRAFTS'){
        state.totalDrafts = total
      }else if(status === 'TRASHED'){
        state.totalTrashed = total
      }
    },
  },
  
  
};
