import PostCategoryService from "../service/postcategory.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        
        //category
        async getListArticleCategory(_ctx, serverParams) {
            return PostCategoryService.getArticleCategoryList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postArticleCategory(_ctx, serverParams) {
            return PostCategoryService.postArticleCategory(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getArticleCategoryById(_ctx, serverParams) {
            return PostCategoryService.getArticleCategoryById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateArticleCategoryById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return PostCategoryService.putArticleCategory(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteArticleCategoryById(_ctx, serverParams) {
            return PostCategoryService.deleteArticleCategoryById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
