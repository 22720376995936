<template>
  <form @submit.prevent="doSave('PUBLISHED')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} Product</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Product Name"
            v-model.trim="$v.product.name.$model"
          />
          <span
            class="text-danger"
            v-if="!$v.product.name.required && $v.product.name.$error"
            >Name is required</span
          >
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Product Order</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Product Order"
                  v-model="product.displayOrder"
                />
                <span
                  class="text-danger"
                  v-if="
                    !$v.product.displayOrder.required &&
                    $v.product.displayOrder.$error
                  "
                  >Product Order is required</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Product Category</label>
                  <v-select
                    v-model="$v.product.category.$model"
                    :options="optionsCategory"
                    label="code"
                  />
                  <span
                    class="text-danger"
                    v-if="
                      !$v.product.category.required &&
                      $v.product.category.$error
                    "
                    >Product Category is required</span
                  >
                </div>
              </div>
              <div v-if="countSubCategory !== 0" class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Sub-category</label>
                  <v-select
                    v-model="product.parentCategory"
                    :options="optionsParentCategory"
                    label="name"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Description</label>
                <vue-editor
                  v-model="product.description"
                  :editor-toolbar="customToolbar"
                />
                <span
                  class="text-danger"
                  v-if="
                    !$v.product.description.required &&
                    $v.product.description.$error
                  "
                  >Description is required</span
                >
              </div>
            </div>
            <br />
          </div>
        </div>
        <h3 class="fw-normal mb-15px pt-3">RIPLAY Information</h3>
        <div class="panel">
          <div class="panel-body">
            <label class="form-label">Penjelasan Manfaat Asuransi</label>
            <textarea
              class="form-control"
              rows="7"
              v-model="product.riplay"
              placeholder="Text"
            ></textarea>
            <span
              class="text-danger"
              v-if="!$v.product.riplay.required && $v.product.riplay.$error"
              >Penjelasan Manfaat Asuransi is required</span
            >
          </div>
        </div>
        <panel
          title="Rincian Kontribusi"
          variant="inverse"
          body-class="p-0"
          :is-collapse="true"
        >
          <div class="table-responsive mb-0">
            <table class="table table-form">
              <thead>
                <tr>
                  <th></th>
                  <th>Label</th>
                  <th>Description</th>
                  <th>Order</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in product.contributions" :key="index">
                  <td v-if="item.contributionDetailType === 'SPAJ'" width="20">
                    <button
                      class="btn btn-xs btn-outline-primary rounded-pill"
                      type="button"
                      @click="removeContribution(index)"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </td>
                  <td v-if="item.contributionDetailType === 'SPAJ'" width="30%">
                    <input
                      type="text"
                      v-model="item.label"
                      class="form-control fw-bolder"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'SPAJ'">
                    <input
                      type="text"
                      v-model="item.value"
                      class="form-control"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'SPAJ'">
                    <input
                      type="number"
                      v-model="item.displayOrder"
                      class="form-control"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'SPAJ'">
                    <input
                      type="text"
                      v-model="item.contributionDetailType"
                      class="form-control"
                      value="SPAJ"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            class="btn btn-action-table btn-xs btn-primary rounded-pill"
            type="button"
            @click="addContribution('SPAJ')"
          >
            <i class="fas fa-plus"></i>
          </button>
          <span
            class="text-danger"
            v-if="
              !$v.product.contributions.required &&
              $v.product.contributions.$error
            "
            >Rincian Kontribusi is required</span
          >
          <span class="text-danger" v-if="$v.product.contributions.$each.$error"
            >Pastikan semua detail kontibusi terisi</span
          >
        </panel>

        <panel
          title="Rincian Benefit Singkat"
          variant="inverse"
          body-class="p-0"
          :is-collapse="true"
        >
          <div class="table-responsive mb-0">
            <table class="table table-form">
              <thead>
                <tr>
                  <th></th>
                  <th>Label</th>
                  <th>Description</th>
                  <th>Order</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in product.contributions" :key="index">
                  <td
                    v-if="item.contributionDetailType === 'SHORT_BENEFIT'"
                    width="20"
                  >
                    <button
                      class="btn btn-xs btn-outline-primary rounded-pill"
                      type="button"
                      @click="removeContribution(index)"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </td>
                  <td
                    v-if="item.contributionDetailType === 'SHORT_BENEFIT'"
                    width="30%"
                  >
                    <input
                      type="text"
                      v-model="item.label"
                      class="form-control fw-bolder"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'SHORT_BENEFIT'">
                    <input
                      type="text"
                      v-model="item.value"
                      class="form-control"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'SHORT_BENEFIT'">
                    <input
                      type="number"
                      v-model="item.displayOrder"
                      class="form-control"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'SHORT_BENEFIT'">
                    <input
                      type="text"
                      v-model="item.contributionDetailType"
                      class="form-control"
                      value="SHORT_BENEFIT"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            class="btn btn-action-table btn-xs btn-primary rounded-pill"
            type="button"
            @click="addContribution('SHORT_BENEFIT')"
          >
            <i class="fas fa-plus"></i>
          </button>
          <span
            class="text-danger"
            v-if="
              !$v.product.contributions.required &&
              $v.product.contributions.$error
            "
            >Rincian Kontribusi is required</span
          >
          <span class="text-danger" v-if="$v.product.contributions.$each.$error"
            >Pastikan semua detail kontibusi terisi</span
          >
        </panel>

        <panel
          title="Pop Up Benefit Product"
          variant="inverse"
          body-class="p-0"
          :is-collapse="true"
        >
          <div class="table-responsive mb-0">
            <table class="table table-form">
              <thead>
                <tr>
                  <th></th>
                  <th>Label</th>
                  <th>Description</th>
                  <th>Order</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in product.contributions" :key="index">
                  <td
                    v-if="item.contributionDetailType === 'POPUP_BENEFIT'"
                    width="20"
                  >
                    <button
                      class="btn btn-xs btn-outline-primary rounded-pill"
                      type="button"
                      @click="removeContribution(index)"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </td>
                  <td
                    v-if="item.contributionDetailType === 'POPUP_BENEFIT'"
                    width="30%"
                  >
                    <input
                      type="text"
                      v-model="item.label"
                      class="form-control fw-bolder"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'POPUP_BENEFIT'">
                    <input
                      type="text"
                      v-model="item.value"
                      class="form-control"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'POPUP_BENEFIT'">
                    <input
                      type="number"
                      v-model="item.displayOrder"
                      class="form-control"
                    />
                  </td>
                  <td v-if="item.contributionDetailType === 'POPUP_BENEFIT'">
                    <input
                      type="text"
                      v-model="item.contributionDetailType"
                      class="form-control"
                      value="POPUP_BENEFIT"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            class="btn btn-action-table btn-xs btn-primary rounded-pill"
            type="button"
            @click="addContribution('POPUP_BENEFIT')"
          >
            <i class="fas fa-plus"></i>
          </button>
          <span
            class="text-danger"
            v-if="
              !$v.product.contributions.required &&
              $v.product.contributions.$error
            "
            >Rincian Kontribusi is required</span
          >
          <span class="text-danger" v-if="$v.product.contributions.$each.$error"
            >Pastikan semua detail kontibusi terisi</span
          >
        </panel>

        <h3 class="fw-normal mb-15px pt-3">Product Configuration</h3>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Code</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Product code"
                    v-model="product.code"
                  />
                  <span
                    class="text-danger"
                    v-if="!$v.product.code.required && $v.product.code.$error"
                    >Product Code is required</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label">E-Policy</label>
                  <div class="pt-2">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="ePolicy"
                        id="ePolicy1"
                        value="true"
                        v-model="product.ePolicy"
                      />
                      <label class="form-check-label" for="ePolicy1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="ePolicy"
                        id="ePolicy2"
                        value="false"
                        v-model="product.ePolicy"
                      />
                      <label class="form-check-label" for="ePolicy2">No</label>
                    </div>
                  </div>
                  <span
                    class="text-danger"
                    v-if="
                      !$v.product.ePolicy.required && $v.product.ePolicy.$error
                    "
                    >E-Policy is required</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label">Sum Insurance</label>
                  <v-select
                    v-model="product.sumInsurance"
                    :options="optionsSumInsurance"
                    label="code"
                  />
                  <span
                    class="text-danger"
                    v-if="
                      !$v.product.sumInsurance.required &&
                      $v.product.sumInsurance.$error
                    "
                    >Sum Insurance is required</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label">Coverage Period (years)</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Coverage Period"
                    v-model="product.coverage"
                  />
                  <span
                    class="text-danger"
                    v-if="
                      !$v.product.coverage.required &&
                      $v.product.coverage.$error
                    "
                    >Coverage Period is required</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <label class="form-label">Wakaf</label>
                <div class="pt-2">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="wakaf"
                      id="wakaf1"
                      value="true"
                      v-model="product.wakaf"
                    />
                    <label class="form-check-label" for="wakaf1">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="wakaf"
                      id="wakaf2"
                      value="false"
                      v-model="product.wakaf"
                    />
                    <label class="form-check-label" for="wakaf2">No</label>
                  </div>
                </div>
                <span
                  class="text-danger"
                  v-if="!$v.product.wakaf.required && $v.product.wakaf.$error"
                  >Wakaf is required</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Benefit Limit</label>
                  <v-select
                    v-model="product.benefit"
                    :options="optionsBenefitLimit"
                    label="code"
                  />
                  <span
                    class="text-danger"
                    v-if="
                      !$v.product.benefit.required && $v.product.benefit.$error
                    "
                    >Benefit Limit is required</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Co - Insurance</label>
                  <v-select
                    v-model="product.insurance"
                    :options="optionsCoInsurance"
                    label="code"
                  />
                  <span
                    class="text-danger"
                    v-if="
                      !$v.product.insurance.required &&
                      $v.product.insurance.$error
                    "
                    >Co - Insurance is required</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <panel
              title="Premi Dasar berdasarkan Usia"
              variant="inverse"
              body-class="p-0"
              :is-collapse="true"
            >
              <div class="table-responsive mb-0">
                <table class="table table-form">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Usia awal</th>
                      <th>Usia akhir</th>
                      <th>Inner limit (Rp)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in product.configurations"
                      :key="index"
                    >
                      <td width="20">
                        <button
                          class="btn btn-xs btn-outline-primary rounded-pill"
                          type="button"
                          @click="removeConfiguration(index)"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="item.minAge"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="item.maxAge"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="item.innerLimit"
                          class="form-control"
                          v-on:keyup="formatRibuan(index, $event.target.value)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button
                class="btn btn-action-table btn-xs btn-primary rounded-pill"
                type="button"
                @click="addConfiguration()"
              >
                <i class="fas fa-plus"></i>
              </button>
              <span
                class="text-danger"
                v-if="
                  !$v.product.configurations.required &&
                  $v.product.configurations.$error
                "
                >Premi is required</span
              >
            </panel>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <panel
              title="Tabel Manfaat"
              variant="inverse"
              body-class="p-0"
              :is-collapse="true"
            >
              <div class="table-responsive mb-0">
                <table class="table table-form">
                  <thead>
                    <tr>
                      <th width="20"></th>
                      <th width="40%">Manfaat Utama</th>
                      <th width="30%">Penjelasan</th>
                      <th width="30%">Nilai</th>
                      <th width="30%">Order</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in product.benefits" :key="index">
                      <td>
                        <button
                          class="btn btn-xs btn-outline-primary rounded-pill"
                          type="button"
                          @click="removeBenefit(index)"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="item.label"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="item.description"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="item.value"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          v-model="item.displayOrder"
                          class="form-control"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button
                class="btn btn-action-table btn-xs btn-primary rounded-pill"
                type="button"
                @click="addBenefit()"
              >
                <i class="fas fa-plus"></i>
              </button>
              <span
                class="text-danger"
                v-if="
                  !$v.product.benefits.required && $v.product.benefits.$error
                "
                >Tabel Manfaat is required</span
              >
              <span class="text-danger" v-if="$v.product.benefits.$each.$error"
                >Pastikan semua detail benefits terisi</span
              >
            </panel>
          </div>
        </div>

        <div v-if="product.category === 'MAIN'" class="row pt-3">
          <div class="col-md-12">
            <panel
              title="Tabel Product Extras"
              body-class="p-0"
              :is-collapse="true"
            >
              <table class="table-responsive mb-0 table table-form">
                <thead>
                  <tr>
                    <th width="20"></th>
                    <th width="100%">Product</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in product.ekstra" :key="index">
                    <td>
                      <button
                        class="btn btn-xs btn-outline-primary rounded-pill"
                        type="button"
                        @click="removeExtras(index, item)"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </td>
                    <td>
                      <v-select
                        type="text"
                        :options="optionsExtra"
                        class="form-control"
                        label="name"
                        @input="changeExtra"
                        v-model="item.item"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                class="btn btn-action-table btn-xs btn-primary rounded-pill"
                type="button"
                @click="addExtras()"
              >
                <i class="fas fa-plus"></i>
              </button>
              <span
                class="text-danger"
                v-if="
                  !$v.product.benefits.required && $v.product.benefits.$error
                "
                >Tabel Manfaat is required</span
              >
            </panel>
          </div>
        </div>
      </div>
      <div v-if="formType === 'edit'" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ product.publishedDate | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ product.modifiedDate | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ product.creator }}</p>
            </div>
            <!-- <a href="#" class="btn btn-outline-primary" target="_blank" @click.prevent="doPreview('DRAFTS')">Preview
              Product</a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click.prevent="doSave('DRAFTS')"
            :class="{ disabled: onLoading }"
          >
            <span
              v-show="onLoading"
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button
            type="submit"
            class="btn btn-primary"
            :class="{ disabled: onLoading }"
          >
            <span
              v-show="onLoading"
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import benefitLimitService from "@/service/benefitlimit.service";
import productService from "@/service/product.service";
import productCategoryService from "@/service/productcategory.service";
import sumInsuranceService from "@/service/suminsurance.service";
import coInsuranceService from "@/service/coinsurance.service";
import { required, minLength, between } from "vuelidate/lib/validators";
import MyCurrencyInput from "@/components/input/MyCurrencyInput.vue";
import dayjs from "dayjs";
import index from "vue-maskedinput";

export default {
  components: {
    MyCurrencyInput,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    this.loadProductExtras();
    return {
      contributionOptionType: [
        { label: "SPAJ", code: "SPAJ" },
        { label: "SHORT BENEFIT", code: "SHORT_BENEFIT" },
        { label: "POPUP BENEFIT", code: "POPUP_BENEFIT" },
      ],
      optionsCategory: [],
      optionsCoInsurance: [],
      optionsSumInsurance: [],
      optionsBenefitLimit: [],
      customToolbar: defaultToolbarEditor,
      optionsParentCategory: [],
      countSubCategory: 0,
      product: {
        code: detail ? detail.code : null,
        name: detail ? detail.name : null,
        category: detail ? detail.category : null,
        benefit: detail ? detail.benefit : null,
        insurance: detail ? detail.insurance : null,
        description: detail ? detail.description : null,
        riplay: detail ? detail.riplay : null,
        contributions: detail ? detail.contributions : [],
        configurations: detail ? detail.configurations : [],
        ePolicy: null,
        sumInsurance: null,
        coverage: null,
        wakaf: null,
        benefits: detail ? detail.benefits : [],
        create: detail ? detail.createBy : null,
        parentCategory: detail ? detail.parentCategory : null,
        ekstra: detail ? detail.ekstra : [],
      },
      optionsExtra: [],
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "",
        pageNumber: 0,
        pageSize: 0,
      },
      status: "",
      formCheckInvalid: true,
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add" : "Edit";
    },
  },
  watch: {
    product: {
      handler(nv, ov) {
        this.formCheckInvalid = this.$v.product.$invalid;
      },
      deep: true,
    },
    "product.category"(nv, ov) {
      if (nv) {
        this.loadCategoryParent();
      }
    },
  },
  methods: {
    addContribution(type) {
      this.product.contributions.push({
        label: "",
        value: "",
        displayOrder: "",
        contributionDetailType: type,
      });
    },
    removeContribution(index) {
      this.product.contributions.splice(index, 1);
    },
    addConfiguration() {
      this.product.configurations.push({
        minAge: "",
        maxAge: "",
        innerLimit: "",
      });
    },
    removeConfiguration(index) {
      this.product.configurations.splice(index, 1);
    },
    addBenefit() {
      this.product.benefits.push({
        label: "",
        description: "",
        value: "",
        displayOrder: "",
      });
    },
    removeBenefit(index) {
      this.product.benefits.splice(index, 1);
    },
    doSave(status) {
      this.$v.product.$touch();
      if (!this.formCheckInvalid) {
        if (this.product.parentCategory) {
          this.product.category = this.product.parentCategory;
        }

        const payload = {
          product: this.product,
          status: status,
        };

        this.$emit("onValidateSuccess", payload);
      }
    },
    async loadItems() {
      await this.loadProductCategory();
      await this.loadSumInsurance();
      await this.loadCoInsurance();
      await this.loadBenefitLimit();
      if (this.formType != "add") {
        await this.loadEdit(this.$route.params.id);
      }
    },
    loadProductExtras() {
      productService
        .getProductByGroup("EXTRA")
        .then((rsp) => {
          if (rsp?.data?.data) {
            this.optionsExtra = rsp.data.data.content;
          } else {
            this.optionsExtra = [];
          }
          // console.log('extras')
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    loadProductCategory() {
      productCategoryService
        .getProductParentCategoryList(this.serverParams)
        .then((rsp) => {
          if (rsp?.data?.data) {
            this.optionsCategory = rsp.data.data;
          } else {
            this.optionsCategory = [];
          }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    loadSumInsurance() {
      sumInsuranceService
        .getSumInsuranceList(this.payload)
        .then((rsp) => {
          if (rsp?.data?.data) {
            this.optionsSumInsurance = rsp.data.data.content;
          } else {
            this.optionsSumInsurance = [];
          }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    loadCoInsurance() {
      coInsuranceService
        .getCoInsuranceList(this.payload)
        .then((rsp) => {
          if (rsp?.data?.data) {
            this.optionsCoInsurance = rsp.data.data.content;
          } else {
            this.optionsCoInsurance = [];
          }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    loadBenefitLimit() {
      let payload = {
        searchField: "",
        searchValue: "",
        sort: "code",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      };
      benefitLimitService
        .getBenefitLimitList(payload)
        .then((rsp) => {
          this.optionsBenefitLimit = rsp.data.data ? rsp.data.data.content : [];
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    loadEdit(id) {
      productService
        .getProductById(id)
        .then((rsp) => {
          let premis = [];
          for (const [k, v] of Object.entries(rsp.data.data.premis)) {
            premis.push({
              ...v,
              innerLimit: this.formatRibuan(k, v.innerLimit),
            });
          }
          this.product = {
            id: rsp.data.data.id,
            code: rsp.data.data.productConfiguration?.code || "",
            name: rsp.data.data.name ? rsp.data.data.name : "",
            displayOrder: rsp.data.data.displayOrder,
            creator: rsp.data.data.createdBy,
            publishedDate: rsp.data.data.created,
            modifiedDate: rsp.data.data.updated,
            category: rsp.data.data.productCategory
              ? rsp.data.data.productCategory.productCategoryGroup
              : "",
            benefit: rsp.data.data.productConfiguration?.benefitLimit
              ? rsp.data.data.productConfiguration.benefitLimit
              : "",
            insurance: rsp.data.data.productConfiguration?.coInsurance
              ? rsp.data.data.productConfiguration.coInsurance
              : "",
            description: rsp.data.data.description
              ? rsp.data.data.description
              : "",
            riplay: rsp.data.data.riplayInformation
              ? rsp.data.data.riplayInformation
              : "",
            contributions: rsp.data.data.contributionDetails
              ? rsp.data.data.contributionDetails
              : "",
            configurations: premis,
            ePolicy: rsp.data.data.productConfiguration?.epolicy
              ? rsp.data.data.productConfiguration.epolicy
              : false,
            sumInsurance: rsp.data.data.productConfiguration?.sumInsurance
              ? rsp.data.data.productConfiguration.sumInsurance
              : [],
            coverage: rsp.data.data.productConfiguration?.coveragePeriod
              ? rsp.data.data.productConfiguration.coveragePeriod
              : "",
            wakaf: rsp.data.data.productConfiguration?.wakaf
              ? rsp.data.data.productConfiguration.wakaf
              : false,
            benefits: rsp.data.data.benefits ? rsp.data.data.benefits : [],
            publishDate: rsp.data.data.publishDate
              ? rsp.data.data.publishDate
              : "",
            author: rsp.data.data.createdBy ? rsp.data.data.createdBy : "",
            lastModified: rsp.data.data.updated ? rsp.data.data.updated : "",
            ekstra: rsp.data.data.ekstra ? rsp.data.data.ekstra : [],
          };
          // console.log('extras1')
          let ekstra = [],
            index;

          for (const [k, v] of Object.entries(rsp.data.data.extraAddons)) {
            ekstra.push({ item: v });
            index = this.optionsExtra.findIndex((r) => r.id === v.id);
            this.optionsExtra.splice(index, 1);
          }
          this.product.ekstra = ekstra;

          this.product.parentCategory = rsp.data.data.productCategory
            ? rsp.data.data.productCategory
            : "";
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    loadCategoryParent() {
      let searchValue =
        typeof this.product.category != "object"
          ? this.product.category
          : this.product.category.productCategoryGroup;
      let payload = {
        searchField: "productCategoryGroup",
        searchValue: searchValue,
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      };

      productCategoryService
        .getProductCategoryList(payload)
        .then((rsp) => {
          this.optionsParentCategory = rsp.data?.data
            ? rsp.data.data.content
            : [];
          this.countSubCategory = rsp.data?.data ? rsp.data.data.length : 0;
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${
              e.response.data?.responseCode || "00"
            }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    doPreview(status) {
      this.$v.product.$touch();
      if (!this.formCheckInvalid) {
        const payload = {
          product: this.product,
          status: status,
        };

        if (payload) {
          let { product, status } = payload;
          let payloadData = {
            name: product.name,
            productCategory: product.category,
            description: product.description,
            riplayInformation: product.riplay,
            contributionDetails: product.contributions,
            productConfiguration: {
              code: product.code,
              epolicy: product.ePolicy,
              benefitLimit: product.benefit,
              sumInsurance: product.sumInsurance,
              coInsurance: product.insurance,
              wakaf: product.wakaf,
              coveragePeriod: product.coverage,
            },
            premis: product.configurations,
            benefits: product.benefits,
            status,
          };
          this.payload = { ...payloadData };

          productService
            .previewProduct(this.payload)
            .then((rsp) => {
              window.open(rsp.data.data, "_blank", "noreferrer");
            })
            .catch((e) => {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
            });
        }
      }
    },
    dateFormat(date) {
      if (date) {
        let arrDate = date.split(" ");
        let newDate = new Date(arrDate[0]);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return newDate.toLocaleDateString("id", options);
      } else {
        return "";
      }
    },
    formatRibuan(i, angka) {
      let number = angka.toString();
      var number_string = number.replace(/[^,\d]/g, "").toString(),
        split = number_string.split("."),
        sisa = split[0].length % 3,
        angka_hasil = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuan) {
        let separator = sisa ? "." : "";
        angka_hasil += separator + ribuan.join(".");
      }

      angka_hasil =
        split[1] != undefined ? angka_hasil + "." + split[1] : angka_hasil;
      if (this.product.configurations[i]?.innerLimit) {
        this.product.configurations[i].innerLimit = angka_hasil;
      } else {
        return angka_hasil;
      }
    },
    removeAddons(index) {
      this.product.addOns.splice(index, 1);
    },
    addOns() {
      this.product.addOns.push({
        label: "",
      });
    },
    removeExtras(index, item) {
      this.product.ekstra.splice(index, 1);
      if (item.item?.id) {
        this.optionsExtra.push(item.item);
      }
    },
    addExtras() {
      this.product.ekstra.push({});
    },
    changeExtra(item) {
      let findIndex = this.optionsExtra.findIndex((r) => r == item);
      this.optionsExtra.splice(findIndex, 1);
    },
  },
  mounted() {
    this.loadItems();
  },
  filters: {
    getDate(date) {
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
  validations: {
    product: {
      code: { required },
      name: { required },
      category: { required },
      benefit: { required },
      insurance: { required },
      description: { required },
      riplay: { required },
      displayOrder: { required },
      contributions: {
        required,
        minLength: minLength(1),
        $each: {
          label: { required },
          value: { required },
          displayOrder: { required },
          contributionDetailType: { required },
        },
      },
      configurations: {
        required,
        minLength: minLength(1),
        $each: {
          minAge: {
            required,
          },
          maxAge: {
            required,
          },
          innerLimit: {
            required,
          },
        },
      },
      ePolicy: { required },
      sumInsurance: { required },
      coverage: { required },
      wakaf: { required },
      benefits: {
        required,
        minLength: minLength(1),
        $each: {
          label: {
            required,
          },
          description: {
            required,
          },
          value: {
            required,
          },
          displayOrder: { required },
        },
      },
    },
  },
};
</script>
