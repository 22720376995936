import { render, staticRenderFns } from "./Trashed.vue?vue&type=template&id=7ac5f198&"
import script from "./Trashed.vue?vue&type=script&lang=js&"
export * from "./Trashed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports