<template>
  <div class="row">
    <div class="col-md-12">
      <label class="form-label" v-text="label"></label>
    </div>
    <div class="col-md-6">
      <div class="mb-15px">
        <p class="mb-2" v-text="labelDesktop"></p>
        <UploadImage
          :src="srcDesktop"
          @input="handleImage('Desktop', $event)"
        />
      </div>
      <span class="text-danger" v-if="$v.value.mainImageDesktop.$error"
        >Desktop Image is required</span
      >
    </div>
    <div class="col-md-6">
      <div class="mb-15px">
        <p class="mb-2" v-text="labelMobile"></p>
        <UploadImage :src="srcMobile" @input="handleImage('Mobile', $event)" />
      </div>
      <span class="text-danger" v-if="$v.value.mainImageMobile.$error"
        >Mobile Image is required</span
      >
    </div>
  </div>
</template>
<script>
import UploadImage from "@/components/upload/Image.vue";
import base64Converter from "@/pages/Helper/base64";
import bannerService from "@/service/banner.service";

export default {
  components: { UploadImage },
  props: {
    value: {
      type: Object,
    },
    label: {
      type: String,
      required: false,
      default: "Main Image",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelDesktop: {
      type: String,
      required: false,
      default: "(dekstop version : 1280x550 px, max. 1mb)",
    },
    labelMobile: {
      type: String,
      required: false,
      default: "(mobile version : 600x300 px, max. 1mb)",
    },
  },
  computed: {
    srcDesktop() {
      if (this.value?.mainImageDesktopFileName) {
        return bannerService.getFileUrl(this.value?.mainImageDesktopFileName);
      } else return null;
    },
    srcMobile() {
      if (this.value?.mainImageMobileFileName) {
        return bannerService.getFileUrl(this.value?.mainImageMobileFileName);
      } else return null;
    },
  },
  methods: {
    async handleImage(type, val) {
      if (val?.length) {
        this.value[`mainImage${type}`] = await base64Converter(val[0]);
      }
    },
  },
  validations() {
    return {
      value: {
        mainImageDesktop: {
          required: function () {
            if (this.required) {
              return this.value.mainImageDesktop !== null;
            } else {
              return true;
            }
          },
        },
        mainImageMobile: {
          required: function () {
            if (this.required) {
              return this.value.mainImageMobile !== null;
            } else {
              return true;
            }
          },
        },
      },
    };
  },
};
</script>