<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="detail" :on-loading="onProgress" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import postService from "../../service/post.service";

export default {
  data() {
    // this.loadItems(this.$route.params.id)
    return {
      onProgress: false,
      detail: {
        id: this.$route.params.id,
        title: "",
        category: "",
        slug: "",
        content: "",
        images: {
          desktop: "",
          mobile: "",
        },
        meta: {
          title: "Amore Health Plan 500",
          description: "",
          keywords: [],
          image: "",
        },
      },
    };
  },
  components: {
    FormPost,
  },

  methods: {
    async doSave(params) {
      // Submit here
      this.onProgress = true
      let { post } = params;
      if (post) {
        let { cta, preview } = post;
        let payload = {
          id: post.id,
          title: post.title,
          slug: post.slug,
          content: post.content,
          source: "CMS",
          // "mainImageDesktop": typeof post.images.desktop === 'object' ? await base64Converter(post.images.desktop[0]) : null,
          // "mainImageMobile": typeof post.images.mobile === 'object' ? await base64Converter(post.images.mobile[0]) : null,
          mainImageDesktop:
            post.images.desktop !== "" ? post.images.desktop : null,
          mainImageMobile:
            post.images.mobile !== "" ? post.images.mobile : null,
          seoInformation: {
            metaTitle: post.meta.title,
            metaDescription: post.meta.description,
            metaImage: post.meta.image !== "" ? post.meta.image : null,
            seoKeywords: post.meta.keywords.toString(),
          },
          status: post.status ?? 'PUBLISHED',
          articleCategory: {
            id: post.category.id,
          },
        };

        postService
          .putArticle(post.id, payload)
          .then((rsp) => {
            if (preview) {
              window.open(process.env.VUE_APP_FRONTEND + '/article-detail/' + post.slug, '_blank', 'noreferrer');
            }
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.onProgress = false
              this.$router.push({ path: "/posts/list" });
            });

          })
          .catch((e) => {
            this.onProgress = false
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          });
      }
    },
    loadItems(id) {
      postService
        .getArticleById(id)
        .then(async (rsp) => {
          if (rsp.data?.data) {
            let articleItem = rsp.data?.data;
            this.detail = {
              id: id,
              title: articleItem.title ? articleItem.title : null,
              category: articleItem.articleCategory
                ? articleItem.articleCategory
                : null,
              slug: articleItem.slug ? articleItem.slug : null,
              content: articleItem.content,
              images: {
                desktop: articleItem.mainImageDesktopFileUrl
                  ? [await this.convertURLtoFile(articleItem.mainImageDesktopFileUrl)]
                  : null,
                mobile: articleItem.mainImageDesktopFileUrl
                  ? [await this.convertURLtoFile(articleItem.mainImageDesktopFileUrl)]
                  : null,
                desktopSrc: articleItem.mainImageDesktopFileUrl
                  ? articleItem.mainImageDesktopFileUrl
                  : '',
                mobileSrc: articleItem.mainImageMobileFileUrl
                  ? articleItem.mainImageMobileFileUrl
                  : '',
              },
              meta: {
                id: articleItem.seoInformation
                  ? articleItem.seoInformation.id
                  : null,
                title: articleItem.seoInformation?.metaTitle
                  ? articleItem.seoInformation.metaTitle
                  : null,
                description: articleItem.seoInformation?.metaDescription
                  ? articleItem.seoInformation.metaDescription
                  : null,
                keywords: articleItem.seoInformation?.keyword
                  ? articleItem.seoInformation.keyword.split(",")
                  : [],
                image: articleItem.seoInformation?.metaImageFileName
                  ? articleItem.seoInformation.metaImageFileName
                  : null,
                imageSrc: articleItem.seoInformation?.metaImageFileName
                  ? articleItem.seoInformation.metaImageFileUrl
                  : '',
              },
              sidebar: {
                created: articleItem.created ? articleItem.created : null,
                updated: articleItem.updated ? articleItem.updated : null,
                updatedBy: articleItem.updatedBy ? articleItem.updatedBy : null,
              },
              source: articleItem.source ? articleItem.source : null,
            };
          }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    async convertURLtoFile(url) {
      try {
        const response = await fetch(url);
        if (response.status != 200) {
          return "";
        }
        const data = await response.blob();
        const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
        const metadata = { type: `image/jpeg` };
        return new File([data], filename, metadata);
      } catch (e) {
        return "";
      }
    },
  },
  mounted() {
    this.loadItems(this.$route.params.id);
  },
};
</script>
