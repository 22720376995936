var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"row w-100"},[_c('label',{staticClass:"form-label col-form-label col-auto"},[_vm._v("Date range")]),_c('div',{staticClass:"form-group col-auto"},[_c('date-range-picker',{staticClass:"btn btn-white",model:{value:(_vm.filters.issueTime),callback:function ($$v) {_vm.$set(_vm.filters, "issueTime", $$v)},expression:"filters.issueTime"}})],1),_c('label',{staticClass:"form-label col-form-label col-auto"},[_vm._v("Drop-off Issue")]),_c('div',{staticClass:"form-group col-3"},[_c('v-select',{attrs:{"reduce":function (option) { return option.code; },"options":[
              { label: 'All', code: null },
              { label: 'Api Integration Error', code: 'API_ERROR' },
              { label: 'Page Error', code: 'PAGE_ERROR' },
              { label: 'Notification Error', code: 'NOTIFICATION_ERROR' },
              { label: 'Service Error', code: 'SERVICE_ERROR' } ],"placeholder":"Type"},model:{value:(_vm.filters.issueType),callback:function ($$v) {_vm.$set(_vm.filters, "issueType", $$v)},expression:"filters.issueType"}})],1),_vm._m(1),_c('div',{staticClass:"col-auto"},[_c('b-dropdown',{attrs:{"variant":"outline-secondary"}},[_c('template',{slot:"button-content"},[_vm._v(" Download "),_c('i',{staticClass:"fa fa-chevron-down"})]),_c('a',{staticClass:"dropdown-item",attrs:{"href":_vm.download.csv,"target":"_BLANK"}},[_vm._v("CSV")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":_vm.download.excel,"target":"_BLANK"}},[_vm._v("Excel")])],2)],1)])])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  initialSortBy: { field: 'issueTime', type: 'desc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'content'
          )?_c('input',{ref:"content",staticClass:"form-control",attrs:{"placeholder":"Search content"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.content.value); }}}):_vm._e()]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("Drop-Off Issue Error")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"button"}},[_vm._v(" Generate ")])])}]

export { render, staticRenderFns }