import regionalService from "@/service/regional.service";
export default {
  namespaced: true,
  state: {
    province: {
      size: 0,
      number: 0,
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
      content: [],
    },
    city: {
      size: 0,
      number: 0,
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
      content: [],
    },
    district: {
      size: 0,
      number: 0,
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
      content: [],
    },
    // city: [],
    // district: [],
  },
  actions: {
    async getProvinceLists({ commit }, serverParams) {
      return regionalService
        .getProvinceList(serverParams)
        .then((result) => {
          commit("setProvinceData", result.data.data);
        })
        .catch((err) => {
          // if(err.response.status === 404){
          //   this.state.regional.province = {
          //     size:0,
          //     number:0,
          //     totalPages:0,
          //     totalElements:0,
          //     numberOfElements:0,
          //     content:[]
          //   }
          // }
          this.state.regional.province = {
            size: 0,
            number: 0,
            totalPages: 0,
            totalElements: 0,
            numberOfElements: 0,
            content: [],
          };
          return err;
        });
    },
    async getCityLists({ commit }, serverParams) {
      return regionalService
        .getCityList(serverParams)
        .then((result) => {
          commit("setCityData", result.data.data);
        })
        .catch((err) => {
          // if(err.response.status === 404){
          //   this.state.regional.city = {
          //     size:0,
          //     number:0,
          //     totalPages:0,
          //     totalElements:0,
          //     numberOfElements:0,
          //     content:[]
          //   }
          // }
          this.state.regional.city = {
            size: 0,
            number: 0,
            totalPages: 0,
            totalElements: 0,
            numberOfElements: 0,
            content: [],
          };
          return err;
        });
    },
    async getDistrictLists({ commit }, serverParams) {
      // console.log("test: ", serverParams);
      return regionalService
        .getDistrictList(serverParams)
        .then((result) => {
          commit("setDistrictData", result.data.data);
        })
        .catch((err) => {
          // if(err.response.status === 404){
          //   this.state.regional.district = {
          //     size:0,
          //     number:0,
          //     totalPages:0,
          //     totalElements:0,
          //     numberOfElements:0,
          //     content:[]
          //   }
          // }
          this.state.regional.district = {
            size: 0,
            number: 0,
            totalPages: 0,
            totalElements: 0,
            numberOfElements: 0,
            content: [],
          };
          return err;
        });
    },
  },
  getters: {
    getProvince(state) {
      return state.province;
    },
    getCity(state) {
      return state.city;
    },
    getDistrict(state) {
      return state.district;
    },
  },
  mutations: {
    setProvinceData(state, data) {
      state.province.size = data.size;
      state.province.number = data.number;
      state.province.totalPages = data.totalPages;
      state.province.totalElements = data.totalElements;
      state.province.numberOfElements = data.numberOfElements;
      state.province.content = [];
      for (let i = 0; i < data.content.length; i++) {
        state.province.content.push({
          label: data.content[i].name,
          id: data.content[i].id,
        });
      }
    },
    setCityData(state, data) {
      state.city.size = data.size;
      state.city.number = data.number;
      state.city.totalPages = data.totalPages;
      state.city.totalElements = data.totalElements;
      state.city.numberOfElements = data.numberOfElements;
      state.city.content = [];
      for (let i = 0; i < data.content.length; i++) {
        state.city.content.push({
          label: data.content[i].name,
          id: data.content[i].id,
        });
      }
    },
    setDistrictData(state, data) {
      state.district.size = data.size;
      state.district.number = data.number;
      state.district.totalPages = data.totalPages;
      state.district.totalElements = data.totalElements;
      state.district.numberOfElements = data.numberOfElements;
      state.district.content = [];
      for (let i = 0; i < data.content.length; i++) {
        state.district.content.push({
          label: data.content[i].name,
          id: data.content[i].id,
        });
      }
    },
    pushSelectedData(state, data) {
      const { type, ...dataPush } = data;
      switch (type) {
        case "province":
          // const isExist = state.province.content.some((e) => {
          //   return e.id === dataPush.id;
          // })
          // if(!isExist){
          //   //state.province.content.push(dataPush)
          //   state.province.content.splice(0, 0, dataPush)
          // }
          const province = state.province.content.filter((e) => {
            return e.id !== dataPush.id;
          });
          province.splice(0, 0, dataPush);
          state.province.content = province;
          break;
        case "city":
          const city = state.city.content.filter((e) => {
            return e.id !== dataPush.id;
          });
          city.splice(0, 0, dataPush);
          state.city.content = city;
          break;
        case "district":
          const district = state.district.content.filter((e) => {
            return e.id !== dataPush.id;
          });
          district.splice(0, 0, dataPush);
          state.district.content = district;
          break;

        default:
          break;
      }
    },
  },
};
