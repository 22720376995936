<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Submissions</h1>
        <!-- END page-header -->
      </div>
    </div>
    <!-- BEGIN row -->
    <div class="panel">
      <div class="panel-heading">
        <div class="row">
          <label class="form-label col-form-label col-auto">Date range</label>
          <div class="form-group col-auto">
            <date-range-picker
              ref="picker"
              class="btn btn-white"
              :opens="dateRange.opens"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
              :singleDatePicker="dateRange.singleDatePicker"
              :timePicker="dateRange.timePicker"
              :timePicker24Hour="dateRange.timePicker24Hour"
              :showWeekNumbers="dateRange.showWeekNumbers"
              :showDropdowns="dateRange.showDropdowns"
              :autoApply="dateRange.autoApply"
              v-model="dateRange.range"
              @update="updateValues"
              :linkedCalendars="dateRange.linkedCalendars"
            >
              <template v-slot:input="picker">
                <i class="fa fa-calendar fa-fw ms-n1"></i>
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                <b class="caret ms-1 opacity-5"></b>
              </template>
            </date-range-picker>
          </div>
          <div class="col-auto">
            <button
              @click.prevent="generate()"
              type="button"
              class="btn btn-outline-primary"
            >
              Generate
            </button>
          </div>
          <div class="col-auto">
            <b-dropdown variant="outline-secondary">
              <template slot="button-content">
                Download <i class="fa fa-chevron-down"></i>
              </template>
              <a
                @click.prevent="download('csv')"
                href="javascript:;"
                class="dropdown-item"
                >CSV</a
              >
              <a
                @click.prevent="download('xlsx')"
                href="javascript:;"
                class="dropdown-item"
                >Excel</a
              >
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-blue">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-blue"
                ><i class="fa fa-th-large"></i
              ></span>
              All Submission
            </h3>
            <p class="text-blue text-end fw-bolder">{{ card.allSubmission }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-cyan">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-cyan">1</span>
              Registration
            </h3>
            <p class="text-cyan text-end fw-bolder">{{ card.registration }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-teal">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-teal">2</span>
              Location & Hospital
            </h3>
            <p class="text-teal text-end fw-bolder">{{ card.hospital }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-green">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-green">3</span>
              Product Plan
            </h3>
            <p class="text-green text-end fw-bolder">{{ card.productPlan }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-lime">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-lime">4</span>
              Rider
            </h3>
            <p class="text-lime text-end fw-bolder">{{ card.rider }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-yellow">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-yellow">5</span>
              SIO Question
            </h3>
            <p class="text-yellow text-end fw-bolder">{{ card.sioQuestion }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-orange">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-orange">6</span>
              Payment
            </h3>
            <p class="text-orange text-end fw-bolder">{{ card.payment }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-light-pink">
          <div class="stats-info">
            <h3>
              <span class="stats-badge bg-pink">7</span>
              Donation
            </h3>
            <p class="text-pink text-end fw-bolder">{{ card.donation }}</p>
          </div>
        </div>
      </div>
    </div>

    <b-alert variant="warning" show dismissible>
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-triangle-exclamation"></i>
        <span
          >Please add more filter or reduce the date range, maximum allowed
          download is 5000 rows</span
        >
      </div>
    </b-alert>
    <ul class="nav nav-tabs nav-tabs--wp">
      <li class="nav-item">
        <router-link
          to="/submissions"
          class="nav-link"
          active-class="active"
          exact
        >
          Completed
          <span class="badge">{{ $store.state.registration.completed }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/submissions/incompleted"
          class="nav-link"
          active-class="active"
          exact
        >
          Incompleted
          <span class="badge">{{ $store.state.registration.incompleted }}</span>
        </router-link>
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import moment from "moment";
import registrationService from "@/service/registration.service";
import analyticService from "@/service/analytic.service";
export default {
  data() {
    return {
      card: {
        allSubmission: 0,
        registration: 0,
        hospital: 0,
        productPlan: 0,
        rider: 0,
        sioQuestion: 0,
        payment: 0,
        donation: 0,
      },
      isLoading: false,
      rows: [],
      dashboardData: null,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      dateRange: {
        opens: "right",
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        linkedCalendars: false,
        range: {
          startDate: moment().subtract(7, "days"),
          endDate: moment(),
          prevStartDate: moment().subtract("days", 15).format("D MMMM"),
          prevEndDate: moment().subtract("days", 8).format("D MMMM YYYY"),
        },
      },
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async getSummarySubmission(param) {
      let registrant = [];
      await analyticService
        .getBusinessAnalytic(param)
        .then((result) => {
          let data = result.data.data.registrationByPhase;
          data.forEach((phase) => {
            if (phase.registrantPhase === "Donasi") {
              this.card.donation = phase.numberOfRegistrant;
              registrant.push(phase.numberOfRegistrant);
            } else if (phase.registrantPhase === "Product Plan") {
              this.card.productPlan = phase.numberOfRegistrant;
              registrant.push(phase.numberOfRegistrant);
            } else if (phase.registrantPhase === "Add On Product") {
              this.card.rider = phase.numberOfRegistrant;
              registrant.push(phase.numberOfRegistrant);
            } else if (phase.registrantPhase === "Select Hospital") {
              this.card.hospital = phase.numberOfRegistrant;
              registrant.push(phase.numberOfRegistrant);
            } else if (phase.registrantPhase === "Purchase Summary") {
              this.card.payment = phase.numberOfRegistrant;
              registrant.push(phase.numberOfRegistrant);
            } else if (phase.registrantPhase === "SIO Question") {
              this.card.sioQuestion = phase.numberOfRegistrant;
              registrant.push(phase.numberOfRegistrant);
            }
            
          });

          const sum = registrant.reduce((partialSum, a) => partialSum + a, 0);
          this.card.allSubmission = sum;
        })
        .catch(() => {
          this.card.allSubmission = 0;
        });
    },
    async generate() {
      let params = {
        startDate: moment(this.dateRange.range.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.dateRange.range.endDate).format("YYYY-MM-DD"),
      };
      await this.getSummarySubmission(params);
    },
    updateValues(d) {
      var startDate = moment(d.startDate);
      var endDate = moment(d.endDate);
      var gap = endDate.diff(startDate, "days");

      this.dateRange.range.prevStartDate = moment(startDate)
        .subtract("days", gap)
        .format("D MMMM YYYY");
      this.dateRange.range.prevEndDate = moment(startDate)
        .subtract("days", 1)
        .format("D MMMM YYYY");
      // console.log(this.dateRange);
    },
    async download(format) {
      let params = {
        from: moment(this.dateRange.range.startDate).format("YYYY-MM-DD"),
        to: moment(this.dateRange.range.endDate).format("YYYY-MM-DD"),
      };

      window.open(
        `${process.env.VUE_APP_API}/web/cms/submissions/${format}?from=${params.from}&to=${params.to}`,
        "_blank"
      );
    },
    formatDate(d) {
      var monthsName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      d = new Date(d);
      d = monthsName[d.getMonth()] + " " + d.getDate();
      return d;
    },
  },
  filters: {
    date: function (value) {
      if (value) {
        return moment(String(value)).format("D MMMM YYYY");
      }
    },
  },
  async mounted() {
    await this.generate();
    this.$store.dispatch("registration/getCompletedData");
    this.$store.dispatch("registration/getIncompletedData");
  },
};
</script>