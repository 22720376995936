<template>
  <div>
    <h1 class="page-header">All Menus</h1>
    <div class="panel">
      <NavTab />
      <div class="panel-body">
        <div class="row align-items-center g-2 mb-4">
          <div class="col-auto">
            <p class="mb-0">
              Edit your menu below, or
              <router-link to="/sections/menus/add" class="text-blue text-decoration-underline">create a new
                menu</router-link>. Do not forget to save your
              changes!
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <h5 class="fw-bolder mb-3">Add menu items</h5>
            <panel title="Pages" body-class="py-0" variant="grey" :is-collapse="true">
              <div class="my-3" v-for="(item, index) in menuItems" :key="index">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="item.id" :id="`item-${item.id}`"
                    name="menuSelect" v-model="menuSelected" />
                  <label class="form-check-label" :for="`item-${item.id}`">
                    {{ item.title }}
                  </label>
                </div>
              </div>
              <template #footer>
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                    <div class="form-check">
                      <input class="form-check-input" id="selectAll" type="checkbox" name="selectAll" />
                      <label for="selectAll" class="form-check-label">Select all</label>
                    </div>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-white" @click="addMenu()" type="button">
                      Add to Menu
                    </button>
                  </div>
                </div>
              </template>
            </panel>
            <panel title="Custom Link" body-class="py-0" variant="grey" :is-collapse="true">
              <div class="my-3">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-2">
                      <label class="form-label fw-normal">Label</label>
                      <input type="text" v-model="custom.label" placeholder="Navigation Label" class="form-control">
                    </div>
                  </div>
                  <div class="col-12">
                    <label class="form-label fw-normal">Link URL</label>
                    <input type="text" v-model="custom.href" placeholder="Link URL" class="form-control">
                  </div>
                </div>
              </div>
              <div class="alert alert-warning" v-show="!!customError" v-text="customError" />
              <div class="row align-items-center justify-content-between mb-2">
                <div class="col-auto"></div>
                <div class="col-auto">
                  <button class="btn btn-white" @click="addMenuCustom">
                    Add to Menu
                  </button>
                </div>
              </div>
            </panel>
          </div>
          <div class="col-md-9">
            <h5 class="fw-bolder mb-3">Menu structure</h5>
            <div class="panel">
              <div class="panel-heading">
                <div class="row g-2 align-items-center">
                  <div class="col-auto">
                    <p class="mb-0 fw-normal">Menu name</p>
                  </div>
                  <div class="col-auto">
                    <input type="text" v-model="serverParams.name" placeholder="Menu name" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <p>
                  Drag the items into the order you prefer. Click the arrow on
                  the right of the item to reveal additional configuration
                  options.
                </p>
                <nested-draggable v-model="list" />
              </div>
              <div class="panel-footer bg-grey">
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-primary" @click.prevent="showAlertConfirm()" type="button">Save Menu</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import menuService from "@/service/menu.service";
import pageService from "@/service/page.service";
import NestedDraggable from "./NestedDraggable.vue";
import NavTab from "./Nav.vue";
export default {
  components: {
    NavTab,
    NestedDraggable,
  },
  data() {
    return {
      menuItemsList: [],
      menuList: "",
      menuSelected: [],
      menuItems: [],
      list: [],
      custom: {
        label: '',
        href: '',
      },
      customError: null,
      serverParams: {
        name: '',
        details: []
      },
      payload: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  methods: {
    onSubmit() {
      this.details(this.list)

      let payload = {
        ...this.serverParams
      }

      menuService
        .postMenu(payload)
        .then((rsp) => {
          this.$router.push("/sections/menus/add");
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Menu Success Add'
          });
          this.loadItems();
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"}`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    details(arrData) {
      this.serverParams.details
      for (const [k, v] of Object.entries(arrData)) {
        let menu = {
          sequence: parseInt(k) + 1,
          href: v.href,
          navigationLabel: v.label,
          titleAttribute: v.attribute,
        }

        if (v.submenus?.length) {
          menu['listChild'] = []
          for (const [k2, v2] of Object.entries(v.submenus)) {
            menu.listChild.push({
              sequence: parseInt(k2) + 1,
              href: v2.href,
              navigationLabel: v2.label,
              titleAttribute: v2.attribute,
            })
            if (v2.submenus?.length) {
              menu['listChild'][k2]['listChild'] = []
              for (const [k3, v3] of Object.entries(v2.submenus)) {
                menu.child[k2].listChild.push({
                  sequence: parseInt(k3) + 1,
                  href: v3.href,
                  navigationLabel: v3.label,
                  titleAttribute: v3.attribute,
                })
              }
            }
          }
        }

        this.serverParams.details.push(menu)
      }
    },
    onChangeMenu() {
      //
    },
    loadItems() {
      menuService
        .getMenuList(this.payload).then((rsp) => {
          if (rsp.data) {
            this.menuItemsList = rsp.data?.data?.content;
          } else {
            this.menuItemsList = [];
          }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"}`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    mappingList(data) {
      this.serverParams.name = data.name
      this.list = []
      if (data.details.length != 0) {
        for (const v of data.details) {

          const item = {
            id: v.id,
            title: v.navigationLabel,
            isShow: true,
            label: v.navigationLabel,
            attribute: v.titleAttribute,
            href: v.href,
            hrefLink: pageService.generateHrefLink(v.href),
            submenus: [],
          }

          if (v.itemDetails) {
            for (const v2 of v.itemDetails) {
              item.submenus.push({
                title: v2.navigationLabel,
                isShow: true,
                label: v2.navigationLabel,
                attribute: v2.titleAttribute,
                href: v2.href,
                hrefLink: pageService.generateHrefLink(v2.href),
                submenus: [],
              })
            }
          }
          this.list.push(item)
        }
        // console.debug(this.list)
      }
    },
    onDelete() {
      if (this.menuList) {
        const { id } = this.menuList
        menuService
          .deleteMenuById(id).then((rsp) => {
            // console.log(rsp, 'rsp')
            if (rsp.status == 200) {
              this.$notify({
                type: "succes",
                title: "Success message",
                text: "Delete Success",
              });
              this.loadItems();
            } else {
              this.$notify({
                type: "error",
                title: "Error message",
                text: "Delete Error",
              });
              this.loadItems();
            }
          }).catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
            this.loadItems();
          });
      }
    },
    loadPages() {
      let params = {
        searchField: "",
        searchValue: "",
        pageNumber: 0,
        pageSize: 10,
        status: '',
      };

      pageService
        .getPageLists(params)
        .then((rsp) => {
          if (rsp.data) {
            this.menuItems = rsp.data.data.content;
          } else {
            this.menuItems = [];
          }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"}`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    addMenu() {
      for (const id of this.menuSelected) {
        const pageFound = this.menuItems.find(v => v.id == id)
        if (pageFound) {
          this.mappingPagesToItems(pageFound)
        } else {
          // console.warn(`Page not found ${id}`)
        }
      }
    },
    addMenuCustom() {
      if (!this.custom.label) {
        this.customError = 'Label harus diisi'
        return
      }
      if (!this.custom.href) {
        this.customError = 'Link URL harus diisi'
        return
      }

      const href = '/pages/' + this.custom.href
      if (this.list.find((e) => e.href == href)) {
        this.customError = `Duplicate Link URL: ${href}`
        return
      }
      this.list.push({
        title: this.custom.label,
        isShow: true,
        label: this.custom.label,
        attribute: this.custom.label,
        href: this.custom.href,
        hrefLink: pageService.generateHrefLink(this.custom.href),
        submenus: []
      })

      this.custom = {
        label: '',
        href: ''
      }

    },
    mappingPagesToItems(data) {
      const href = '/pages/' + data.slug
      if (this.list.find((e) => e.href == href)) {
        // console.warn(`Duplicate menu: ${href}`);
        return
      }

      this.list.push({
        title: data.title,
        isShow: true,
        label: data.title,
        attribute: data.title,
        href,
        hrefLink: pageService.generateHrefLink(href)
      })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "Yakin",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onSubmit(id);
          }
        });
    },
  },
  mounted() {
    this.loadItems();
    this.loadPages();
  },
  watch: {
    custom: {
      deep: true,
      handler() {
        this.customError = null
      }
    },
    'menuList'(newVal) {
      if (newVal != null) {
        this.$store.dispatch("menu/getMenuById", newVal.id).then((rsp) => {
          this.mappingList(rsp.data.data)
        });
      } else {
        // data()
      }

    },
    list: {
      deep: true,
      handler: (v) => {
        // console.log(v)
      }
    }
  },
};
</script>
