import PaymentFrequencyService from "../service/paymentfrequency.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListPaymentFrequency(_ctx, serverParams) {
            return PaymentFrequencyService.getPaymentFrequencyList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postPaymentFrequency(_ctx, serverParams) {
            return PaymentFrequencyService.postPaymentFrequency(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getPaymentFrequencyById(_ctx, serverParams) {
            return PaymentFrequencyService.getPaymentFrequencyById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updatePaymentFrequencyById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return PaymentFrequencyService.putPaymentFrequency(id, payload).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deletePaymentFrequencyById(_ctx, serverParams) {
            return PaymentFrequencyService.deletePaymentFrequencyById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
