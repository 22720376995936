<template>
  <div class="panel">
    <div class="panel-body">
      <form @submit.prevent="doFilter">
        <div class="row align-items-end">
          <div class="col">
            <div class="row">
              <div class="col-md-6">
                <label class="form-label">Filter by Province</label>
                <!-- <v-select 
                  :options="optionsArea" 
                  v-model="filter.area" 
                /> -->
                <v-select
                  :disabled="formTypeFilter"
                  v-model="hospital.province"
                  :options="paginatedProvince.content"
                  :reduce="(option) => option.id"
                  :filterable="false"
                  @input="provinceSelected"
                  @open="openSelectProvince"
                  @search="searchProvince"
                  @option:selected="selectedProvToTemporary"
                >
                  <li slot="list-footer" class="pagination">
                    <button
                      :disabled="!hasProvPrevPage"
                      @click.prevent="handlePrevProv('province')"
                    >
                      Prev
                    </button>
                    <button
                      :disabled="!hasProvNextPage"
                      @click.prevent="handleNextProv('province')"
                    >
                      Next
                    </button>
                  </li>
                </v-select>
                 <span
                      class="text-danger"
                      v-if="
                        !$v.hospital.province.required &&
                        $v.hospital.province.$error
                      "
                      >Province is required</span
                    >
              </div>
              <div class="col-md-6">
                <label class="form-label">Filter by City</label>
                <v-select
                  :disabled="formTypeFilter"
                  v-model="hospital.city"
                  :options="paginatedCity.content"
                  :reduce="(option) => option.id"
                  :filterable="false"
                  @input="citySelected"
                  @open="openSelectCity"
                  @search="searchCity"
                  @option:selected="selectedCityToTemporary"
                >
                <li slot="list-footer" class="pagination">
                    <button
                      :disabled="!hasCityNextPage"
                      @click.prevent="handlePrevProv('city')"
                    >
                      Prev
                    </button>
                    <button
                      :disabled="!hasCityPrevPage"
                      @click.prevent="handleNextProv('city')"
                    >
                      Next
                    </button>
                  </li>
                </v-select>

                <span
                  class="text-danger"
                  v-if="!$v.hospital.city.required && $v.hospital.city.$error"
                  >City is required</span
                >
              </div>
              <!-- <div class="col-md-6">
                <label class="form-label">Filter by District</label>
                 <v-select
                    v-model="hospital.district"
                    :options="paginatedDistrict.content"
                    :reduce="(option) => option.id"
                    :filterable="false"
                    @input="districtSelected"
                    @open="openSelectDistrict"
                    @search="searchDistrict"
                    @option:selected="selectedDistrictToTemporary"
                  >
                    <li slot="list-footer" class="pagination">
                      <button
                        :disabled="!hasDistrictPrevPage"
                        @click.prevent="handlePrevProv('district')"
                      >
                        Prev
                      </button>
                      <button
                        :disabled="!hasDistrictNextPage"
                        @click.prevent="handleNextProv('district')"
                      >
                        Next
                      </button>
                    </li>
                  </v-select>
              </div> -->
              <!-- <div class="col-md-12">
                <label class="form-label">Filter by Hospital Tier</label>
                 <v-select
                    v-model="hospital.hospitalTierId"
                    :options="paginatedHospitalTier.content"
                    :reduce="(option) => option.id"
                    :filterable="false"
                    @input="tierSelected"
                    @open="openSelectHospitalTier"
                    @search="searchHospitalTier"
                    @option:selected="selectedHospitalTierToTemporary"
                  >
                    <li slot="list-footer" class="pagination">
                        <button
                          :disabled="!hasTierPrevPage"
                          @click.prevent="handlePrevProv('tier')"
                        >
                          Prev
                        </button>
                        <button
                          :disabled="!hasTierNextPage"
                          @click.prevent="handleNextProv('tier')"
                        >
                          Next
                        </button>
                    </li>
                  </v-select>
              </div> -->
            </div>
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-outline-primary" :disabled="formTypeFilter">
              Filter
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import regionalService from '@/service/regional.service';
import { required, helpers, requiredIf } from "vuelidate/lib/validators";
import hospitalService from "../../../service/hospital.service";
export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    filterSelect: {
      type: Object,
      default: null,
    }
  },
  mounted(){
    // console.log('this.formType: ', this.formType)
    // console.log('this.selectProvince: ', this.selectProvince)
  },
  data() {
    return {
      optionsArea: [
        "All Area"
      ],
      optionsTier: ["A", "B", "C"],
      filter: {
        selectProvince: "",
        selectCity: "",
        selectDistrict: "",
        //selectTier: "",
      },
      hospital: {
        tier: null,
        province: null,
        city: null,
        district: null
      },
      provServerParamsProvince: {
        // searchField: 'name',
        // searchValue: '',
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsCity: {
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsDistrict: {
        pageSize: 10,
        pageNumber: 0,
      },
      provServerParamsHospitalTier: {
        pageSize: 10,
        pageNumber: 0,
      },
      provSelected: {},
      citySelectedData: {},
      districtSelectedData: {},
      hospitalTierSelectedData: {},
    };
  },
  methods: {
    doFilter() {
      // const payload = {
      //   area: this.filter.area,
      //   tier: this.filter.tier,
      // };

      this.$v.hospital.$touch();
      if (!this.$v.hospital.$invalid) {
        this.$emit("onFilter", this.filter);
      } else {
        this.hospital.status = null
        // console.log(this.$v.hospital)
        // console.debug("False")
      }
      
    },
    searchProvince(query, loading) {
      this.provServerParamsProvince.pageNumber = 0
      this.provServerParamsProvince.searchField = 'name'
      this.provServerParamsProvince.searchValue = query
      this.getProvince()
      
    },
    searchCity(query, loading) {
      this.provServerParamsCity.pageNumber = 0
      this.provServerParamsCity.searchField = 'name'
      this.provServerParamsCity.searchValue = query
      this.getCity()
    },
    searchDistrict(query, loading) {
      this.provServerParamsDistrict.pageNumber = 0
      this.provServerParamsDistrict.searchField = 'name'
      this.provServerParamsDistrict.searchValue = query
      this.getDistrict()
    },
    searchHospitalTier(query, loading){
      this.provServerParamsHospitalTier.pageNumber = 0
      this.provServerParamsHospitalTier.searchField = 'tier'
      this.provServerParamsHospitalTier.searchValue = query
      this.getHospitalTier()
    },
    getProvince() {
      this.$store.dispatch(
        "regional/getProvinceLists",
        this.provServerParamsProvince
      ).then(
        () => {
          if(Object.keys(this.provSelected).length > 0){
            this.provSelected.type = 'province'
            this.$store.commit('regional/pushSelectedData', this.provSelected)
          }
        }
      ).catch(  
        (error) => {
          // console.debug(error);
        }
      )
     // this.formInProgressProvince = false
    },
    getCity() {
      const payload = this.provServerParamsCity
      if(this.hospital.province){
        payload.idProvince = this.hospital.province
      }
      
      this.$store.dispatch("regional/getCityLists", payload)
      .then(
        () => {
           if(Object.keys(this.citySelectedData).length > 0){
            this.citySelectedData.type = 'city'
            this.$store.commit('regional/pushSelectedData', this.citySelectedData)
          }
        }
      )
      .catch(  
        (error) => {
          // console.debug(error);
        }
      )
    },
    getDistrict() {
      const payload = this.provServerParamsDistrict
      if(this.hospital.city){
        payload.idCity = this.hospital.city
      }
      this.$store.dispatch("regional/getDistrictLists", payload)
      .then(
        () => {
          if(Object.keys(this.districtSelectedData).length > 0){
            this.districtSelectedData.type = 'district'
            this.$store.commit('regional/pushSelectedData', this.districtSelectedData)
          }
        }
      )
      .catch(  
        (error) => {
          // console.debug(error);
        }
      )
    },
    getHospitalTier() {
      this.$store.dispatch(
        "hospital/getHospitalTier",
        this.provServerParamsHospitalTier
      )
      .then(
        () => {
          if(Object.keys(this.hospitalTierSelectedData).length > 0){
            this.hospitalTierSelectedData.type = 'tier'
            this.$store.commit('hospital/pushSelectedData', this.hospitalTierSelectedData)
          }
        }
      )
      .catch(  
        (error) => {
          // console.debug(error);
        }
      )
     // this.formInProgressProvince = false
    },
    selectedProvToTemporary(provSelected){
      this.filter.selectProvince = provSelected.label
      this.provSelected = provSelected
    },
    selectedCityToTemporary(citySelectedData){
      this.filter.selectCity = citySelectedData.label
      this.citySelectedData = citySelectedData
    },
    selectedDistrictToTemporary(districtSelectedData){
      this.filter.selectDistrict = districtSelectedData.label
      this.districtSelectedData = districtSelectedData
    },
    // selectedHospitalTierToTemporary(hospitalTierSelectedData) {
    //   // const tierContent = this.$store.state['hospital'].tier.content
    //   // const tier = tierContent.find(e => e.id === this.hospital.hospitalTierId)
    //   this.filter.selectTier = hospitalTierSelectedData.label
    //   this.hospitalTierSelectedData = hospitalTierSelectedData
    // },
    openSelectProvince(){
      this.provServerParamsProvince.searchValue = ""
      this.provServerParamsProvince.pageNumber = 0
      this.getProvince()
      //this.provSelected = {}  
    },
    openSelectCity(){
      this.provServerParamsCity.searchValue = ""
      this.provServerParamsCity.pageNumber = 0
      this.getCity()
      //this.provSelected = {}  
    },
    openSelectDistrict(){
      this.provServerParamsDistrict.searchValue = ""
      this.provServerParamsDistrict.pageNumber = 0
      this.getDistrict()
      //this.provSelected = {}  
    },
    openSelectHospitalTier(){
      this.provServerParamsHospitalTier.searchValue = ""
      this.provServerParamsHospitalTier.pageNumber = 0
      this.getHospitalTier()
    },
    provinceSelected(payload) {
       this.hospital.city = null
       this.hospital.district = null
       this.filter.selectProvince = payload  
       this.filter.selectCity = null 
       this.filter.selectDistrict = null 
       this.citySelectedData = {}
       this.districtSelectedData = {}
    },
    citySelected(payload) {
      this.hospital.district = null
      this.filter.selectCity = payload 
      this.filter.selectDistrict = null 
      this.districtSelectedData = {}
    },
    districtSelected(payload) {
      this.filter.selectDistrict = payload 
    },
    // tierSelected(payload) {
    //   this.filter.selectTier = payload 
    // },
    handleNextProv(kind) {
      let lastPage
      
      switch (kind) {
        case 'province':
            lastPage = this.$store.state['regional'].province.totalPages - 1
            if(  this.provServerParamsProvince.pageNumber < lastPage ) {
              this.provServerParamsProvince.pageNumber += 1
            }
            //this.onSearchProvince = true
            this.getProvince()
          break;
        case 'city':
          if (this.hospital.province !== null) {
            lastPage = this.$store.state['regional'].city.totalPages - 1
            if(this.provServerParamsCity.pageNumber < lastPage ) {
              this.provServerParamsCity.pageNumber += 1
            }
            // await this.provinceSelected()
            this.getCity()
          }
          break;
        case 'district':
           if (this.hospital.city !== null) {
            lastPage = this.$store.state['regional'].district.totalPages - 1
            if(this.provServerParamsDistrict.pageNumber < lastPage ) {
              this.provServerParamsDistrict.pageNumber += 1
            }
            this.getDistrict()
           }
          break;
        case 'tier':
            lastPage = this.$store.state['hospital'].tier.totalPages - 1
            if(this.provServerParamsHospitalTier.pageNumber < lastPage ) {
              this.provServerParamsHospitalTier.pageNumber += 1
            }
            //this.onSearchTier = true
            this.getHospitalTier()
          break;
      
        default:
          break;
      }
    },
    handlePrevProv(kind) {
       switch (kind) {
        case 'province':
            if(this.provServerParamsProvince.pageNumber !== 0){
                this.provServerParamsProvince.pageNumber -= 1
            }
            this.getProvince()
          break;
        case 'city':
          if (this.hospital.province !== null) { 
            if(this.provServerParamsCity.pageNumber !== 0){
                this.provServerParamsCity.pageNumber -= 1
            }
            // await this.provinceSelected()
            this.getCity()
          }
          break;
        case 'district':
          if (this.hospital.city !== null) { 
            if(this.provServerParamsDistrict.pageNumber !== 0){
                this.provServerParamsDistrict.pageNumber -= 1
            }
            this.getDistrict()
          }
          break;
        case 'tier':
          if(this.provServerParamsHospitalTier.pageNumber !== 0){
              this.provServerParamsHospitalTier.pageNumber -= 1
            }
          this.getHospitalTier()
          break;
      
        default:
          break;
      }
    },
  },
  validations() {
      //     hospital: {
      //   tier: null,
      //   province: null,
      //   city: null,
      //   district: null
      // },
    return {
      hospital: {
        province: {
          required,
        },
        city: {
          required,
        }
      },
    };
  },
  computed: {
    filterProvCity() {
      return this.selectProvince
    },
    paginatedProvince() {
      return this.$store.getters["regional/getProvince"];
    },
    paginatedCity() {
      return this.$store.getters["regional/getCity"];
    },
    paginatedDistrict() {
      return this.$store.getters["regional/getDistrict"];
    },
    paginatedHospitalTier() {
      return this.$store.getters["hospital/getTier"];
    },
    hasProvNextPage() {
      return true
    },
    hasProvPrevPage() {
      return true
    },
    hasCityNextPage() {
      return true
    },
    hasCityPrevPage() {
      return true
    },
    hasDistrictNextPage() {
      return true
    },
    hasDistrictPrevPage() {
      return true
    },
    hasTierNextPage() {
      return true
    },
    hasTierPrevPage() {
      return true
    },
    formTypeFilter() {
      return this.formType === "add" ? false : true
    },
  },
  watch:{
    filterSelect: { 
      handler(newValue, oldValue) {
       this.hospital.province = newValue.selectProvince
       this.hospital.city = newValue.selectCity
        
      },
      deep: true
    },
  }
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>

