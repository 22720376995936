import api from "./api";

class InternalCoreService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create("web");
  }
  getStatus(id) {
    return this.api.get(`/cms/core-system-statuses/${id}`);
  }
}

export default new InternalCoreService();
