<template>
    <form @submit.prevent="doSave" class="pb-5 mb-4">
        <h1 class="page-header">Import Referral</h1>
        <div v-if="upload.status != null">
            <div v-if="upload.status === false">
                <b-alert variant="danger" show dismissible>
                    <div class="d-flex align-items-center">
                        <i class="fa-solid fa-circle-xmark"></i>
                        <span>
                            <strong>Import Referral Failed.</strong>
                            {{ upload.message }}
                        </span>
                    </div>
                </b-alert>
            </div>
            <div v-else-if="$v.category.$error === false">
                <b-alert variant="danger" show dismissible>
                    <div class="d-flex align-items-center">
                        <i class="fa-solid fa-circle-xmark"></i>
                        <span>
                            <strong>Import Referral required.</strong>
                        </span>
                    </div>
                </b-alert>
            </div>
            <div v-else>
                <b-alert variant="success" show dismissible>
                    <div class="d-flex align-items-center">
                        <i class="fa-solid fa-circle-check"></i>
                        <span><strong>Import Referral Success.</strong> Kindly check <router-link to="/products">All
                                Referral</router-link>
                            page.</span>
                    </div>
                </b-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9 col-md-8">
                <div class="row align-items-center mb-4">
                    <div class="col-auto">
                        <Upload v-model="importFile" />
                    </div>
                    <div class="col-auto">
                        <p class="mb-0">or <a href="/assets/excel/referral/TemplateImportReferralCode.xlsx"
                                class="text-primary">download template</a></p>
                    </div>
                    <div class="col-12">
                        <p class="mt-2" v-if="importFile">Selected File: {{ importFile.name }}</p>
                    </div>
                </div>
                <h3 class="fs-18px mb-15px fw-normal">Import History</h3>
                <div class="table-responsive bg-white">
                    <table class="table table-form">
                        <thead>
                            <tr>
                                <th width="40%">File Name</th>
                                <th>Data</th>
                                <th>Imported by</th>
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in rows" :key="index">
                                <td>{{ item.filename }}</td>
                                <td>
                                    <div v-for="item in item.payload">
                                        {{ item }}
                                    </div>
                                </td>
                                <td>{{ item.createdBy }}</td>
                                <td>{{ dateFormat(item.created) }}</td>
                                <td>{{ item.status }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</template>
  
<script>
import Upload from '../../components/upload/csv.vue'
import referralService from "@/service/referral.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
    components: {
        Upload,
    },
    data() {
        return {
            importFile: null,
            upload: {
                message: '',
                status: null
            },
            rows: [],
            serverParams: {
                searchField: "",
                searchValue: "",
                sort: "",
                direction: "ASC",
                pageNumber: 0,
                pageSize: 100,
                name: [],
                by: [],
                date: [],
                status: []
            }
        };
    },
    methods: {
        doSave() {
            console.log('test')
            this.$v.importFile.$touch();
            if (!this.$v.importFile.$invalid) {
                const formData = new FormData()
                formData.append('file', this.importFile, this.importFile.name)

                referralService
                    .postImportReferral(formData)
                    .then((rsp) => {
                        this.$notify({
                            type: "succes",
                            title: "Success Message",
                            text: rsp?.data?.responseMessage,
                        });
                        this.loadItems();
                    }).catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.message || "Terjadi Kesalahan",
                        });
                    })
            }
        },
        loadItems() {
            this.isLoading = true
            // Cancel previous call
            if (this.$data._debounceQueryTimer)
                clearTimeout(this.$data._debounceQueryTimer);
            this.$data._debounceQueryTimer = setTimeout(() => {
                referralService
                    .getReferralHistory(this.serverParams)
                    .then((rsp) => {
                        let arrData = []
                        for (const [k, v] of Object.entries(rsp.data.data)) {
                            let data = v.payload.replace('[', '').replace(']', '').split(',')
                            arrData.push({
                                ...v,
                                payload: data
                            })
                        }
                        this.rows = arrData
                    })
                    .catch((e) => {
                        this.isLoading = false
                        this.$notify({
                            type: "error",
                            title: "Error Notification",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    });
                this.$data._debounceQueryTimer = null;
            }, 300);
        },
        dateFormat(date) {
            let arrDate = date.split(' ')
            let newDate = new Date(arrDate[0])
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return newDate.toLocaleDateString("id", options);
        }
    },
    watch: {
        'importFile'(newVal) {
            if (newVal) {
                this.doSave()
            }
        }
    },
    mounted() {
        this.loadItems();
    },
    validations: {
        importFile: {
            required,
        },
    }
};
</script>
  