<template>
  <div class="pb-5">
    <form>
      <div v-for="(item, index) in plans" :key="index" class="panel">
        <div class="panel-body">
          <h4 class="fw-normal mb-3">{{ item.title }}</h4>
          <div class="row">
            <div class="col-md-8">
              <div class="table-responsive">
                <table class="table table-form">
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        class="text-center"
                        :class="{
                          'bg-primary-dark text-white': innerLimit === item.id,
                        }"
                        v-for="(item, index) in item.heading"
                        :key="index"
                      >
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Select</td>
                      <td
                        class="text-center"
                        v-for="(item, index) in item.heading"
                        :key="index"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`addons-${index}`"
                          :value="item.id"
                          v-model="selectedProduct"
                          :disabled="
                            selectedProduct.length > 1 &&
                            selectedProduct.indexOf(item.id) === -1
                          "
                          :class="{
                            disabled: isReadOnly,
                          }"
                        />
                      </td>
                    </tr>
                    <tr v-for="(item, index) in item.rows" :key="index">
                      <td>{{ item.label }}</td>
                      <td
                        v-for="(value, indexValue) in item.items"
                        :key="indexValue"
                      >
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isReadOnly" class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button @click.prevent="doSave()" class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import productService from "@/service/product.service";
import productcategoryService from "@/service/productcategory.service";
import registrationService from "@/service/registration.service";
import _ from "lodash";
import productFilter from "./functions/productFilter";
export default {
  data() {
    return {
      status: false,
      extra: null,
      addOns: null,
      innerLimit: null,
      extraHealth: null,
      selectedProduct: [],
      productParams: {
        params: {
          pageSize: 10,
          pageNumber: 0,
          sort: "name",
          direction: "ASC",
          searchField: "",
          searchValue: "",
          status: "PUBLISHED",
          categoryGroup: "ADDONS",
        },
        content: [],
        totalElments: 0,
      },
      plansa: {
        heading: [],
        rows: [],
      },
      plans: [],
      productRows: [],
      age: 0,
      weighContribution: {
        home: null,
        office: null,
      },
      defaultFrequency: null,
      weighGroup: null,
      finalProductPrice: null,
      paymentFrequency: null,
      body: null,
      stepThirdBody: {
        idProdukUtama: null,
        namaProdukUtama: null,
        hargaProdukUtama: null,
        idProdukExtra: null,
        namaProdukExtra: null,
        hargaProdukExtra: null,
      },
    };
  },
  watch: {
    productRows: function (val) {
      let users = [];
      let promises = [];
      for (let i = 0; i < val.length; i++) {
        promises.push(
          productcategoryService
            .getProductCategoryById(val[i].idProductCategory)
            .then((res) => {
              val[i].subCategoryName = res.data.data.name;
              users.push(val[i]);
            })
        );
      }
      Promise.all(promises).then(() => {
        var gMap = _.groupBy(users, "subCategoryName");
        let plansTemporary = [];
        let header = [];
        for (const key in gMap) {
          header = [];
          gMap[key].forEach((product) => {
            header.push({
              id: product.id,
              name: product.name,
            });
          });
          plansTemporary.push({
            selected: false,
            title: `ADDONS As / ${key}`,
            heading: header,
            rows: this.setBenefitList(gMap[key]),
          });
        }
        this.plans = plansTemporary;
      });
    },
  },
  methods: {
    listingFacility() {
      this.innerLimit =
        this.$store.state.registration.submitionsData?.customerProduct?.product?.nameProduct;
    },
    pushProductNameToHeader(list, pointer) {
      list.forEach((product) => {
        this.plans[pointer].heading.push(product.name);
      });
    },
    setBenefitList(list) {
      let benefitName = [];
      let rows = [];
      let row = {};
      let item = [];
      list.forEach((product) => {
        product["benefits"].forEach((benefit) => {
          benefitName.push(benefit.label);
        });
      });
      let uniqueChars = [...new Set(benefitName)];
      uniqueChars.forEach((label, indexLabel) => {
        row = {};
        item = [];
        list.forEach((product, productIndex) => {
          let obj = product["benefits"].find((x) => x.label === label);
          row["label"] = label;
          if (obj === undefined) {
            item.push("-");
          } else {
            item.push(obj.value);
          }
        });
        row.items = item;
        rows.push(row);
      });
      return rows;
    },
    async getProductList(category, pointer) {
      this.productParams.params.category = category;
      await productService
        .getProductList(this.productParams.params)
        .then((res) => {
          if (res.data.responseMessage === "Data's Empty!") {
            return;
          } else {
            this.productRows = res.data.data.content;
            // console.log(res);
            // this.pushProductNameToHeader(res.data.data.content, pointer);
            // this.setBenefitList(res.data.data.content, pointer);
          }
        });
      // .catch(() => {
      //   alert("Something Wrong");
      // });
    },
    async doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const convertedData = productFilter.getProductPlansDetail(
          this.productRows,
          this.selectedProduct
        );
        const body = await productFilter.getDetailProduct(
          convertedData,
          this.age,
          "Step4"
        );
        await registrationService
          .stepThirdUpdate(this.$route.params.id, body)
          .then((response) => {
            this.$swal({
              title: "Data berhasil diupdate",
              showCloseButton: true,
            });
          })
          .catch((error) => {
            this.$swal({
              title:
                error.response?.data?.responseMessage || "Terjadi Kesalahan",
              showCloseButton: true,
            });
          });
      } else {
        console.log("False");
      }
    },
    async handleDetail() {
      await this.$store
        .dispatch("registration/getSingleSubmission", this.$route.params.id)
        .then(
          (res) => {
            let dataProduct = res.data.data.customerProduct;
            let registrationData = res.data.data.customerRegistration;
            // this.innerLimit =
            //   res.data.data.customerProduct?.product.nameProduct;
            this.status = res.data.data.customerRegistration?.status;
            this.age = productFilter.getAge(registrationData.tanggalLahir);
            productFilter.setOriginalDataProduct(dataProduct);
            productFilter.setGroupValue(
              registrationData.customerHospitalHome,
              registrationData.customerHospitalOffice
            );
            if (dataProduct.idProdukAddons !== null) {
              this.selectedProduct.push(
                dataProduct.idProdukAddons !== null
                  ? dataProduct.idProdukAddons
                  : ""
              );
            }
            if (dataProduct.idProdukAddons2 !== null) {
              this.selectedProduct.push(
                dataProduct.idProdukAddons2 !== null
                  ? dataProduct.idProdukAddons2
                  : ""
              );
            }
            this.stepThirdBody = {
              idProdukUtama:
                dataProduct.idProdukUtama !== null
                  ? dataProduct.idProdukUtama
                  : null,
              namaProdukUtama:
                dataProduct.namaProdukUtama !== null
                  ? dataProduct.namaProdukUtama
                  : null,
              hargaProdukUtama:
                dataProduct.hargaProdukUtama !== null
                  ? dataProduct.hargaProdukUtama
                  : null,
              idProdukExtra:
                dataProduct.idProdukExtra !== null
                  ? dataProduct.idProdukExtra
                  : null,
              namaProdukExtra:
                dataProduct.namaProdukExtra !== null
                  ? dataProduct.namaProdukExtra
                  : null,
              hargaProdukExtra:
                dataProduct.hargaProdukExtra !== null
                  ? dataProduct.hargaProdukExtra
                  : null,
            };
          },
          () => {
            this.$swal({
              title: "Terjadi Kesalahan",
              showCloseButton: true,
            });
          }
        );
    },
  },
  validations() {
    return {};
  },
  async mounted() {
    await productFilter.getPaymentFrequency();
    await productFilter.getWeightContribution();
    await this.getProductList();
    this.handleDetail();
  },
  computed: {
    isReadOnly() {
      if (this.status === "COMPLETE") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>

