<template>
    <div class="product-form">
        <FormUsers v-if="!!detail" form-type="edit" :detail-data="detail" @onValidateSuccess="doSave"
            :on-loading="onProgress" />
    </div>
</template>
<script>
import FormUsers from "./Form.vue";
import referralService from "@/service/referral.service";

export default {
    data() {
        return {
            onProgress: true,
            detail: null,
        };
    },
    components: {
        FormUsers,
    },
    async mounted() {
        await this.loadItems(this.$route.params.id);
        // console.log(this.detail, 'detailData');
    },
    methods: {
        async doSave(payload) {
            // Submit here
            this.onProgress = true;
            let { referral } = payload
            referralService
                .putReferral(this.$route.params.id, referral)
                .then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: "/referral/list" });
                    });
                })
                .catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Notification",
                        text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                    });
                })
                .finally(() => (this.onProgress = false));
        },
        loadItems(id) {
            referralService
                .getReferralById(id)
                .then((rsp) => {
                    this.detail = rsp.data.data
                })
                .catch((e) => {
                    this.$notify({
                        type: "error",
                        title: "Error Notification",
                        text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                    });
                })
                .finally(() => (this.onProgress = false));
        },
    },
};
</script>
  