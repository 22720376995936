import api from "./api";

class CoInsuranceService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }
    
    getCoInsuranceList(serverParams) {
        return this.api.get("product/coInsurance", { params: serverParams });
    }

    postCoInsurance(serverParams) {
        return this.api.post("product/coInsurance", { ...serverParams });
    }

    getCoInsuranceById(serverParams) {
        return this.api.get("product/coInsurance/" + serverParams);
    }

    putCoInsurance(id, serverParams) {
        return this.api.put("product/coInsurance/" + id, { ...serverParams });
    }

    deleteCoInsuranceById(serverParams) {
        return this.api.delete("product/delete/coInsurance/" + serverParams);
    }
}

export default new CoInsuranceService();