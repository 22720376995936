import api from "./api";

class PaymentFrequencyService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }
    
    getPaymentFrequencyList(serverParams) {
        return this.api.get("product/paymentFrequency", { params: serverParams });
    }

    postPaymentFrequency(serverParams) {
        return this.api.post("product/paymentFrequency", { ...serverParams });
    }

    getPaymentFrequencyById(serverParams) {
        return this.api.get("product/paymentFrequency/" + serverParams);
    }

    putPaymentFrequency(id, serverParams) {
        return this.api.put("product/paymentFrequency/" + id, { ...serverParams });
    }

    deletePaymentFrequencyById(serverParams) {
        return this.api.delete("product/delete/paymentFrequency/" + serverParams);
    }
}

export default new PaymentFrequencyService();