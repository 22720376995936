import ProductCategoryService from "../service/productcategory.service";

export default {
    namespaced: true,
    state: {},
    actions: {
        async getListProductCategory(_ctx, serverParams) {
            return ProductCategoryService.getProductCategoryList(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async postProductCategory(_ctx, serverParams) {
            return ProductCategoryService.postProductCategory(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async getProductCategoryById(_ctx, serverParams) {
            return ProductCategoryService.getProductCategoryById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async updateProductCategoryById(_ctx, serverParams) {
            const { id, ...payload } = serverParams
            return ProductCategoryService.putProductCategory(id, serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
        async deleteProductCategoryById(_ctx, serverParams) {
            return ProductCategoryService.deleteProductCategoryById(serverParams).then((result) => {
                return result
            }).catch((err) => {
                return err
            });
        },
    },
};
