import api from "./api";

class ManagePdfService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getManagePdfList(serverParams) {
        return this.api.get("/cms/pdf", { params: serverParams });
    }

    postManagePdf(serverParams) {
        return this.api.post("/cms/pdf", { ...serverParams });
    }

    getManagePdfById(serverParams) {
        return this.api.get("/cms/pdf/" + serverParams);
    }

    putManagePdf(id, serverParams) {
        return this.api.put("/cms/pdf/" + id, { ...serverParams });
    }

    deleteManagePdfById(serverParams) {
        return this.api.delete("/cms/delete/pdf/" + serverParams);
    }
}

export default new ManagePdfService();