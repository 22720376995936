<template>
  <dl class="row">
    <template v-for="item in items">
      <slot :name="item.key" v-bind="item">
        <slot :name="item.key + ':label'" v-bind="item">
          <dt :key="item.key + '-dt'" class="col-md-3">
            <slot :name="item.key + ':label:dt'" v-bind="item">
              {{ item.key | titlecase }}
            </slot>
          </dt>
        </slot>
        <slot :name="item.key + ':value'" v-bind="item">
          <dd :key="item.key + '-dd'" class="col-md-9">
            <slot :name="item.key + ':value:dd'" v-bind="item">
              {{ item.value || "—" }}
            </slot>
          </dd>
        </slot>
      </slot>
    </template>
  </dl>
</template>
<script>
import moment from "moment";

const autoTransformDateKeys = ["created", "updated", "deleted", "activityTime"];
const autoTransformMoneyKeys = ["harga"];

export default {
  props: {
    value: { type: Object },
    /** Only show specific fields */
    show: { type: Array, default: () => [] },
    hide: { type: Array, default: () => [] },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (!this.value) return;
        if (this.items.length) this.items.splice(0);

        Object.keys(this.value).forEach((key) => {
          if (this.show.indexOf(key) < 0) {
            if (this.show.length > 0 || this.hide.indexOf(key) >= 0) return;
            // Autohide key with underscore prefix
            if (key[0] == "_") return;
          }

          let value = this.value[key];
          if (autoTransformDateKeys.indexOf(key) >= 0) {
            value = this.tanggal(value);
          } else if (autoTransformMoneyKeys.indexOf(key) >= 0) {
            value = this.rp(value);
          }

          if (value && value != "—") {
            this.items.push({ key, value });
          }
        });
        this.items.sort((a, b) => {
          if (a.key < b.key) {
            return -1;
          }
          if (a.key > b.key) {
            return 1;
          }
          return 0;
        });
      },
    },
  },
  methods: {
    rp(value, prefix = "Rp. ") {
      if (value === 0) return `${prefix}0`;
      else if (!value) return "-";
      else return prefix + parseFloat(value).toLocaleString();
    },
    tanggal(value) {
      return value ? moment(value).format("DD/MM/YYYY HH:mm:ss.SSSZ") : '-';
    },
  },
  filters: {
    titlecase(value) {
      const result = value
        .replace(/([A-Z]+)/g, " $1")
        .replace(/(_+[a-z])/g, (r) => " " + r[r.length - 1].toUpperCase());
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
  },
};
</script>