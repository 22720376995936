import api from "./api";

class ReferralService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web/cms/')
        this.apim = api.create('web/cms/')
    }

    getReferralList(serverParams) {
        return this.api.get("/referral-code", { params: serverParams });
    }

    postReferral(serverParams) {
        return this.api.post("/referral-code", { ...serverParams });
    }

    getReferralById(serverParams) {
        return this.api.get("/referral-code/" + serverParams);
    }

    putReferral(id, serverParams) {
        return this.api.put("/referral-code/" + id, { ...serverParams });
    }

    deleteReferralById(serverParams) {
        return this.api.delete("/delete/referral-code/" + serverParams);
    }

    postImportReferral(serverParams) {
        return this.apim.post("/referral-code/import", serverParams);
    }

    deleteReferralBulk(serverParams) {
        return this.api.delete("/delete-bulk/referral-code?" + serverParams);
    }

    getReferralExport(serverParams) {
        return this.api.get("/referral-code/export/xlsx");
    }

    getReferralHistory(serverParams) {
        return this.api.get("/history/referral-code");
    }
}

export default new ReferralService();