import api from "./api";

class BmiService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }
    
    getBmiList(serverParams) {
        return this.api.get("/cms/master-bmi", { params: serverParams });
    }

    postBmi(serverParams) {
        return this.api.post("cms/master-bmi", { ...serverParams });
    }

    getBmiById(serverParams) {
        return this.api.get("/cms/master-bmi/" + serverParams);
    }

    putBmi(id, serverParams) {
        return this.api.put("/cms/master-bmi/" + id, { ...serverParams });
    }

    deleteBmiById(serverParams) {
        return this.api.delete("/cms/delete/master-bmi/" + serverParams);
    }

    updateBmiStatus(id, serverParams) {
        return this.api.put("/cms/update/master-bmi-status/" + id, {}, { params: serverParams });
    }
    
    updateBmiPosition(serverParams) {
        return this.api.put("/cms/reposition/master-bmi", { ...serverParams });
    }
}

export default new BmiService();