import api from "./api";

class AreaService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create('hospital')
  }

  createArea(area) {
    return this.api.post("hospital/area", area).catch((error) => {
      return Promise.reject(error)
    })
  }

  getAreaList(serverParams) {
    return this.api.get("hospital/area", { params: serverParams }).catch((error) => {
      return Promise.reject(error)
    })
  }

  getAreaListPerLevel(serverParams) {
    return this.api.get("hospital/area/level", { params: serverParams }).catch((error) => {
      return Promise.reject(error)
    })
  }

  deleteArea(id) {
    return this.api.delete(`hospital/delete/area/${id}`).catch((error) => {
      return Promise.reject(error)
    })
  }
}

export default new AreaService();