<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Master BMI</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="$can($permissionsAll[95])">
        <router-link to="/sections/bmi/add">
          <a class="btn btn-outline-primary"> Add New Bmi</a>
        </router-link>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body p-0">
        <div class="table-responsive mb-0">
          <table class="table table-form">
            <thead>
              <tr>
                <th scope="col">Age</th>
                <th scope="col">Type</th>
                <th scope="col">Gender</th>
                <th scope="col">BMI</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <draggable v-model="rows" tag="tbody" ghost-class="ghost" :move="checkMove" @start="dragging = true"
              @end="dragging = false">
              <tr v-for="item in rows" :key="item.id" class="bg-white">
                <td>
                  <div>{{ item.ageMin + ' - ' + item.ageMax }}</div>
                </td>
                <td>
                  <div> {{ item.typeBmi }}</div>
                </td>
                <td>
                  <div>{{ item.gender }}</div>
                </td>
                <td>
                  <div>{{ item.bmiMin + ' - ' + item.bmiMax }}</div>
                </td>
                <td>
                  <div class="row g-2 align-items-center justify-content-end">
                    <div class="col-auto" v-if="$can($permissionsAll[96])">
                      <router-link :to="`/sections/bmi/edit/${item.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                        title="Edit">
                        <i class="fas fa-pencil"></i>
                      </router-link>
                    </div>
                    <div class="col-auto" v-if="$can($permissionsAll[97])">
                      <button type="button" @click.prevent="showAlertConfirm(item.id)" class="btn btn-lg text-dark"
                        v-b-tooltip.hover title="Move to Trash">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import BmiService from "../../../service/bmi.service";
import debounce from "lodash.debounce";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      isLoading: false,
      dragging: false,
      rows: [],
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    loadItems() {
      BmiService
        .getBmiList(this.serverParams)
        .then((rsp) => {
          this.rows = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    onDelete(id) {
      BmiService
        .deleteBmiById(id)
        .then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    checkMove: debounce(function (val) {
      const updateSequentials = [];
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        updateSequentials.push({ idSio: row.id, newSequence: i + 1 })
      }
      // console.log("Orders changed", updateSequentials);
      let payload = {
        list: updateSequentials
      }
      this.reposisiSio(payload)
    }, 1000),
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.onDelete(id);
          }
        });
    },

    reposisiSio(payload) {
      SioService
        .sioReposisi(payload)
        .then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Reposisi Success",
          });
          this.loadItems()
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    }
  },
  mounted() {
    this.loadItems();
  },
};
</script>
