<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionNine"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Tanya Jawab Tentang Amore Health"
        v-model="value.sectionTitle"
      />
    </div>
    <div class="mb-15px">
      <label class="form-label">CTA Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Cek FAQ selengkapnya"
        v-model="value.ctaTitle"
      />
    </div>
    <label class="form-label">CTA Link Page</label>
    <input
      type="url"
      class="form-control"
      placeholder="https://"
      v-model="value.ctaLinkPage"
    />
  </panel>
</template>

<script>
export default {
  name: "FAQ",
  title: "FAQ Section",
  props: ["value"],
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>