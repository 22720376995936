var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"form-label col-form-label col-auto"},[_vm._v("Date range")]),_c('div',{staticClass:"form-group col-auto"},[_c('date-range-picker',{staticClass:"btn btn-white",model:{value:(_vm.filters.activityTime),callback:function ($$v) {_vm.$set(_vm.filters, "activityTime", $$v)},expression:"filters.activityTime"}})],1),_vm._m(1),_c('div',{staticClass:"col-auto"},[_c('b-dropdown',{attrs:{"variant":"outline-secondary"}},[_c('template',{slot:"button-content"},[_vm._v(" Download "),_c('i',{staticClass:"fa fa-chevron-down"})]),_c('a',{staticClass:"dropdown-item",attrs:{"href":_vm.download.csv,"target":"_BLANK"}},[_vm._v("CSV")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":_vm.download.excel,"target":"_BLANK"}},[_vm._v("Excel")])],2)],1)])])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: true,
      dropdownAllowAll: false,
    },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
      enabled: false,
      initialSortBy: { field: 'activityTime', type: 'desc' },
    },"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
    var column = ref.column;
    var updateFilters = ref.updateFilters;
return [(
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'username'
        )?_c('input',{staticClass:"form-control",attrs:{"placeholder":"Search username"},on:{"input":function ($event) { return updateFilters(column, $event.target.value); }}}):_vm._e(),(
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'action'
        )?_c('select',{staticClass:"form-select",on:{"input":function ($event) { return updateFilters(column, $event.target.value); }}},[_c('option',{attrs:{"value":""}},[_vm._v("All")]),_c('option',[_vm._v("Login")]),_c('option',[_vm._v("Logout")]),_c('option',[_vm._v("Get")]),_c('option',[_vm._v("Change")]),_c('option',[_vm._v("Create")]),_c('option',[_vm._v("Update")]),_c('option',[_vm._v("Delete")]),_c('option',[_vm._v("List")]),_c('option',[_vm._v("History")]),_c('option',[_vm._v("Notification")]),_c('option',[_vm._v("Send")]),_c('option',[_vm._v("Refresh")])]):_vm._e(),(
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'actionType'
        )?_c('select',{staticClass:"form-select",on:{"input":function ($event) { return updateFilters(column, $event.target.value); }}},[_c('option',{attrs:{"value":""}},[_vm._v("All")]),_c('option',[_vm._v("Auth")]),_c('option',[_vm._v("Page")]),_c('option',[_vm._v("Data")]),_c('option',[_vm._v("Download")]),_c('option',[_vm._v("Email")]),_c('option',[_vm._v("Import")]),_c('option',[_vm._v("Service")]),_c('option',[_vm._v("SMS")])]):_vm._e(),(
          column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'information'
        )?_c('input',{staticClass:"form-control",attrs:{"placeholder":"Search info"},on:{"input":function ($event) { return updateFilters(column, $event.target.value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === '_action')?_c('span',[_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Show"},on:{"click":function($event){return _vm.showDetail(props.row)}}},[_c('i',{staticClass:"fa-solid fa-eye"})])])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{ref:"detailModal",attrs:{"title":"Detail aktivitas","hide-footer":"","size":"lg"}},[_c('description-list',{attrs:{"hide":['id'],"value":_vm.selected},scopedSlots:_vm._u([{key:"details:value:dd",fn:function(ref){
        var value = ref.value;
return [(value[0] == '{')?_c('description-list',{attrs:{"value":JSON.parse(value)}}):_c('pre',{domProps:{"textContent":_vm._s(value)}})]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("Activity Report")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"button"}},[_vm._v(" Generate ")])])}]

export { render, staticRenderFns }