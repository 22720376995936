<template>
    <panel title="Co-insurance" variant="inverse" body-class="p-0" :is-collapse="true">
        <div class="table-responsive mb-0">
            <table class="table table-form">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Value</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in coInsurance" :key="index">
                        <td>
                            <input type="text" v-model="item.code"
                                :class="{ 'text-muted': !item.isEditable, 'form-control': true }"
                                :readonly="!item.isEditable" />
                            <span class="text-danger" v-if="$v.coInsurance.$each[index].code.$error">Code is
                                required</span>
                        </td>
                        <td>
                            <input type="text" v-model="item.value"
                                :class="{ 'text-muted': !item.isEditable, 'form-control': true }"
                                :readonly="!item.isEditable" />
                            <span class="text-danger" v-if="$v.coInsurance.$each[index].value.$error">Code is
                                required</span>
                        </td>
                        <td>
                            <div v-if="!item.id || item.isEditable" class="row">
                                <div class="col-auto">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="doSave(item)">
                                        <span>Save
                                        </span>
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-primary"
                                        @click.prevent="removeCoInsurance(index, item)">
                                        <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                            <div v-else class="row">
                                <div class="col-auto">
                                    <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Edit"
                                        @click="editCoInsurance(index, item)">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete"
                                        @click="showAlertConfirm(index, item)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button class="btn btn-action-table btn-xs btn-primary rounded-pill" type="button" @click="addCoInsurance()">
            <i class="fas fa-plus"></i>
        </button>
    </panel>
</template>

<script>
import coInsuranceService from "@/service/coinsurance.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            coInsurance: [],
            serverParams: {
                searchField: "",
                searchValue: "",
                sort: "",
                direction: "ASC",
                pageNumber: 0,
                pageSize: 10
            },
        };
    },
    methods: {
        doSave(item) {
            this.$v.coInsurance.$touch();
            if (!this.$v.coInsurance.$invalid) {
                let { isEditable, ...data } = item
                coInsuranceService
                    .postCoInsurance(data)
                    .then((rsp) => {
                        this.$swal({
                            title: "Data berhasil disimpan",
                            showCloseButton: true,
                        }).then(() => {
                            this.loadItems()
                        });
                    })
                    .catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })
            }
        },
        loadItems() {
            coInsuranceService
                .getCoInsuranceList(this.serverParams)
                .then((rsp) => {
                    this.coInsurance = rsp.data?.data
                        ? rsp.data.data.content.map(program => ({ isEditable: false, ...program }))
                        : [];
                })
                .catch((e) => {
                    this.$notify({
                        type: "error",
                        title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
                            }`,
                        text: e.response.data?.responseMessage || "Gagal mengambil data",
                    });
                });
        },
        dateFormat(date) {
            // let arrDate = date.split(' ')
            // let newDate = new Date(arrDate[0])
            // const options = { year: 'numeric', month: 'long', day: 'numeric' };
            // return newDate.toLocaleDateString("id", options);
        },
        addCoInsurance() {
            let lastArray = this.coInsurance[this.coInsurance.length - 1];
            if (lastArray?.id || !lastArray) {
                this.coInsurance.push({
                    code: '',
                    value: 0,
                    isEditable: true
                })
            }
        },
        removeCoInsurance(index, item) {
            if (!item.id) {
                this.coInsurance.splice(index, 1)
            } else if (item.isEditable) {
                this.coInsurance[index].isEditable = false
            } else {
                coInsuranceService
                    .deleteCoInsuranceById(item.id)
                    .then((rsp) => {
                        this.$notify({
                            type: 'succes',
                            title: 'Succes Notification',
                            text: 'Success Delete'
                        });
                        this.loadItems()
                    })
                    .catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })
            }
        },
        editCoInsurance(index, item) {
            let coInsurances = this.coInsurance.find((e) => e.isEditable == true);
            if (coInsurances) {
                let coInsurancesIndex = this.coInsurance.findIndex((e) => e.code == coInsurances.code)
                this.coInsurance[coInsurancesIndex].isEditable = false
            }
            this.coInsurance[index].isEditable = true
        },
        showAlertConfirm(index, item) {
            this.$swal
                .fire({
                    title: "",
                    text: "Apakah Data Akan Di Hapus?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya",
                })
                .then(async (result) => {
                    if (result.value) {
                        await this.removeCoInsurance(index, item);
                    }
                });
        },
    },
    watch: {
    },
    mounted() {
        this.loadItems();
    },

    validations: {
        category: { required },
        categoryParent: { required }
    },
    validations: {
        coInsurance: {
            required,
            minLength: minLength(1),
            $each: {
                code: { required },
                value: { required }
            }
        },
    }
};
</script>
