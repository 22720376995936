import api from "./api";

class RedeemService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }
    
    getRedeemById(id) {
        return this.api.get("/cms/redeem/" + id);
    }

    putRedeem(id, serverParams) {
        return this.api.put("/cms/redeem/" + id, { ...serverParams });
    }

    getRedeemList(serverParams) {
        return this.api.get("/cms/redeem", { params: serverParams });
    }

    postRedeem(serverParams) {
        return this.api.post("cms/redeem", { ...serverParams });
    }

    deleteRedeemById(serverParams) {
        return this.api.delete("/cms/delete/redeem/" + serverParams);
    }

    // updateBmiStatus(id, serverParams) {
    //     return this.api.put("/cms/update/redeem-status/" + id, {}, { params: serverParams });
    // }
    
    // updateBmiPosition(serverParams) {
    //     return this.api.put("/cms/reposition/redeem", { ...serverParams });
    // }
}

export default new RedeemService();