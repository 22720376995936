<template>
    <panel title="Payment Frequency / Factor" variant="inverse" body-class="p-0" :is-collapse="true">
        <div class="table-responsive mb-0">
            <table class="table table-form">
                <thead>
                    <tr>
                        <th>Payment Frequency</th>
                        <th>Value</th>
                        <th>Default</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in payments" :key="index">
                        <td>
                            <input type="text" v-model="item.code"
                                :class="{ 'text-muted': !item.isEditable, 'form-control': true }"
                                :readonly="!item.isEditable" />
                            <span class="text-danger" v-if="$v.payments.$each[index].code.$error">Code is
                                required</span>
                        </td>
                        <td>
                            <input type="text" v-model="item.value"
                                :class="{ 'text-muted': !item.isEditable, 'form-control': true }"
                                :readonly="!item.isEditable" />
                            <span class="text-danger" v-if="$v.payments.$each[index].value.$error">Value is
                                required</span>
                        </td>
                        <td>
                            <v-select :options="options" label="label" @input="change(index, item)"
                                v-model="item.defaultData" :disabled="!item.isEditable" />
                            <span class="text-danger" v-if="$v.payments.$each[index].defaultData.$error">Default is
                                required</span>
                        </td>
                        <td>
                            <div v-if="!item.id || item.isEditable" class="row">
                                <div class="col-auto">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="doSave(item)">
                                        <span>Save
                                        </span>
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-primary"
                                        @click.prevent="removePayment(index, item)">
                                        <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                            <div v-else class="row">
                                <div class="col-auto">
                                    <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Edit"
                                        @click="editPayment(index, item)">
                                        <i class=" fas fa-pencil"></i>
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete"
                                        @click="showAlertConfirm(index, item)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <span class="text-danger" v-if="errorMessage">{{ errorMessage }}</span>
        </div>
        <button class="btn btn-action-table btn-xs btn-primary rounded-pill" type="button" @click="addPayment()">
            <i class="fas fa-plus"></i>
        </button>
    </panel>
</template>

<script>
import paymentFrequencyService from "@/service/paymentfrequency.service";
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            payments: [],
            errorMessage: null,
            options: [{ label: 'Ya', value: true }, { label: 'Tidak', value: false }],
            serverParams: {
                searchField: "",
                searchValue: "",
                sort: "",
                direction: "ASC",
                pageNumber: 0,
                pageSize: 10
            },
        };
    },
    methods: {
        doSave(item) {
            this.$v.payments.$touch();
            let checkPaymentsDefault = this.payments.filter((r) => r.defaultData.value == true)
            if (checkPaymentsDefault.length > 1) {
                this.errorMessage = 'Default Yes hanya boleh 1'
            }

            if (!this.$v.payments.$invalid && this.errorMessage === null) {
                let { isEditable, defaultData, ...data } = item
                data = { ...data, defaultSelection: (defaultData.value === true) ? true : false }
                if (isEditable === true) {
                    paymentFrequencyService
                        .putPaymentFrequency(item.id, data)
                        .then((rsp) => {
                            this.$notify({
                                type: 'succes',
                                title: 'Succes Notification',
                                text: 'Success'
                            });
                            this.loadItems()
                        })
                        .catch((e) => {
                            this.$notify({
                                type: "error",
                                title: "Error Message",
                                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                            });
                        })
                } else {
                    paymentFrequencyService
                        .postPaymentFrequency(data)
                        .then((rsp) => {
                            this.$notify({
                                type: 'succes',
                                title: 'Succes Notification',
                                text: 'Success'
                            });
                            this.loadItems()
                        })
                        .catch((e) => {
                            this.$notify({
                                type: "error",
                                title: "Error Message",
                                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                            });
                        })
                }
            }
        },
        loadItems() {
            paymentFrequencyService
                .getPaymentFrequencyList(this.serverParams)
                .then((rsp) => {
                    this.payments = rsp.data?.data
                        ? rsp.data.data.content.map(program => ({
                            isEditable: false,
                            ...program,
                            defaultData: (program.defaultSelection === true) ?
                                { label: 'Ya', value: true } :
                                { label: 'Tidak', value: false }
                        }))
                        : [];
                })
                .catch((e) => {
                    this.$notify({
                        type: "error",
                        title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
                            }`,
                        text: e.response.data?.responseMessage || "Gagal mengambil data",
                    });
                });
        },
        dateFormat(date) {
            // let arrDate = date.split(' ')
            // let newDate = new Date(arrDate[0])
            // const options = { year: 'numeric', month: 'long', day: 'numeric' };
            // return newDate.toLocaleDateString("id", options);
        },
        addPayment() {
            let lastArray = this.payments[this.payments.length - 1];
            if (lastArray?.id || !lastArray) {
                this.payments.push({
                    code: '',
                    value: 0,
                    isEditable: true
                })
            }
        },
        removePayment(index, item) {
            if (!item.id) {
                this.payments.splice(index, 1)
            } else if (item.isEditable) {
                this.payments[index].isEditable = false
            } else {
                paymentFrequencyService
                    .deletePaymentFrequencyById(item.id)
                    .then((rsp) => {
                        this.$notify({
                            type: 'succes',
                            title: 'Succes Notification',
                            text: 'Success Delete'
                        });
                        this.loadItems()
                    })
                    .catch((e) => {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
                        });
                    })
            }
        },
        editPayment(index, item) {
            let payment = this.payments.find((e) => e.isEditable == true);
            if (payment) {
                let paymentIndex = this.payments.findIndex((e) => e.code == payment.code)
                this.payments[paymentIndex].isEditable = false
            }
            this.payments[index].isEditable = true
        },
        showAlertConfirm(index, item) {
            this.$swal
                .fire({
                    title: "",
                    text: "Apakah Data Akan Di Hapus?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya",
                })
                .then(async (result) => {
                    if (result.value) {
                        await this.removePayment(index, item);
                    }
                });
        },
        change: function (index, item) {
        }
    },
    watch: {
    },
    mounted() {
        this.loadItems();
    },
    validations: {
        payments: {
            required,
            minLength: minLength(1),
            $each: {
                code: { required },
                value: { required },
                defaultData: { required }
            }
        },
    }
};
</script>
