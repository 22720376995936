<template>
    <form @submit.prevent="doSave" class="pb-5 mb-4" autocomplete="off">
        <div class="row">
            <div class="col-12">
                <h1 class="page-header">{{ formTitle }} Reward</h1>
            </div>
            <!-- <div class="col-lg-9 col-md-8">
                <div class="mb-15px">
                    <input type="text" class="form-control form-control-lg" placeholder="Title" v-model="reward.title"
                        name="username" autocomplete="off" value="" />
                    <span class="text-danger" v-if="$v.reward.title.$error">Title Reward is required</span>
                </div>
            </div> -->
        </div>
        <div class="panel col-lg-9">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12 mb-15px">
                    <label class="form-label">Product</label>
                        <v-select
                            v-model="reward.productId"
                            :options="data.content"
                            :reduce="(product) => product.id"
                            label="name"
                            :filterable="false"
                            @search="onSearch"
                            @option:selected="selected"
                            @input="inputed"
                            multiple
                        >
                        <div slot="list-footer" class="pagination">
                          <button :disabled="hasPrevPage" @click.prevent="handlePrev()">
                            Prev
                          </button>
                          <button :disabled="hasNextPage" @click.prevent="handleNext()">
                            Next
                          </button>
                        </div>
                      </v-select>
                      <span
                        class="text-danger"
                        v-if="
                          !$v.reward.productId.required &&
                          $v.reward.productId.$error
                        "
                        >product is required</span
                      >
                      </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Reward Code</label>
                        <input type="text" class="form-control" placeholder="Reward Code" v-model="reward.codeReward"
                            name="codeReward" autocomplete="off" value="" />
                        <span class="text-danger" v-if="$v.reward.codeReward.$error">Code is
                            required</span>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Quantities</label>
                        <input type="number" class="form-control" placeholder="Quantities" v-model="reward.quantity"
                            name="quantity" autocomplete="off" value="" />
                        <span class="text-danger" v-if="$v.reward.quantity.$error">Quantity is
                            required</span>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Keterangan</label>
                        <textarea rows="5" class="form-control" placeholder="Keterangan" v-model="reward.description" />
                        <span class="text-danger" v-if="$v.reward.description.$error">Code is
                            required</span>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Expired Date</label>
                        <input type="date" v-model="reward.expiredDate" class="form-control" :min="minExpirationDate"/>
                        <span class="text-danger" v-if="!$v.reward.expiredDate.required && $v.reward.expiredDate.$error">Expired Date required</span>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Status Reward</label>
                        <div class="pt-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="status" id="status1" value="ACTIVE"
                                    v-model="reward.status" />
                                <label class="form-check-label" for="">Active</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="status" id="status2" value="INACTIVE"
                                    v-model="reward.status" />
                                <label class="form-check-label" for="">Inactive</label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="!$v.reward.status.required && $v.reward.status.$error">Status required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Reward Type</label>
                        <div class="pt-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeReward" id="typeReward1" value="CASHBACK"
                                    v-model="reward.typeReward" />
                                <label class="form-check-label" for="">Cashback</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeReward" id="typeReward2" value="DISCOUNT"
                                    v-model="reward.typeReward" />
                                <label class="form-check-label" for="">Discount</label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="!$v.reward.typeReward.required && $v.reward.typeReward.$error">Reward type required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Nominal Type</label>
                        <div class="pt-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeNominal" id="typeNominal1" value="RUPIAH"
                                    v-model="reward.typeNominal" />
                                <label class="form-check-label" for="">Rupiah</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="typeNominal" id="typeNominal2" value="PERCENT"
                                    v-model="reward.typeNominal" />
                                <label class="form-check-label" for="">Percent</label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="!$v.reward.typeNominal.required && $v.reward.typeNominal.$error">Nominal type required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Nominal</label>
                        <input type="number" class="form-control" placeholder="Nominal" v-model="reward.nominal"
                            name="nominal" autocomplete="off" value="" />
                        <span class="text-danger" v-if="$v.reward.nominal.$error">Nominal is
                            required</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-15px">
                        <label class="form-label">Remark</label>
                        <input type="text" class="form-control" placeholder="remark" v-model="reward.remark"
                            name="remark" autocomplete="off" value="" />
                        <span class="text-danger" v-if="$v.reward.remark.$error">Remark is required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-action">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button type="submit" class="btn btn-primary" :class="{ disabled: onLoading }">
                        <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import productService from "@/service/product.service";

const cachedProductsById = new Map();

export default {
    props: {
        formType: {
            type: String,
            default: "add",
        },
        detailData: {
            type: Object,
            default: null,
        },
        onLoading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.getProductData(this.serverParams);
        const today = new Date();
        today.setDate(today.getDate() + 1); // Menambahkan 1 hari ke tanggal hari ini
        this.minExpirationDate = today.toISOString().split('T')[0]; // format tanggal ISO (YYYY-MM-DD)
    },
    data() {
        return {
            last: false,
            first: true,
            empty: false,
            totalPages: 0,
            onSearchProduct: false,
            formInProgress: false,
            reward: {
                productId: null,
                // codeReward: null,
                //quantity: null,
                // expiredDate: null,
                status: null,
                typeReward: null,
                typeNominal: null,
                nominal: null,
                remark: null
            },
            minExpirationDate: '',
            serverParams: {
                searchField: "",
                searchValue: "",
                sort: "",
                direction: "ASC",
                pageNumber: 0,
                pageSize: 15,
                status: 'PUBLISHED'
            },
            productSelect: "",
            data:{
                totalElements: 0,
                content: []
            }
        };
    },
    watch: {
        detailData: {
            immediate: true,
            handler(newVal) {
                this.reward = newVal
                if(newVal.rewardProducts){
                    this.reward.productId = newVal.rewardProducts.map((product) => product.productId)
                }
            },
        }
    },
    computed: {
        formTitle() {
            return this.formType === "add" ? "Add New" : "Edit";
        },
        hasNextPage() {
            // console.log('cekcekcek1 : ', (this.serverParams.pageSize + 1) < this.totalPages)
            // console.log('cekcekcek2 : ', this.totalPages)
            // console.log('cekcekcek3 : ', (this.serverParams.pageSize + 1))
            return this.last
        },
        hasPrevPage() {
            // console.log('this.first: ', this.first)
            return this.first
        },
    },
    methods: {
        selected(selectedOption) {
            // console.log('selectedOption', selectedOption)
            this.productSelect = selectedOption;
        },
        inputed(val) {
            // console.log('inputed', val)
            this.reward.productId = val
            if (val === null) {
                this.productSelect = "";
            }
            this.getProductData()
        },
        doSave() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const payload = {
                    reward: this.reward
                };
                this.$emit("onValidateSuccess", payload);
            }
        },
        handleNext() {
            // return true
            this.onSearchProduct = true;
            this.serverParams.pageNumber += 1;
            this.getProductData(this.serverParams);
            this.onSearchProduct = false;
        },
        handlePrev() {
            // return true
            this.onSearchProduct = true;
            this.serverParams.pageNumber -= 1;
            this.getProductData(this.serverParams);
            this.onSearchProduct = false;
        },
        getProductData() {
            if (!this.onSearchProduct) {
                this.formInProgress = true;
            }
            return productService.getProductList(this.serverParams)
                .then(
                ({data}) => {
                    // console.log('test: ', test)
                    this.first = data.data.first
                    this.last = data.data.last
                    this.empty = data.data.empty
                    this.totalPages = data.data.totalPages
                    const items = data.data.content
                    const total = data.data.totalElements
                    // console.log(totalElements)
                    // console.log(items)
                    items.forEach((product) => {
                        if (!cachedProductsById.has(product.id)) {
                            cachedProductsById.set(product.id, product);
                        }
                    });
                    this.data.totalElements = total;
                    this.data.content.splice(0);
                    this.data.content.push(...items);
                    // const missingProducts = this.reward.rewardProducts?.filter(
                    // console.log('cekcek1: ', this.reward.productId)
                    const missingProducts = this.reward.productId?.filter(
                        (idSelect) => items.findIndex(({ id }) => id == idSelect) < 0
                    );
                    // console.log('cekcek12 ', missingProducts)
                    // console.log('selected: ', selected)
                    // console.log('missingProducts: ', missingProducts)
                    // console.log('missingProducts: ', missingProducts)
                    if (missingProducts?.length) {
                        missingProducts.forEach(async (id) => {
                            if (!cachedProductsById.has(id)) {
                            await productService
                                .getProductById(id)
                                .then((product) => {
                                    cachedProductsById.set(id, product);
                                });
                            }
                            this.data.content.push(cachedProductsById.get(id));
                        });
                    }
                },
                (error) => {
                    console.debug(error);
                }
            )
            .finally(() => (this.formInProgress = false));
        },
        onSearch(query, loading) {
            loading((this.onSearchProduct = true));
            if (query.length == 0) {
                if (this.serverParams.name) {
                    this.serverParams.pageNumber = 0;
                    delete this.serverParams.name;
                }
            } else {
                this.serverParams.pageNumber = 0;
                this.serverParams.name = query;
            }
            this.getProductData(this.serverParams).finally(() =>
                loading((this.onSearchProduct = false))
            );
        },
    //     loadItems() {
    //         this.serverParams.status = 'PUBLISHED'
    //         // console.log(this.serverParams)
    //         productService
    //             .getProductList(this.serverParams)
    //             .then(async (rsp) => {
    //             let arrData = rsp.data?.data ?
    //                 rsp.data.data.content : [];
    //             for (const [k, v] of Object.entries(arrData)) {
    //                 if ((typeof v.idProductCategory) == 'string') {
    //                 arrData[k].productCategory = await this.loadPerParentCategory(v);
    //                 }
    //             }
    //             this.rows = arrData
    //             this.totalRecords = rsp.data.data.totalElements
    //             })
    //             .catch((e) => {
    //             // console.log(e)
    //             this.$notify({
    //                 type: "error",
    //                 title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
    //                 }`,
    //                 text: e.response.data?.responseMessage || "Gagal mengambil data",
    //             });
    //         });
    //     },
    // },
   
    },
    validations: {
        reward: {
            productId: { required },
            // codeReward: { required },
            // quantity: { required },
            // expiredDate: { required },
            status: { required },
            typeReward: { required },
            typeNominal: { required },
            nominal: { required },
            remark: { required }
        }
    },
};
</script>