<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionFive"
  >
    <div class="mb-15px">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        placeholder="Kalkulator Premi"
        v-model="value.sectionTitle"
      />
    </div>

    <div class="mb-15px">
      <label class="form-label">Sub Heading</label>
      <input
        type="text"
        class="form-control"
        placeholder="Sub Heading"
        v-model="value.sectionSubtitle"
      />
    </div>
  </panel>
</template>

<script>
export default {
  name: "KALKULATOR",
  title: "Kalkulator Premi",
  props: ["value"],
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>