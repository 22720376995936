import _ from "lodash";
class HospitalFilter {
  singleGroupHospital = [];
  multipleGroupHospital = [];
  finalGroupSortir = [];

  restructureHospital(hospitals) {
    this.multipleGroupHospital = [];
    this.singleGroupHospital = [];
    hospitals.forEach((hospital) => {
      let sparatedGroupId = hospital.groupIds.split("\n");
      if (sparatedGroupId.length === 1) {
        this.singleGroupHospital.push(hospital);
      } else if (sparatedGroupId.length > 1) {
        this.multipleGroupHospital.push(hospital);
      }
    });

    return this.restructureMultiGroup(this.multipleGroupHospital);
  }
  restructureMultiGroup(hospitals) {
    let result = [];
    hospitals.forEach((hospital) => {
      let sparatedGroupId = hospital.groupIds.split("\n");
      let sparatedGroupCodes = hospital.groupCodes.split("\n");
      let sparatedGroupNames = hospital.groupNames.split("\n");

      for (let i = 0; i < sparatedGroupId.length; i++) {
        result.push({
          name: hospital.name,
          id: hospital.id,
          number: hospital.number,
          multiplier: hospital.multiplier,
          latitude: hospital.latitude,
          longitude: hospital.longitude,
          tier: hospital.tier,
          groupIds: sparatedGroupId[i],
          alamat: hospital.alamat,
          groupCodes: sparatedGroupCodes[i],
          groupNames: sparatedGroupNames[i],
        });
      }
    });
    let grouped = _.groupBy(
      this.singleGroupHospital.concat(result),
      (item) =>
        `${item.groupIds}+${item.groupCodes}+${item.groupNames}+${item.tier}`
    );
    return grouped;
  }
}

export default new HospitalFilter();
