<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">{{ formTitle }} FAQ</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="FAQ Title"
            v-model="faq.title"
          />
          <span class="text-danger" v-if="$v.faq.title.$error"
            >Title is required</span
          >
        </div>
        <div class="panel">
          <div class="panel-body">
            <label class="form-label"> FAQ category </label>
            <div class="row align-items-center">
              <div class="col-md-6">
                <v-select
                  v-model="faq.category"
                  :options="optionsCategory"
                  label="name"
                ></v-select>
                <span class="text-danger" v-if="$v.faq.category.$error"
                  >Category is required</span
                >
              </div>
              <div class="col-md-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="showOnHomepage"
                    name="showOnHomepage"
                    v-model="faq.showOnHomepage"
                  />
                  <label class="form-check-label" for="showOnHomepage">
                    Show on homepage
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel">
          <div class="panel-body p-0">
            <vue-editor
              v-model="faq.description"
              :editor-toolbar="customToolbar"
            />
            <span class="text-danger" v-if="$v.faq.description.$error"
              >Description is required</span
            >
          </div>
        </div>
      </div>
      <div v-if="formType === 'edit'" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ faq.publishDate | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ faq.lastModified | getDate }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ faq.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="saveDraft"
          >
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Publish</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import { required, minLength, between } from "vuelidate/lib/validators";
import faqCategoryService from "@/service/faqcategory.service";
import dayjs from "dayjs";

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
  },
  filters: {
    getDate(date) {
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    this.loadItems();
    return {
      optionsCategory: this.optionsCategory,
      customToolbar: defaultToolbarEditor,
      faq: {
        title: detail ? detail.title : null,
        category: detail ? detail.category : null,
        description: detail ? detail.description : null,
        showOnHomepage: detail ? detail.showOnHomepage : null,
        recordStatus: detail ? detail.recordStatus : null,
        publishDate: detail ? detail.created : null,
        lastModified: detail ? detail.updated : null,
        author: detail ? detail.createdBy : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  methods: {
    doSave() {
      this.faq.recordStatus = this.faq.recordStatus ?? "PUBLISHED";
      this.$v.faq.$touch();
      if (!this.$v.faq.$invalid) {
        const payload = {
          faq: this.faq,
        };
        this.$emit("onValidateSuccess", payload);
      }
    },
    saveDraft() {
      this.faq.recordStatus = "DRAFTS";
      this.doSave();
    },
    loadItems() {
      let paramsearch = {
        searchField: "",
        searchValue: "",
        sort: "created",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 100,
      };
      faqCategoryService
        .getFaqCategoryList(paramsearch)
        .then((rsp) => {
          this.optionsCategory = rsp.data?.data ? rsp.data.data.content : [];
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },

    dateFormat(date) {
      if (date) {
        let arrDate = date.split(" ");
        let newDate = new Date(arrDate[0]);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return newDate.toLocaleDateString("id", options);
      } else {
        return "";
      }
    },
  },
  watch: {
    detailData(newVal) {
      // console.log(newVal, 'test')
      this.faq = newVal;
    },
  },

  validations: {
    faq: {
      title: { required },
      category: { required },
      description: { required },
    },
  },
};
</script>
