var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_vm._m(0),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"row"},[(_vm.$can(_vm.$permissionsAll[70]))?_c('div',{staticClass:"col-auto"},[_c('router-link',{attrs:{"to":"/redeem/add"}},[_c('a',{staticClass:"btn btn-outline-primary"},[_vm._v(" Add Redeem")])])],1):_vm._e()])])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
                        enabled: true,
                        dropdownAllowAll: false,
                    },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
                        enabled: false,
                        initialSortBy: { field: 'created', type: 'desc' },
                    },"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"selected-row-actions",fn:function(props){return [(_vm.$can(_vm.$permissionsAll[72]))?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm('multiTrash')}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]}},{key:"column-filter",fn:function(ref){
                    var column = ref.column;
                    var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
                        column.filterOptions.customFilter &&
                        column.field === 'status'
                        )?_c('select',{ref:"status",staticClass:"form-select",attrs:{"placeholder":"Filter Status"},on:{"change":function (value) { return updateFilters(column, _vm.$refs['status'].value); }}},[_c('option'),_vm._l((_vm.optionsStatus),function(option,key){return _c('option',{key:key,domProps:{"value":option.value,"textContent":_vm._s(option.label)}})})],2):_vm._e(),(column.filterOptions &&
                        column.filterOptions.customFilter &&
                        column.field === 'typeRedeem'
                        )?_c('select',{ref:"typeRedeem",staticClass:"form-select",attrs:{"placeholder":"Filter Type Redeem"},on:{"change":function (value) { return updateFilters(column, _vm.$refs['typeRedeem'].value); }}},[_c('option'),_vm._l((_vm.optionsTypeRedeem),function(option,key){return _c('option',{key:key,domProps:{"value":option.value,"textContent":_vm._s(option.label)}})})],2):_vm._e(),(column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'codeRedeem'
                )?_c('input',{ref:"codeRedeem",staticClass:"form-control",attrs:{"id":"custom-input-placeholder","placeholder":"Code Redeem"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.codeRedeem.value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.$can(_vm.$permissionsAll[71]))?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/redeem/edit/" + (props.row.id)),"title":"Edit"}},[_c('i',{staticClass:"fas fa-pencil"})])],1):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("All Redeem")])])}]

export { render, staticRenderFns }