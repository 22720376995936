<template>
  <panel
    :title="title"
    variant="inverse"
    :is-collapse="true"
    :XXcheckbox-hidden="true"
    XXv-model="value.isShow"
    checkbox-name="showHomepageSectionEight"
  >
    <label class="form-label">Title</label>
    <input
      type="text"
      class="form-control"
      placeholder="Berita dan Artikel Terbaru"
      v-model="value.sectionTitle"
    />
  </panel>
</template>
<script>
export default {
  name: "ARTICLE",
  title: "Post Section",
  props: ["value"],
  computed: {
    title() {
      return `Section ${this.value?.sequence} - ${this.$options.title}`;
    },
  },
};
</script>