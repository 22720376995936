import api from "./api";

class PostService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('web')
    }

    getArticleList(serverParams) {
        return this.api.get("/cms/article", { params: serverParams });
    }

    postArticle(serverParams) {
        return this.api.post("/cms/article", { ...serverParams });
    }

    getArticleById(serverParams) {
        return this.api.get("/cms/article/" + serverParams);
    }

    putArticle(id, serverParams) {
        return this.api.put("/cms/article/" + id, { ...serverParams });
    }

    deleteArticleById(serverParams) {
        return this.api.delete("/cms/delete/article/" + serverParams);
    }

    updateArticleByField(id, serverParams) {
        return this.api.put("/cms/update/article-status/" + id, {}, { params: serverParams });
    }

    duplicateArticle(id){
        return this.api.post(`/cms/article/duplicate?id=${id}`);
    }

    changeArticleStatus(id, status) {
        return this.api.put(`/cms/update/article-status/${id}?status=${status}`)
    }

    //category
    /* getArticleCategoryList(serverParams) {
        return this.api.get("/cms/article/category", { params: serverParams });
    }

    postArticleCategory(serverParams) {
        return this.api.post("/cms/article/category", { ...serverParams });
    }

    getArticleCategoryById(serverParams) {
        return this.api.get("/cms/article/category/" + serverParams);
    }

    putArticleCategory(id, serverParams) {
        return this.api.put("/cms/article/category/" + id, { ...serverParams });
    }

    deleteArticleCategoryById(serverParams) {
        return this.api.delete("/cms/delete/article/category/" + serverParams);
    } */
}

export default new PostService();