<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All PDFs</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="$can($permissionsAll[61])">
        <!-- <router-link to="#"> -->
        <input id="fileUpload" accept="application/pdf" ref="fileUpload" type="file" @change="postPdf" hidden>
        <button class="btn btn-outline-primary" @click="chooseFiles">Upload New PDF</button>
        <!-- </router-link> -->
      </div>
    </div>

    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'pdfTitle', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="pdfTitle" class="form-control" placeholder="Search PDFs" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'pdfTitle'
          " @input="(value) => updateFilters(column, $refs.pdfTitle.value)" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <!-- <div class="col-auto">
              <button type="button" @click.prevent="onNewTab(props.row.pdfFileUrl)" class="btn btn-lg text-dark"
                v-b-tooltip.hover title="Show / Hide">
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move">
                <i class="fa-solid fa-arrows-up-down"></i>
              </button>
            </div> -->
            <div class="col-auto">
              <button type="button" @click.prevent="onCopy(props.row.pdfFile)" class="btn btn-lg text-dark"
                v-b-tooltip.hover title="Copy Link">
                <i class="fa-solid fa-copy"></i>
              </button>
            </div>
            <div class="col-auto" v-if="$can($permissionsAll[62])">
              <button type="button" @click.prevent="onDelete(props.row.id)" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Move to Trash">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import base64 from '../../Helper/base64'
import ManagePdfService from "../../../service/managepdf.service";
export default {
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "PDF Title",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ pageNumber: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageNumber: 0 });
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: 'name',
        direction: params[0].type.toUpperCase(),
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams({
        searchField: 'name',
        searchValue: params.columnFilters.name,
      });
      this.loadItems();
    },
    loadItems() {
      ManagePdfService
        .getManagePdfList(this.serverParams)
        .then((rsp) => {
          this.rows = rsp.data.data ?
            rsp.data.data.content : [];
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
    chooseFiles() {
      const elem = this.$refs.fileUpload
      elem.click()
    },
    async postPdf(e) {
      let payload = {
        name: e.target.files[0].name,
        file: await base64(e.target.files[0])
      }

      ManagePdfService
        .postManagePdf(payload)
        .then((rsp) => {
          this.$notify({
            type: 'succes',
            title: 'Succes Notification',
            text: 'Success Add'
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        })
    },
    onDelete(id) {
      ManagePdfService
        .deleteManagePdfById(id)
        .then((rsp) => {
          this.$notify({
            type: "succes",
            title: "Success message",
            text: "Delete Success",
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
          this.loadItems();
        });
    },
    onNewTab(pdfFileUrl) {
      window.open(pdfFileUrl, '_blank', 'noreferrer');
    },
    onCopy(file) {
      navigator.clipboard.writeText(process.env.VUE_APP_FRONTEND + '/files/view/' + file);
      this.$notify({
        type: "succes",
        title: "Success message",
        text: "Succes Copy",
      });
    }
  },
  mounted() {
    this.loadItems();
  },
};
</script>
