import { store } from "../stores";
import Permissions from "@/config/Permissions";
/**
 * @param {Number | (Number[])} permssions 
 * @returns {boolean}
 */
function checkPermission(permssions) {

  if (!store) {
    console.warn('Calling "can" but $store not ready')
    return false
  }
  if (!store.state.auth.user) {
    console.warn('Calling "can" but not logged in')
    return false
  }

  if (store.state.auth.user.permissions.includes(999)) {
    return true;
  }
  
  if (!store.state.auth.permissions) {
    console.warn('Calling "can" but no permission')
    return false
  }


  if (Array.isArray(permssions)) {
    return !!permssions.find(checkPermission.bind(this))
  } else {
    return -1 < store.state.auth.permissions.findIndex((r) => r.name == permssions)
  }
}

window['$can'] = checkPermission

const Guard = {
  /**
   * @type {import("vue").PluginFunction<any>}
  */
  install(vue) {
    vue.prototype.$can = checkPermission
    vue.prototype.$permissionsAll = Permissions
  }
};

export default Guard

