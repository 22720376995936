<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Banner</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="$can($permissionsAll[50])">
        <router-link to="/sections/banner/add">
          <a class="btn btn-outline-primary"> Add New Banner</a>
        </router-link>
      </div>
    </div>
    <ul class="nav nav-tabs nav-tabs--wp">
      <li class="nav-item">
        <router-link to="/sections/banner" class="nav-link" active-class="active" exact>
          Published <span class="badge">{{ totalRecords.PUBLISHED }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/sections/banner/drafts" class="nav-link" active-class="active" exact>
          Drafts <span class="badge">{{ totalRecords.DRAFTS }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/sections/banner/trashed" class="nav-link" active-class="active" exact>
          Trashed <span class="badge">{{ totalRecords.TRASHED }}</span>
        </router-link>
      </li>
    </ul>
    <router-view @reloadData="reloadData"></router-view>
  </div>
</template>
<script>
import bannerService from "@/service/banner.service";

export default {
  data() {
    return {
      totalRecords: {
        PUBLISHED: 0,
        DRAFTS: 0,
        TRASHED: 0
      },
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10,
        status: []
      },
      reload: false
    };
  },
  watch: {
  },
  methods: {
    loadItems(type) {
      this.serverParams.status = type;
      bannerService
        .getBannerList(this.serverParams)
        .then((rsp) => {
          this.totalRecords[type] = (rsp.data?.data) ?
            rsp.data?.data?.totalElements : 0
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: `Terjadi Kesalahan: ${e.response.data?.responseCode || "00"
              }`,
            text: e.response.data?.responseMessage || "Gagal mengambil data",
          });
        });
    },
    reloadData(e) {
      this.loadItems(e)
    }
  },
  mounted() {
    this.loadItems('PUBLISHED');
    this.loadItems('DRAFTS');
    this.loadItems('TRASHED');
  },
};
</script>